import * as firebase from "firebase/app";
import axios from 'axios';
import { getFeedPosts, getPetitions, getPoliProfile} from '../actions';
import _ from 'lodash';
import config from '../../config';
const { allyus_url } = config;


export const setEditPost = (post) => (dispatch) => {
    const editingPost = post;
    var petition = editingPost && editingPost.has_petition ? editingPost.petition_id : '';
    if (_.isArray(petition)){
      petition = petition[0];
    }
    var postType = editingPost.post_type === 1 ? 'poll' : editingPost.post_type === 2 ? 'news' :
        editingPost.post_type === 3 ? 'talk' : '';  
    let petitionReceiverName = '';
    let petitionRecieverId = '';
    if (petition && petition.receiver_id){
        if (petition.receiver_id._id){
            petitionReceiverName = petition.receiver_id.first_name + " " + petition.receiver_id.last_name;
            petitionRecieverId = petition.receiver_id._id;
        } else{
            petitionRecieverId = petition.receiver_id;
        }
    }   
    var payload = {
            selectedTags: editingPost ? editingPost.tags : [],

            createPoll:  editingPost.post_type === 1,
            createPetition: petition ? true : false,
            petitionTitle: petition ? petition.title : '',
            petitionText: petition ? petition.pitch :  '',
            petitionReciver: petition && petition.receiver ? petition.receiver : petitionReceiverName,
            petitionRecieverId:  petitionRecieverId,
            petitionReceiverName:  petitionReceiverName,
            petitionStance: petition && petition.vote_side === 'YES' ? 'yes_stance' : 
                petition.vote_side === 'NO' ? 'no_stance' : '',
            petitionImg: editingPost && editingPost.url && postType === 'poll' ? editingPost.url : editingPost && postType === 'poll' && editingPost.image ? editingPost.image : '',
            postVideo: editingPost && postType === 'poll' && editingPost.video ? editingPost.video : '',
            preview_image: editingPost && editingPost.preview_image ? editingPost.preview_image : '',
            preview_url: editingPost && editingPost.url && postType === 'poll' ? editingPost.url : '',
            postText: editingPost ? editingPost.title : '',
            file: '',
            add_image: false,
            featured: editingPost && editingPost.featured ? true : false,
            anon_comments_disabled: editingPost.anon_comments_disabled ? editingPost.anon_comments_disabled : false,
            editMode: true,
            _id: editingPost._id,
    }
    dispatch({
        type: 'TOGGLE_MODAL',
        modal: 'create_post_modal',
    });
    dispatch({
        type: 'SET_EDIT_POST',
        payload: payload
    });
    dispatch({
        type : 'post/setPostType',
        payload: postType
    })

}

export const editPost = (post_id, updatedData, updatedPetition) => async (dispatch) => {
    try {
        const  access_token = await firebase.auth().currentUser.getIdToken(true);
        var post = { updatedData: updatedData };
        if (updatedPetition){
            post.updatedPetition = updatedPetition;
        }
        const edited = await axios.put(`${allyus_url}/posts/${post_id}?access_token=${access_token}`, post);
        dispatch(getFeedPosts());
        dispatch(getPetitions());
    } catch (err) {
        console.log(err);
    }
}

export const deletePost = (post_id) => async (dispatch, getState) => {
    try {
        const  access_token = await firebase.auth().currentUser.getIdToken(true);
         await axios.delete(`${allyus_url}/posts/${post_id}?access_token=${access_token}`);
        dispatch(getFeedPosts());
        dispatch(getPetitions());
        const nameUrl = getState().politicianProfile.profile.nameUrl;
        if (nameUrl){
            dispatch(getPoliProfile(nameUrl))
        }
    } catch (err) {
        console.log(err);
    }
}