import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../config';
const { allyus_url } = config;


// For api call:
// ?type=___ all, posts, or news
// ?trending=___ trending or following or recent
// ?country=__ United States, Canada, etc
// ?region=___ (province or state) Ontario, Florida, etc
// can include ?days=___ for number of days since post created
// ?status=___ open or closed for petitions
   
// TODO lazy loading.
// TODO featured posts    
export const getFeedPosts = () => async (dispatch, getState)=> {
    try {
        const { 
            type,
            trending,
            country,
            region,
            } = getState().feedFilters;

        const featuredPosts = await axios.get(`${allyus_url}/featured`);
        if (firebase.auth().currentUser){
            const  access_token = await firebase.auth().currentUser.getIdToken(true);
            const posts = await axios.get(`${allyus_url}/posts/feed?access_token=${access_token}&type=${type}&trending=${trending}&country=${country}&region=${region}`);
            const petitions = await  axios.get(`${allyus_url}/petitions/feed?access_token=${access_token}&limit=10&trending=${trending}&country=${country}&region=${region}&status=open`);

            dispatch ({
                type: 'FEED_FETCH_SUCCESS',
                payload: {
                    posts: posts.data,
                    petitions: petitions.data,
                    featuredPosts: featuredPosts.data
                }
            });
        } else {
            const posts = await axios.get(`${allyus_url}/posts/feed?type=${type}&trending=${trending}&country=${country}&region=${region}`);
            const petitions = await axios.get(`${allyus_url}/petitions/feed?limit=1&trending=${trending}&country=${country}&region=${region}&status=open`);
            dispatch ({
                type: 'FEED_FETCH_SUCCESS',
                payload: {
                    posts: posts.data,
                    petitions: petitions.data,
                    featuredPosts: featuredPosts.data
                }
            });
        }
    } catch(err) {
        console.log(err)
    }
  }

  export const updateFeedFilter = (filter_name, filter_value) => async (dispatch, getState)=> {
        dispatch ({
            type: 'UPDATE_FEED_FILTER',
            filter_name : filter_name,
            filter_value : filter_value
        });
        dispatch(getFeedPosts());
  }
