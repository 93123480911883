import React from 'react';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, RedditShareButton,} from 'react-share';
import {CopyToClipboard} from 'react-copy-to-clipboard';

export default class Share extends React.Component {
  render() {
    const { closeShareModal , share_url} = this.props;
    var share_text = this.props.share_text;
    share_text = share_text ? share_text : '';
    const url = share_url ? share_url : `${window.location.origin}`;
    return (
      <div className="share">
         <FacebookShareButton url={url}  className="social-links" beforeOnClick={closeShareModal}
          quote={share_text} >
          <span className="social-icon facebook"><i class="fab fa-facebook"></i> </span>
          <span className="social-title">facebook</span>
        </FacebookShareButton>
        <TwitterShareButton url={url}  className="social-links" beforeOnClick={closeShareModal}
        title={share_text}>
          <span className="social-icon twitter"><i class="fab fa-twitter"></i> </span>
          <span className="social-title">twitter</span>
        </TwitterShareButton>
        <WhatsappShareButton url={url}  className="social-links" beforeOnClick={closeShareModal} 
         title={share_text}>
          <span className="social-icon whatsapp"><i class="fab fa-whatsapp"></i>  </span>
          <span className="social-title">whatsapp</span>
        </WhatsappShareButton>
        <RedditShareButton url={url}  className="social-links" beforeOnClick={closeShareModal}
         title={share_text} >
          <span className="social-icon reddit"><i class="fab fa-reddit"></i>  </span>
          <span className="social-title">reddit</span>
        </RedditShareButton>
        <CopyToClipboard 
          className="social-links"
          text={share_text + url}
          onCopy={() => {
            alert("Link has been copied " + share_url);
            if (closeShareModal){
              closeShareModal();
            }
          }}
           >
          <div>
            <span className="social-icon copy"><i class="fas fa-copy"></i></span> 
            <span className="social-title">copy</span>
          </div>
        </CopyToClipboard> 
      </div>
    );
  }
}