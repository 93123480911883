import ACTIONS from './actionTypes'; 

export const showChatWindow = (item) => {
    return ({
      type: ACTIONS.SHOW_CHAT_WINDOW,
      payload: item
    })
}

export const hideChatWindow = () => {
  return ({
    type : ACTIONS.HIDE_CHAT_WINDOW
  })
}


