import React from 'react';
import MicrolinkCard from '@microlink/react';

const insertTextAtIndices = (text, obj) => {
    return text.replace(/./g, function(character, index) {
      return obj[index] ? obj[index] + character : character;
    });
  };

  export const textTolink = (text) => {
      const urlMatches = text.match(/\b(http|https)?:\/\/\S+/gi) || [];
      urlMatches.forEach(link => {
        const startIndex = text.indexOf(link);
        const endIndex = startIndex + link.length;
        text = insertTextAtIndices(text, {
          [startIndex]: `<a href="${link}" target="_blank" rel="noopener noreferrer" class="embedded-link">`,
          [endIndex]: '</a>',
        });
      });
      
      return text;
  }

  export const textTolinkPreviewPost = (text) => {
    const urlMatches = text.match(/\b(http|https)?:\/\/\S+/gi) || [];
    urlMatches.forEach(link => {
      const startIndex = text.indexOf(link);
      const endIndex = startIndex + link.length;
      text = insertTextAtIndices(text, {
        [startIndex]: `<a href="${link}" target="_blank" rel="noopener noreferrer" class="embedded-link">`,
        [endIndex]: '</a>',
      });
    });
    const LinkPreviews = urlMatches.map(link => <MicrolinkCard url={link} size='large' className="post-media" style={{maxWidth: '100%',  objectFit:'cover'}} />);
  //   console.log(LinkPreviews)
    return LinkPreviews;
}