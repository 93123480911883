import React, { PureComponent, PropTypes } from 'react';
import {
  PieChart, Pie, Tooltip, Legend
} from 'recharts';


const CustomTooltip  = props =>{
    const { active } = props;
    if (active) {
      const { payload, label, total } = props;
      var percent = Math.round((payload[0].value/total * 100)* 10)/10;
      return (
        <div className="custom-tooltip">
          <p className="label" style={{fontWeight:'bold', color:'black', fontSize:16,}} >{`${payload[0].name} : ${percent}%`}</p>
          
        </div>
      );
    }
    return null;
};

const getLevelName = (age_group) => {
  switch (age_group) {
      case 18:
          return '18-24';
      case 25:
          return '25-31';
      case 31:
          return '31-39';
      case 40:
          return '40-49';
      case 50:
          return '50-59';
      case 60:
          return '60+';
      default: 
          return '60+';
  }
}
const yes_colours = ['#b8e986', '#9cc671', '#7a9f56', '#56713b', '#3c4f29', '#2b381d'];
const no_colours = ['#ff1431', '#db0b24', '#ad091d', '#66020d', '#470108'];
const neutral_colours = ['#5c9af7', '#2a7bf5', '#0d5dd4', '#094bad', '#053887', '#02204f'];

export default class DemoPieChart extends PureComponent {


  render() {
    const { upvotes, downvotes, neutralvotes, type, post: {post_type}, demographics } = this.props;

    var upvotes_data = demographics.upvotes[type];
    var downvotes_data = demographics.downvotes[type];
    var neutral_data = demographics.neutral[type];
    var total = 0;

    var data01 = [];
    var data02 = [];
    var data03 = [];
    
    var upvotes_total = 0;
    var downvotes_total = 0;
    var neutral_total = 0;

    for (var u in upvotes_data){
        var color = yes_colours[u % 6];
        var count = upvotes_data[u].count;
        var name = type === 'age_group' ? getLevelName(upvotes_data[u]._id) : upvotes_data[u]._id;
        
        data01.push({name: name, value: count, fill: color });
        total += upvotes_data[u].count;
        upvotes_total += upvotes_data[u].count;
    }
    for (var d in downvotes_data){
        var color = no_colours[d % 6]; 
        var count = downvotes_data[d].count;
        var name = type === 'age_group' ? getLevelName(downvotes_data[d]._id) : downvotes_data[d]._id;
        
        data02.push({name: name, value: count, fill: color  });
        total +=  downvotes_data[d].count;
        downvotes_total +=  downvotes_data[d].count;
    }
    for (var n in neutral_data){
        var color = neutral_colours[n % 6];
        var count = neutral_data[n].count;
        var name = type === 'age_group' ? getLevelName(neutral_data[n]._id) : neutral_data[n]._id;
        
        data03.push({name: name, value: count,  fill: color  });
        total += neutral_data[n].count;
        neutral_total += neutral_data[n].count;
    }

    
    return (
      <div style={{display:'flex', justifyContent: 'center',  textAlign:'center', flexWrap:'wrap' }}>
        {
          !data01.length ? null :
        <div style={{margin:'4% 5% 0 5%'}}>
          <PieChart width={250} height={data01.length > 4 ? 320 : 275}>
            <Pie
              data={data01}
              cx={125}
              cy={100}
              labelLine={false}
              // label={renderCustomizedLabel}
              outerRadius={90}
              innerRadius={50}
              fill="#8884d8"
              dataKey="value"
          />
            <Tooltip content={<CustomTooltip total={upvotes_total}/>}/>
            <Legend />
          </PieChart>
          <h4 style={{marginTop:'2%'}}><span className="">{post_type === 2 ? "Accurate" : "Agree"}</span></h4>
        </div>
      }
      {
        !data02.length ? null :
      <div style={{margin:'4% 5% 0 5%'}}>
        <PieChart width={250} height={data01.length > 4 ? 320 : 275}>
          <Pie
            data={data02}
            cx={125}
            cy={100}
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={90}
            innerRadius={50}
            fill="#8884d8"
            dataKey="value"
         />
          <Tooltip content={<CustomTooltip total={downvotes_total}/>}/>
          <Legend />
        </PieChart>
        <h4 style={{marginTop:'2%'}}><span className="">{post_type === 2 ? "Refute" : "Disagree"}</span></h4>
      </div>
      }
      {
       !data03.length ? null : 
      <div style={{margin:'4% 5% 0 5%'}}>
        <PieChart width={250} height={data01.length > 4 ? 320 : 275}>
          <Pie
            data={data03}
            cx={125}
            cy={100}
            labelLine={false}
            // label={renderCustomizedLabel}
            outerRadius={90}
            innerRadius={50}
            fill="#8884d8"
            dataKey="value"
         />
          <Tooltip content={<CustomTooltip total={neutral_total}/>}/>
          <Legend />
        </PieChart>
        <h4 style={{marginTop:'2%'}}><span className="">Other</span></h4>

      </div>
      }
    </div>
    );
  }
}
