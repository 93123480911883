import React from 'react';
import { Link } from 'react-router-dom';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';

import { connect } from 'react-redux';
import { openShareModal, toggleSignInModal, toggleEditPostModal, deletePost, editPost, setEditPost} from "../../../store/actions";
import DeleteModal from '../../deleteModal';

import {textTolink, textTolinkPreviewPost} from '../../../functions/textTolink';

import * as firebase from "firebase/app";
import axios from "axios";

import ReactGA from 'react-ga';

import config from "../../../config";
const { allyus_url } = config;


class PostHead extends React.Component {
  state = { deletePostModal: false };

  async hidePost() {
    if (firebase.auth().currentUser){
      try {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        if (this.props.postType === 'petition'){
          await axios.put(`${allyus_url}/petitions/${this.props.petition._id}/hide?access_token=${access_token}`);
        } else {
          await axios.put(`${allyus_url}/posts/${this.props.post_id}/hide?access_token=${access_token}`);
        }
      } catch (err){
        // console.log(err);
      }
    } else {
      this.props.toggleSignInModal();
    }
  }

  async flagPost() {
    if (firebase.auth().currentUser){
      try {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(`${allyus_url}/posts/${this.props.post_id}/flag?access_token=${access_token}`);
      } catch (err){
        // console.log(err);
      }
    } else {
      this.props.toggleSignInModal();
    }
  }

  sharePost() {
    const { post_id, postType, post } = this.props;
    if (["news", "talk", "poll"].includes(postType)) {
      const url = post.shareURL ? post.shareURL : `${window.location.origin}/posts/${post_id}`;
      this.props.openShareModal(url);
    } else if (postType === "petition") {
      const petition_id = this.props.petition._id;
      var petition = this.props.petition;
      const url = petition.shareURL ? petition.shareURL : `${window.location.origin}/petitions/${petition_id}`;
      this.props.openShareModal(url);    
    }
  }

  onClickNationCrowd() {
    const {postType, post_id, post, petition } = this.props;
    var label = 'NC Link From: ' + (postType === "petition" ? `petitions/${petition._id}` : `posts/${post_id}`) + " " + post.title;
    var action = 'Click NC Link: ' + (post.nc_link ? post.nc_link : "https://causefund.nationcrowd.com/");
    ReactGA.event({
      category: 'NationCrowd Link',
      action: action,
      label: label
    });
    return true;
  }

  render() {
    const { props } = this;
    const { petition, post_id, post, currentUser } = props;
    const { deletePostModal } = this.state;
    var my_post =  petition && petition.user && ((currentUser._id && currentUser._id === petition.user) || (currentUser._id && currentUser._id === petition.user._id) 
    || (currentUser.uuid && currentUser.uuid === petition.user.uuid));
    my_post = my_post || (currentUser._id && currentUser._id === post.user._id)
    || (currentUser.uuid &&  currentUser.uuid === post.user.uuid);
    my_post = my_post || (currentUser && currentUser.admin);

    let renderHeadByType;
    let renderTags;
    let renderSupports;
    let receiver = '';
    if(petition){
      if (petition.receiver) {
        receiver = {
          full_name: petition.receiver,
          nameUrl: ''
        }
      }
      else if (petition.receiver_id){
        receiver = Object.assign({}, petition.receiver_id);
        receiver.full_name = receiver.first_name + " " + receiver.last_name;
      }
    }
    if ((props.postType === "poll" || props.postType === "petition")  && props.hasPetition && petition) {
      renderHeadByType = 
        <div className="allyus-menu">
          <UncontrolledDropdown>
            <DropdownToggle>
              <img src="/icons/new/petition-icon.svg" alt="poll" /> &nbsp;
                 <span className="allyus-card-summery">see petition pitch</span>
            </DropdownToggle>
            <DropdownMenu >
              <DropdownItem>
                <p className="allyus-text text-center">
                  <span> Petition to </span>&nbsp;
                  <Link to={receiver && receiver.nameUrl ? `/politicians/${receiver.nameUrl}` : '#'} className="allyus-text-primary"> { receiver && receiver.full_name} </Link>,&nbsp;
                  <span>where too</span>
                </p>
              </DropdownItem>
              <DropdownItem>
                <p className="allyus-text-bold text-center"> { petition.title } </p>
              </DropdownItem>
              <DropdownItem>
                <p className="text-overflow-wrap ">
                  {petition.pitch}
                </p>
                <p >
                  <Link to={`/petitions/${petition._id}`} className="allyus-text-primary">see petition &nbsp; <i className="fa fa-angle-right"></i></Link>
                </p>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>
      renderTags =
        <div className="group">
          <p>
            {
              props.postTags.map((t, i) => {
                return <span  className="tags mr-2" key={i}>{t}</span>
              })
            }
          </p>
          {
          <a href={post.nc_link ? post.nc_link : "https://causefund.nationcrowd.com/"} target="blank" onClick={() => this.onClickNationCrowd()}><img src="/images/nationcrowd.jpg" alt="nationcrowd" style={{ width: 32, height: 32 }} /></a>
          }
        </div>
        if (props.pollType) {
          renderSupports =
            <p className="allyus-text">
              <span className="count">{`${props.supportors}`} </span>
              <span className="label text-muted">Supporters </span>
            </p>
        }
    }

    else if (props.postType === "talk" || props.postType === "poll") {
      renderHeadByType =
        <p>
          {
            props.postTags.map((t, i) => {
              return <span className="tags mr-2" key={i}>{t}</span>
            })
          }
        </p>
      renderTags =
        <div style={{display:'flex', justifyContent:'flex-end', marginBottom:-28}}>
          <a href={post.nc_link ? post.nc_link : "https://causefund.nationcrowd.com/"} target="blank" onClick={() => this.onClickNationCrowd()}><img src="/images/nationcrowd.jpg" alt="nationcrowd" style={{ width: 32, height: 32 }} /></a>
        </div>;
    }
    else if (props.postType === "news") {
      renderHeadByType =
      <p className="allyus-menu">
        <UncontrolledDropdown>
          <DropdownToggle>
              <img src="/icons/old/Fake News Icon.svg" alt="post"/> &nbsp;
              <span className="allyus-card-summery">Fake News?</span>
            </DropdownToggle>
            <DropdownMenu style={{width:100}}>
              <DropdownItem style={{cursor:"default"}}>
                Tell us why?
              </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
      </p>
      renderTags =
        <div className="group">
          <p>
            {
              props.postTags.map((t, i) => {
                return <span className="tags mr-2" key={i}>{t}</span>
              })
            }
          </p>
          {
          <a href={post.nc_link ? post.nc_link : "https://causefund.nationcrowd.com/"} target="blank" onClick={() => this.onClickNationCrowd()}><img src="/images/nationcrowd.jpg" alt="nationcrowd" style={{ width: 32, height: 32 }} /></a>
        }
        </div>
    }

    return (
      <div className={"allyus-post-head" + (props.postType === "talk"  || props.postType === "petition" ? " allyus-post-head-talk" : "")}>
        <div className="group">
          {renderHeadByType}
          {renderSupports}
          <div className="ml-auto allyus-menu">
            <UncontrolledDropdown >
              <DropdownToggle >
                <img src="/icons/new/more-icon.svg" alt="post" />
              </DropdownToggle>
              <DropdownMenu right >
                <DropdownItem  onClick={() => this.sharePost()}>
                  <p><img src="/images/share_icon.svg" alt="poll" style={{width:24, height:22}}/>&nbsp; Share</p>
                </DropdownItem>
                {
                  my_post ? (
                    <div>
                    {props.postType === "petition" && petition && !petition.closed ? 
                    <DropdownItem onClick={() => this.props.closePetition()}>
                      <p><img src="/icons/icons8-send-50.png" alt="submit" style={{width:20}}/>&nbsp; Submit</p>
                    </DropdownItem>
                    : null }
                    <DropdownItem onClick={() => this.props.setEditPost(post)}>
                      <p><i className="far fa-edit"></i>&nbsp; Edit Post</p>
                    </DropdownItem>
                    <DropdownItem onClick={() => this.setState({deletePostModal: true})}>
                      <p><i className="far fa-trash-alt"></i>&nbsp; Delete Post</p>
                    </DropdownItem>
                    </div>
                  ): (
                    <div>
                    <DropdownItem onClick={this.flagPost.bind(this)}>
                      <p><img src="/images/flag_icon.svg" alt="poll" style={{width:24, height:22}} />&nbsp; Flag Post</p>
                    </DropdownItem>
                    <DropdownItem onClick={this.hidePost.bind(this)}>
                      <p><i className="far fa-window-close"/>&nbsp; Hide Post</p>
                    </DropdownItem>
                    </div>
                    )
                }
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </div>
        {renderTags}
        <h4 className="allyus-card-summery" style={(this.props.postType === "talk" || (props.postType === "poll" && !this.props.hasPetition)) ? {marginRight:37} :{marginRight:32}} dangerouslySetInnerHTML={{
                  __html: textTolink(props.postSummery)
                }}>
        </h4>
        {
          props.postType !== 'talk' ? null :
          textTolinkPreviewPost(props.postSummery)
        }
        <DeleteModal
             isOpen={deletePostModal}
             toggle={() => this.setState({deletePostModal: !deletePostModal})}
             deleteFunction={() => this.props.deletePost(this.props.post_id)}
             deleteName="post"
         />
      </div>
    )
  }
}

const mapStateToProps = ( state ) => {
  return {
    modals: state.modals,
    currentUser: state.currentUser
  };
};

export default connect(
  mapStateToProps,
  { openShareModal, toggleSignInModal, toggleEditPostModal, deletePost, editPost, setEditPost }
)(PostHead);
