export const getProfileUrl = (profile) => {
    let profile_link = `/`;
    if (!profile){
        return profile_link;
    } 
    if (profile.__t === 'politician' && profile.nameUrl){
        if (profile.organization){
            profile_link = `/organizations/${profile.nameUrl}`;
        } else{
            profile_link = `/politicians/${profile.nameUrl}`;
        }
    } else if (profile.username && !profile._id) {
        if (profile.influencer){
            profile_link = `/anonymous/influencers/${profile.username}`;
        } else if (profile.lobbyist){
            profile_link = `/anonymous/lobbyists/${profile.username}`;
        } else {
            profile_link = `/anonymous/voters/${profile.username}`;
        }
    } else if (!profile.username && profile._id) {
        if (profile.influencer){
            profile_link = `/influencers/${profile._id}`;
        } else if (profile.lobbyist){
            profile_link = `/lobbyists/${profile._id}`;
        } else {
            profile_link = `/voters/${profile._id}`;
        }
    } else if (profile.username && profile._id && profile.anonymous)  {
        if (profile.influencer){
            profile_link = `/anonymous/influencers/${profile.username}`;
        } else if (profile.lobbyist){
            profile_link = `/anonymous/lobbyists/${profile.username}`;
        } else {
            profile_link = `/anonymous/voters/${profile.username}`;
        }
    } else if (profile.username && profile._id && !profile.anonymous)  {
        if (profile.influencer){
            profile_link = `/influencers/${profile._id}`;
        } else if (profile.lobbyist){
            profile_link = `/lobbyists/${profile._id}`;
        } else {
            profile_link = `/voters/${profile._id}`;
        }
    }
    return profile_link;
};

export const getProfileUrlCurrentUser = (currentUser, anon) => {
    let profile_link = `/`;
    if (!currentUser){
        return profile_link;
    } 
    if (currentUser.__t && currentUser.__t === 'politician'){
        if (currentUser.organization) {
            return `/organizations/${currentUser.nameUrl}`;
        } else {
            return `/politicians/${currentUser.nameUrl}`;
        }
    }
    else if (anon){
        if (currentUser.influencer){
            return `/anonymous/influencers/${currentUser.username}`;
        } else if (currentUser.lobbyist){
            return `/anonymous/lobbyists/${currentUser.username}`;
        }
        return `/anonymous/voters/${currentUser.username}`;
    } else {
        if (currentUser.influencer){
            return `/influencers/${currentUser._id}`;
        } else if (currentUser.lobbyist){
            return `/lobbyists/${currentUser._id}`;
        }
        return `/voters/${currentUser._id}`;
    }
};


export const getUserProfilePic = (user) => {
    if (user && user.username && !user._id){
        if (user.anon_profile_picture){
            return user.anon_profile_picture;
        }
        return "/images/profile_icon_standin.png";
    } else if (user.profile_picture &&  user.profile_picture.includes('bioguide.congress.gov/bioguide/photo')){
        return "/images/temp_anon_icon.svg";
    } else if (user && !user.username && user._id) {
        if (user.profile_picture){
             return user.profile_picture;
        }
        return "/images/temp_anon_icon.svg";
    }
    else if (user && user.anonymous){
        if (user.anon_profile_picture){
            return user.anon_profile_picture;
        }
        return "/images/profile_icon_standin.png";
    }
    else if (user && !user.anonymous && user.profile_picture) {
        return user.profile_picture;
    }
    return "/images/temp_anon_icon.svg";
};

export const getProfileLinkPost = (post) => {
    let profile_link = `/`;
    if (post.user.__t === 'politician' && post.user.nameUrl){
        if (post.user.organization) {
            profile_link = `/organizations/${post.user.nameUrl}`;
        } else {
            profile_link = `/politicians/${post.user.nameUrl}`;
        }
    }
    else if (post.anonymous && post.user.username){
        if (post.user.influencer){
            profile_link = `/anonymous/influencers/${post.user.username}`;
        }else if (post.user.lobbyist){
            profile_link = `/anonymous/lobbyists/${post.user.username}`;
        } else{
            profile_link = `/anonymous/voters/${post.user.username}`;
        }

    } else if (!post.anonymous && post.user._id) {
        if (post.user.influencer){
            profile_link = `/influencers/${post.user._id}`;
        }else if (post.user.lobbyist){
            profile_link = `/lobbyists/${post.user._id}`;
        } else{
            profile_link = `/voters/${post.user._id}`;
        }
    }
    return profile_link;
}

export const getProfileNamePost = (post) => {
    let name = '';
    if (post.anonymous && post.user.username){
        name = post.user.username;
    } else {
        name = post.user.first_name + " " + post.user.last_name;
    }
    return name;
}

export const getProfilePicPost = (post) => {
    if (post.anonymous){
        if (post.user.anon_profile_picture){
            return post.user.anon_profile_picture;
        }
        return "/images/profile_icon_standin.png";
    } else if (post.user.profile_picture && post.user.profile_picture.includes('bioguide.congress.gov/bioguide/photo')){
        return "/images/temp_anon_icon.svg";
    } else if (post.user.profile_picture) {
        return post.user.profile_picture;
    } 
    return "/images/temp_anon_icon.svg";
}


export const profilePicError = (e)  => {
    // e.target.error = "";
    // console.log(e.target.src);
    e.target.src = "/images/temp_anon_icon.svg";
}