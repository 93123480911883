import React from "react";

import Button from "../button/";
import { Link } from "react-router-dom";

import { getProfileUrl, getUserProfilePic, profilePicError } from '../../functions/profile';


export const UserCard = props => {
  const { currentUser, unfollowUser, followUser, item } = props;
  const currentUserFollowing = currentUser.following;
 
  const profile_link = getProfileUrl(item);

  const img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
  : item.organization ? 'user-img-organization' : 'user-img-voter';

  const btn_style_solid = item.influencer ? 'allyus-influencer-btn' : item.lobbyist ? 'allyus-lobbyist-btn' : item.user_politician ? 'allyus-secondery-btn'
  : item.organization ? 'allyus-organization-btn' : 'allyus-primary-btn';

  const btn_style_border = item.influencer ? 'allyus-influencer-border-btn' : item.lobbyist ? 'allyus-lobbyist-border-btn' : item.user_politician ? 'allyus-secondery-border-btn'
  : item.organization ? 'allyus-organization-border-btn' : 'allyus-primary-border-btn';
  return (
    <div className="allyus-list-item">
      <Link to={profile_link}>
          <img
            src={getUserProfilePic(item)}
            onError={e => profilePicError(e)} 
            className={`user-img ${img_style}`} 
            alt="connection"
          />
      </Link>
      <div>
        <Link to={profile_link}>
          {item.username ? (
            <p className="allyus-card-heading mb-0 ">{item.username}</p>
          ) : (
            <p className="allyus-card-heading mb-0 ">
              {item.first_name + " " + item.last_name} &nbsp;&nbsp;
              {item.civic_verified && item.__t !== "politician" ? (
                <img
                  src="/icons/new/verified-checkmark-voter.svg"
                  alt="connection"
                />
              ) : item.civic_verified ? (
                <img
                  src="/icons/new/verified-checkmark-politician.svg"
                  alt="connection"
                />
              ) : (
                ""
              )}
            </p>
          )}
        </Link>
        {item.username ? (
          <img src="/icons/new/private-icon.svg" alt="connection" />
        ) : (
          <p className="allyus-card-sub-heading ">
            {item.office ? item.office + ", " : ""}
            {item.location_region}
          </p>
        )}
      </div>
      {currentUser.username === item.username ||
      currentUser._id === item._id ? (
        <Button className={`${btn_style_solid}`}>This is You</Button>
      ) : currentUserFollowing.includes(item.username) ||
        currentUserFollowing.includes(item._id) ? (
        <Button
          click={() => unfollowUser({ id: item._id, username: item.username })}
          className={`${btn_style_solid}`}
        >
          Following
        </Button>
      ) : (
        <Button
          click={() => followUser({ id: item._id, username: item.username })}
          className={`allyus-btn ${btn_style_border}`}
        >
          Follow
        </Button>
      )}
    </div>
  );
};
