import React, { Component } from "react";
import Select from "react-select";
import _ from "lodash";
import axios from "axios";
import config from "../../config";
const allyus_url = config.allyus_url;

export default class LocationPicker extends Component {
  constructor(props) {
    super(props);
    this.state = {
      countries: [],
      regions: [],
      cities: [],
      country: "",
      region: "",
      city: "",
      postalcode: "",
    };
  }
  async componentDidMount() {
    try {
      const country_data = await axios.get(`${allyus_url}/forms/countrylist`);
      var countries = [];
      _.forEach(country_data.data, function (v, key) {
        countries.push({
          value: v.country_name,
          label: v.country_name,
          country_code: v.country_code,
          country_name: v.country_name,
        });
      });
      this.setState({ countries });
    } catch (error) {
      console.log(error);
    }
  }
  async handleChangeCountry(e) {
    try {
      var country = e.value;
      this.setState({ country: e, region: "", city: "" });
      this.props.onChange({ country: e, region: "", city: "" });
      const region_data = await axios.post(`${allyus_url}/forms/regionslist`, {
        country: country,
      });
      var regions = [];
      _.forEach(region_data.data.details.regionalBlocs, function (v, key) {
        regions.push({ value: v.state_name, label: v.state_name });
      });
      this.setState({ regions });
    } catch (error) {
      console.log(error);
    }
  }

  async handleChangeRegion(e) {
    try {
      var region = e.value;
      var country = this.state.country.value;
      this.setState({ region: e, city: "" });
      this.props.onChange({ region: e, city: "" });

      const city_data = await axios.post(`${allyus_url}/forms/citieslist`, {
        region: region,
        country: country,
      });
      var cities = [];
      _.forEach(city_data.data, function (v, key) {
        cities.push({ value: v.city_name, label: v.city_name });
      });
      this.setState({ cities });
    } catch (error) {
      console.log(error);
    }
  }

  render() {
    const {
      country,
      region,
      city,
      postal_code,
      countries,
      regions,
      cities,
    } = this.state;
    return (
      <div>
        <div className="form-group">
          <label>Country</label>
          <Select
            options={countries}
            value={country}
            onChange={(e) => this.handleChangeCountry(e)}
          />
        </div>

        <div className="form-group">
          <label>Region</label>
          <Select
            options={regions}
            value={region}
            onChange={(e) => this.handleChangeRegion(e)}
          />
        </div>

        <div className="form-group">
          <label>City</label>
          <Select
            options={cities}
            value={city}
            onChange={(e) => {
              this.setState({ city: e });
              this.props.onChange({ city: e });
            }}
          />
        </div>

        <div class="form-group">
          <label>Postal Code/Zip Code (optional)</label>
          <input
            className="form-control"
            id="postalcodeId"
            value={postal_code}
            onChange={(e) => {
              var postal_code = e.target.value;
              this.setState({ postal_code: postal_code });
              this.props.onChange({ postal_code: postal_code });
            }}
          ></input>
        </div>
      </div>
    );
  }
}
