import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../button/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Col, Row } from 'reactstrap';
import EditProfile from '../../editProfile';

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../../config";
const { allyus_url } = config;


export default class PolititionDetailCard extends React.Component {
    constructor(props) {
        super(props);
        const { profile } = props;
        this.state = {
          modal: false,
          emailSignUpsURL: ''
         };
    }
    async componentDidMount(){
     await this.getEmailSignUpsURL();
    }

    async componentDidUpdate(prevProps){
        if (prevProps.profile._id !== this.props.profile._id){
            await this.getEmailSignUpsURL();
        }
    }

    async getEmailSignUpsURL() {
        try {
            if (firebase.auth().currentUser){
              const access_token = await firebase.auth().currentUser.getIdToken(true);
              var getEmailSignUpsURL = `${allyus_url}/admin/politicians/${this.props.profile._id}/emailsignups_csv?access_token=${access_token}`;
              this.setState({ emailSignUpsURL: getEmailSignUpsURL });
            }
          } catch(err){
            console.log(err);
          }
    }

    toggle = (e) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    render() {
        const {
            first_name,
            last_name,
            full_name,
            location_country,
            location_region,
            location_city,
            regional_distric_number,
            county,
            office,
            political_lean,
            reps,
            followers_count,
            following_count,
            _id,
            organization,
            user_politician
         } = this.props.profile;
        const { my_profile, currentUser, userAdmin } = this.props;
        return (
        <div className="polititionDetail-card">
            <div className="polititionDetail-card-head">
                <div className="group1">
                    <div className="user-detail">
                        <div className="user-title">
                            <h2 className="allyus-main-heading  mb-3">{full_name}</h2>
                           
                            {
                                user_politician ?
                                <h3 className="allyus-sub-heading mb-1">{office}</h3>
                                :  <h3 className="allyus-sub-heading">Organization</h3>
                            }
                            
                        </div>
                        { !my_profile ? (
                            <div className="user-btn-group mt-3">
                            {
                                 currentUser && currentUser.following && currentUser.following.includes(_id) ?
                                   <Button
                                   className={organization ? 'allyus-organization-btn' : "allyus-secondery-btn"}
                                   width="120"
                                   click={() =>this.props.unfollowUser({id:_id})}
                                   >
                                       Following
                                   </Button>
                             :
                                   <Button
                                   className={organization ? "allyus-organization-border-btn" : "allyus-secondery-border-btn"}
                                   width="120"
                                   click={() => this.props.followUser({id:_id})}

                                   >
                                       Follow
                                   </Button>

                            }
                                <Button
                                    className={organization ? 'allyus-organization-btn' : "allyus-secondery-btn "}
                                    width="120"
                                    click={this.props.userEmailSignUp}
                                    name="emailSignup"
                                >
                                    Email SignUp
                                </Button>
                            </div>
                        ) : (
                            <div className="user-btn-group mt-3">
                            <Button
                                className={organization ? "allyus-organization-border-btn" : "allyus-secondery-border-btn"}
                                width="120"
                                click={this.toggle}
                                name="editProfile"
                                >edit profile
                             </Button>
                             <a href={this.props.currentUser.getEmailSignUpsURL} download >
                             <Button
                                    className={organization ? 'allyus-organization-btn' : "allyus-secondery-btn "}
                                    width="165"
                                    name="emailSignup"
                                >
                                    Get Email SignUps
                            </Button>
                            </a>
                             </div>
                        )}
                        {
                            !userAdmin ? null :
                            <div className="user-btn-group mt-3">
                            <Button
                                className={organization ? "allyus-organization-border-btn" : "allyus-secondery-border-btn"}
                                width="120"
                                click={this.toggle}
                                name="editProfile"
                                >edit profile
                             </Button>
                             <a href={this.state.emailSignUpsURL} download >
                             <Button
                                    className={organization ? 'allyus-organization-btn' : "allyus-secondery-btn "}
                                    width="165"
                                    name="emailSignup"
                                >
                                    Get Email SignUps
                            </Button>
                            </a>
                             </div>

                        }
                    </div>
                    <div className="user-states mt-4">
                        <div >
                            <span className="states-count allyus-text">{`${followers_count}`}</span>
                            <span className="states-label text-muted pl-1">Followers</span>
                        </div>
                        <div>
                            <span className="states-count allyus-text">{`${following_count}`}</span>
                            <span className="states-label text-muted pl-1">Following</span>
                        </div>
                        <div>
                            <span className="states-count allyus-text">{reps}</span>
                            <img src="/icons/new/rep-icon.svg"  alt="rating"/>
                        </div>
                    </div>
                </div>

                <div className="group2">
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">state</span>
                        <span className="allyus-card-sub-heading"> {(location_region ? location_region : '')}</span>
                    </p>

                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">city</span>
                        {
                            // !regional_distric_number ?
                            <span className="allyus-card-sub-heading" style={{textTransform:'capitalize'}}>{location_city ? location_city : ""}</span>
                            // :
                            // <span className="allyus-card-sub-heading">{location_region +  "- District" + regional_distric_number}</span>
                        }
                    </p>

                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">county</span>
                        <span className="allyus-card-sub-heading" style={{textTransform:'capitalize'}}>{county}</span>
                    </p>
                    {organization ? null :
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">political affiliation</span>
                        <span className="allyus-card-sub-heading">{political_lean}</span>
                    </p>
                    }
                </div>
            </div>
            <EditProfile profile={this.props.profile} open={this.state.modal} toggle={this.toggle} editUser={this.props.editUser} />

        </div>
        );
    }
}
