import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import AllyusButton from "../../../components/button";
import AllyusCard from "../../../components/allyusCard";


export default class CreatePolitician extends Component {

    render() {
        const { email, office, organization, first_name, last_name, message, renderSignInScreen, onInputHandle, onSubmitCreatePolitician, onSubmitCreateOrganization, politicianType, politician_or_organization} = this.props;
        var title = politician_or_organization === 'politician' ? (politicianType + " Office Signup") : "Organization Signup"
        return (
            <AllyusCard title={title} className="signIn" isModal={this.props.isModal} onClose={this.props.toggle}>
            {/* {authError ? <div className="alert alert-danger">{authError} </div> : ""} */}
            <Form className="allyus-form button-group">
                <FormGroup className="allyus-form-group">
                <Label for="email">Email</Label>
                <Input
                    id="email"
                    type="email"
                    value={email}
                    onChange={onInputHandle}
                />
                </FormGroup>

                
                {
                    politician_or_organization === 'politician' ?
                    <FormGroup className="allyus-form-group">
                        <Label for="office">Office</Label>
                        <Input
                            id="office"
                            type="office"
                            value={office}
                            onChange={onInputHandle}
                        />
                    </FormGroup>
                    :
                    <FormGroup className="allyus-form-group">
                        <Label for="organization">Organization Name</Label>
                        <Input
                            id="organization"
                            type="organization"
                            value={organization}
                            onChange={onInputHandle}
                        />
                    </FormGroup>
                }
                
                <FormGroup className="allyus-form-group">
                <Label for="first_name">First Name</Label>
                <Input
                    id="first_name"
                    type="first_name"
                    value={first_name}
                    onChange={onInputHandle}
                />
                </FormGroup>
                <FormGroup className="allyus-form-group">
                <Label for="last_name">Last Name</Label>
                <Input
                    id="last_name"
                    type="last_name"
                    value={last_name}
                    onChange={onInputHandle}
                />
                </FormGroup>
                <FormGroup className="allyus-form-group">
                    <Label for="last_name">Message (optional)</Label>
                    <Input
                        type="textarea"
                        id="message"
                        name="message"
                        onChange={onInputHandle}
                        value={message} 
                        />
                </FormGroup>       

                <FormGroup className="allyus-form-group">
                <Button
                    id="submit"
                    className="allyus-btn allyus-primary-btn signup-btn-m"
                    onClick={politician_or_organization === 'politician' ? 
                        onSubmitCreatePolitician : onSubmitCreateOrganization
                    }
                >
                    {" "}
                    Request Account{" "}
                </Button>
                </FormGroup>
            </Form>
            <div className="bottom-text">
                <div>
                Already have an account? <span onClick={()=>renderSignInScreen()}>Log in</span>
                </div>
                <div className="terms-line">
                By signing up, you agree to our <a href="https://allyus.org/terms" target="blank"><span>Terms</span></a> and that you have
                read our <a href="https://allyus.org/privacypolicy" target="blank"><span>Privacy Policy</span></a>
                </div>
            </div>
            </AllyusCard>
        );
    }
}