import React from 'react';
import Button from '../button/index';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';

class DeleteModal extends React.Component {

    render() {
        const { deleteFunction, isOpen, toggle, deleteName, secondary  } = this.props;
        return (
            <div className="news">
                <Modal isOpen={isOpen} toggle={toggle} >
                    <ModalHeader toggle={toggle}>Delete {deleteName}</ModalHeader>
                    <ModalBody>
                        <p className="allyus-text"> Are you sure you want to delete this {deleteName}? </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-white-btn" click={() => { deleteFunction(); toggle();}}> Delete </Button>
                        <Button className={"allyus-btn " + (!secondary ? "allyus-primary-btn" : "allyus-secondery-btn")} click={toggle}> Not really </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default DeleteModal;