import ACTIONS from './actionTypes';
import producer from 'immer';

const initialState = {
    suggestions: [],
    searchText: '',
    searchResults: [],
};

export default (state = initialState, action) => {
    return producer(state, draft => {
        switch (action.type) {
            case ACTIONS.GET_SUGGESTIONS:
                // draft.suggestions = [
                //     "branda lilly james",
                //     "branda albert",
                //     "branda abalos",
                //     "bruner tim",
                //     "bruts toot",
                //     "branda lee",
                //     "branda nuu",
                //     "norah branda diaz",
                //     "brenda anne northcott",
                //     "nadia iqbal",
                //     "scott peacock"
                // ]
                draft.suggestions = [];
                //action.payload;
                break;
            case ACTIONS.SET_AC_TEXT:
                draft.searchText = action.payload;
                break;
            case 'SEARCH_FETCH_SUCCESS':
                draft.searchResults = action.payload;
                break;
            default:
                return state;
        }
    })
}