import React, { Component } from 'react';
import  Player from 'react-player'
import AllyusCard from '../../allyusCard/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';

class PolititionAboutCard extends Component {
    constructor(props) {
        super(props);
        const { profile } = props;
        this.state = { 
          modal: false,
          aboutVideo: profile.aboutVideo,
          aboutTitle: profile.aboutTitle,
          aboutParagraph: profile.aboutParagraph,
        };    
    }
    
    componentDidUpdate(prevProps) {
        if (prevProps.profile._id !== this.props.profile._id){
            const { profile } = this.props;
            this.setState({ 
                modal: false,
                aboutVideo: profile.aboutVideo,
                aboutTitle: profile.aboutTitle,
                aboutParagraph: profile.aboutParagraph,
              });   
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleInputChange = (event)=> {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }

  onSubmit = () => {
    const { 
      aboutVideo,
      aboutTitle,
      aboutParagraph
     } = this.state;
    const updatedProfile = { 
      aboutVideo,
      aboutTitle,
      aboutParagraph
    };
    this.props.editUser(updatedProfile);
    this.toggle();
  }

    render() {
        const { my_profile, profile, userAdmin } = this.props;
        return (
            <AllyusCard
                title='About'
                edit={my_profile || userAdmin}
                click={this.toggle}
                theme='p'
            >
                <div>
                    {
                        !profile.aboutVideo ? null :
                    <div className="mb-3">
                        <img src="" alt="" />
                        <Player width={"auto"} controls
                            url={profile.aboutVideo} >
                        </Player>
                    </div>
                    }
                    <div>
                        <h3 className="allyus-card-heading">{profile.aboutTitle}</h3>
                        <p className="allyus-text text-overflow-wrap">
                            {profile.aboutParagraph}
                        </p>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className="allyus-card-title">Edit about</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="aboutVideo">about media (url of video)</Label>
                                <Input type="text" id="aboutVideo" name="aboutVideo" value={this.state.aboutVideo} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="aboutTitle">about title</Label>
                                <Input type="text" id="aboutTitle" name="aboutTitle" value={this.state.aboutTitle} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="about">about</Label>
                                <Input type="textarea" id="about" name="aboutParagraph" value={this.state.aboutParagraph} onChange={this.handleInputChange} style={{height:300}} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={() => this.onSubmit()}> save </Button>
                    </ModalFooter>
                </Modal>
            </AllyusCard>
        )
    }

}

export default PolititionAboutCard;
