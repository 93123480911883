import React from "react";
import { Row, Col, FormGroup, Label, Input } from "reactstrap";

const LocationDetails = props => {
  const { location_info } = props;
  if (location_info && location_info.location_country === "Canada") {
    return (
      <div>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Country</Label>
              <Input value={location_info.location_country} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Province</Label>
              <Input value={location_info.location_region} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">City</Label>
              <Input value={location_info.location_city} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Postal Code</Label>
              <Input value={location_info.postal_code} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  } else if (
    location_info &&
    location_info.location_country === "United States"
  ) {
    return (
      <div>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Country</Label>
              <Input value={location_info.location_country} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">State</Label>
              <Input value={location_info.location_region} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">City</Label>
              <Input value={location_info.location_city} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">County</Label>
              <Input value={location_info.county} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Zip Code</Label>
              <Input value={location_info.postal_code} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Congressional District</Label>
              <Input value={`Congressional District ${location_info.cd}`} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  } else if (location_info) {
    return (
      <div>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Country</Label>
              <Input value={location_info.location_country} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Region</Label>
              <Input value={location_info.location_region} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">City</Label>
              <Input value={location_info.location_city} />
            </FormGroup>
          </Col>
        </Row>
        <Row form>
          <Col md={12}>
            <FormGroup className="allyus-form-group">
              <Label for="exampleEmail">Postal Code</Label>
              <Input value={location_info.postal_code} />
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  } else {
    return <div>LOADING</div>;
  }
};

export default LocationDetails;
