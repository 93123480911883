import React from "react";
import { ReactFormGenerator, ElementStore } from "react-form-builder2";
import { get, post } from "./requests";
import LocationPicker from "../../../components/locationPicker";
import { Alert } from 'reactstrap';
import axios from "axios";
import $ from "jquery";

export default class Demobar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      locationError: false,
      country: '',
      region: '',
      city: '',
      postal_code: ''
    };
    this.onChangeLocation = this.onChangeLocation.bind(this);
    this.submit = this.onSubmit.bind(this);
    const update = this.onChange.bind(this);
    ElementStore.subscribe(state => update(state.data));
  }

  showPreview() {
    this.setState({
      previewVisible: true
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false
    });
  }

  onChange(data) {
    this.setState({
      data
    });
  }

  onChangeLocation(change){
    this.setState(change);
  }


  onSubmit(data) {
    const { answersUrl } = this.props;
    var {country, region, city, postal_code} = this.state;
    var country_code = country.country_code;
    country = country.value;
    region = region.value;
    city = city.value;
    if (!country || !region || !city){
      this.setState({locationError: true});
      return;
    }
    this.setState({locationError: false});
    var answers = {
      answers: data,
      location: {
        country,
        country_code,
        region,
        city,
        postal_code
      }
    };
    console.log("onSubmit", answers);
    this.closePreview();
  }

  async publishForm() {
    try {
      const { publishUrl } = this.props;
      await axios.put(publishUrl);
      this.closePreview();
    } catch (error) {
      // console.log(error)
    }
  }

  render() {
    let modalClass = "modal";
    if (this.state.previewVisible) {
      modalClass += " show";
    }

    return (
      <div className="clearfix" style={{ margin: "10px", width: "70%" }}>
        <h4 className="pull-left">Preview</h4>
        <button
          className="btn pull-right allyus-primary-btn"
          style={{ marginRight: "10px", borderColor: "transparent" }}
          onClick={this.showPreview.bind(this)}
        >
          Preview Form
        </button>

        {this.state.previewVisible && (
          <div className={modalClass} style={{ overflow: "auto" }}>
            <div className="modal-dialog">
              <div className="modal-content">
                <div style={{ paddingTop: 20, paddingRight: 20 }}>
                  <button
                    className="btn pull-right allyus-primary-border-btn"
                    style={{ marginRight: "10px" }}
                    onClick={this.publishForm.bind(this)}
                  >
                    Publish
                  </button>
                </div>
                <div className="modal-body">
                  {
                    !this.state.locationError ? null :
                    <Alert color="danger">
                      Country, region, and city are required.
                    </Alert>
                  }
                  <h3>Location</h3>
                  <LocationPicker onChange={this.onChangeLocation} />
                  <ReactFormGenerator
                    download_path=""
                    answer_data={{}}
                    action_name="Demo Submit"
                    // form_action="/"
                    form_method="POST"
                    onSubmit={this.submit}
                    variables={this.props.variables}
                    data={this.state.data}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-default"
                    data-dismiss="modal"
                    onClick={this.closePreview.bind(this)}
                  >
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
