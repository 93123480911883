import React, { Component } from 'react';
import Button from '../../button/index';
import { getUserProfilePic, getProfileUrl, profilePicError } from '../../../functions/profile';
import CivicButton from '../../civcButton';

import { Link } from 'react-router-dom';

export default class UserInfoCard extends Component {

  render() {
    const {
      currentUser
    } = this.props;
    const img_style = currentUser.influencer ? 'feed-profile-img-influencer' : currentUser.lobbyist ? 'feed-profile-img-lobbyist' : currentUser.user_politician ? 'feed-profile-img-politician'
    : currentUser.organization ? 'feed-profile-img-organization' : 'feed-profile-img-voter';
    const btn_solid_style = currentUser.influencer ? 'allyus-influencer-btn' : currentUser.lobbyist ? 'allyus-lobbyist-btn' : currentUser.user_politician ? 'allyus-secondery-btn'
    : currentUser.organization ? 'allyus-organization-btn' : 'allyus-primary-btn';

    if (currentUser.anonymous) {
      return (
        <div className="userInfo-card allyus-card p-0">
          <div className="allyus-cover-img">
            <Link to={getProfileUrl(currentUser)}><img src={currentUser.anon_cover_photo ? currentUser.anon_cover_photo  :"/images/bg2.jpg"} alt="info" className="cover" /></Link>
          </div>
          <div className="userInfo-card-body">
            <div className="user-img">
            <Link to={getProfileUrl(currentUser)}><img src={getUserProfilePic(currentUser)} onError={e => profilePicError(e)} alt="profile img" className={`profile ${img_style}`} /></Link>
            </div>
            <div className="user-detail">
              <div className="user-title">
              <Link to={getProfileUrl(currentUser)}><h2 className="allyus-main-heading mb-1">{currentUser.username}</h2></Link>
                <h3 className="allyus-sub-heading">Hidden Location</h3>
              </div>
              <Button
                className={btn_solid_style}
                click={this.props.toggleAnonymous}
              >
                go public
              </Button>
            </div>
            <div className="user-states allyus-text">
              <div >
                {/* <img src='/icons/new/private-icon.svg' alt="private" /> */}
                <span className="states-count ">{`${currentUser.anon_profile_followers_count}`}</span>
                <span className="states-label text-muted pl-1">Followers</span>
              </div>
              <div>
                {/* <img src='/icons/new/private-icon.svg' alt="private" /> */}
                <span className="states-count ">{`${currentUser.anon_profile_following_count}`}</span>
                <span className="states-label text-muted pl-1">Following</span>
              </div>
              <div>
                <img src='/icons/new/private-icon.svg' alt="private" />
                <img src="/icons/new/rep-icon.svg" />
              </div>
            </div>
            <hr />
            {
              currentUser.civic_verified ?
              <div className="user-verify">
                 <p className="allyus-text mb-0">Profile verified <img src='/icons/new/verified-checkmark-voter.svg' alt="rep" /> </p>
                 
              </div>
              :
              <div className="user-verify">
                <p className="allyus-text mb-0">Profile not verified</p>
                <CivicButton />
              </div>
            }
          </div>
        </div>
      )
    }

    if (!currentUser.anonymous) {
      const {location_city, location_region} = currentUser;

      return (
        <div className="userInfo-card allyus-card p-0">
          <div className="allyus-cover-img">
            <Link to={getProfileUrl(currentUser)}><img src={currentUser && currentUser.cover_photo ? currentUser.cover_photo : "/images/bg1.jpg"} alt="info" className="cover" /></Link>
          </div>
          <div className="userInfo-card-body">
            <div className="user-img">
              <Link  to={getProfileUrl(currentUser)}><img src={getUserProfilePic(currentUser)} onError={e => profilePicError(e)} alt="profile img" className={`profile ${img_style}`} /></Link>
            </div>
            <div className="user-detail">
              <div className="user-title">
                <Link to={getProfileUrl(currentUser)}><h2 className="allyus-main-heading mb-1">{currentUser.full_name}</h2></Link>
                <h3 className="allyus-sub-heading">{(location_city ? location_city : '') + (location_region && location_city ? ", " : '') + (location_region ? location_region : '')}</h3>

              </div>
              {
                currentUser.__t === 'politician' ? null : 
                <Button
                  className={btn_solid_style}
                  click={this.props.toggleAnonymous}
                >
                  go private
                </Button>
              }
         
            </div>
            <div className="user-states allyus-text">
              <div >
                <span className="states-count ">{`${currentUser.followers_count}`}</span>
                <span className="states-label text-muted pl-1">Followers</span>
              </div>
              <div>
                <span className="states-count ">{`${currentUser.following_count}`}</span>
                <span className="states-label text-muted pl-1">Following</span>
              </div>
              <div>
                <span className="states-count">{`${currentUser.reps}`}</span>
                <img src="/icons/new/rep-icon.svg" />
              </div>
            </div>
            <hr />
            {
              currentUser.civic_verified ?
              <div className="user-verify">
                 <p className="allyus-text mb-0">Profile verified <img src='/icons/new/verified-checkmark-voter.svg' alt="rep" /> </p>
              </div>
              :
              <div className="user-verify">
                <p className="allyus-text mb-0">Profile not verified</p>
                <CivicButton />
              </div>
            }
          </div>
        </div>
      )
    }

  }
}
