import React, { Component } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";
import Autocomplete from "../../autoComplete/";
import {Connections} from './Connections';

export default class UserConnectionsCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSubTab: props.activeConnectionsTab ? props.activeConnectionsTab : "1"
    };
  }
  componentDidUpdate(prevProps){
    if (prevProps.activeConnectionsTab !== this.props.activeConnectionsTab){
      this.toggle(this.props.activeConnectionsTab);
    }
  }

  toggle = tab => {
    if (this.state.activeSubTab !== tab) {
      this.setState({
        activeSubTab: tab
      });
    }
  };
  render() {
    const {
      connectionsList,
      followersList,
      followingList,
      my_profile,
      first_name,
      currentUser,
      followUser,
      unfollowUser,
      repsList
    } = this.props;
    var name = my_profile ? "your" : first_name + "'s";

    return (
      <div className="userConnections-card allyus-card allyus-sub-tab-nav">
        <p className="allyus-card-title">{name} Connections </p>
        <hr />
        {/* <Autocomplete
          className="d-block d-lg-none mb-3"
          suggestions={[
            "branda lilly james",
            "branda albert",
            "branda abalos",
            "branda lee",
            "branda nuu",
            "norah branda diaz",
            "brenda anne northcott",
            "nadia iqbal",
            "scott peacock"
          ]}
        /> */}
        <Nav tabs>
          {
            this.props.anon_connections ? null :
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeSubTab === "1"
              })}
              onClick={() => {
                this.toggle("1");
              }}
            >
              Connections
            </NavLink>
          </NavItem>
          }
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeSubTab === "2"
              })}
              onClick={() => {
                this.toggle("2");
              }}
            >
              Followers
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeSubTab === "3"
              })}
              onClick={() => {
                this.toggle("3");
              }}
            >
              Following
            </NavLink>
          </NavItem>
          {
            this.props.anon_connections ? null :
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeSubTab === "4"
              })}
              onClick={() => {
                this.toggle("4");
              }}
            >
              Reps
            </NavLink>
          </NavItem>
          }
          <NavItem className="ml-auto d-none d-lg-block">
            {/* <Autocomplete
              suggestions={[
                "branda lilly james",
                "branda albert",
                "branda abalos",
                "branda lee",
                "branda nuu",
                "norah branda diaz",
                "brenda anne northcott",
                "nadia iqbal",
                "scott peacock"
              ]}
            /> */}
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeSubTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Connections list={connectionsList} currentUser={currentUser} followUser={followUser} unfollowUser={unfollowUser} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Connections list={followersList} currentUser={currentUser} followUser={followUser} unfollowUser={unfollowUser} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Connections list={followingList} currentUser={currentUser} followUser={followUser} unfollowUser={unfollowUser} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
               <Connections list={repsList} currentUser={currentUser} followUser={followUser} unfollowUser={unfollowUser} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
