import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col } from "reactstrap";
import AllyusCard from "../../components/allyusCard/index";
import Button from "../../components/button/index";
import PollCard from "../../components/Post/poll/index";
import DisucssionCard from "../../components/Post/discussion/index";
import NewsCard from "../../components/Post/news/index";
import { UserCard } from "../../components/userCard";
import { followUser, unfollowUser } from "../../store/actions";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

class AllyusSearch extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    const { suggestions, searchResults } = this.props;
    // console.log(searchResults);
    return (
      <div className="mt-3">
        <Container fluid>
          <Helmet>
            <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
            <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
            {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
            <meta name="twitter:image" content="/allyuslogo.png"  />
            <meta property="og:image" content="/allyuslogo.png"  />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
          <Row>
            <Col sm={3}>
              {/* <AllyusCard title="Search Filter" theme="c"></AllyusCard>
              <AllyusCard title="Find People" theme="c"></AllyusCard> */}
            </Col>
            <Col sm={6}>
              <AllyusCard title="People" theme="c" className="user-search-content" >
                <div className="pane">
                <ul >
                  {searchResults &&
                    searchResults.users &&
                    searchResults.users.map((item, i) => {
                      return (
                        <li key={i} >
                          <UserCard item={item} {...this.props} />
                        </li>
                      );
                    })}
                </ul>
                </div>
              </AllyusCard>
              <AllyusCard title="Posts" theme="c">
                {searchResults &&
                  searchResults.posts &&
                  searchResults.posts.map((post, i) => {
                    if (post) {
                      if (post.post_type === 1) {
                        return  <PollCard post={post} key={post._id} />
                      } else if (post.post_type === 3) {
                        return <DisucssionCard post={post} key={post._id} />;
                      }
                    }
                  })}
              </AllyusCard>
              <AllyusCard title="News" theme="c">
                {searchResults &&
                  searchResults.news &&
                  searchResults.news.map((post) => {
                    if (post) {
                      if (post.post_type === 2) {
                        return  <NewsCard post={post} key={post._id} />
                      }
                    }
                  })}
              </AllyusCard>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    suggestions: state.autoComplete.suggestions,
    searchResults: state.autoComplete.searchResults,
    currentUser: state.currentUser
  };
};

export default connect(
  mapStateToProps,
  { unfollowUser, followUser }
)(AllyusSearch);
