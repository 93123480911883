import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, Modal } from "reactstrap";
import { signOut, resendEmailComf } from "../../../store/actions";
import { connect } from "react-redux";
import * as firebase from "firebase/app";
import { Redirect } from 'react-router-dom';
import AllyusCard from "../../../components/allyusCard";
import LocationDetailsSectioon from "../sections/location";
import { getProfileUrlCurrentUser } from '../../../functions/profile';
import ReactGA from 'react-ga';

class VerifyEmail extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  handleSignOut = () => {
    this.props.signOut();
    this.props.history.push('/');
  }
  render() {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || ((currentUser.providerData[0].providerId === 'password' || currentUser.providerData[0].providerId === 'phone') && (currentUser.emailVerified || currentUser.phoneNumber))){
      if (currentUser && this.props.currentUser && !this.props.currentUser.profile_creation_completed && this.props.currentUser.__t !== 'politician'){
        if (!this.props.currentUser.location_country || (this.props.currentUser.location_country === "United States" && !this.props.currentUser.postal_code)){
          this.props.history.push(`/location`);
        } else {
          this.props.history.push('/completeprofile');

        }
      } else {
        return <Redirect to='/' />
      }
    }
    return (
      <div style={{paddingTop:'1%'}} className="page-signup">
        <div>
          <Button
            className="allyus-btn allyus-primary-border-btn"
            style={{
              width: 150,
              position: "absolute",
              right: 20,
              borderWidth: 0
            }}
            onClick={this.handleSignOut}
          >
            Log Out
          </Button>
        </div>
        <AllyusCard className="signIn"  title="Thanks for signing up!" style={{ marginBottom: "5%", textAlign:'center' }}>
          <div >
            <p>Please verify your email by clicking the link we sent you!</p>
            <p>If you already have, please refresh the page.</p>
          </div>
          <div>
            <Button
              id="submit"
              className="allyus-btn allyus-primary-btn signup-btn-m"
              onClick={() => this.props.resendEmailComf()}
            >
              {" "}
              Resend Email{" "}
            </Button>
          </div>
          <LocationDetailsSectioon />
        </AllyusCard>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { currentUser: state.currentUser}
}

export default connect(
  mapStateToProps,
  { signOut, resendEmailComf}
)(VerifyEmail);