import React, { Component } from "react";
import { Link } from 'react-router-dom';

// TODO
class Autocomplete extends Component {
  state = {
    activeSuggestion: 0,
    filteredSuggestions: [],
    showSuggestions: false,
    userInput: ""
  };

   onChange = e => {
    const { suggestions , onChangeText } = this.props;
    const userInput = e.currentTarget.value;
    onChangeText(userInput);

    const filteredSuggestions = suggestions.filter(
      suggestion =>
        suggestion.toLowerCase().indexOf(userInput.toLowerCase()) > -1
    );

    this.setState({
      activeSuggestion: 0,
      filteredSuggestions,
      showSuggestions: true,
      userInput: e.currentTarget.value
    });
  };

  onClick = e => {
    this.setState({
      activeSuggestion: 0,
      filteredSuggestions: [],
      showSuggestions: false,
      userInput: e.currentTarget.innerText
    });
  };

  onKeyDown = e => {
    const { activeSuggestion, filteredSuggestions } = this.state;

    // User pressed the enter key, update the input and close the
    // suggestions
    if (e.keyCode === 13) {
      this.setState({
        activeSuggestion: 0,
        showSuggestions: false,
        userInput: filteredSuggestions[activeSuggestion]
      });      
      this.props.history.push('/search');
    }
    // User pressed the up arrow, decrement the index
    else if (e.keyCode === 38) {
      if (activeSuggestion === 0) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion - 1 });
    }
    // User pressed the down arrow, increment the index
    else if (e.keyCode === 40) {
      if (activeSuggestion - 1 === filteredSuggestions.length) {
        return;
      }

      this.setState({ activeSuggestion: activeSuggestion + 1 });
    }
  };

  suggestionsListComponent = () => {
    const {
      onClick,
      state: {
        activeSuggestion,
        filteredSuggestions,
        showSuggestions,
        userInput
      }
    } = this;

    if (showSuggestions && userInput) {
      if (filteredSuggestions.length) {
        return (
          <ul className="suggestions">
            {filteredSuggestions.map((suggestion, index) => {
              let className;

              // Flag the active suggestion with a class
              if (index === activeSuggestion) {
                className = "suggestion-active";
              }

              return (
                <li
                  className={className}
                  key={suggestion}
                  onClick={onClick}
                >
                  {suggestion}
                </li>
              );
            })}
            <li className="search-all">
              <Link to="/search" >see all results for {this.state.userInput} <span className="fa fa-angle-right"></span>
              </Link>
            </li>
          </ul>
        );
      } else {
        // return (
        //   <ul className="suggestions">
        //   <li className="search-all">
        //     <Link to="/search">see all results for {this.state.userInput} <span className="fa fa-angle-right"></span>
        //     </Link>
        //   </li>
        // </ul>
        // );
        return null;
      }
    }
  }

  render() {
    const {onChange, onKeyDown } = this; 
    const { userInput}  = this.state;
    const { className}  = this.props;

    return (
      <div className={`auto-complete ${className ? className : ''}`}>
        <div className="input-group">
          <input type="text"
            className="form-control"
            value={userInput}
            onChange={onChange}
            onKeyDown={onKeyDown}
            placeholder="Search"
          />

          <div className="input-group-append">
            <button className="search-btn" type="button">
              <img src="/icons/new/search-icon.svg" alt=""/>
            </button>
          </div>
        </div>
        {this.suggestionsListComponent()}
      </div>
    );
  }
}


export default Autocomplete