import React from "react";
import * as firebase from "firebase/app";

import { Route , Switch, Redirect } from 'react-router-dom';
import About from '../Pages/about/index';
import Feeds from '../Pages/feeds/index';
import PublicProfile from '../Pages/publicProfile/index';
import PublicAnonymousProfile from '../Pages/publicProfile/anonymous';
import politicianProfile from '../Pages/politicianProfile/index';
import signIn from '../Pages/auth/index'
import Petitions from '../Pages/petitions/index';
import AllyusMessages from '../Pages/messages/index';
import AllyusSearch from '../Pages/search/index';
import PhoneAuth from '../Pages/auth/pages/phoneAuth';
import VerifyEmail from '../Pages/auth/pages/verifyemail';
import FillLocation from '../Pages/auth/pages/location';
// import MembershipPayment from '../Pages/auth/pages/membershipPayment';
import Notifications from '../Pages/notifications';
import PrivacyPolicy from '../Pages/privacypolicy';
import Terms from '../Pages/terms';
import ProfileComplete from '../Pages/profilecreate';
import BreakingNewsEdit from '../Pages/breakingnews/edit';
import BreakingNews from '../Pages/breakingnews/about';

// NOTE: path="/" do exact and not found or do like below?
export default (
  <Switch className="app-content">
    <PrivateRoute path="/vision" component={About}/>
    <PrivateRoute path="/voters/:name" component={PublicProfile}/>
    <PrivateRoute path="/influencers/:name" component={PublicProfile}/>
    <PrivateRoute path="/lobbyists/:name" component={PublicProfile}/>
    <PrivateRoute path="/anonymous/voters/:username" component={PublicAnonymousProfile}/>
    <PrivateRoute path="/anonymous/influencers/:username" component={PublicAnonymousProfile}/>
    <PrivateRoute path="/anonymous/lobbyists/:username" component={PublicAnonymousProfile}/>
    <PrivateRoute path="/politicians/:name" component={politicianProfile}/>
    <PrivateRoute path="/organizations/:name" component={politicianProfile}/>
    <VeryPrivateRoute path="/messages" component={ AllyusMessages } />
    <VeryPrivateRoute path="/notifications" component={ Notifications } />

    <PrivateRoute path="/search" component={ AllyusSearch } />
    <PrivateRoute path="/posts/:post_id" component={Feeds} />    
    {/* <PrivateRoute path="/posts/" component={Feeds} />     */}
    <PrivateRoute path="/petitions/:petition_id" component={Petitions} />    
    <PrivateRoute path="/petitions" component={Petitions} />
    
    <Route path="/signin" component={signIn}/>
    <Route path="/phone_auth" component={PhoneAuth} />
    <Route path="/verifyemail" component={VerifyEmail} />
    <Route path="/location" component={FillLocation} />
    {/* <Route path="/membership_payment" component={MembershipPayment} /> */}


    <Route path="/privacypolicy" component={PrivacyPolicy} />
    <Route path="/terms" component={Terms} />

    <Route path="/completeprofile" component={ProfileComplete} />

    <Route path="/covid_edit" component={BreakingNewsEdit} />
    <Route path="/covid-19" component={BreakingNews} />

    <Route  path="/" component={Feeds} />     
  </Switch>
);


function PrivateRoute({ component: Component, ...rest }) {
  const currentUser = firebase.auth().currentUser;
  const passwordAuth = currentUser && (currentUser.providerData[0].providerId === 'password' || currentUser.providerData[0].providerId === 'phone');
  return (
    <Route
      {...rest}
      render={props =>
        currentUser && passwordAuth && !currentUser.phoneNumber && !currentUser.emailVerified  ? (
          <Redirect
            to={{
              pathname: "/phone_auth",
              state: { from: props.location }
            }}
         />
        ) :
        // ) : currentUser && passwordAuth && !currentUser.emailVerified ? (
        //   <Redirect
        //     to={{
        //       pathname: "/verifyemail",
        //       state: { from: props.location }
        //     }}
        //  />
        // ) 
         (
          <Component {...props} />
        )
      }
    />
  );
}

function VeryPrivateRoute({ component: Component, ...rest }) {
  const currentUser = firebase.auth().currentUser;
  const passwordAuth = currentUser && (currentUser.providerData[0].providerId === 'password' || currentUser.providerData[0].providerId === 'phone');
  return (
    <Route
      {...rest}
      render={props =>
         !currentUser ?
         (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location }
            }}
         />
        ) 
       : currentUser && passwordAuth && !currentUser.phoneNumber && !currentUser.emailVerified  ? (
          <Redirect
            to={{
              pathname: "/phone_auth",
              state: { from: props.location }
            }}
         />
       ) :
        // ) : currentUser && passwordAuth && !currentUser.emailVerified ? (
        //   <Redirect
        //     to={{
        //       pathname: "/verifyemail",
        //       state: { from: props.location }
        //     }}
        //  />
        // ) 
        (
         <Component {...props} />
       ) 
      }
    />
  );
}


