import React from "react";
import { Row, Col, Nav, NavItem, NavLink } from "reactstrap";
import { compose } from "redux";
import { Container } from "reactstrap";
import { withRouter } from "react-router-dom";
import { NavLink as RRNavLink } from "react-router-dom";
import CommonNav from "../common";
import { connect } from "react-redux";
import { getCurrentUser, getNotifications, toggleReportBugModal, toggleTutorialModal } from "../../../store/actions";

class MobileNavbar extends React.Component {
  componentDidMount() {
    this.props.getCurrentUser();
    if (this.props.auth.uid) {
      this.props.getNotifications();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.uid !== this.props.auth.uid && this.props.auth.uid) {
      this.props.getNotifications();
    }
  }
  render() {
    return (
      <div className="allyus-nav-mobile" style={{backgroundColor:'white'}}>
        <Container fluid>
          <Row>
            <CommonNav mobileNav={true} history={this.props.history} />
          </Row>
          <Row>
            <Nav className="secondery-nav">
              <NavItem>
                <NavLink
                  tag={RRNavLink}
                  to="/"
                  activeClassName="active"
                  exact
                  path="/"
                >
                  <img src="/icons/feeds.PNG" alt="nav icon" width="30px" />
                  <p> Feed </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/vision" tag={RRNavLink} activeClassName="active">
                  <img src="/icons/vision.PNG" alt="nav icon" width="30px" />
                  <p> About </p>
                </NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink to="/" onClick={this.props.toggleTutorialModal} tag={RRNavLink} activeClassName="" >
                   <img src="/icons/icons8-info-squared-50.png" alt="nav icon" width="30px"/>
                  <p> Tutorial </p>
                </NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink to="/covid-19" tag={RRNavLink} activeClassName="" >
                   <img src="/icons/icons8-error-64.png" alt="nav icon" width="32px" style={{marginTop:5}}/>
                  <p> Covid-19 </p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink to="/" onClick={this.props.toggleReportBugModal} tag={RRNavLink} activeClassName="">
                   <img src="/icons/icons8-bug-64.png" alt="nav icon" width="32px"  />
                  <p> Bug </p>
                </NavLink>
              </NavItem>
            </Nav>
          </Row>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    currentUser: state.currentUser
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      getCurrentUser,
      getNotifications,
      toggleReportBugModal,
      toggleTutorialModal,
    }
  )
)(MobileNavbar);
