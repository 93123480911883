import ACTIONS from './actionTypes';
import producer from 'immer';

const initialState = {
    show: false,
    msgStatus: null,
    messages : null
};

export default (state = initialState, action) => {
    return producer(state, draft => {
        switch (action.type) {
            case ACTIONS.SHOW_CHAT_WINDOW:
                draft.show = true;
                draft.messages = action.payload;
                break;
            case ACTIONS.HIDE_CHAT_WINDOW:
                draft.show = false;
                break;
            case ACTIONS.MSG_SENT_SUCCESS:
                draft.msgStatus = true;
                break;
            case ACTIONS.MSG_SENT_FAILD:
                draft.msgStatus = false;
                break
            default:
                return state;
        }
    })
}



