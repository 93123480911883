
import * as firebase from "firebase/app";
import axios from "axios";

import config from "../../config";
const { allyus_url } = config;

// TODO should move types to actionTypes file

export const getProfile = (id) => async (dispatch, getState) => {
        try {
        dispatch({type: 'PROFILE_LOADING'});
        const profile_data = await axios.get(`${allyus_url}/users/${id}`);
        const followers_data = await axios.get(`${allyus_url}/users/${id}/followers`);
        const following_data = await axios.get(`${allyus_url}/users/${id}/following`);
        const connections_data = await axios.get(`${allyus_url}/users/${id}/connections`);
        const reprentatives_data = await axios.get(`${allyus_url}/representatives/for_user/${id}`);
        const petitions_data = await axios.get(`${allyus_url}/users/${id}/petitions`);

        var profile = profile_data.data;
        
        if ( profile && firebase.auth().currentUser  && (!profile.userhistory_private || getState().currentUser._id === profile._id) ){
            profile.anonymous = false; // IMPORTANT user could have account in anonymous mode, but we want to show non anonymous profile on public profile page
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            const userActivity_data = await axios.get(`${allyus_url}/users/${id}/history?access_token=${access_token}`);
            dispatch({ 
                type: 'PROFILE_FETCH_SUCCESS',
                payload:{
                    profile: profile,
                    following: following_data.data.following,  
                    following_count: following_data.count,
                    followers: followers_data.data.followers,  
                    followers_count: followers_data.count,
                    connections: connections_data.data,
                    representatives: reprentatives_data.data,
                    userActivity: userActivity_data.data ? userActivity_data.data : [] ,
                    petitions: petitions_data.data,
                    loaded: true
                }
            });

        } else if (profile) {
            profile.anonymous = false;
            dispatch({ 
                type: 'PROFILE_FETCH_SUCCESS',
                payload:{
                    profile: profile,
                    following: following_data.data.following,  
                    following_count: following_data.count,
                    followers: followers_data.data.followers,  
                    followers_count: followers_data.count,
                    connections: connections_data.data,  
                    representatives: reprentatives_data.data,
                    petitions: petitions_data.data,
                    loaded: true,
                    error: false,  
                  }
            });
        }

        } catch(err) {
          console.log(err)
          dispatch({type: 'PROFILE_FETCH_ERROR'});

        }
};


export const getAnonymousProfile = (username) => async (dispatch, getState) => {
  try {
  dispatch({type: 'PROFILE_LOADING'});
  const profile_data = await axios.get(`${allyus_url}/users/username/${username}`);
  var profile = profile_data.data;
  if (profile) {
      profile.anonymous = true;  // IMPORTANT user could have account in not public mode, but we want to show anonymous profile on anonymous profile page
      if ( profile && firebase.auth().currentUser  && (getState().currentUser.uuid === profile.uuid) ){
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        const userActivity_data = await axios.get(`${allyus_url}/users/me/history?access_token=${access_token}`);
        dispatch({ 
            type: 'PROFILE_FETCH_SUCCESS',
            payload:{
                profile: profile,
                userActivity: userActivity_data.data ? userActivity_data.data : [] ,
                loaded: true,
                error: false,  
            }
        });
      } else {
        dispatch({ 
            type: 'PROFILE_FETCH_SUCCESS',
            payload:{
                profile: profile,
                userActivity: [],
                loaded: true,
                error: false,  
            }
        });
    }
  }

  } catch(err) {
  console.log(err)
  dispatch({type: 'PROFILE_FETCH_ERROR'});

  }
};

