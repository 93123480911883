import React from 'react';
import { getUserProfilePic, profilePicError } from '../../functions/profile';

const AllyusListItemCard = ({ item , toggle, edit, my_endorsement }) => {
    const endorser = item.endorser_id;
    var img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
        : item.organization ? 'user-img-organization' : 'user-img-voter';
    return (
        <div className="allyus-list-item">
            <img src={getUserProfilePic(endorser)} className={`user-img ${img_style}`} alt="rep"  onError={e => profilePicError(e)} />
            <div>
                <p className="allyus-card-heading mb-0 ">{endorser.full_name} &nbsp;&nbsp;
                  {
                        endorser.civic_verified ? <img src='/icons/new/verified-checkmark-politician.svg' alt="rep" /> : ''
                    }
                </p>

                <p className="allyus-card-sub-heading ">
                    {endorser.office ? endorser.office + ", " : ""}{endorser.location_city ? endorser.location_city : endorser.location_region}
                </p>
            </div>
            { edit || my_endorsement ?
                <div className="list-item-wrapper" >
                    <img
                        src={'/icons/new/close-icon.svg'}
                        alt="close"
                        className="ml-auto"
                        onClick={(e) => toggle(e, endorser._id)}
                    />
                </div>
            : null }
        </div>
    )
}

export default AllyusListItemCard;