import React, { Component } from "react";
import Button from "../../button/index";
import { Link } from "react-router-dom";
import { getUserProfilePic, profilePicError } from "../../../functions/profile";

const RepItem = props => {
  const { currentUser, followUser, unfollowUser, index, rep } = props;
  return currentUser._id === rep.id ? null : (
    <div class="rep-main" key={index}>
      <div className="rep-img">
        <Link to={`/politicians/${rep.nameUrl}`}>
          <img src={getUserProfilePic(rep)} alt="" className="user-img-politician" onError={e => profilePicError(e)} />
        </Link>
      </div>
      <div className="title">
        <Link to={`/politicians/${rep.nameUrl}`}>
          <p className="allyus-card-heading mb-0 ">{rep.full_name}</p>
        </Link>
        <p className="allyus-card-sub-heading ">{rep.office}</p>
        {currentUser.following.includes(rep._id) ? (
          <Button
            click={() => unfollowUser({ id: rep._id })}
            className="allyus-btn allyus-secondery-btn"
            width="100"
          >
            Following
          </Button>
        ) : (
          <Button
            click={() => followUser({ id: rep._id })}
            className="allyus-btn allyus-secondery-border-btn"
            width="100"
          >
            Follow
          </Button>
        )}
      </div>
    </div>
  );
};

export default class RepresentativeCard extends Component {
  constructor() {
    super();
    this.state = { screenWidth: 0, seeAll: false };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateWindowDimensions());
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ screenWidth: window.innerWidth });
  }

  render() {
    const { currentUser, followUser, unfollowUser } = this.props;
    const { seeAll, screenWidth } = this.state;
    var { representatives } = currentUser;
    if (!currentUser || !representatives) {
      representatives = [];
    }
    return (
      <div className="rep-card allyus-card">
        {this.props.is_politician ? (
          <p className="rep-card-title allyus-card-title">
            other representatives{" "}
          </p>
        ) : (
          <p className="rep-card-title allyus-card-title">
            find your representatives{" "}
          </p>
        )}
        {
          currentUser._id || currentUser.uuid ? null :
          <p style={{marginBottom:0}}>(sign-in required)</p>
        }
        
        <hr />
        <div className="rep-card-body">
          {representatives.map((rep, index) => {
            if ((!seeAll && index < 4) || seeAll || (screenWidth < 1200))
              return <RepItem rep={rep} index={index} {...this.props} />;
          })}
        </div>
        {
          seeAll ? 
          <p
            className="allyus-text-primary"
            onClick={() => this.setState({ seeAll: false })}
            style={{ cursor: "pointer" }}
          >
            View Less <i className="fa fa-angle-up"></i>
          </p>
          :
          <p
            className="allyus-text-primary"
            onClick={() => this.setState({ seeAll: true })}
            style={{ cursor: "pointer" }}
          >
            View All <i className="fa fa-angle-down"></i>
          </p>
        }
      </div>
    );
  }
}
