export const setInvites = (contacts, owner) => async (
    dispatch,
    getState,
  ) => {
    var contacts_array = getState().invitesReducer.emails;
    for (var i = 0; i < contacts.length; i++){
    //   contacts_array.push({email: contacts[i].selectedEmail(), first_name: contacts[i].first_name });
        contacts_array.push(contacts[i].selectedEmail());
    }
    //var owner_email = (owner && owner.email && owner.email[0] && owner.email[0].address) || "";
    var owner_email = "";
    dispatch({
        type: 'SET_INVITES',
        set_invites: {
            owner_email,
            emails: contacts_array
        }
    })
}

export const clearInvites = () => {
    return({
        type: 'CLEAR_INVITES'
    })
}