import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';
import Button from '../../button/index';
import Autocomplete from '../../autoComplete/index';
import { getUserProfilePic, profilePicError } from '../../../functions/profile';

import _ from 'lodash';
import { Link } from 'react-router-dom';

const Reps = props => {
  const repsList = props.list;
  const {currentUser, followUser, unfollowUser} = props;
  return (
    <ul>
      {
        repsList && repsList.map((item, i) => {
          const buttonType = item.following ? 'allyus-secondery-btn' : 'allyus-secondery-border-btn';
          const btnText = item.following ? "following" : "follow";
          var img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
          : item.organization ? 'user-img-organization' : 'user-img-voter';
          return <li key={i}>
            <div className="allyus-list-item">
               <Link to={`/politicians/${item.nameUrl}`}>
                 <img src={getUserProfilePic(item)} onError={e => profilePicError(e)} className={`user-img ${img_style}`} alt="rep" />
                </Link>
              <div>
                <p className="allyus-card-heading mb-0 ">{item.full_name} &nbsp;&nbsp;
                {
                    item.civic_verified ? <img src='/icons/new/verified-checkmark-politician.svg' alt="rep" /> : ''
                  }
                </p>

                <p className="allyus-card-sub-heading ">
                  {item.office}
                </p>

              </div>
                {currentUser.following.includes(item._id) ? (
                    <Button click={() => unfollowUser({ id: item._id })}
                      className="allyus-btn allyus-secondery-btn" width="100">
                      Following
                    </Button>
                  ) : (
                    <Button click={() => followUser({ id: item._id })}
                      className="allyus-btn allyus-secondery-border-btn" width="100">
                      Follow
                    </Button>
                )}
            </div>
          </li>
        })
      }
    </ul>
  );
}

export default class UserRepresentativeCard extends Component {
  state = {
    activeSubTab: '1',
  }
  toggle = (tab) => {
    if (this.state.activeSubTab !== tab) {
      this.setState({
        activeSubTab: tab
      });
    }
  }
  render() {
    const { allList, my_profile, first_name } = this.props;
    var name = my_profile ? "your" : first_name + "'s";
    var municipalList = _.filter(allList, function(o) { return o.officeLevel === 'municipal'; });
    var regionalList = _.filter(allList, function(o) { return o.officeLevel === 'regional'; });
    var federalList = _.filter(allList, function(o) { return o.officeLevel === 'federal'; });

    return (
      <div className="userConnections-card allyus-card allyus-sub-tab-nav">
        <p className="allyus-card-title">{name} Representatives </p>
        <hr />
        {/* <Autocomplete
          className="d-block d-md-none mb-3"
          suggestions={[
            "branda lilly james",
            "branda albert",
            "branda abalos",
            "branda lee",
            "branda nuu",
            "norah branda diaz",
            "brenda anne northcott",
            "nadia iqbal",
            "scott peacock"
          ]}
        /> */}
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === '1' })}
              onClick={() => { this.toggle('1'); }}
            >
              All
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === '2' })}
              onClick={() => { this.toggle('2'); }}
            >
              Municipal
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === '3' })}
              onClick={() => { this.toggle('3'); }}
            >
              State
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === '4' })}
              onClick={() => { this.toggle('4'); }}
            >
              Federal
            </NavLink>
          </NavItem>
          <NavItem className="ml-auto ml-auto d-none d-md-block">
            {/* <Autocomplete
              suggestions={[
                "branda lilly james",
                "branda albert",
                "branda abalos",
                "branda lee",
                "branda nuu",
                "norah branda diaz",
                "brenda anne northcott",
                "nadia iqbal",
                "scott peacock"
              ]}
            /> */}
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeSubTab}>
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <Reps list={allList} {...this.props}/>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <Reps list={municipalList} {...this.props}/>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="3">
            <Row>
              <Col sm="12">
                <Reps list={regionalList} {...this.props}/>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="4">
            <Row>
              <Col sm="12">
                <Reps list={federalList} {...this.props}/>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    )
  }
}
