import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';
import AllyusCard from '../../allyusCard/index';
import {AllyusDatePicker } from '../../common/index';
import profile_fields from '../../../formdata/profilefields.json';
import moment from 'moment';

// TODO zip code to district id
class PolititionProfileInfoCard extends Component {
    constructor(props) {
        super(props);
        const { info } = props;
        this.state = { 
          modal: false,
          office : info.office,
          incumbency : info.incumbency,
          ethnicity : info.ethnicity,
          religion : info.religion,
          education: info.education,
          marital_status : info.marital_status,
          gender: info.gender,
          year : info.electionDate ? moment(info.electionDate).format("Y") : null,
          month : info.electionDate ? parseInt(moment(info.electionDate).format("M")) - 1 : null,
          day : info.electionDate ? moment(info.electionDate).format("DD") : null,
         };    
    }
    toggle = (e) => {
        this.setState(prevState => ({
          modal: !prevState.modal
        }));
      }
    
    handleDateChange = (value, element) => {
        this.setState({[element]: value})
    }

    componentDidUpdate(prevProps) {
        if (prevProps.info._id !== this.props.info._id){
            const { info } = this.props;
            this.setState({ 
              modal: false,
              office : info.office,
              incumbency : info.incumbency,
              ethnicity : info.ethnicity,
              religion : info.religion,
              education: info.education,
              marital_status : info.marital_status,
              gender: info.gender,
              year : info.electionDate ? moment(info.electionDate).format("Y") : null,
              month : info.electionDate ? parseInt(moment(info.electionDate).format("M")) - 1 : null,
              day : info.electionDate ? moment(info.electionDate).format("DD") : null,
             });   
        }
    }

    
    handleInputChange = (event)=> {
        const value = event.target.value;
        // const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = event.target.name;
        this.setState({
        [name]: value
        });
    }

    onSubmit = () => {
        const { 
            office,
            incumbency,
            ethnicity ,
            religion ,
            education,
            marital_status ,
            gender ,          
            year,
            month,
            day,
         } = this.state;
        const electionDate = new Date(year, month, day);
        const updatedProfile = { 
            office,
            incumbency,
            ethnicity ,
            religion ,
            education,
            marital_status ,
            gender ,
            electionDate
        } 
        this.props.editUser(updatedProfile);
        this.toggle();
      }
    
    renderProfileInfo = () => {
        const { info, my_profile, userAdmin } = this.props;
        return (
            <AllyusCard
                title='Full Profile'
                edit={my_profile || userAdmin}
                click={this.toggle}
                theme='p'
            >
                <div className="profileInfo">
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">office</span>
                        <span className="allyus-card-sub-heading"> {info.office}</span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">incumbency</span>
                        <span className="allyus-card-sub-heading">{info.incumbency} </span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">election date</span>
                        <span className="allyus-card-sub-heading">{info.electionDate ? moment(info.electionDate).format("DD/MM/YYYY") : ''}</span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">Ethnicity</span>
                        <span className="allyus-card-sub-heading">{info.ethnicity} </span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">Religion</span>
                        <span className="allyus-card-sub-heading">{info.religion} </span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">Education</span>
                        <span className="allyus-card-sub-heading">{info.education}</span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">Marital Status</span>
                        <span className="allyus-card-sub-heading">{info.marital_status} </span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">Gender</span>
                        <span className="allyus-card-sub-heading">{info.gender} </span>
                    </p>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className="allyus-card-title">Edit Full Profile</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">office</Label>
                                <Input name="office"  onChange={this.handleInputChange} value={this.state.office} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">incumbency</Label>
                                <Input  id="exampleSelect" name="incumbency"  onChange={this.handleInputChange} value={this.state.incumbency} >
                                </Input>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">Election Date</Label>
                                <AllyusDatePicker className="w-75" start="1940" {...this.state} handleDateTimeChange={this.handleDateChange.bind(this)} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">Ethnicity</Label>
                                <Input type="select" id="exampleSelect" name="ethnicity" onChange={this.handleInputChange} value={this.state.ethnicity}>
                                    <option value=""></option>
                                    {
                                        profile_fields.ethnicity.map((field, i) => {
                                            return <option value={field}>{field}</option>
                                        })
                                    }  
                                </Input>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">Religion</Label>
                                <Input type="select" id="exampleSelect" name="religion" onChange={this.handleInputChange} value={this.state.religion}>
                                      <option value=""></option>
                                     {
                                        profile_fields.religion.map((field, i) => {
                                            return <option value={field}>{field}</option>
                                        })
                                    }  
                                </Input>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">Education</Label>
                                <Input type="select" id="exampleSelect" name="education" onChange={this.handleInputChange} value={this.state.education}>
                                     <option value=""></option>
                                    {
                                        profile_fields.education.map((field, i) => {
                                            return <option value={field}>{field}</option>
                                        })
                                    }  
                                </Input>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">Marital Status</Label>
                                <Input type="select" name="marital_status" onChange={this.handleInputChange} value={this.state.marital_status}>
                                     <option value=""></option>
                                    {
                                        profile_fields.marital_status.map((field, i) => {
                                            return <option value={field}>{field}</option>
                                        })
                                    }  
                                </Input>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">Gender</Label>
                                <Input type="select" id="exampleSelect" name="gender" onChange={this.handleInputChange} value={this.state.gender} >
                                     <option value=""></option>
                                    {
                                        profile_fields.gender.map((field, i) => {
                                            return <option value={field}>{field}</option>
                                        })
                                    }  
                                </Input>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={this.onSubmit}> save </Button>
                    </ModalFooter>
                </Modal>

            </AllyusCard>
        )
    }
    render() {
        return this.renderProfileInfo();
    }

}

export default PolititionProfileInfoCard;
