import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

export default class PrivacyPolicy extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {

    return (
      <div className="mt-3">
        <Container fluid>
          <Helmet>
            <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
            <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
            {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
            <meta name="twitter:image" content="/allyuslogo.png"  />
            <meta property="og:image" content="/allyuslogo.png"  />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>

          <Row style={{justifyContent:'center'}}>
              <Col xs={12} lg={6}>
              <iframe title='Privacy Policy' width='100%' height='900px'  style ={{maxWidth:'100%'}} src="https://docs.google.com/document/d/e/2PACX-1vQTjmruEue2HTWpZgvn3qMcXYA6vb0iKbAsGfCZ7lkf2yTQT0B0ygq5l6d3A-RfkYm2d-ir03weBoD9/pub?embedded=true"></iframe>

              </Col>
          </Row>
        </Container>
      </div>
    );
  }
}


