import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../button";
import LocationDetails from "./index";

const LocationDetailsModal = props => {
    const {location_info, open, toggle, onSubmitLocation } = props;
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="user-detail-modal-dialog"
    >
      <ModalHeader
        toggle={toggle}
      >
        Location Details
      </ModalHeader>

      <ModalBody>
        <div className="allyus-form">
          <LocationDetails location_info={location_info} />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="allyus-btn allyus-primary-btn"
          click={onSubmitLocation}
        >
          {" "}
          confirm{" "}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default LocationDetailsModal;