import React from 'react';
import {
  NavItem,
  NavLink,
  Nav,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import DemoPie from './DemoPieGraph';

export default class Demoghraphics extends React.Component {
  state = {
    dropdownOpen: false,
    activeTab: 'political affiliation'
  };

  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }

  renderActiveTab(){
    const {demographics} = this.props;
    switch (this.state.activeTab) {
      case 'age group': 
        return  <DemoPie {...this.props}  type={"age_group"} />
      case 'gender': 
        return  <DemoPie  {...this.props} type={this.state.activeTab} />
      case 'location': 
        return  <DemoPie  {...this.props} type={'location_city'}/>
      case 'education': 
        return  <DemoPie  {...this.props} type={this.state.activeTab} />
      case 'religion': 
    return  <DemoPie  {...this.props} type={this.state.activeTab}/>  
      case 'ethnicity': 
        return  <DemoPie   {...this.props} type={this.state.activeTab} />      
      case 'marital status': 
        return  <DemoPie  {...this.props} type={this.state.activeTab.replace(" ", "_")} />  
      case 'political affiliation': 
        return  <DemoPie {...this.props} type={this.state.activeTab.replace(" affiliation", "_lean")}/>
      default:
        return   <DemoPie {...this.props} type={this.state.activeTab.replace(" affiliation", "_lean")}/>
    }

  }

  render() {
    const { upvotes, downvotes, neutralvotes, demographics, currentUser, } = this.props;
    var demo_data = false;
    for (let i in demographics){
      for (let j in demographics[i]){
        if (demographics[i][j].length){
          demo_data = true;
          break;
        }
      }
    }

    if  ( this.props.isPoll && !this.props.post.featured && 
        (!currentUser || 
        (!currentUser.profile_creation_completed && (currentUser.__t !== 'politician') && !currentUser.admin )
        )
        ) {
      return(
      <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
        Complete your profile to see demographics data
      </div>
      );
     }
    else if (!demo_data){
      return(
        <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
          Demographics not available yet
        </div>
        );
    }
    return (
      <div className="demographics">
        <Nav className="allyus-menu">
          <NavItem>
            <NavLink active>sort</NavLink>
          </NavItem>
          <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
            <DropdownToggle nav caret>
              {this.state.activeTab}
            </DropdownToggle>
            <DropdownMenu left>
              <DropdownItem onClick={() => this.setState({activeTab:'age group'})}>age group</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'gender'})}>gender</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'location'})}>location</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'education'})}>education</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'religion'})}>religion</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'ethnicity'})}>ethnicity</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'marital status'})}>marital status</DropdownItem>
              <DropdownItem onClick={() => this.setState({activeTab:'political affiliation'})}>political affiliation</DropdownItem>
            </DropdownMenu>
          </Dropdown>
        </Nav>
        {
          !demographics ? null : 
          this.renderActiveTab()
        }
        <p>(hover to see percentages)</p>
      </div>
    );
  }
}