import React, { Component } from 'react';
import { AllyusAccordian, AccordianItem } from '../../allyusAccordian';
import { Row, Col } from 'reactstrap';
import Button from '../../button/';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import IssueCard from '../../issuesCard/index';
import IssueIconsData from '../../../formdata/issueIcons.json';

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../../config";
const { allyus_url } = config;

// TODO refresh after save
class PolititionIssueSCard extends Component {
    state = {
        modalEditIssue: false,
        activeItem : 1,

        title: '',
        description: '',
        icon_image: '',
        legislation: '',
        news_link: '',
        issueID: '',
        anon_comments_disabled: false,
    }

    toggleEditIssue = (e) => {
        this.setState(prevState => ({
            modalEditIssue: !prevState.modalEditIssue
        }));
    }
    toggle = itemId => {
        this.setState({
            activeItem: this.state.activeItem !== itemId ? itemId : ''
        });
    }

    openEditIssue = (e, item) => {
        this.setState({
            modalEditIssue: true,
            title: item.title,
            description: item.description,
            icon_image: item.icon_image,
            legislation: item.legislation,
            news_link: item.news_link,
            issueID: item._id,
            anon_comments_disabled: item.anon_comments_disabled,
        });
    }

    openAddIssue = (e) => {
        this.setState({
            modalEditIssue: true,
            title: '',
            description: '',
            icon_image: '',
            legislation: '',
            news_link: '',
            anon_comments_disabled: false,
        });
    }

    async saveIssue() {
        try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            const { userAdmin, profile: {_id} } = this.props;
            const {
                title,
                description,
                icon_image,
                legislation,
                news_link,
                issueID,
                anon_comments_disabled
            } = this.state;
            const platform = { 
                title: title,
                description : description,
                icon_image: icon_image,
                legislation: legislation,
                news_link: news_link,
                anon_comments_disabled: anon_comments_disabled
            };
            if (issueID){
                await axios.put(`${allyus_url}/politiciansplatform/${issueID}?access_token=${access_token}`, {updatedData : platform});
            }else if (!userAdmin) {
                await axios.post(`${allyus_url}/politiciansplatform?access_token=${access_token}`, {platform : platform});
            } else {
                await axios.post(`${allyus_url}/admin/politicians/${_id}/platform?access_token=${access_token}`, {platform : platform});
            }
            this.setState({
                modalEditIssue: false,
                title: '',
                description: '',
                icon_image: '',
                legislation: '',
                news_link: '',
                issueID: '',
                anon_comments_disabled: false
            });
            this.props.getProfile();
        }
        catch(err){
            console.log(err);
        }
    }

    async deleteIssue(issueID) {
        try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            if (issueID){
                await axios.delete(`${allyus_url}/politiciansplatform/${issueID}?access_token=${access_token}`);
                this.props.getProfile();
            }
        }
        catch(err){
            console.log(err);
        }
    }

    render() {
        const { my_profile, profile, issuesList, userAdmin, currentUser } = this.props;
        const {
            title,
            description,
            icon_image,
            legislation,
            news_link,
        } = this.state;
        const { issueIcons } = IssueIconsData;
        return (
            <AllyusAccordian>
                {
                    (my_profile || userAdmin) && issuesList.length === 0 ?
                        <Col sm="12"  >
                            <Button click={this.openAddIssue}>
                                    <img src={`/icons/new/plus-icon.svg`} alt="add" /> &nbsp;
                                <span className="allyus-text-secondery"> Add Issue </span>
                                </Button> 
                        </Col>
                    :
                    issuesList.map((item, index) => {
                        return (
                            <AccordianItem toggle={ (itemId)=>{ this.toggle(itemId) }} itemId={index + 1} activeItem={this.state.activeItem}>
                                <Col sm="12">
                                    <IssueCard
                                        issue={item}
                                        key={index}
                                        name={profile.full_name}
                                        toggleAddIssue={(e) => this.openAddIssue(e)}
                                        toggleEditIssue={(e) => this.openEditIssue(e, item)}
                                        edit={my_profile || userAdmin}
                                        toggle={ (itemId)=>{ this.toggle(itemId) }}
                                        itemId={index + 1}
                                        activeItem={this.state.activeItem}
                                        isOpen={this.state.activeItem === (index + 1)}
                                        deletePost={this.deleteIssue.bind(this)}
                                    />
                                </Col>
                            </AccordianItem>
                        )
                    })
                }
                {/* ************************ Modal Edit Event ******************************* */}

                <Modal isOpen={this.state.modalEditIssue} toggle={this.toggleEditIssue} >
                    <ModalHeader toggle={this.toggleEditIssue}>Edit Issue</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="issueTitle">Issue title</Label>
                                <Input type="text" id="issueTitle" placeholder="e.g housing" 
                                 value={this.state.title}
                                 onChange={(e) => this.setState({title: e.target.value})}
                                 />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="issueTitle">Icon</Label>
                                <div className="issue-icons">
                                    {
                                        issueIcons.map((src, i) => {
                                            return (
                                                <div className={this.state.icon_image === src ? "selectedIcon" : ''}>
                                                    <a onClick={() => this.setState({icon_image: src})} >
                                                    <img src={src} alt={src} key={i}  style={{fill:'blue'}}/>
                                                    </a>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="issueDesc">Issue description</Label>
                                <Input type="textarea" id="issueDesc" 
                                    value={this.state.description}
                                    onChange={(e) => this.setState({description: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="IssueAct">endorsed acts or legislation on the issue</Label>
                                <Input type="text" id="IssueAct" placeholder="e.g the common sense housing investment act (H.R.987)"
                                  value={this.state.legislation}
                                  onChange={(e) => this.setState({legislation: e.target.value})}
                                 />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="newslink">NEWS Link</Label>
                                <Input type="text" id="newslink" 
                                   value={this.state.news_link}
                                   onChange={(e) => this.setState({news_link: e.target.value})}
                               />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        {
                          currentUser && ( currentUser.user_politician || currentUser.admin ) ?
                        <FormGroup check>
                            <Label check>
                            <Input type="checkbox" 
                            checked={this.state.anon_comments_disabled}
                            onClick={() => this.setState({anon_comments_disabled:!this.state.anon_comments_disabled})}
                            />{' '}
                            Disable Anonymous Comments
                            </Label>
                        </FormGroup>
                        : null }
                        <Button className="allyus-btn allyus-secondery-btn" click={this.saveIssue.bind(this)}> save </Button>
                    </ModalFooter>
                </Modal>
            </AllyusAccordian>

        )
    }
}

export default PolititionIssueSCard;