import React, {Component} from 'react';

import { connect } from 'react-redux';
import { getCurrentUser } from '../../store/actions'; 

import config from '../../config';
import axios from 'axios';
import * as firebase from "firebase/app";

const {allyus_url} = config;
const civic = window.civic;
const civicSip = new civic.sip({ appId: 'p3uB8aSJF' });
 
class CivicButton extends Component {

    getCivicSignup = () => {      
        civicSip.signup({  scopeRequest: civicSip.ScopeRequests.BASIC_SIGNUP });

        civicSip.on('auth-code-received', async (event) => {

          // encoded JWT Token is sent to the server
          var jwtToken = event.response;
          // Your function to pass JWT token to your server
          try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
  
            await axios.put(
                `${allyus_url}/civic/verify?access_token=${access_token}`,
                {jwtToken:jwtToken}
               );
             this.props.getCurrentUser();
              // window.location.reload();
            } catch(error){
                console.log(error);
                alert("Error Saving Civic Verification. ");
            }
        });
        // Error events.
        civicSip.on('civic-sip-error', function (error) {
            // handle error display if necessary.
            console.log('   Error type = ' + error.type);
            console.log('   Error message = ' + error.message);
        });
    };
      
    render(){
        return(
            <p className="allyus-text " ><a onClick={()=> this.getCivicSignup()} className="allyus-text-primary">Verify your profile</a> with Civic</p>
        );
    }
  }

 
  export default connect(()=>{}, {getCurrentUser})(CivicButton);