import React from 'react'
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { updateFeedFilter } from '../../../store/actions';
import locations from  '../../../formdata/locations.json'

class SubNavbarFeed extends React.Component {
  state = {
    isOpen: false,
    locations_index: null
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

 
  render() {
    const { 
      type,
      trending,
      country,
      region,
      updateFeedFilter
    } = this.props;
   
    return (
      <div className="allyus-sub-nav mobile-none">
        <Navbar color="light" light expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink >Sort</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {type}
                </DropdownToggle>
                <DropdownMenu left="true">
                  <DropdownItem onClick={() => updateFeedFilter('type', 'all')}>
                    all
                  </DropdownItem>
                  <DropdownItem onClick={() => updateFeedFilter('type', 'polls')}>
                    polls
                  </DropdownItem>
                  <DropdownItem onClick={() => updateFeedFilter('type', 'news')}>
                    news
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {trending}
                </DropdownToggle>
                <DropdownMenu left="true">
                  <DropdownItem onClick={() => updateFeedFilter('trending', 'trending')}>
                    trending
                  </DropdownItem>
                  <DropdownItem  onClick={() => updateFeedFilter('trending', 'following')}>
                    following
                  </DropdownItem>
                  <DropdownItem  onClick={() => updateFeedFilter('trending', 'recent')}>
                    recent
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {country === 'all' ? 'Country': country}
                </DropdownToggle>
                <DropdownMenu left ="true">
                  {Object.values(locations).map((location, index) => {
                    return (
                          <DropdownItem onClick={() => {this.setState({locations_index: index}); updateFeedFilter('country', location.name); updateFeedFilter('region', 'all'); }}>
                            {location.name}
                        </DropdownItem>
                    );
                  })}
                    <DropdownItem onClick={() => {this.setState({locations_index: null}); updateFeedFilter('country', 'all'); updateFeedFilter('region', 'all');}}>
                          all
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                   {region === 'all' ? 'State': region}
                </DropdownToggle>
                <DropdownMenu left="true" style={{overflow: 'auto', height: 500}}>
                  <DropdownItem onClick={() => {updateFeedFilter('region', 'all');}}>
                          all
                  </DropdownItem>
                  {
                    // country && country.toLowerCase() !== 'all' ?
                      Object.values(locations["United States"].states).map((region, index) => {
                        return (
                          <DropdownItem onClick={() => updateFeedFilter('region', region.name)}>
                            {region.name}
                        </DropdownItem>
                      );
                    })
                    // : null
                  }
                </DropdownMenu>
              </UncontrolledDropdown> */}
            </Nav>
          </Collapse>
        </Navbar>

      </div>
    )
  }
}
const mapStateToProps = state => {
  const { 
    type,
    trending,
    country,
    region,
  } = state.feedFilters;
  const { currentUser } = state;
  return {
    type,
    trending,
    country,
    region,
    currentUser
  };
}

export default connect(mapStateToProps, { updateFeedFilter })(SubNavbarFeed);