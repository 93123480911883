import React, { Component } from 'react';

const AllyusAccordian = props => {
    return (
        <div className="accordian">
            {props.children}
        </div>
    )
}

class AccordianItem extends Component {
    state = {
        activeItem: 1
    }
    
    render() {
        const { itemId } = this.props;
        const { activeItem } = this.props;
        let itemClass = ["accordian-item"];
        if (activeItem === itemId) {
                itemClass.push("active-accordian");
        }


        return (
            <div
                className={itemClass.join(' ')}
                // onClick={() => this.props.toggle(itemId)}
            >
                {this.props.children}
            </div>
        )
    }

}
const AccordianHead = props => {
    return (
        <div className={`accordian-item-head`}>
            {props.children}
        </div>
    )
}
const AccordianBody = props => {
    return (
        <div className={`accordian-item-body`}>
            {props.children}
        </div>
    )
}

export { AllyusAccordian, AccordianItem, AccordianHead, AccordianBody }