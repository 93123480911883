import React from 'react';
import Button from '../button/index';
import { Modal, ModalHeader, ModalFooter, ModalBody } from 'reactstrap';
import MicrolinkCard from '@microlink/react';

class NewsCard extends React.Component {
    state = {
        modal: false,
    }

    toggle = (e) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }
    render() {
        const { news: { title, description, icon, link, image, provider, _id }, edit, deleteNews } = this.props;
        return (
            <div className="news">
                { edit ?
                <div className="news-wrapper" >
                    <img
                        src={'/icons/new/close-icon.svg'}
                        alt="close"
                        className="ml-auto"
                        onClick={this.toggle}
                    />
                </div>
                : null }
                {/* <div className="news-title mb-3">
                    <img src={icon} alt={provider} className="news-icon" />
                    &nbsp;{provider}
                </div>
                <div className="news-summery">
                    <h3 className="allyus-card-summery">{title}</h3>
                </div>
                <div className="news-desc">
                    <p className="allyus-text">{description}</p>
                </div>
                <a href={link} target="blank" ><p className="allyus-text-secondery">Read More <i className="fa fa-angle-right"></i> </p></a> */}

                <MicrolinkCard url={link} />
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}>Delete Article</ModalHeader>
                    <ModalBody>
                        <p className="allyus-text"> Are you sure you want to delete this  article? </p>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-white-btn" click={() => { deleteNews(_id); this.toggle();}}> Delete </Button>
                        <Button className="allyus-btn allyus-secondery-btn" click={this.toggle}> Not really </Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default NewsCard;