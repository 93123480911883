const INITIAL_STATE = {
    type: 'all',
    trending: 'recent',
    country: 'all',
    region: 'all',
};

 const selectorsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'UPDATE_FEED_FILTER':
            return {...state, [action.filter_name]: action.filter_value};
        default:
            return state;
    }
};

export default selectorsReducer;