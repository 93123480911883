import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import ChatHead from "./chatHead/index";
import MessageList from "./messageList/index";
import ChatInput from "./chatInput";
import AllyuCardWrapper from "../allyusCardWrapper/index";
import { hideChatWindow } from "./actions";
import {
  initializeChatKit,
  sendMessage,
  sendMessageBlast,
  setReadCursor,
  startConvo,
  joinRoom
} from "../../store/actions";

// TODO new chat
class AllyusChatWindow extends Component {
  state = {
    inputtext: ""
  };

  sendMessage() {
    const { roomId } = this.props.chatkit;
    this.props.sendMessage(roomId, this.state.inputtext);
    this.setState({ inputtext: "" });
  }

  render() {
    const {
      currentUser,
      chatkit: { chatKitUser, room_msgs, rooms, roomId, otherUser, currentRoom }
    } = this.props;

    let renderInput = true;
    if (currentRoom && currentRoom.customData && currentRoom.customData.politicianblast && chatKitUser.id !== currentRoom.customData.politicianId){
      renderInput = false;
    }
    
    return (
      <AllyuCardWrapper className="Allyus-chat-win">
        <ChatHead
          onClose={this.props.hideChatWindow}
          senderName={otherUser ? otherUser.name : ""}
        />
        <MessageList
          messages={room_msgs[roomId] ? room_msgs[roomId] : []}
          chatKitUserId={chatKitUser ? chatKitUser.id : ""}
        />
        {!renderInput ? null : (
          <ChatInput
            value={this.state.inputtext}
            textHandle={e => this.setState({ inputtext: e.target.value })}
            onKeyDown={e => {
              if (e.keyCode === 13 && this.state.inputtext) {
                this.sendMessage();
              }
            }}
          />
        )}
      </AllyuCardWrapper>
    );
  }
}

const mapStateToProps = state => {
  return {
    msgStatus: state.chat.msgStatus,
    chatkit: state.chatkit,
    currentUser: state.currentUser
  };
};

export default compose(
  connect(
    mapStateToProps,
    {
      hideChatWindow,
      initializeChatKit,
      sendMessage,
      sendMessageBlast,
      setReadCursor,
      startConvo,
      joinRoom
    }
  )
)(AllyusChatWindow);
