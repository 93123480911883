import * as firebase from "firebase/app";
import "firebase/database";
import _ from 'lodash';
import axios from 'axios';
import config from '../../config';
const { allyus_url } = config;

export const getNotifications = (set_clicked) => async(dispatch, getState)=> {
    try {
        const currentUser = firebase.auth().currentUser;
        const access_token = await currentUser.getIdToken(true);
        const firebase_id = currentUser.uid;

        firebase.database().ref('notifications/' + firebase_id + '/notifications')
            .on('value', async function(snapshot) {
                const notifications_data = await axios.get(`${allyus_url}/notifications?access_token=${access_token}`);
                const notifications = notifications_data.data;
                var badge = 0;
                if (!set_clicked){
                    badge =  _.filter(notifications, function(o) { return !o.details.clicked; }).length;
                }
                dispatch({
                    type: 'NOTIFICATIONS_FETCH',
                    notifications: notifications,
                    badge: badge
                })
            });
    } catch (err) {
        console.log(err);
    }
}

export const setBadgeClicked = () => {
    return({
        type: 'SET_BADGE_CLICKED'
    })
}

export const clickNotification = (notification_id) => async(dispatch, getState)=> {
    try {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(`${allyus_url}/notifications/${notification_id}/clicked?access_token=${access_token}`);
        dispatch(getNotifications(true));

    } catch(err){
        // console.log(err);
    }

}