import React, { Component } from 'react';

export default class SponsoredCard extends Component {
  state = {
    sponsored: [{
      title: 'sed do eiusmod tempor incididunt',
      text: 'consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      url: 'www.magnaliq.com',
      img1: '/images/sbg1.png',
      img2: '/images/sbg3.png'
    }, {
      title: 'sed do eiusmod tempor incididunt',
      text: 'consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore magna aliqua',
      url: 'www.magnaliq.com',
      img1: '/images/sbg4.jpg',
      img2: ''
    }]
  }
  render() {
    const { sponsored } = this.state;
    return (
      <div className="sponsored-card allyus-card">
        <p className="sponsored-card-title allyus-card-title">sponsored </p>
        <hr />
          {
            sponsored.map((sp, i) => {
              return (
                <div className="mb-3" key={i}>
                  <div className="sponsored-detail">
                    <h4 className="allyus-card-summery"> {sp.title} </h4>
                    <p className="allyus-text"> {sp.text} </p>
                    <p><a href="/" className="allyus-text-primary">{sp.url}</a></p>
                  </div>
                  <div className="sponsored-img allyus-card-images">
                    {sp.img1 && <img src={sp.img1} alt="sponsor"/>}
                    {sp.img2 && <img src={sp.img2} alt="sponsor"/>}
                  </div>
                </div>
              )
            })
          }
      </div>

    )
  }
}
