import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, FormFeedback } from "reactstrap";
import AllyusButton from "../../../components/button";
import { connect } from "react-redux";
import AllyusCard from "../../../components/allyusCard";
import CreatePolitician from "./createPolitician";

import { signInAction, signInSocial, signUpAction } from "../../../store/actions";
import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../../config';

import ReactGA from 'react-ga';
import emailjs from 'emailjs-com';

const { allyus_url }= config;

class signInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      renderAcountType: true,
      renderSignIn: false,
      renderEmailSignIn: false,
      renderCreate: false,
      renderCreatePolitician: false,
      email: "",
      password: "",
      first_name: "",
      last_name: "",
      username: "",
      usernameTaken: false,
      signup_form: true,
      office:'',
      message: '',
      politicianType: '',
      voterAccountType: 'voter',
      politician_or_organization: 'politician',
      organization: '',
    };
  }

  checkUserName = (event) => {
    const value = event.target.value;
    this.setState({
        username: value
    });
    try {
      if (value){
        setTimeout(() => {
            axios.get(`${allyus_url}/users/username/${value}`).then(() =>{
                this.setState({usernameTaken: true});
            }).catch((err) =>{
                // console.log(err);
                // User does not exist
                this.setState({usernameTaken: false});
            });
        }, 500);
      }
    } catch(err){
      console.log(err);
    }
}

  validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-zA-Z]{2}|com|org|net|gov|info|ca|us|io)\b/;
    return re.test(email);
  }

  onSubmitCreatePolitician = async e => {
    const {first_name, last_name, email, office, message, politicianType} = this.state;
    if (email && first_name && last_name && office && this.validateEmail(email)
      ) {
      var template_params = {
        "office": office,
        "first_name": first_name,
        "last_name": last_name,
        "message": message,
        "email": email,
        "type": "Policician Sign Up",
        "politician_type": politicianType
     }
     var service_id = "default_service";
     var template_id = "template_FDVQBhJg";
     try {
      ReactGA.event({
        category: 'SignUp/Login',
        action: 'Submit Account Request',
        label: politicianType
      });
      await emailjs.send(service_id, template_id, template_params)
      alert("Thanks for signing up! We'll review your information and contact you soon.")
      if (this.props.isOpen){
        this.props.toggle();
      }
      // this.props.history.push({
      //   pathname: '/membership_payment',
      //   state: { detail: { email: email, name: first_name + ' ' + last_name } }
      // });
      this.props.history.push('/');
      this.setState({email: '', message: '', first_name: '', last_name: '', office: '', postal_code: '', politicianType: ''});
     } catch(err){console.log(err)};
    } else{
      alert("Please fill all fields and enter a valid email. Contact development@allyus.org with any questions or concerns.");
    }
  }

  onSubmitCreateOrganization = async e => {
    const {first_name, last_name, email, message, organization} = this.state;
    if (email && first_name && last_name && organization && this.validateEmail(email)
      ) {
      var template_params = {
        "organization": organization,
        "first_name": first_name,
        "last_name": last_name,
        "message": message,
        "email": email,
        "type": "Organization Sign Up",
     }
     var service_id = "default_service";
     var template_id = "template_FDVQBhJg";
     try {
      ReactGA.event({
        category: 'SignUp/Login',
        action: 'Submit Account Request',
        label: 'organization'
      });
      await emailjs.send(service_id, template_id, template_params)
      alert("Thanks for signing up! We'll review your information and contact you soon.")
      if (this.props.isOpen){
        this.props.toggle();
      }
      this.setState({email: '', message: '', first_name: '', last_name: '', organization: '', postal_code: '', politicianType: '', politician_or_organization: 'politician'});
      this.props.history.push('/');
     } catch(err){console.log(err)};
    } else{
      alert("Please fill all fields and enter a valid email. Contact development@allyus.org with any questions or concerns.");
    }
  }

  onInputHandle = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  onSubmitCreate = () => {
    if (this.validate()) {
      ReactGA.event({
        category: 'SignUp/Login',
        action: 'Submit Account Create Email',
        label: this.state.voterAccountType,
      });
      this.props.signUpAction(this.state);
      this.setState({password:''});
    } else {
      alert("Please fill all fields");
    }
  };

  onSubmit = () => {
      ReactGA.event({
        category: 'SignUp/Login',
        action: 'Submit Email Login',
      });
      this.props.signInAction(this.state);
      this.setState({password:''});
  };

  sendPasswordReset = () => {
    var auth = firebase.auth();
      var emailAddress = this.state.email;
      auth.sendPasswordResetEmail(emailAddress).then(function() {
        alert("Password Reset instructions sent!");
        ReactGA.event({
          category: 'SignUp/Login',
          action: 'Forgot Password',
        });
      }).catch(function(error) {
        // An error happened.
      });
  }
  
  validate = () => {
    const { email, password, first_name, last_name, username, usernameTaken } = this.state;
    if (email != null && password != null && first_name && last_name && username && !usernameTaken) {
      return true;
    } else {
      return false;
    }
  };

  renderFooter() {
    return (
      <div className="bottom-text">
        <div>
          Already have an account? <span onClick={()=>this.setState({renderSignIn:true, renderAcountType:false, renderCreate:false, signup_form:false})}>Log in</span>
        </div>
        <div className="terms-line">
          By signing up, you agree to our<a href="https://allyus.org/terms" target="blank"><span>Terms</span></a> and that you have
          read our <a href="https://allyus.org/privacypolicy" target="blank"><span>Privacy Policy</span></a>
        </div>
      </div>
    );
  }
  renderForgotPassword() {
    return (
      <div className="bottom-text">
        <div>
          Forgot Your Password? Fill in your email and <span onClick={()=>this.sendPasswordReset()}>Click here</span>
        </div>
        <div className="terms-line">
          By signing up, you agree to our <a href="https://allyus.org/terms" target="blank"><span>Terms</span></a> and that you have
          read our <a href="https://allyus.org/privacypolicy" target="blank"><span>Privacy Policy</span></a>
        </div>
      </div>
    );
  }
  renderEmailSignIn() {
    const { password, email, renderEmailSignIn } = this.state;
    const {authError} = this.props;

    if (renderEmailSignIn) {
      return (
        <AllyusCard title="Log In" className="signIn" isModal={this.props.isModal} onClose={this.props.toggle}>
          {authError ? <div className="alert alert-danger">{authError} </div> : ""}
          <Form className="allyus-form button-group">
            <FormGroup className="allyus-form-group">
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={this.onInputHandle}
              />
            </FormGroup>

            <FormGroup className="allyus-form-group">
              <Label for="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={this.onInputHandle}
              />
            </FormGroup>

            <FormGroup className="allyus-form-group">
              <Button
                id="submit"
                className="allyus-btn allyus-primary-btn signup-btn-m"
                onClick={this.onSubmit}
              >
                {" "}
                Log In{" "}
              </Button>
            </FormGroup>
          </Form>
          {this.renderForgotPassword()}
        </AllyusCard>
      );
    }
  }
  renderCreateAccount() {
    const { password, email, first_name, last_name, username, renderCreate, voterAccountType } = this.state;
    const {authError} = this.props;

    if (renderCreate) {
      return (
        <AllyusCard title={`${voterAccountType} Signup`} className="signIn" isModal={this.props.isModal} onClose={this.props.toggle}>
          {authError ? <div className="alert alert-danger">{authError} </div> : ""}
          <Form className="allyus-form button-group">
            <FormGroup className="allyus-form-group">
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={this.onInputHandle}
              />
            </FormGroup>

            <FormGroup className="allyus-form-group">
              <Label for="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={this.onInputHandle}
              />
            </FormGroup>

            <FormGroup className="allyus-form-group">
              <Label for="first_name">First Name</Label>
              <Input
                id="first_name"
                type="first_name"
                value={first_name}
                onChange={this.onInputHandle}
              />
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Label for="last_name">Last Name</Label>
              <Input
                id="last_name"
                type="last_name"
                value={last_name}
                onChange={this.onInputHandle}
              />
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Label for="username">Anonymous Username</Label>
              <Input
                id="username"
                type="username"
                value={username}
                onChange={this.checkUserName} invalid={this.state.usernameTaken} valid={!this.state.usernameTaken && username}           
               />
              <FormFeedback valid>Great! that name is available</FormFeedback>
              <FormFeedback invalid>Sorry! that name is taken</FormFeedback>
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Button
                id="submit"
                className="allyus-btn allyus-primary-btn signup-btn-m"
                onClick={this.onSubmitCreate}
              >
                {" "}
                Create Account{" "}
              </Button>
            </FormGroup>
          </Form>
          {this.renderFooter()}

        </AllyusCard>
      );
    }
  }

  renderSignIn() {
    const { signup_form, voterAccountType} = this.state;
    if (this.state.renderSignIn) {
      return (
        <AllyusCard
          title={signup_form ? `${voterAccountType} Signup` : "Log In"}
          className="signIn"
          isModal={this.props.isModal} onClose={this.props.toggle}
        >
          <div className="button-group">
            <AllyusButton
              className="allyus-btn signup-btn-m google-btn"
              onClick={() => {
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: signup_form ? `${voterAccountType} Signup` : "Log In",
                  label: 'Google',
                });
                this.props.signInSocial("google", voterAccountType);
              }}
            >
              <img
                src="/icons/google.png"
                className="google-icon"
                alt="google"
              />{" "}
              &nbsp;{signup_form ? "Sign Up with" : "Log In with"} Google
            </AllyusButton>
            <AllyusButton
              className="allyus-btn twitter-btn signup-btn-m"
              onClick={() => {
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: signup_form ? `${voterAccountType} Signup` : "Log In",
                  label: 'Twitter',
                });
                this.props.signInSocial("twitter", voterAccountType);
              }}
            >
              <i class="fab fa-twitter"></i> &nbsp;
              {signup_form ? "Sign Up with" : "Log In with"} Twitter
            </AllyusButton>
            <AllyusButton
              className="allyus-btn facebook-btn signup-btn-m"
              onClick={() => {
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: signup_form ? `${voterAccountType} Signup` : "Log In",
                  label: 'Facebook',
                });
                this.props.signInSocial("facebook", voterAccountType);
              }}
            >
              <i class="fab fa-facebook-square"></i> &nbsp;
              {signup_form ? "Sign Up with" : "Log In with"} Facebook
            </AllyusButton>
            <AllyusButton
              className="allyus-btn allyus-primary-btn signup-btn-m"
              onClick={() =>{
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: signup_form ? `${voterAccountType} Signup` : "Log In",
                  label: 'Email',
                });
                signup_form ?
                this.setState({ renderCreate: true, renderSignIn: false })
                :      this.setState({ renderEmailSignIn: true, renderSignIn: false })
              }}
            >
              <i class="far fa-envelope"></i> &nbsp;
              {signup_form ? "Sign Up with" : "Log In with"} Email
            </AllyusButton>
          </div>
          <hr />
          {!signup_form ? null : this.renderFooter()}
        </AllyusCard>
      );
    }
  }

  renderChooseType() {
    if (this.state.renderAcountType) {
      return (
        <AllyusCard title="Signup" className="signIn" isModal={this.props.isModal} onClose={this.props.toggle}>
          <div>
            <p className="subtitle">Join us and make your voice heard.</p>
            <p>Please select which signup option applies to you:</p>
          </div>
          <div className="button-group">
            <AllyusButton
              className="allyus-btn allyus-primary-btn signup-btn-m white-text"
              onClick={() => {
                this.setState({ renderSignIn: true, renderAcountType: false });
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: 'SignUp Button',
                  label: 'Voter'
                });
              }}
              style={{ color: "white" }}
            >
              Voter
            </AllyusButton>
            <AllyusButton className="allyus-btn allyus-secondery-btn signup-btn-m"
              onClick={() =>{
                this.setState({ renderCreatePolitician: true, renderAcountType: false, politicianType: 'constituency' });
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: 'SignUp Button',
                  label: 'Politician Constituency',
                });
              }} >
                Elected Official Constituency Office
            </AllyusButton>
            <AllyusButton className="allyus-btn allyus-secondery-btn signup-btn-m"
              onClick={() =>{
                this.setState({ renderCreatePolitician: true, renderAcountType: false, politicianType: 'campaign'  });
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: 'SignUp Button',
                  label: 'Politician Campaign',
                });
              }} >
                Political Campaign Office
            </AllyusButton>
            <AllyusButton className="allyus-btn allyus-influencer-btn signup-btn-m"
             onClick={() =>{
              this.setState({ renderSignIn: true, renderAcountType: false, voterAccountType: 'influencer' });
              ReactGA.event({
                category: 'SignUp/Login',
                action: 'SignUp Button',
                label: 'Influencer/Pundit',
              });
            }}
            >
              Influencer/Pundit 
            </AllyusButton>
            <AllyusButton className="allyus-btn allyus-lobbyist-btn signup-btn-m"
              onClick={() =>{
                this.setState({ renderSignIn: true, renderAcountType: false, voterAccountType: 'lobbyist' });
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: 'SignUp Button',
                  label: 'Lobbyist',
                });
              }}
              >
              Lobbyist
            </AllyusButton>
            <AllyusButton className="allyus-btn allyus-org-btn signup-btn-m"
              onClick={() =>{
                this.setState({ renderCreatePolitician: true, renderAcountType: false, politician_or_organization: 'organization'  });
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: 'SignUp Button',
                  label: 'Organization',
                });
              }} >
              Organization
            </AllyusButton>
          </div>
          <hr />
          <div className="bottom-text button-group">
            <div>
              Already have an account? 
            </div>
          <AllyusButton className="allyus-btn allyus-primary-btn signup-btn-m white-text" style={{fontSize: 16, backgroundColor:'#3b7d72'}}
              onClick={()=>{
                this.setState({renderSignIn:true, renderAcountType:false, renderCreate:false, signup_form:false});
                ReactGA.event({
                  category: 'SignUp/Login',
                  action: 'SignUp Button',
                  label: 'Log In',
                });
                }} >
              Log In
            </AllyusButton>
            <div className="terms-line">
              By signing up, you agree to our <a href="https://allyus.org/terms" target="blank"><span>Terms</span></a> and that you have
              read our <a href="https://allyus.org/privacypolicy" target="blank"><span>Privacy Policy</span></a>
            </div>
          </div>
        </AllyusCard>
      );
    }
  }

  renderCreateAccountPolitician(){
    if (this.state.renderCreatePolitician) {
      return <CreatePolitician  
        onInputHandle={this.onInputHandle.bind(this)}  
        renderFooter={this.renderFooter} 
        onSubmitCreatePolitician={this.onSubmitCreatePolitician.bind(this)}
        onSubmitCreateOrganization={this.onSubmitCreateOrganization.bind(this)}
        renderSignInScreen={()=>this.setState({renderSignIn:true, renderCreatePolitician:false})}
        {...this.state} {...this.props} />
    }
  }

  render() {
    const { authError } = this.props;
    return (
      <div >
        {this.renderChooseType()}
        {this.renderSignIn()}
        {this.renderEmailSignIn()}
        {this.renderCreateAccount()}
        {this.renderCreateAccountPolitician()}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    authError: state.auth.authError,
    auth: state.firebase.auth
  };
};


export default connect(
  mapStateToProps,
  {signInAction, signUpAction, signInSocial }
)(signInModal);
