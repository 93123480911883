import React from 'react';
import AllyusCard from '../../allyusCard/';
import Button from '../../button/';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import { AllyusDatePicker, AllyusTimePicker } from '../../common/';
import moment from 'moment';
import ItemCard from './eventCard';

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../../config";
const { allyus_url } = config;

class PolititionEventsCard extends React.Component {
    state = {
        modalEditEvent: false,

        title: '',
        description: '',
        host_contact_info: '',
        rsvp_link: '',
        tickets_link: '',
        location: '',
        eventID: '',
        year: null,
        month: null,
        day: null,
        hour: "12",
        min: "00",
        period: "am"
    }

    toggleEditEvent = (e) => {
        this.setState(prevState => ({
            modalEditEvent: !prevState.modalEditEvent
        }));
    }

    openEditEvent = (e, item) => {
        this.setState({
            modalEditEvent: true,
            title: item.title,
            description: item.description,
            host_contact_info: item.host_contact_info,
            rsvp_link: item.rsvp_link,
            tickets_link: item.tickets_link,
            location: item.location,
            eventID: item._id,

            year: item.event_date_time ? moment(item.event_date_time).format("Y") : null,
            month: item.event_date_time ? parseInt(moment(item.event_date_time).format("M")) - 1 : null, 
            day: item.event_date_time ? moment(item.event_date_time).format("DD") : null,
            hour: item.event_date_time ? moment(item.event_date_time).format("hh") : null,
            min: item.event_date_time ? moment(item.event_date_time).format("mm") : null,
            period: item.event_date_time ? moment(item.event_date_time).format("a") : null,
        });
    }

    openAddEvent = (e) => {
        this.setState({
            modalEditEvent: true,
            title: '',
            description: '',
            host_contact_info: '',
            rsvp_link: '',
            tickets_link: '',
            location: '',
            eventID: '',
            year: null,
            month: null,
            day: null,
            hour: "12",
            min: "00",
            period: "am"
        });
    }

    handleTimeChange = (e, element) => {
        this.setState({[element]: e.target.value})
    }
    handleDateChange = (value, element) => {
        this.setState({[element]: value})
    }


    async saveEvent() {
        try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            const { userAdmin, profile: {_id} } = this.props;
            const {
                title,
                description,
                host_contact_info,
                rsvp_link,
                tickets_link,
                location,
                eventID,
                year,
                month,
                day,
                hour,
                min,
                period
            } = this.state;
            let hour24 = hour;
            if (period.toLowerCase() === "pm"){
                hour24 = parseInt(hour) + 12;
                hour24 = hour24.toString();
            }
            let event_date_time = new Date(year, month, day, hour24, min);
            const event = { 
                title: title,
                description : description,
                event_date_time: event_date_time,
                host_contact_info: host_contact_info,
                rsvp_link: rsvp_link,
                tickets_link: tickets_link,
                location: location
            };
            if (eventID){
                await axios.put(`${allyus_url}/politicianevents/${eventID}?access_token=${access_token}`, {updatedData : event});
            }else if (!userAdmin) {
                await axios.post(`${allyus_url}/politicianevents?access_token=${access_token}`, {politicianEvent : event});
            } else {
                await axios.post(`${allyus_url}/admin/politicians/${_id}/events?access_token=${access_token}`, {politicianEvent : event});
            }
            this.setState({
                modalEditEvent: false,
                title: '',
                description: '',
                event_date_time: '',
                host_contact_info: '',
                rsvp_link: '',
                tickets_link: '',
                location: '',
                eventID: ''
            });
            this.props.getProfile();
        }
        catch(err){
            console.log(err);
        }
    }
    async deleteEvent(eventID) {
        try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            if (eventID){
                await axios.delete(`${allyus_url}/politicianevents/${eventID}?access_token=${access_token}`);
                this.props.getProfile();
            }
        }
        catch(err){
            console.log(err);
        }
    }

    render() {
        var { my_profile, profile, eventsList, userAdmin } = this.props;

        return (
            <AllyusCard
                title='Events'
                add={my_profile || userAdmin}
                addText="Add Event"
                click={(e) => this.openAddEvent(e)}
                theme='p'
            >
                <Row>
                    {
                        eventsList.map((item, index) => {
                            return (
                                <Col sm="12">
                                    <ItemCard event={item} key={item.id} 
                                        toggleEdit={(e) => this.openEditEvent(e, item)} 
                                        edit={my_profile || userAdmin} 
                                        deletePost={this.deleteEvent.bind(this)}
                                        />
                                    <hr />
                                </Col>
                            )
                        })
                    }
                </Row>
                {/* ************************ Modal Edit Event ******************************* */}

                <Modal isOpen={this.state.modalEditEvent} toggle={this.toggleEditEvent} >
                    <ModalHeader toggle={this.toggleEditEvent}>Edit Event</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">event title</Label>
                                <Input type="text" id="eventTitle" placeholder="e.g fundraiser dinner" 
                                 value={this.state.title}
                                 onChange={(e) => this.setState({title: e.target.value})}
                                 />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">event date</Label>
                                <AllyusDatePicker className="w-75" handleDateTimeChange={this.handleDateChange.bind(this)} {...this.state}/>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">event time</Label>
                                <AllyusTimePicker className="w-75"  handleDateTimeChange={this.handleTimeChange.bind(this)} {...this.state} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">event description</Label>
                                <Input type="textarea" id="eventTitle"
                                    value={this.state.description}
                                    onChange={(e) => this.setState({description: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">host contact information</Label>
                                <Input type="text" id="eventTitle"
                                    value={this.state.host_contact_info}
                                    onChange={(e) => this.setState({host_contact_info: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">RSVP Link</Label>
                                <Input type="text" id="eventTitle"
                                    value={this.state.rsvp_link}
                                    onChange={(e) => this.setState({rsvp_link: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">buy tickets Link</Label>
                                <Input type="text" id="eventTitle"
                                    value={this.state.tickets_link}
                                    onChange={(e) => this.setState({tickets_link: e.target.value})}
                                />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="eventTitle">add location</Label>
                                <Input type="text" id="eventTitle"
                                    value={this.state.location}
                                    onChange={(e) => this.setState({location: e.target.value})}
                                />
                                {/* TODO google places api
                                 <Autocomplete
                                    suggestions={[
                                        "canada",
                                        "pakistan",
                                        "Us"
                                    ]}
                                    className="mt-3"
                                /> */}
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={this.saveEvent.bind(this)}> save </Button>
                    </ModalFooter>
                </Modal>
            </AllyusCard>

        )
    }
}

export default PolititionEventsCard;