const INITIAL_STATE = {
    trending: 'recent',
    country: 'all',
    region: 'all',
    status: 'Open Petitions',
};

 const selectorsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'UPDATE_PETITION_FILTER':
            return {...state, [action.filter_name]: action.filter_value};
        default:
            return state;
    }
};

export default selectorsReducer;