import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';
import AllyusCard from '../../allyusCard/index';

class UserAboutCard extends Component {
  state = {
    modal: false,
    about: this.props.profile.bio
  }

  toggle = (e) => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleSave = () => {
    const updatedProfile = { bio: this.state.about };
    this.props.editUser(updatedProfile);
    this.toggle();
  }

  render() {
    const { my_profile, profile, userAdmin } = this.props
    var name =  my_profile ? 'your' : profile.first_name + "'s";
    if (profile.anonymous){
      name = my_profile ? 'your' : profile.username + "'s";
  }
    return (
      !profile.anonymous ?
        (
          <AllyusCard title={`about`} edit={my_profile || userAdmin} theme="c" click={this.toggle}>
            <p className="userAbout-desc">
              {profile.bio}
            </p>
            <Modal isOpen={this.state.modal} toggle={this.toggle} >
              <ModalHeader toggle={this.toggle}>Edit About</ModalHeader>
              <ModalBody>
                <Form className="allyus-form">
                  <FormGroup className="allyus-form-group">
                    <Label for="exampleText">About</Label>
                    <Input type="textarea" name="text" id="about" value={this.state.about} onChange={(e) => this.setState({ about : e.target.value })} />
                  </FormGroup>
                </Form>
              </ModalBody>
              <ModalFooter>
                <Button className="allyus-btn allyus-primary-btn" click={this.handleSave}> save </Button>
              </ModalFooter>
            </Modal>
          </AllyusCard>
          )
        :
        (
          <div className="userAbout-card allyus-card">
            <div className="userAbout-card-top">
              <p className="allyus-card-title">About </p>
            </div>
            <hr />
            <h2 className="allyus-sub-heading">{name} profile is hidden</h2>
          </div>
        )
    )
  }

}

export default UserAboutCard;
