import React from "react";
import { Collapse } from "reactstrap";
import Button from "../../button/";
import moment from "moment";
import DeleteModal from '../../deleteModal';

export default class ItemCard extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = { collapse: false, deletePostModal: false };
  }

  toggle() {
    this.setState(state => ({ collapse: !state.collapse }));
  }

  render() {
    const { event, toggleEdit, edit } = this.props;
    const {deletePostModal} = this.state;
    return (
      <div>
        <div className="event">
          <div className="event-detail">
            <h3 className="allyus-card-heading">{event.title}</h3>
            {this.state.collapse ? null : (
              <p className="allyus-card-sub-heading">
                <span className="day">
                  {moment(event.event_date_time).format("dddd, MMMM Do YYYY")}
                </span>{" "}
                &nbsp;
                <span className="text-lowercase allyus-text-secondery">{`at `}</span>
                <span className="time">
                  {moment(event.event_date_time).format("h:mm A")}
                </span>
              </p>
            )}
          </div>
          <div className="event-edit">
            {edit ? (
              <img
                src="/icons/new/edit-icon-p.svg"
                style={{ cursor: "pointer" }}
                alt="edit"
                onClick={toggleEdit}
              />
            ) : null}
            <p
              className="allyus-card-sub-heading"
              style={{ cursor: "pointer" }}
              onClick={this.toggle}
            >
              <span>Detail</span>/<span>RSVP</span> &nbsp;
              <span className="fa fa-angle-down"></span>
            </p>
          </div>
        </div>
        <Collapse
          isOpen={this.state.collapse}
          className="event-location-details"
        >
          <p>
            <strong>Start:</strong>{" "}
            {moment(event.event_date_time).format("dddd, MMMM Do YYYY")} {`at `}{" "}
            {moment(event.event_date_time).format("h:mm A")}
          </p>
          <p>
            <strong>Location:</strong> {event.location}{" "}
          </p>
          <div className="contact-box" style={{ display: "flex" }}>
            <p>
              <strong>Host Contact Info:</strong> {event.host_contact_info}
            </p>
            <div>
                
             <a href={event.rsvp_link ? `${event.rsvp_link}` : "#"}  target={event.rsvp_link ? "blank" : false} > 
                <Button
                    className="allyus-secondery-btn"
                    width="120"                    
                >
                    RSVP
                </Button>
              </a>
              <a href={event.tickets_link ? `${event.tickets_link}` : "#"}  target={event.tickets_link ? "blank" : false} > 
                <Button
                    className="allyus-white-btn"
                    width="120"
                >
                    Buy Tickets
                </Button>
            </a>
            </div>
          </div>
          {/* TODO MAP */}
          <p>{event.description}</p>
          { edit ? 
              <div style={{textAlign: 'right'}}>
                  <span 
                      className="secondary-color" 
                      style={{cursor: 'pointer'}}
                      onClick={() => this.setState({deletePostModal: !deletePostModal})}
                      >
                      <i class="far fa-trash-alt"></i>
                  </span>   
              </div>
          : null }
        </Collapse>
        <DeleteModal
            isOpen={deletePostModal}
            toggle={() => this.setState({deletePostModal: !deletePostModal})}
            deleteFunction={() => this.props.deletePost(event._id)}
            deleteName="event"
            secondary={true}
        />
      </div>
    );
  }
}
