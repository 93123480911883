import React, { Component } from 'react';
import Button from '../../button/index';
import AllyusCard from '../../allyusCard/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';

import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../../config';
const { allyus_url } = config;


class PolititionVolunteerCard extends Component {

    constructor(props) {
        super(props);
        const { profile, currentUser } = props;
        this.state = { 
            modal: false,
            lableStyle : null,
            interests: profile.volunteerOpenings.map((t, i) => {
                return ({name: t, checked : false});
            }),
            newVolunteerOpenings: profile.volunteerOpenings && profile.volunteerOpenings.length ? profile.volunteerOpenings : ["Knock on Doors", "Send Texts", "Send Postcards", "Make Calls", "Host a Party", "Phone Banking", "Social Media", "Fundraising"],
            newOpening: '',
            volunteersURL: ''
        };    
    }
 
    componentDidMount(){
      this.setVolunteersURL()
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile._id !== this.props.profile._id){
            const { profile } = this.props;
            this.setState({ 
                modal: false,
                lableStyle : null,
                interests: profile.volunteerOpenings.map((t, i) => {
                    return ({name: t, checked : false});
                }),
                newVolunteerOpenings: profile.volunteerOpenings && profile.volunteerOpenings.length ? profile.volunteerOpenings : ["Knock on Doors", "Send Texts", "Send Postcards", "Make Calls", "Host a Party", "Phone Banking", "Social Media", "Fundraising"],
                newOpening: '',
                volunteersURL: ''
            });
            this.setVolunteersURL();
        }
    }

   async setVolunteersURL(){
        try{
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            var volunteersURL= `${allyus_url}/admin/politicians/${this.props.profile._id}/volunteers_csv?access_token=${access_token}`;
            this.setState({ volunteersURL: volunteersURL});
            }catch(err){
    
            }
    }

    handleSubmit = async () => {
        try {
            if (firebase.auth().currentUser){
                const { interests } = this.state;
                const { profile: { nameUrl, volunteerOpenings }} = this.props;
                let volunteer_interests = [];
                for (let i in interests){
                    if (interests[i].checked){
                        volunteer_interests.push(interests[i].name);
                    }
                }
                const access_token = await firebase.auth().currentUser.getIdToken(true);
                if (volunteer_interests.length > 0) {
                    await axios.post(`${allyus_url}/politicians/${nameUrl}/volunteers?access_token=${access_token}`, {volunteer_interests: volunteer_interests});
                    this.setState({
                        interests: volunteerOpenings.map((t, i) => {
                            return ({name: t, checked : false});
                        })
                    });
                    alert('Thanks for volunteering!');
                }
            } else {
                this.props.toggleSignInModal();
            }
        } catch(err){
            console.log(err);
        }
    }
    saveVolunteerOpenings = () => {
        var { newVolunteerOpenings, newOpening } = this.state;
        if (newOpening){
            newVolunteerOpenings.push(newOpening);
        }
        const updatedProfile = {
            volunteerOpenings: newVolunteerOpenings
        };
        this.props.editUser(updatedProfile);
        this.toggle();
    }

    toggle = e => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    deleteOption = (opt) => {
        var { newVolunteerOpenings } = this.state;
        newVolunteerOpenings = newVolunteerOpenings.filter(function(opening) {
            return opening !== opt;
        });
        this.setState({newVolunteerOpenings})
    }
    onKeyDownAddOption = (e) => {
        if (e.keyCode === 13){
            var { newVolunteerOpenings, newOpening } = this.state;
            if (newOpening) {
                newVolunteerOpenings.push(newOpening);
                this.setState({newVolunteerOpenings, newOpening:''});
            }
        }
    }

    render() {
        var { my_profile, profile: { volunteerOpenings, organization, user_politician }, userAdmin } = this.props;
        const { newOpening, newVolunteerOpenings, interests } = this.state;
        volunteerOpenings = volunteerOpenings && volunteerOpenings.length ? volunteerOpenings : ["Knock on Doors", "Send Texts", "Send Postcards", "Make Calls", "Host a Party", "Phone Banking", "Social Media", "Fundraising"];
        return (
            <AllyusCard
                edit={my_profile || userAdmin}
                title='Volunteer'
                click={this.toggle}
                className="volunteer-card"
                theme='p'
            >
                <div className="volunteer-detail">
                    <p className="allyus-text">
                        Check all options you're interested in and we'll provide details for
                        each once we know you're in!
                   </p>
                    <div className="allyus-form group">
                        {
                            interests && volunteerOpenings.map((t, i) => {
                                return (
                                    <div key={i} className="form-group">
                                        <div className="checkboxwrapper">
                                            <label for="checkboxInput" className={interests[i] && interests[i]['checked'] ? 'ischecked' : null}
                                                 value={interests[i] ? interests[i]['checked'] : false}
                                                 onClick={() => {
                                                    let a = interests;
                                                    a[i]['checked'] = !a[i]['checked'];
                                                    this.setState({interests: a});
                                                 }}
                                                 />
                                        </div>
                                        <span className="text-muted"> {t}</span>
                                    </div >
                                )
                            })
                        }
                        {
                            (my_profile || userAdmin) && volunteerOpenings.length ? // TODO if want to show submit button admin too
                            <a href={!userAdmin ? this.props.currentUser.getVolunteersURL : this.state.volunteersURL} download >
                                 <Button width="165" className="allyus-secondery-border-btn" >Get Volunteers List</Button>
                            </a>
                            : my_profile  || userAdmin ? 
                              <Button width="165" className="allyus-secondery-border-btn-text"  click={this.toggle}>Add Options</Button>
                            : <Button width="100" className={organization ? "allyus-organization-border-btn" : "allyus-secondery-border-btn"} click={this.handleSubmit}>Submit</Button>

                        }
                    </div>
                </div>
                {/* ************************ Modal Edit Event ******************************* */}

                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}>Edit Volunteer Opportunities</ModalHeader>
                    <ModalBody>
                        <div className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="volunteerOpenings">Volunteer Openings</Label>
                                <div className="volunteer-openings">
                                    {
                                        newVolunteerOpenings.map((opt, i) => {
                                            return (
                                                <div>
                                                    <img src={'/icons/new/close-icon.svg'} alt="delete" key={i} onClick={() => this.deleteOption(opt, i)}/> &nbsp;
                                                    <span className="text-muted">{ opt }</span>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </FormGroup>  
                            <FormGroup className="allyus-form-group">
                                <Label for="addOpenings">Add Opening</Label>
                                <Input type="text" id="addOpenings" placeholder="e.g social"
                                 value={newOpening} onChange={e => this.setState({newOpening: e.target.value})} onKeyDown={(e) => this.onKeyDownAddOption(e)} />
                            </FormGroup>                            
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={() => this.saveVolunteerOpenings()}> save </Button>
                    </ModalFooter>
                </Modal>

                
            </AllyusCard>
        );
    }

}

export default PolititionVolunteerCard;
