import producer from 'immer';

const INITIAL_STATE = {
    poll_tabs : false,
    active_tab : 1
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'TOGGLE_TABS':
            return {...state, [action.tab]: !state[action.tab], active_tab : action.active};
        default:
            return state;
    }
};