const INITIAL_STATE = {
        selectedTags: [],
        createPoll: false,
        createPetition: false,
        petitionTitle: '',
        petitionText: '',
        petitionReciver: '',
        petitionRecieverId: '',
        petitionStance: '',
        petitionImg: '',
        postText: '',
        image_link: '',
        file: '',
        add_image: false,
        featured: false,
        editMode: false,
        petitionReceiverName: '',
        _id: '',

};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'SET_EDIT_POST':
            return action.payload;
        case 'CLEAR_EDIT_POST':
            return INITIAL_STATE;
        default:
            return state;
    }
};