import React from "react";
import { Modal, FormGroup, Label, Input, Form, Col } from "reactstrap";
import AllyusCard from "../../../components/allyusCard";
import Button from "../../../components/button";
import emailjs from 'emailjs-com';

export default class ClaimPoliticianModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      message: "",
      campaign: false,
      constituency: false
    };
  }

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-zA-Z]{2}|com|org|net|gov|info|ca|us|io)\b/;
    return re.test(email);
  }

  onSubmitClaimPolitician = async e => {
    const {email, message, campaign, constituency} = this.state;
    const { toggle, isOpen, profile } = this.props;
    const politician_type = campaign && constituency ? 'both' 
      : campaign ? 'campaign'
      : 'constituency';

    if (email && this.validateEmail(email)
      ) {
      var template_params = {
        "message": `${message}`,
        "email": `${email}`,
        "politician_id": `${profile._id}`,
        "politician_name_url" : `${profile.nameUrl}`,
        "type": "Politician Account Claim",
        "politician_type": politician_type
     }
     var service_id = "default_service";
     var template_id = "template_FDVQBhJg";
     try {
      await emailjs.send(service_id, template_id, template_params)
      alert("Thanks for claiming this account! We'll review your information and contact you soon.");
      this.setState({email: '', message: '', campaign:false, constituency:false});
      if (isOpen){
        toggle();
      }
     } catch(err){console.log(err)};
    } else{
      alert("Please enter a valid email. Contact development@allyus.org with any questions or concerns.");
    }
  }


  render() {
    const { toggle, isOpen } = this.props;
    const { email, message, campaign, constituency} = this.state;
    const { onChange, onSubmitClaimPolitician } = this;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={true}
        className="modal-style"
      >
        <AllyusCard title="Claim Account" className="signIn" isModal={true} onClose={toggle}>
          {/* {authError ? <div className="alert alert-danger">{authError} </div> : ""} */}
          <div className="allyus-form button-group">
            <FormGroup className="allyus-form-group">
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={onChange}
              />
            </FormGroup>
      
            <FormGroup className="allyus-form-group">
              <Label for="last_name">Message (optional)</Label>
              <Input
                type="textarea"
                id="message"
                name="message"
                onChange={onChange}
                value={message}
              />
            </FormGroup>

            <FormGroup >
                <FormGroup check>
                  <Label check    
                    style={{
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      letterSpacing: 0.5,
                      marginBottom: 0 
                    }}>
                    <Input type="checkbox" id="checkbox1" checked={campaign} 
                     onClick={() => this.setState({campaign:!campaign})}
                    />{' '}
                    Campaign Office Account
                  </Label>
                </FormGroup>
            </FormGroup>
            <FormGroup >
                <FormGroup check>
                  <Label check
                     style={{
                      fontSize: '0.9rem',
                      fontWeight: 600,
                      letterSpacing: 0.5,
                      marginBottom: 0
                    }}>
                    <Input type="checkbox" id="checkbox2" checked={constituency}
                      onClick={() => this.setState({constituency:!constituency})}
                    />{' '}
                    Constituency Office Account
                  </Label>
                </FormGroup>
            </FormGroup>

            <FormGroup className="allyus-form-group">
              <Button
                id="submit"
                className="allyus-btn allyus-primary-btn signup-btn-m"
                onClick={onSubmitClaimPolitician}
              >
                {" "}
                Claim Account{" "}
              </Button>
            </FormGroup>
            <div className="bottom-text" style={{marginLeft:'10%'}}>
                <div className="terms-line">
                By signing up, you agree to our <a href="https://allyus.org/terms" target="blank"><span>Terms</span></a> and that you have
                read our <a href="https://allyus.org/privacypolicy" target="blank"><span>Privacy Policy</span></a>
                </div>
            </div>
          </div>
        </AllyusCard>
      </Modal>
    );
  }
}
