import React, { Component } from "react";
import SignIn from "./sections/signin";

class SignInPage extends Component {
  render() {
    return (
      <div className="page-signup">
        <SignIn {...this.props}/>
      </div>
    );
  }
}

export default SignInPage;
