import ACTIONS from "../actionTypes";

import { toggleSignInModal,  getProfile, getAnonymousProfile, getPoliProfile, getNotifications, getFeedPosts, getPetitions  } from "../actions";

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../config";
const { allyus_url } = config;

export const getCurrentUser = () => (dispatch, getState) => {
  
  if (firebase.auth().currentUser) {
    firebase
      .auth()
      .currentUser.getIdToken(true)
      .then(idToken => {
        // console.log(idToken);
        axios
          .get(`${allyus_url}/me?access_token=${idToken}`)
          .then(async result => {
            var user = result.data;
            // dispatch({
            //   type: ACTIONS.USER_PROFILE_FETCH_SUCCESS,
            //   currentUser: user
            // });
            if (user.__t === "politician") {
              user.getVolunteersURL = `${allyus_url}/politicians/me/volunteers_csv?access_token=${idToken}`;
              user.getEmailSignUpsURL = `${allyus_url}/politicians/me/emailsignups_csv?access_token=${idToken}`;
            }
            try {
            const representatives = await axios.get(
              `${allyus_url}/representatives/for_me?access_token=${idToken}`
            );
            const intersection_data = user.anonymous
              ? await axios.get(
                  `${allyus_url}/users/anonprofile/followingfollowers?access_token=${idToken}`
                )
              : await axios.get(
                  `${allyus_url}/users/${user._id}/followingfollowers`
                );

            const anon_profile_following = await axios.get(`${allyus_url}/users/me/anon_following?access_token=${idToken}`);
            const anon_profile_followers = await axios.get(`${allyus_url}/users/me/anon_followers?access_token=${idToken}`);
            const my_feed = await axios.get(`${allyus_url}/users/${user._id}/my_feed?access_token=${idToken}`);

            user.representatives = representatives.data;
            user.msguserslist = intersection_data.data;
            user.anon_profile_following = anon_profile_following.data ? anon_profile_following.data.following : [];
            user.anon_profile_followers = anon_profile_followers.data ?  anon_profile_followers.data.followers : [];
            user.anon_profile_following_count = anon_profile_following.data ? anon_profile_following.data.count : 0;
            user.anon_profile_followers_count = anon_profile_followers.data ? anon_profile_followers.data.count : 0;
            user.my_feed = my_feed.data ? my_feed.data : [];
            }catch(err){
              // console.log(err);
            }
            dispatch({
              type: ACTIONS.USER_PROFILE_FETCH_SUCCESS,
              currentUser: user
            });
          })
          .catch(error => {
            // console.log(error);
            // dispatch({ type: 'SIGNOUT' });
          });
      })
      .catch(error => {
        // console.log(error);
        dispatch({ type: 'SIGNOUT' });
      });
  } else {
    // dispatch({ type: 'SIGNOUT' });
  }
};

export const toggleAnonymous = () => (dispatch, getState) => {
  
  const userAnon = getState().currentUser.anonymous;
  const anonSetting = { anonymous: !userAnon };
  firebase
    .auth()
    .currentUser.getIdToken(true)
    .then(idToken => {
      axios
        .put(`${allyus_url}/users?access_token=${idToken}`, anonSetting)
        .then(result => {
          dispatch({
            type: ACTIONS.USER_PROFILE_FETCH_SUCCESS,
            currentUser: result.data
          });
          dispatch(getCurrentUser());
          dispatch(getNotifications());
        })
        .catch(error => {
          // console.log(error);
        });
    })
    .catch(error => {
      // console.log(error);
    });
};

export const logOutUser = () => {
  return {
    type: 'SIGNOUT'
  };
};

export const editUser = updatedProfile => async (
  dispatch,
  getState,
) => {
    try {
      var { admin } = getState().currentUser;
      var { _id, anonymous, username } = getState().userProfile.profile;

      const access_token = await firebase.auth().currentUser.getIdToken(true);
      let result;
      if (!admin){
        result = await axios.put(`${allyus_url}/users?access_token=${access_token}`, updatedProfile);
      } else if (admin && updatedProfile.username){
        result = await axios.put(`${allyus_url}/admin/users/username/${username}?access_token=${access_token}`, updatedProfile);

      }else {
        result = await axios.put(`${allyus_url}/admin/users/${_id}?access_token=${access_token}`, updatedProfile);
      }
      dispatch(getCurrentUser());
      if (anonymous){
        dispatch(getAnonymousProfile(updatedProfile.username));
      } else {
        dispatch(getProfile(_id));
      }
    } catch(err) {
      console.log(err);
    }
};


export const setUserHistoryPrivacy = (setting) => async (dispatch, getState) => {
  // setting is private or public
  try{
    var { _id } = getState().userProfile.profile;
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      await axios.put(`${allyus_url}/preferences/userhistoryprivacy/${setting}?access_token=${access_token}`);
      dispatch(getProfile(_id));
  } catch(err) {
  // console.log(err)
  dispatch({type: 'PROFILE_FETCH_ERROR'});

  }
}

export const editPoliUser = updatedProfile => async (
  dispatch,
  getState,
) => {
  try {
   
    const currentUser = getState().currentUser;
    var { admin } = currentUser;
    var { _id, nameUrl } = getState().politicianProfile.profile;
    const access_token = await firebase.auth().currentUser.getIdToken(true);
    let result;
    if (!admin){
      result = await axios.put(`${allyus_url}/politicians?access_token=${access_token}`, updatedProfile);
    } else {
   
      result = await axios.put(`${allyus_url}/admin/politicians/${_id}?access_token=${access_token}`, updatedProfile);
    }
    dispatch(getCurrentUser());
    dispatch(getPoliProfile(nameUrl));
  } catch(err) {
    console.log(err);
  }
};

export const followUser = ({ id, username }) => async (
  dispatch,
  getState,
) => {
  
  if (firebase.auth().currentUser) {
    try {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      if (id) {
        await axios.put(
          `${allyus_url}/users/follow?access_token=${access_token}&id=${id}`
        );
      } else if (username) {
        await axios.put(
          `${allyus_url}/users/follow?access_token=${access_token}&username=${username}`
        );
      }
      dispatch(getCurrentUser());
    } catch (err) {
      console.log(err);
    }
  } else {
    dispatch(toggleSignInModal());
  }
};

export const unfollowUser = ({ id, username }) => async (
  dispatch,
  getState,
) => {
  
  if (firebase.auth().currentUser) {
    try {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      if (id) {
        await axios.put(
          `${allyus_url}/users/unfollow?access_token=${access_token}&id=${id}`
        );
      } else if (username) {
        await axios.put(
          `${allyus_url}/users/unfollow?access_token=${access_token}&username=${username}`
        );
      }
      dispatch(getCurrentUser());
    } catch (err) {
      console.log(err);
    }
  } else {
    dispatch(toggleSignInModal());
  }
};

export const clearRedux = () =>(dispatch) =>{
  dispatch({ type : 'SIGNOUT'});
  dispatch(getFeedPosts());
  dispatch(getPetitions());
}