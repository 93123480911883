import React, { Component } from "react";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Row,
  Col
} from "reactstrap";
import Button from "../components/button/index";
import AllyusCard from "../components/allyusCard/index";
import { AllyusDatePicker } from "../components/common/index";
import moment from "moment";
import { connect } from "react-redux";
import profile_fields from "../formdata/profilefields.json";
import {editUser, toggleInviteModal} from "../store/actions";
import { Redirect } from "react-router-dom";
import * as firebase from "firebase/app";
import ReactGA from 'react-ga';

class UserProfileInfoCard extends Component {
  constructor(props) {
    super(props);
    const { info } = props;
    this.state = {
      modal: false,
      age: info.age,
      gender: info.gender,
      ethnicity: info.ethnicity,
      religion: info.religion,
      education: info.education,
      marital_status: info.marital_status,
      political_lean: info.political_lean,
      year: info.dob ? moment(info.dob).format("Y") : null,
      month: info.dob ? parseInt(moment(info.dob).format("M")) - 1 : null,
      day: info.dob ? moment(info.dob).format("DD") : null
    };
  }

  toggle = e => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  };

  handleInputChange = event => {
    const value = event.target.value;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    });
  };

  handleDateChange = (value, element) => {
    this.setState({ [element]: value });
  };

  onSubmit = () => {
    const {
      gender,
      ethnicity,
      religion,
      education,
      marital_status,
      political_lean,
      year,
      month,
      day
    } = this.state;
    const dob = new Date(year, month, day);

    var full_profile =  gender &&
    ethnicity &&
    religion &&
    education &&
    marital_status &&
    political_lean &&
    year &&
    month &&
    day;
    const updatedProfile = {
      gender,
      ethnicity,
      religion,
      education,
      marital_status,
      political_lean,
      dob
    };
    this.props.editUser(updatedProfile);
    this.setState({filled:true});
    ReactGA.event({
      category: 'Complete Profile',
      action: 'Save Profile',
      label: full_profile ? 'Complete Profile' : 'Partially Complete Profile'
    });
    // this.props.history.push('/');
  };

  handleDateChange = (date, placeholder) => {
    this.setState({
      [placeholder]: date
    });
  };

  render() {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return <Redirect to="/" />;
    }

    return (
      <div style={{ paddingTop: "1%" }}>
        <Row>
          <Col md={12} lg={{size: 8, offset: 2 }} xl={{size: 6, offset: 3 }} >
            {
              this.props.info.profile_creation_completed ?
              <AllyusCard title="Complete Your Profile" >
                Thanks for completing your profile!
                <Button
                className="allyus-btn allyus-primary-btn button-primary"
                click={() => this.props.history.push('/')}
                width="150"
                style={{ alignSelf: "flex-end" }}
              >
                {" "}
                Go to the Feed{" "}
              </Button>
              </AllyusCard>
              : this.state.filled ?
              <AllyusCard title="Complete Your Profile" >
                Thanks for filling out your profile!
                <Button
                className="allyus-btn allyus-primary-btn button-primary"
                click={() => this.props.history.push('/')}
                width="150"
                style={{ alignSelf: "flex-end" }}
              >
                {" "}
                Go to the Feed{" "}
              </Button>
              </AllyusCard>
              :
            <AllyusCard title={
              <div style={{display:'flex'}}> 
               <p>Complete Your Profile</p>
               <Button
                className="allyus-btn allyus-primary-btn button-primary"
                click={() => {
                  this.props.history.push('/');
                  ReactGA.event({
                    category: 'Complete Profile',
                    action: 'Skip All',
                  });
                 }}
                width="150"
                style={{ position: 'absolute', right: 10, backgroundColor: '#00e8c1', fontSize: 16,}}
              >
                {" "}
                Skip All{" "}
              </Button>
              </div>
            } 
            >
              <Form className="allyus-form" onChange={this.update}>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Birthday</Label>
                  <AllyusDatePicker
                    className="w-75"
                    {...this.state}
                    start="1900"
                    handleDateTimeChange={this.handleDateChange.bind(this)}
                  />
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Political Affiliation</Label>
                  <Input
                    type="select"
                    name="political_lean"
                    id="exampleSelect"
                    onChange={this.handleInputChange}
                    value={this.state.political_lean}
                  >
                    <option value=""></option>
                    {profile_fields.political_lean.map((field, i) => {
                      return <option value={field}>{field}</option>;
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Gender</Label>
                  <Input
                    type="select"
                    name="gender"
                    id="exampleSelect"
                    onChange={this.handleInputChange}
                    value={this.state.gender}
                  >
                    <option value=""></option>
                    {profile_fields.gender.map((field, i) => {
                      return <option value={field}>{field}</option>;
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Ethnicity</Label>
                  <Input
                    type="select"
                    name="ethnicity"
                    id="exampleSelect"
                    onChange={this.handleInputChange}
                    value={this.state.ethnicity}
                  >
                    <option value=""></option>
                    {profile_fields.ethnicity.map((field, i) => {
                      return <option value={field}>{field}</option>;
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Religion</Label>
                  <Input
                    type="select"
                    name="religion"
                    id="exampleSelect"
                    onChange={this.handleInputChange}
                    value={this.state.religion}
                  >
                    <option value=""></option>
                    {profile_fields.religion.map((field, i) => {
                      return <option value={field}>{field}</option>;
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Education</Label>
                  <Input
                    type="select"
                    name="education"
                    id="exampleSelect"
                    onChange={this.handleInputChange}
                    value={this.state.education}
                  >
                    <option value=""></option>
                    {profile_fields.education.map((field, i) => {
                      return <option value={field}>{field}</option>;
                    })}
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Marital Status</Label>
                  <Input
                    type="select"
                    name="marital_status"
                    id="exampleSelect"
                    onChange={this.handleInputChange}
                    value={this.state.marital_status}
                  >
                    <option value=""></option>
                    {profile_fields.marital_status.map((field, i) => {
                      return <option value={field}>{field}</option>;
                    })}
                  </Input>
                </FormGroup>
              </Form>

              <Button
                className="allyus-btn allyus-primary-btn"
                click={this.onSubmit}
                width="150"
                style={{ alignSelf: "flex-end" }}
              >
                {" "}
                Save{" "}
              </Button>
            </AllyusCard>
            }
          </Col>
        </Row>
        <Row>
        <Col  md={12} lg={{size: 8, offset: 2 }} xl={{size: 6, offset: 3 }} >
            <AllyusCard title="Invite Friends">
              <p>Invite friends to join the discussion!</p>
              <Button
                className="allyus-primary-btn"
                width="150"
                onClick={() => {
                  this.props.toggleInviteModal();
                  ReactGA.event({
                    category: 'Complete Profile',
                    action: 'Open Invite Friends',
                  });
                }}
                style={{ alignSelf: "flex-end" }}
              >
                Invite Friends
              </Button>
            </AllyusCard>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    info: state.currentUser
  };
};

export default connect(
  mapStateToProps,
  {editUser, toggleInviteModal}
)(UserProfileInfoCard);
