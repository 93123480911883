import React from 'react';

export default props => {
  return (
    <button className={`allyus-btn ${props.className}`} 
            style={{...props.style, width : `${props.width}px`}} 
            onClick={props.click ? props.click : props.onClick}
            name={props.name}
    >
      {props.children}
    </button>
  )
};