import React from 'react';
import Autosuggest from 'react-autosuggest';

const getSuggestionValue = suggestion => suggestion.full_name ? suggestion.full_name : suggestion.username;

const renderSuggestion = suggestion => (
  <div>
    {suggestion.full_name ? suggestion.full_name : suggestion.username}
  </div>
);

export default class PoliticianAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.editValue ? props.editValue : '',
      suggestions: []
    };
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    });
    this.props.setAutoCompleteName(newValue)
    this.props.setAutoCompleteId('');

  };

  onSuggestionsFetchRequested = async ({ value }) => {
    this.setState({
      suggestions: await this.props.getSuggestions(value)
    });
  };

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    });
  };

  onSuggestionSelected = (event, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
      const suggestion_id = suggestion._id ? suggestion._id : suggestion.uuid;
      this.props.setAutoCompleteId(suggestion_id);
  }


  render() {
    const { value, suggestions } = this.state;
    var { placeholder } = this.props;
    if (!placeholder){
      placeholder = 'e.g Local Politician'
    }
    const inputProps = {
      placeholder,
      value,
      onChange: this.onChange
    };

    return (
      <Autosuggest
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
        onSuggestionSelected={this.onSuggestionSelected}
      />
    );
  }
}