
const INITIAL_STATE = {
    profile: '',
    platform: [],
    events: [],
    news: [],
    endorsements: [],
    feed: [],
    loaded: false,
    error: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'POLI_PROFILE_FETCH_SUCCESS':
            return action.payload;
        case 'POLI_PROFILE_LOADING':
            return INITIAL_STATE;
        case 'POLI_PROFILE_FETCH_ERROR':
            return {...INITIAL_STATE, loaded:true, error: true}
        default:
            return state;
    }
};