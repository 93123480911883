import React from 'react';
import Button from '../../button/index';
import EditUsername from './editUsername';

export default class AnonymousUserDetail extends React.Component {
    constructor(props) {
        super(props);
        this.state = { 
          usernameModal: false,
         };    
    }

    render() {
        const {currentUser, my_profile, username} = this.props;
        const {influencer, lobbyist} = this.props.profile;
        return(
            <div className="userDetail-card">
                <div className="userDetail-card-head">
                    <div className="group1">
                        <div className="user-detail">
                            <div className="user-title">
                                {/* <div className="username-edit" > */}
                                    <h2 className="allyus-main-heading  mb-3"> {username}</h2>
                                    {/* {
                                    my_profile || currentUser.admin ?
                                        <button  onClick={() => this.setState({usernameModal:true})}>
                                        <img src={`/icons/new/edit-icon-c.svg`} alt="edit" className="edit-icon" />
                                        </button>
                                    : null
                                    } */}
                                {/* </div> */}
                                <h3 className="allyus-sub-heading mb-5">This profile is private</h3>
                            </div>
                                {
                                    my_profile ? (
                                    <div className="user-btn-group mt-3">
                                     {this.props.currentUser.anonymous ?
                                        <Button
                                        className={influencer ? "allyus-influencer-btn" : lobbyist ? "allyus-lobbyist-btn" : "allyus-primary-btn"}
                                        width="120"
                                        click={this.props.toggleAnonymous}
                                        >
                                        go public
                                        </Button>
                                        : 
                                        <Button
                                        className={influencer ? "allyus-influencer-btn" : lobbyist ? "allyus-lobbyist-btn" : "allyus-primary-btn"}
                                        width="120"
                                        click={this.props.toggleAnonymous}
                                        >
                                        go private
                                        </Button>
                                     }
                                     <Button
                                     className="allyus-btn allyus-white-btn"
                                     width="120"
                                     onClick={() => this.setState({usernameModal:true})}
                                     name="editProfile"
                                     >
                                         edit profile
                                     </Button>
                                     </div>
                                    
                                    ) : 
                                    <div className="user-btn-group mt-3">
                                       { currentUser && currentUser.following.includes(username) ?
                                            <Button
                                            className={influencer ? "allyus-influencer-btn" : lobbyist ? "allyus-lobbyist-btn" : "allyus-primary-btn"}
                                            width="120"
                                            click={() =>this.props.unfollowUser({username:username})}
                                            >
                                                Following
                                            </Button>
                                            :
                                            <Button
                                            className={influencer ? "allyus-influencer-border-btn" : lobbyist ? "allyus-lobbyist-border-btn" : "allyus-primary-border-btn"}
                                            width="120"
                                            click={() => this.props.followUser({username:username})}
                                            >
                                                Follow
                                            </Button>
                                       }
                                        {/* <Button
                                        className={"allyus-med-blue-btn"}
                                        width="120"
                                        // style={{}}
                                        // click={() => this.props.followUser({username:username})}
                                        >
                                           Message
                                        </Button> */}
                                    </div>
                              
                                }
                                {
                                    !my_profile && currentUser.admin ? 
                                    <div className="user-btn-group mt-3">
                                        <Button
                                        className="allyus-btn allyus-white-btn"
                                        width="120"
                                        onClick={() => this.setState({usernameModal:true})}
                                        name="editProfile"
                                        >
                                            edit profile
                                        </Button>
                                    </div>  
                                    : null
                                }
                        </div>
                        <div className="user-states mt-4">
                            <div >
                                {my_profile ? 
                                <span  style={{cursor:'pointer'}} onClick={() => this.props.openConnectionsTab("2")} className="states-count allyus-text">{currentUser.anon_profile_followers_count}</span>
                                : <img src='/icons/new/private-icon.svg' alt="private" /> }
                                <span style={{cursor: my_profile ?'pointer' : 'default'}} className="states-label text-muted pl-1" onClick={() => this.props.openConnectionsTab("2")}>Followers</span>
                            </div>
                            <div>
                                {my_profile ? 
                                <span style={{cursor:'pointer'}} onClick={() => this.props.openConnectionsTab("3")} className="states-count allyus-text">{currentUser.anon_profile_following_count}</span>
                                : <img src='/icons/new/private-icon.svg' alt="private"/> }
                                <span style={{cursor: my_profile ?'pointer' : 'default'}} className="states-label text-muted pl-1" onClick={() => this.props.openConnectionsTab("3")}>Following</span>
                            </div>
                            <div>
                                <img src="/icons/new/rep-icon.svg" alt="rep" />
                            </div>
                        </div>
                    </div>

                    <div className="group2">
                        <p className="mb-2">
                            <span className="allyus-card-heading mb-0">location</span>
                            <img src='/icons/new/private-icon.svg' alt="private" />
                        </p>

                        <p className="mb-2">
                            <span className="allyus-card-heading mb-0">congressional district</span>
                            <img src='/icons/new/private-icon.svg' alt="private" />
                        </p>

                        <p className="mb-2">
                            <span className="allyus-card-heading mb-0">county</span>
                            <img src='/icons/new/private-icon.svg' alt="private" />
                        </p>

                        <p className="mb-2">
                            <span className="allyus-card-heading mb-0">political affiliation</span>
                            <img src='/icons/new/private-icon.svg' alt="private" />
                        </p>
                    </div>
                </div>
                <EditUsername {...this.props} open={this.state.usernameModal}  toggle={() => this.setState({usernameModal: !this.state.usernameModal})}/>
            </div>
        );
    }
}