import React from "react";
import { ReactFormGenerator, ElementStore } from "react-form-builder2";
import { Alert } from 'reactstrap';
import axios from "axios";
import AllyusInfo from './allyusinfo';
import Share from '../../../components/Post/share';
import LocationPicker from "../../../components/locationPicker";

const share_url = 'https://share.allyus.org/covid19';

export default class Demobar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      previewVisible: false,
      locationError: false,
      country: '',
      region: '',
      city: '',
      postal_code: ''
    };

    this.submit = this.onSubmit.bind(this);
    this.onChangeLocation = this.onChangeLocation.bind(this);
    const update = this.onChange.bind(this);
    ElementStore.subscribe(state => update(state.data));
  }


  showPreview() {
    this.setState({
      previewVisible: true
    });
  }

  closePreview() {
    this.setState({
      previewVisible: false
    });
  }

  onChange(data) {
    this.setState({
      data
    });
  }

  onChangeLocation(change){
    this.setState(change);
  }


  onSubmit(data) {
    const { answersUrl } = this.props;
    var {country, region, city, postal_code} = this.state;
    var country_code = country.country_code;
    country = country.value;
    region = region.value;
    city = city.value;
    if (!country || !region || !city){
      this.setState({locationError: true});
      return;
    }
    this.setState({locationError: false});
    var answers = {
      answers: data,
      location: {
        country,
        country_code,
        region,
        city,
        postal_code
      }
    };
    axios
      .post(answersUrl, answers)
      .then(x => {
        // this.closePreview();
        alert('Thank you for filling out our survey.')
        window.location.href = '/covid19';
        //TODO thank you pop up
      })
      .catch(err => {
        console.log(err);
      });
  }

  render() {
    // let modalClass = "modal";
    // if (this.state.previewVisible) {
    //   modalClass += " show";
    // }

    return (
      <div className="clearfix" style={{ margin: "10px", width: "100%" }}>
    
          <div style={{ overflow: "auto" }}>
            <div className="modal-dialog" style={{maxWidth:800}}>
              <div className="modal-content " style={{borderColor:'transparent'}}>
                <div className="modal-body">
                <h3 style={{fontWeight:600}}>Covid-19 Symptom Reporting Survey</h3>
                <br/>
                <div>
                This survey was built to help predict where future Covid-19 outbreaks will occur. <br/><br/>

Our goal is to focus public awareness to the possibility of a surge of cases in their area, which we hope will encourage efforts to self isolate. <br/><br/>

Testing cannot currently cover the entire population and also may provide false negative results. As such, in the absence of widespread testing ,the survey results provide an alternative data point (but is not meant to replace legitimate testing). <br/><br/>

As well. we will be adding features to warn people to the possibility that  more people have contracted Covid-19 in their area and to take extra caution. <br/><br/>

Often people use the (low) number of confirmed cases as a rationale to not self-isolate, when really the number may be significantly higher than what is being reflected by test results. <br/><br/>

In summary, we hope to use symptom patterns as a warning marker for possible outbreak hot-spots. We hope that this information works in conjunction with the efforts of our Public Health system to identify outbreak patterns.<br/><br/>

We will be notifying the government agencies responsible for those areas in which significant numbers are reported. <br/><br/>                  </div>
                  <br/>
                 
                  {
                    !this.state.locationError ? null :
                    <Alert color="danger">
                      Country, region, and city are required.
                    </Alert>
                  }
                
                  <LocationPicker onChange={this.onChangeLocation} />

                  <ReactFormGenerator
                    download_path=""
                    answer_data={{}}
                    action_name="Submit"
                    // form_action="/"
                    form_method="POST"
                    onSubmit={this.submit}
                    variables={this.props.variables}
                    data={this.state.data}
                  />
                  <div >
                    <br/><br/>
                   <label style={{fontWeight: 'bold',}}>Please Share and help us us flatten the curve.</label>
                   <br/><br/>
                   <Share share_url={share_url}
                   share_text="Have you or anyone you know experienced symptoms of Covid-19 but have not been tested? Please share this one minute survey and help predict future outbreak hotspots, promote self isolation and protect your parents, children and community. "
                   />
                   <br/><br/>
                  </div>
                  <div>
                    Please Contact <a href="mailto:info@allyus.org">info@allyus.org</a> with any questions or concerns.
                  </div>
                  <br/>
                </div>
                <div className="modal-footer">
                  <AllyusInfo />
                </div>
              </div>
            </div>
          </div>
        {/* )} */}
      </div>
    );
  }
}
