import React from 'react'
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  NavbarToggler,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from 'reactstrap';
import { connect } from 'react-redux';
import { updatePetitionFilter } from '../../../store/actions';
import locations from  '../../../formdata/locations.json'

class SubNavbarPetitions extends React.Component {
  state = {
    isOpen: false,
    locations_index: null
  };

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }


  render() {
    const { 
      status,
      trending,
      country,
      region,
      updatePetitionFilter
    } = this.props;
   
    return (
      <div className="allyus-sub-nav mobile-none">
        <Navbar color="light" light expand="md">
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="mr-auto" navbar>
              <NavItem>
                <NavLink >Sort</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {status}
                </DropdownToggle>
                <DropdownMenu left="true">
                  <DropdownItem onClick={() => updatePetitionFilter('status', 'Open Petitions')}>
                    Open Petitions
                  </DropdownItem>
                  <DropdownItem onClick={() => updatePetitionFilter('status', 'Submitted Petitions')}>
                    Submitted Petitions
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {trending}
                </DropdownToggle>
                <DropdownMenu left="true">
                  <DropdownItem onClick={() => updatePetitionFilter('trending', 'trending')}>
                    trending
                  </DropdownItem>
                  <DropdownItem  onClick={() => updatePetitionFilter('trending', 'following')}>
                    following
                  </DropdownItem>
                  <DropdownItem  onClick={() => updatePetitionFilter('trending', 'recent')}>
                    recent
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              {/* <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {country}
                </DropdownToggle>
                <DropdownMenu left ="true">
                  {Object.values(locations).map((location, index) => {
                    return (
                          <DropdownItem onClick={() => {this.setState({locations_index: index}); updatePetitionFilter('country', location.name); updatePetitionFilter('region', 'all'); }}>
                            {location.name}
                        </DropdownItem>
                    );
                  })}
                    <DropdownItem onClick={() => {this.setState({locations_index: null}); updatePetitionFilter('country', 'all'); updatePetitionFilter('region', 'all');}}>
                          all
                    </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown> */}
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                 {region === 'all' ? 'State': region}
                </DropdownToggle>
                <DropdownMenu left="true" style={{overflow: 'auto', height: 500}}>
                  <DropdownItem onClick={() => {updatePetitionFilter('region', 'all');}}>
                          all
                  </DropdownItem>
                  {
                    // country && country.toLowerCase() !== 'all' ?
                      Object.values(locations["United States"].states).map((region, index) => {
                        return (
                          <DropdownItem onClick={() => updatePetitionFilter('region', region.name)}>
                            {region.name}
                        </DropdownItem>
                      );
                    })
                    // : null
                  }
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Navbar>

      </div>
    )
  }
}
const mapStateToProps = state => {
  const { 
    status,
    trending,
    country,
    region,
  } = state.petitionFilters;
  const { currentUser } = state;
  return {
    status,
    trending,
    country,
    region,
    currentUser
  };
}

export default connect(mapStateToProps, { updatePetitionFilter })(SubNavbarPetitions);