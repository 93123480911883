import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    getNotifications,
    clickNotification,
  } from '../../store/actions';
// components
import { Container, Row, Col, Button } from "reactstrap";
import { NotificationItem } from '../../components/allyusNotification';
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

class Notifications extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
    render() {
        const { notificationsInfo : { notifications} } = this.props;
        return(
            <div className="mt-3 notifications-page">
            <Helmet>
              <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
              <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
              {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
              <meta name="twitter:image" content="/allyuslogo.png"  />
              <meta property="og:image" content="/allyuslogo.png"  />
              <meta property="og:image:width" content="600" />
              <meta property="og:image:height" content="315" />
            </Helmet>
                <div className="notifications-container">
                    {
                        notifications.map((item, index) => {
                            return <NotificationItem notification={item} key={index} 
                            getNotifications={this.props.getNotifications} clickNotification={this.props.clickNotification} />
                        })
                    }
                    {
                      !notifications ||  notifications.length === 0 ?
                      <div style={{marginBottom:'5%'}}>
                      No Notifications Yet
                      
                      </div>
                      : null
                    }
                </div>   
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
      notificationsInfo: state.notifications,
    }
  }
  
  export default 
    connect(mapStateToProps,
      {
        getNotifications, clickNotification
      })(Notifications);