import ACTIONS from './actionType';
import axios from 'axios';
import * as firebase from "firebase/app";

import { getCurrentUser, getFeedPosts, getPetitions, editUser } from '../actions';
import config from '../../config';
const { allyus_url } = config;

export const signInAction = (credentials) => (dispatch, getState) => {
  firebase.auth().signInWithEmailAndPassword(
    credentials.email,
    credentials.password,
  ).then(() => {
    firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
      // console.log(idToken);
      axios.get(`${allyus_url}/firebase/auth?access_token=${idToken}`)
      .then((user) =>{
        dispatch({ 
          type: ACTIONS.SIGNIN_SUCCESS, 
          payload: {
            token: idToken
          } 
        });

        dispatch(getCurrentUser());
        dispatch({
          type: 'TOGGLE_MODAL',
          modal: 'signin_modal',
        });
        }).catch(function(error) {
          dispatch({ type: ACTIONS.SIGNIN_FAILED, payload: error });
        });
    }).catch(function(error) {
      dispatch({ type: ACTIONS.SIGNIN_FAILED, payload: error });
    });
   
  }).catch((err) => {

    dispatch({ type: ACTIONS.SIGNIN_FAILED, payload: err });
  })
}


export const signInSocial = (social, accountType) => (dispatch, getState) => {  let provider;
  if (social === 'facebook'){
    provider = new firebase.auth.FacebookAuthProvider();
  } else if (social === 'google'){
    provider = new firebase.auth.GoogleAuthProvider();
  } else if (social === 'twitter') {
    provider = new firebase.auth.TwitterAuthProvider();
  }

  firebase.auth().signInWithPopup(provider).then(function(result) {
    firebase.auth().currentUser.getIdToken(true).then(function(idToken) {
      var auth_url = `${allyus_url}/firebase/auth?access_token=${idToken}`;
      if (accountType === 'influencer' || accountType === 'lobbyist'){
        auth_url = `${allyus_url}/firebase/auth/${accountType}?access_token=${idToken}`;
      } 
      axios.get(auth_url)
        .then((user) =>{
          dispatch({ 
            type: ACTIONS.SIGNIN_SUCCESS, 
            payload: {
              token: idToken
            } 
          });
          dispatch({
            type: 'TOGGLE_MODAL',
            modal: 'signin_modal',
          });

          dispatch(getCurrentUser());
        }).catch(function(error) {
          console.log(error);
          dispatch({ type: ACTIONS.SIGNIN_FAILED, payload: error });
        });

    }).catch(function(error) {
      dispatch({ type: ACTIONS.SIGNIN_FAILED, payload: error });
    });
   
  }).catch((err) => {

    dispatch({ type: ACTIONS.SIGNIN_FAILED, payload: err });
  })
}


export const signUpAction = (newUser) => async (dispatch, getState) => {  
  let name = newUser.first_name + " " + newUser.last_name;
  const updatedProfile = {username: newUser.username};
  const accountType = newUser.voterAccountType;

  try {
    await firebase.auth().createUserWithEmailAndPassword(
      newUser.email,
      newUser.password
    );
    const user = firebase.auth().currentUser;
    await user.updateProfile({
        displayName: name
    });
    const idToken = await user.getIdToken(true);
    var auth_url = `${allyus_url}/firebase/auth?access_token=${idToken}`;
    if (accountType === 'influencer' || accountType === 'lobbyist'){
      auth_url = `${allyus_url}/firebase/auth/${accountType}?access_token=${idToken}`;
    } 
    await axios.get(auth_url);
    dispatch(editUser(updatedProfile));
    await user.sendEmailVerification();
    dispatch({ type: ACTIONS.SIGNUP_SUCCESS });
    dispatch({
      type: 'TOGGLE_MODAL',
      modal: 'signin_modal',
    });
    
  } catch(err) {
    dispatch({ type: ACTIONS.SIGNUP_FAILED, payload: err });
  }
}

export const signOut = () => (dispatch, getState)=>{
    firebase.auth().signOut().then(()=>{
    dispatch({ type : 'SIGNOUT'});
    dispatch(getFeedPosts());
    dispatch(getPetitions());
  })

}

export const forgotPassword = (emailAddress)  => (dispatch, getState)=>{  firebase.auth().sendPasswordResetEmail(emailAddress).then(function() {
    alert("Password Reset instructions sent!")
  }).catch(function(error) {
    // An error happened.
  });
}

export const resendEmailComf = ()  => (dispatch, getState)=>{  const user = firebase.auth().currentUser;
  if (user) {
    user.sendEmailVerification().then(function() {
      alert("Email sent!")
    }).catch(function(error) {
      // An error happened.
    });
  }
}