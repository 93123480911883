import firebase from 'firebase/app';
import 'firebase/auth';


/// Initialize Firebase
var config = {
    apiKey: "AIzaSyA9Xt0N_vo920SAU29IK8tE8KA3NEPZb2U",
    authDomain: "allyus-f18c7.firebaseapp.com",
    databaseURL: "https://allyus-f18c7.firebaseio.com",
    projectId: "allyus-f18c7",
    storageBucket: "allyus-f18c7.appspot.com",
    messagingSenderId: "352283276844",
    appId: "1:352283276844:web:617c7a94000d392b",
    measurementId: "G-X8N8VSQ119"
};

firebase.initializeApp(config);

export default firebase;