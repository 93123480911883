import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import ReactPlayer from 'react-player'
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

export default class About extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  render() {

    return (
      <div className="mt-3">
        <Container fluid >
         <Helmet>
            <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
            <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
            {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
            <meta name="twitter:image" content="/allyuslogo.png"  />
            <meta property="og:image" content="/allyuslogo.png"  />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '1%' }}>
                <Col xs={12} lg={6} style={{textAlign:'center', lineHeight: 1.75}}>
                    <p className="allyus-main-heading" style={{color:'black', textTransform:'uppercase', margin:20}}>Vision</p>
                    <ReactPlayer url='https://www.youtube.com/watch?v=8-C_XuBHpxM' width={'100%'} />
                </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '2.5%'}}>
                <Col xs={12} lg={6} className="allyus-text" style={{textAlign:'center', lineHeight: 1.75}}>
                    Citizens should be able to rapidly retrieve data, obtain the information they need and apply a critical lens to finding solutions for our greatest political challenges. 
                    <br/>
                    Today, they can't. 
                    <br/>
                    Citizens don’t have the tools they need to take ownership of their democracy. 
                    <br/>

                    Allyus solves this problem. 
                    <br/>

                    Allyus is a non-partisan political platform that will revolutionize the way users connect with those elected, those looking to be elected and the policies that govern the society in which we work, live and play. 
                    <br/>

                    Our system is broken. Our trust in government continues to be at historically low levels. Our confidence in polls is dismal. Big budgets, powerful lobbies and special interest groups are able to misinform the public with no recourse, silencing the voices of so many who want to be heard. 
                    <br/>

                    Allyus solves this problem. 
                    <br/>

                    Allyus is a tool which makes government more accountable, more transparent and fully available to its citizens through direct dialogue. The internal polling and petition system allows users to decide what matters most to them and holds our elected officials to account on those very issues. Through Allyus, we can start to understand the opposing views, break down silos and misinformation, find areas for cooperation and most importantly – force real action. 
                    <br/>

                    Unlike other social media platforms, Allyus is committed to the privacy and rights of our users. The cost of using online platforms should not be our privacy. We don’t sell personal data. Period. 
                    <br/>

                    In a world of fake news, ideological discord and identity politics – we need a platform that cuts through the noise. The time to act is now. Our society depends on it.
                </Col>
            </Row>
            <Row style={{  alignItems: 'center', justifyContent: 'center',  margin: '2.5%'}} className="hide-mobile">
              <Col xs={12} style={{textAlign:'center', lineHeight: 1.75}}>
                  <p className="allyus-main-heading" style={{color:'black', textTransform:'uppercase'}}>App</p>
                  <a href="https://apps.apple.com/us/app/allyus/id1534643196" target="_blank"><img src="/images/appstore.png"  width="200" style={{marginBottom:10, marginRight:5}} /></a>
                  <a href="https://play.google.com/store/apps/details?id=com.allyus" target="_blank"><img src="/images/playstore.png"  width="200" style={{marginBottom:10, marginLeft:5}} /></a>
              </Col>
            </Row>

            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center',  margin: '2.5%'}} >
                <Col xs={12} lg={6} className="allyus-text" style={{textAlign:'center', lineHeight: 1.75}}>
                <p className="allyus-main-heading" style={{color:'black', textTransform:'uppercase'}}>Partners</p>
                  <img src="/images/partners.png"  width="100%" style={{marginBottom:20}} />
                    If you are interested in partnering with us please email info@allyus.org 
                </Col>
            </Row>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '2.5%'}}>
                <Col xs={12} lg={6} className="allyus-text" style={{textAlign:'center', lineHeight: 1.75}}>
                    <p className="allyus-main-heading" style={{color:'black', textTransform:'uppercase'}}>Team</p>
                    <img src="/images/teamallyus.png"  width="100%" 
                    alt="Team: Ben Shahin Founder Ceo, Scott Peacock, Bela Campello, Laura Garrett,
                    Paul Caruso, David Cohen, Sulaim Siddiqi, Susahosh Rahman"
                    />
                </Col>
            </Row>
            <Row style={{margin:'2.5%'}}></Row>
        </Container>
      </div>
    );
  }
}


