import React from "react";
// components
import TimeAgo from "timeago-react";

//fucnctional
import { getOtherUser } from './functions';
// TODO move to components
export const Message = props => {
    const { msg, click, room, currentUserId } = props;
    if (room) {
      const count = room.unreadCount ? `(${room.unreadCount})` : "";
      let other_user = getOtherUser(room, currentUserId);
      let blast = room.customData && room.customData.politicianblast;
      return (
        <div className={`${props.className} `} onClick={click}>
          <div className="list-item">
            <img src={other_user.avatarURL ? other_user.avatarURL : "/images/temp_anon_icon.svg" } alt="userphoto" className="userImg" />
            <div className=" ml-2 w-80">
              <p className="allyus-text mb-0 d-flex">
                <span className={"allyus-text-bold mr-auto" + (blast ? " secondary-color" : "")} >{`${other_user.name} ${count}`}</span>
  
                <span className="text-muted">
                  <TimeAgo className={(blast ? " secondary-color" : "")} datetime={msg.updatedAt} />
                </span>
              </p>
              <p className={"allyus-text mb-0 text-overflow-ellipise "+ (blast ? " secondary-color" : "")} >{msg.text}</p>
            </div>
          </div>
        </div>
      );
    }
    return <div/>
  };