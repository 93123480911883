import ACTIONS from './actionTypes';
import producer from 'immer';

const initialState = {
    // pollYes: false,
    // pollNo: false,
    // showPolltabs: false,
    // currentPollId : null,
    postType : 'poll'
};

export default (state = initialState, action) => {
    return producer(state, draft => {
        switch (action.type) {
            // case ACTIONS.POLL_POST_YES:
            //     draft.currentPollId = action.payload
            //     draft.pollYes = !draft.pollYes
            //     draft.pollNo = false
            //     draft.showPolltabs = !draft.showPolltabs
            //     break;
                
            // case ACTIONS.POLL_POST_NO:
            //     draft.currentPollId = action.payload
            //     draft.pollNo = !draft.pollNo
            //     draft.pollYes = false
            //     draft.showPolltabs = !draft.showPolltabs
            //     break;
            
            case ACTIONS.SET_POST_TYPE :
                draft.postType = action.payload
                break;

            default:
                return state;
        }
    })
}