import React, {Component} from "react";
import {
  Modal,

  ModalBody,
  ModalHeader,
  ModalFooter,
  Button,
  Label, 
  Input,
  FormGroup
} from "reactstrap";

import { connect } from 'react-redux';
import config from '../../config';
import axios from 'axios';
import * as firebase from "firebase/app";

const cloudsponge = window.cloudsponge;
const {allyus_url} = config;


class InviteModal extends Component {
  state = {personalMessage: "I'm excited to have you join me on Allyus.", 
  include_post: false, post_index: 0, include_post2: false, post2_index:0 };

  render() {
  const { isOpen, toggle, inviteConstituents, invitesList } = this.props;
  const { personalMessage, include_post, post_index, include_post2, post2_index } = this.state;
  const user_posts = this.props.currentUser && this.props.currentUser.my_feed ? this.props.currentUser.my_feed : [];

  const submit = async () => {
      try{
        if (invitesList.emails.length < 1){
          alert("Please select at least one email.")
        } else {
          const access_token = await firebase.auth().currentUser.getIdToken(true);
          var invite_post = {
            include_post: include_post
          };
          var invite_post2 = {
            include_post: include_post2
          }
          if (include_post && user_posts && user_posts[post_index]){
            var post = user_posts[post_index];
            invite_post.title = post.title;
            invite_post.image = post.preview_image ? post.preview_image : post.image ? post.image : '';
            invite_post._id = post._id;
          } else if (include_post && (!user_posts || !user_posts[post_index])){
            invite_post.include_post = false;
          }
          if (include_post2 && user_posts && user_posts[post2_index]){
            var post = user_posts[post2_index];
            invite_post2.title = post.title;
            invite_post2.image = post.preview_image ? post.preview_image : post.image ? post.image : '';
            invite_post2._id = post._id;
          } else if (include_post2 && (!user_posts || !user_posts[post2_index])){
            invite_post2.include_post = false;
          } 
          if (post2_index === post_index) {
            invite_post2.include_post = false;
          }

          if (!inviteConstituents) {
            await axios.post(`${allyus_url}/invites?access_token=${access_token}`, 
            {invites:invitesList, personalMessage: this.state.personalMessage, invitePost: invite_post, invitePost2: invite_post2});
          } else {
          await axios.post(`${allyus_url}/invites_constituents?access_token=${access_token}`, 
            {invites:invitesList, personalMessage: this.state.personalMessage, invitePost: invite_post, invitePost2: invite_post2});
          }
          this.setState({personalMessage: "I'm excited to have you join me on Allyus.", include_post: false, post_index:0, include_post2: false, post2_index:0});
          toggle();
        }
      }catch(err){
        console.log(err);
        this.setState({personalMessage: "I'm excited to have you join me on Allyus.", include_post: false, post_index:0, include_post2: false, post2_index:0});
        toggle();
      }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="modal-style"
      backdrop={true}
    >
      <div id="cloudsponge-widget-container"></div>
      <ModalHeader toggle={toggle}>{inviteConstituents ? "Invite Constituents" : "Invite Friends"}</ModalHeader>
      <ModalBody>
        <form>
          <div class="form-group">
            {
              invitesList && (!invitesList.emails || !invitesList.emails.length)
              ?
                <Button
                className={"allyus-btn" + (inviteConstituents ? " allyus-secondery-btn" : " allyus-primary-btn")}
                onClick={e => {
                  cloudsponge.launch();
                }}
                style={{ marginBottom: 20, marginTop:5, width:'50%', marginLeft: '25%'}}
              >
                Select from Address Book
              </Button>
             : 
             <div>  
               <p>
                {invitesList.emails.length} contacts selected
               </p>
               <a
                class={(inviteConstituents ? " allyus-text-orange" : " allyus-text-primary")}
                href="#"
                onClick={e => {
                  cloudsponge.launch();
                }}
                style={{fontWeight:'bold', marginBottom: 5}}
              >
                Add more contacts from another address book
              </a>
             </div>
            }

           
            <FormGroup>
               <Label
                 class="form-control"   
                style={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  letterSpacing: 0.5,
                  marginTop: 16
                }}>
              Enter a custom message (optional):
            </Label>
              <textarea
              class="form-control"
              placeholder="Enter a custom message (optional)"
              value={personalMessage}
              onChange={(e) => this.setState({personalMessage: e.target.value})}
            ></textarea>
            </FormGroup>

          </div>

          <div>
            <FormGroup check>
                <Label check 
                class="form-control"   
                style={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  letterSpacing: 0.5,
                }}>
                <Input 
                  type="checkbox"
                  checked={include_post} 
                onChange={() => {
                  if (!include_post) {
                    this.setState({include_post:!include_post, 
                    personalMessage: personalMessage ? 
                      personalMessage + " Please have a look at one of the issues I'd like to discuss with you."
                    : "Please have a look at one of the issues I'd like to discuss with you."})
                  } else {
                    this.setState({include_post:!include_post,
                      personalMessage: personalMessage.replace("Please have a look at one of the issues I'd like to discuss with you.", "")
                    });
                  }

                }}
                />{' '}
                Include a preview of one of your posts (optional)
              </Label>
            </FormGroup>
            {
              include_post && (!user_posts || !user_posts.length) ?
              <FormGroup check>
              <Label check 
              class="form-control"   
              style={{
                fontSize: '0.9rem',
                fontWeight: 600,
                letterSpacing: 0.5,
                color: 'gray'
              }}>
              You don't have any posts yet, create one to include it
            </Label>
          </FormGroup>
              : null
            }
            {!include_post || !user_posts || !user_posts.length ? null :
            <FormGroup className="allyus-form-group">
                  <Input type="select" onChange={(e) => {
                    this.setState({ post_index: e.target.value});
                  }} value={post_index}>
                      {
                          user_posts && user_posts.map((post_opt, i) => {
                              return <option  value={i}>{post_opt.title}</option>
                          })
                      }  
                  </Input>
              </FormGroup>
            }{
              !include_post || !user_posts || !user_posts.length  ? null :
              <div className="link-preview-container">
                <div className="div1">
                  <img className="img1"
                   src={user_posts[post_index].preview_image ? user_posts[post_index].preview_image :
                    user_posts[post_index].image ? user_posts[post_index].image: "/allyuslogo.png"} 
                    alt="preview" />
                </div>
                <div className="div2">
                    <p className="p1">allyus.org</p>
                    <p className="p2">{user_posts[post_index].title}</p>
                  </div>
                    
                </div>
            }

            </div>

            {!include_post || !user_posts || user_posts.length < 1 ? null :
            <div>
            <FormGroup check>
                <Label check 
                class="form-control"   
                style={{
                  fontSize: '0.9rem',
                  fontWeight: 600,
                  letterSpacing: 0.5,
                }}>
                <Input 
                  type="checkbox"
                  checked={include_post2} 
                onChange={() => {
                    this.setState({include_post2:!include_post2,
                    });
                }}
                />{' '}
                Include a preview of a second post (optional)
              </Label>
            </FormGroup>
            {
              include_post2 && (!user_posts || user_posts.length <= 1) ?
              <FormGroup check>
              <Label check 
              class="form-control"   
              style={{
                fontSize: '0.9rem',
                fontWeight: 600,
                letterSpacing: 0.5,
                color: 'gray'
              }}>
              You don't have enough posts yet, create one to include it
            </Label>
          </FormGroup>
              : null
            }
            {!include_post2 || !user_posts || user_posts.length <= 1 ? null :
            <FormGroup className="allyus-form-group">
                  <Input type="select" onChange={(e) => {
                    this.setState({ post2_index: e.target.value});
                  }} value={post2_index}>
                      {
                          user_posts && user_posts.map((post_opt, i) => {
                              return <option  value={i}>{post_opt.title}</option>
                          })
                      }  
                  </Input>
              </FormGroup>
            }{
              !include_post2 || !user_posts || user_posts.length <= 1  ? null :
              <div className="link-preview-container">
                <div className="div1">
                  <img className="img1" 
                    src={user_posts[post2_index].preview_image ? user_posts[post2_index].preview_image :
                      user_posts[post2_index].image ? user_posts[post2_index].image: "/allyuslogo.png"} 
                  alt="preview" />
                </div>
                <div className="div2">
                    <p className="p1">allyus.org</p>
                    <p className="p2">{user_posts[post2_index].title}</p>
                  </div>
                    
                </div>
            }

            </div>
            }
        </form>
      </ModalBody>

      <ModalFooter>
        <Button className={"allyus-btn" + (inviteConstituents ? " allyus-secondery-btn" : " allyus-primary-btn")} style={{width:150}} onClick={() => submit()}>Send Invites</Button>
      </ModalFooter>
    </Modal>
  );
  }
};

const mapStateToProps = state => {
  return {
      inviteConstituents: state.modals.invite_constituents,
      invitesList: state.invitesReducer,
      currentUser: state.currentUser,
  }
}


export default connect(mapStateToProps, {})(InviteModal);
