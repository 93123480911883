const INITIAL_STATE = {
    signin_modal: false,
    create_post_modal: false,
    edit_post_modal: false,
    report_bug_modal: false,
    tutorial_modal: false,
    invite_modal: false,
    invite_constituents: false,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'TOGGLE_MODAL':
            if (action.invite_constituents){
                return {...state, [action.modal]: !state[action.modal], invite_constituents: true};
            }
            return {...state, [action.modal]: !state[action.modal],  invite_constituents: false};
        default:
            return state;
    }
};