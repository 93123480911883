import React from 'react';
import MainNavbar from '../navbar/index';
import MobileNavbar from '../navbar/mobile/index';

export default class Header extends React.Component {
  render() {
    return (
      <header className="sticky-top">
        <MainNavbar {...this.props} />
        <MobileNavbar {...this.props} />
      </header>
    )
  }

}
