import React from "react";
import { Modal, FormGroup, Label, Input } from "reactstrap";

const ImageInput = props => {
  const { add_image, onChange, toggle } = props;
  return (
    <Modal
      isOpen={add_image}
      toggle={toggle}
      className="post-modal-dialog"
      backdrop={true}
    >
      <div className="allyus-form">
        <div className=" create-petition " style={{ backgroundColor: "white" }}>
          <button onClick={toggle} className="close-btn close">
              <span className="close" >x</span>
          </button>
          <FormGroup className="allyus-form-group">
            {/* <Label for="imageurl">Paste Image Link</Label>
            <Input
              onChange={onChange}
              name="image_link"
              value={value}
              // placeholder="Image Link"
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none"
              }}
            /> */}
            <input
              type="file"
              onChange={onChange}
              name="image_link"
              // value={value}
            />
          </FormGroup>
        </div>
      </div>
    </Modal>
  );
};

export default ImageInput;
