import React, { Component } from 'react';
import PostTarget from '../postTarget/index';
import PostHead from '../postHead/index';
import PostBody from '../postBody/index';
import AllyusPost from '../allyusPost/index';
import AllyusCardWrapper from '../../allyusCardWrapper/index';
import _ from 'lodash';
import { getProfileNamePost, getProfilePicPost, getProfileUrl } from '../../../functions/profile';


export default class PollCard extends Component {

render() {
    const poll = this.props.post;
    
    const { active, currentUser } = this.props;

    var petition = poll.has_petition ? poll.petition_id : null;
    if (_.isArray(petition)){
      petition = petition[0];
    }    
    const post = this.props.post;
    var my_post = (currentUser && post && post.user) && ((currentUser._id === post.user) || (currentUser._id === post.user._id)
    || (currentUser.uuid === post.user.uuid));
    my_post = my_post || (currentUser && currentUser.admin);
    return (
      <AllyusCardWrapper className={active ? "active-card" : ""}>
        <AllyusPost >
          <PostTarget
            targetName={getProfileNamePost(poll)}
            targetLocation={poll.location_city + ", " + poll.location_region}
            targetImg={getProfilePicPost(poll)}
            postTitle={!poll.featured ? 'Poll' : 'Featured Poll'} 
            postDate={poll.created_at}
            targetLink={getProfileUrl(poll.user)}
            post={post}
          />
          <PostHead
            supportors={poll.hasPetition ? petition.signatures : ''} 
            postTags={poll.tags}
            postSummery={poll.title}
            postType={'poll'}
            postCommentsCount={poll.no_of_comments}
            postUpvote={poll.upvotes}
            postDownvote={poll.downvotes}
            hasPetition={poll.has_petition ? true : false}
            petition ={petition} 
            post_id={poll._id}
            my_post={my_post}
            // deletePost={this.props.deletePost}
            post={post}
          />
          <PostBody
            postType={'poll'}
            postImg={poll.image}
            postVedio={poll.video}
            pollType={false}
            petition ={petition} 
            post={this.props.post}
            post_model="posts"
            featured={poll.featured}
          > 
          </PostBody>
         
        </AllyusPost>
      </AllyusCardWrapper>
    )
  }
}
