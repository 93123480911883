import React, { Component } from 'react';
import { Container, TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import PolititionDetailCard from '../../components/politicianProfile/polititionDetailsCard/';
import classnames from 'classnames';
import PolititionAboutCard from '../../components/politicianProfile/polititionAboutCard/';
import PolititionProfileInfoCard from '../../components/politicianProfile/polititionProfileInfoCard/index';
import PolititionContectCard from '../../components/politicianProfile/polititionContectCard/';
import PolititionRecentNewsCard from '../../components/politicianProfile/polititionRecentNewsCard/index';
import PolititionVolunteerCard from '../../components/politicianProfile/polititionVolunteerCard/index';
import PolititionFeedsCard from '../../components/politicianProfile/polititionFeedsCard/index';
import PolititionEndorsementCard from '../../components/politicianProfile/polititionEndorsementCard/index';
import PolititionEventsCard from '../../components/politicianProfile/polititionEventsCard/index';
import PolititionIssuesCard from '../../components/politicianProfile/polititionIssuesCard/index';
import PoliticianDonateCard from '../../components/politicianProfile/donateCard/';
import PoliticianClaimAccountModal from '../../components/politicianProfile/claimAccountModal';
import Button from '../../components/button/index';
import { connect } from 'react-redux';
import { editPoliUser, followUser, unfollowUser, toggleSignInModal,
  getPoliProfile, addEndorsement, deleteEndorsement, removeMyEndorsement, editPost, deletePost,
  toggleInviteModal, toggleCreatePostModal } from "../../store/actions";
import AllyusCardWrapper from '../../components/allyusCardWrapper/index';
import { getUserProfilePic, profilePicError } from '../../functions/profile';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../config";
import AllyusCard from '../../components/allyusCard';

const { api, allyus_url } = config;

const TAB_ACTIVE_ABOUT = 1,
  TAB_ACTIVE_PLATFORM = 2,
  TAB_ACTIVE_EVENT = 3,
  TAB_ACTIVE_MEDIA_NEWS = 4,
  TAB_ACTIVE_ENDORSEMENT = 5;

class politicianProfile extends Component {
  state = {
    activeTab: 1 ,
    modalDetail: false,
    modalAbout: false,
    modalClaimAccount: false,
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  toggleProfile = (type) => {
    this.setState({
      userProfileType: type
    })
  }

  componentDidMount(){
    this.props.getPoliProfile(this.props.match.params.name);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.name !== prevProps.match.params.name ){
      this.props.getPoliProfile(this.props.match.params.name);
      // this.forceUpdate();
    }    
  }

  userEmailSignUp = async (nameUrl) => {
    try {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      await axios.post(`${allyus_url}/politicians/${nameUrl}/emailsignup?access_token=${access_token}`);
      alert('Thanks for signing up!');
    } catch(err) {
      console.log(err);
    }
  }




  render() {
    const {  profile, platform, events, news, endorsements, feed } = this.props.politicianProfile;
    const { currentUser } = this.props;
    const { displayedModules } = profile;
    // endorsements: {type: Boolean, default: true},
    // events: {type: Boolean, default: true},
    // news: {type: Boolean, default: true},
    // platform: {type: Boolean, default: true},
    // volunteer: {type: Boolean, default: true},
    // feed: {type: Boolean, default: true},
    // donate: {type: Boolean, default: true},
    var my_profile = this.props.currentUser._id === profile._id

    const item = profile;
    const img_style = item.influencer ? 'profile-img-influencer' : item.lobbyist ? 'profile-img-lobbyist' : item.user_politician ? 'profile-img-politician'
    : item.organization ? 'profile-img-organization' : 'profile-img-voter';

    if (!profile) { return <div /> } //loading

    return (
      <Container fluid>
        <Helmet>
          <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
          <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
          {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
          <meta name="twitter:image" content="/allyuslogo.png"  />
          <meta property="og:image" content="/allyuslogo.png"  />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="315" />
        </Helmet>
        <Row className="mt-0 mt-xl-3">
          <Col xl={{size : 8 , offset :0}} md={{size: 8, offset: 2 }} >
            <div className="politition-profile-card p-0">
              <AllyusCardWrapper>
                <div className="allyus-cover-img ">
                  <img src={profile.cover_photo ? profile.cover_photo : "/images/p-bg.jpg"}  alt="cover img" className="cover" />
                  { !profile.user_politician || profile.claimed ? null :
                    <Button
                      className="allyus-btn allyus-secondery-border-btn btn-claim"
                      width="120"
                      click={() => this.setState({modalClaimAccount: true})}
                      >
                        Claim
                    </Button>
                  }
                </div>
                <div className={`allyus-profile-img ${img_style}`}>
                  <img src={getUserProfilePic(profile)} alt="profile img" className="profile" onError={e => profilePicError(e)} />
                </div>
                <div className={ (profile.organization ? "organization-profile-nav" : "politition-profile-nav") + " allyus-tab-nav "}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TAB_ACTIVE_ABOUT })}
                        onClick={() => { this.toggle(TAB_ACTIVE_ABOUT); }}
                      >
                        Feed
                    </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TAB_ACTIVE_PLATFORM })}
                        onClick={() => { this.toggle(TAB_ACTIVE_PLATFORM); }}
                      >
                        Platform
                    </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TAB_ACTIVE_EVENT })}
                        onClick={() => { this.toggle(TAB_ACTIVE_EVENT); }}
                      >
                        Event
                    </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TAB_ACTIVE_MEDIA_NEWS })}
                        onClick={() => { this.toggle(TAB_ACTIVE_MEDIA_NEWS); }}
                      >
                        {`Media & News `}
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === TAB_ACTIVE_ENDORSEMENT })}
                        onClick={() => { this.toggle(TAB_ACTIVE_ENDORSEMENT); }}
                      >
                        Endorsement
                      </NavLink>
                    </NavItem>
                    { ((profile.account_type === 'constituency')) ? null :
                    <NavItem>
                      <NavLink href={profile.donate_link ? profile.donate_link : '#'} target="blank">
                        <Button  className={profile.organization ? "allyus-organization-btn" : "allyus-secondery-btn"}>
                          Donate
                        </Button>
                      </NavLink>
                    </NavItem>
                  }
                  </Nav>
                  <PolititionDetailCard profile={profile} my_profile={my_profile} userAdmin={currentUser.admin} editUser={this.props.editPoliUser}
                     followUser={this.props.followUser} 
                     unfollowUser={this.props.unfollowUser} 
                     userEmailSignUp={() => firebase.auth().currentUser ? this.userEmailSignUp(profile.nameUrl) : this.props.toggleSignInModal()} 
                     currentUser={currentUser} />
                </div>
              </AllyusCardWrapper>
              <TabContent activeTab={this.state.activeTab} className="primary">
                <TabPane tabId={TAB_ACTIVE_ABOUT}>
                  <Row>
                    <Col xl="5">
                      {
                        profile.user_politician ?
                        <PolititionProfileInfoCard info={profile} my_profile={my_profile} userAdmin={currentUser.admin} editUser={this.props.editPoliUser} />
                        : null
                      }
                      <PolititionContectCard contacts={profile} my_profile={my_profile} userAdmin={currentUser.admin} editUser={this.props.editPoliUser} />
                    </Col>
                    <Col xl="7">
                      <PolititionFeedsCard data={feed} target={profile.first_name} currentUser={currentUser} 
                        deletePost={this.props.deletePost} editPost={this.props.editPost} my_profile={my_profile} userAdmin={currentUser.admin}
                        toggleCreatePostModal={this.props.toggleCreatePostModal} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TAB_ACTIVE_PLATFORM}>
                  <Row>
                    <PolititionIssuesCard currentUser={currentUser} my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} issuesList={platform} getProfile={() => this.props.getPoliProfile(this.props.match.params.name)}/>
                  </Row>
                </TabPane>
                <TabPane tabId={TAB_ACTIVE_EVENT}>
                  <Row>
                    <Col>
                      <PolititionEventsCard my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} eventsList={events}  getProfile={() => this.props.getPoliProfile(this.props.match.params.name)}/>
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TAB_ACTIVE_MEDIA_NEWS}>
                  <Row>
                    <Col>
                      <PolititionRecentNewsCard my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} news={news} getProfile={() => this.props.getPoliProfile(this.props.match.params.name)} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId={TAB_ACTIVE_ENDORSEMENT}>
                  <Row>
                    <Col>
                      <PolititionEndorsementCard my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} endorsements={endorsements} 
                        currentUser={currentUser} deleteEndorsement={this.props.deleteEndorsement}
                        addEndorsement={this.props.addEndorsement} removeMyEndorsement={this.props.removeMyEndorsement} />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Col>
          <Col xl={{size : 4 , offset :0}}  md={{size: 8, offset: 2 }}>
          {
               profile.user_politician && my_profile ? 
              <AllyusCard title="Invite Constituents">
                <Button 
                    onClick={e => {
                        this.props.toggleInviteModal('constituents');
                      }}
                className="allyus-secondery-border-btn"  width="165">Invite Constituents</Button>
             
              </AllyusCard>
              : null
          }
           {
              profile.account_type === 'constituency' ? null : 
              <PolititionVolunteerCard my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} editUser={this.props.editPoliUser} 
              currentUser={currentUser} toggleSignInModal={this.props.toggleSignInModal} />
            }
            <PolititionAboutCard my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} editUser={this.props.editPoliUser} />

            {(!my_profile && !currentUser.admin) || profile.account_type === 'constituency' ? null : 
                      <PoliticianDonateCard my_profile={my_profile} userAdmin={currentUser.admin} profile={profile} editUser={this.props.editPoliUser}  />
                      }

          </Col>
        </Row>
        <PoliticianClaimAccountModal profile={profile} isOpen={this.state.modalClaimAccount} toggle={() => this.setState({modalClaimAccount: !this.state.modalClaimAccount})} />
      </Container>
    );
  }
}

const mapStateToProps = state => {
  return {
      currentUser: state.currentUser,
      politicianProfile: state.politicianProfile,

  }
}

export default connect(mapStateToProps, { getPoliProfile, addEndorsement, deleteEndorsement, removeMyEndorsement, editPoliUser, followUser, unfollowUser, toggleSignInModal, editPost, deletePost, toggleInviteModal, toggleCreatePostModal })(politicianProfile)
