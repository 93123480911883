import React, { Component } from 'react';
import AllyusCard from '../../allyusCard/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';

class PolititionContectCard extends Component {
    constructor(props) {
        super(props);
        const { contacts } = props;
        this.state = { 
          modal: false,
          generalContact: contacts.generalContact,
          pressContact: contacts.pressContact,
          contactNumber: contacts.contactNumber,
          facebook: contacts.facebook,
          twitter: contacts.twitter,
          instagram: contacts.facebook,
        };    
    }

    componentDidUpdate(prevProps) {
        if (prevProps.contacts._id !== this.props.contacts._id){
            const { contacts } = this.props;
            this.setState({ 
                modal: false,
                generalContact: contacts.generalContact,
                pressContact: contacts.pressContact,
                contactNumber: contacts.contactNumber,
                facebook: contacts.facebook,
                twitter: contacts.twitter,
                instagram: contacts.facebook,
              });   
        }
    }

    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleInputChange = (event)=> {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }


  onSubmit = () => {
    const { 
      generalContact,
      pressContact,
      contactNumber,
      facebook,
      twitter,
      instagram
     } = this.state;
    const updatedProfile = { 
        generalContact,
        pressContact,
        contactNumber,
        facebook,
        twitter,
        instagram
    };
    this.props.editUser(updatedProfile);
    this.toggle();
  }

    render() {
        const { contacts, my_profile, userAdmin } = this.props;
        return (
            <AllyusCard
                title="Contact"
                edit={my_profile || userAdmin}
                click={this.toggle}
                theme='p'
            >
                <div className="profileInfo">
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">general contact</span>
                        <span className="allyus-card-sub-heading"> {contacts.generalContact}</span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">press contact</span>
                        <span className="allyus-card-sub-heading"> {contacts.pressContact}</span>
                    </p>
                    <p className="mb-2">
                        <span className="allyus-card-heading mb-0">call</span>
                        <span className="allyus-card-sub-heading"> {contacts.contactNumber}</span>
                    </p>
                    <div className="mb-2" style={{ display: 'flex' }}>
                        <p className="mb-2">
                            {!contacts.facebook ? null :
                            <a href={contacts.facebook.includes("facebook.com") ? contacts.facebook : `https://www.facebook.com/${contacts.facebook}`} target="blank">
                            <i class="fab fa-facebook-f" style={{color:'black', marginRight:10}}></i>
                            </a>
                            }
                            {!contacts.twitter ? null :
                            <a href={contacts.twitter.includes("twitter.com") ? contacts.twitter : `https://www.twitter.com/${contacts.twitter}`} target="blank">
                            <i class="fab fa-twitter" style={{color:'black', marginRight:10}} ></i>                        
                            </a>
                            }
                            {!contacts.instagram ? null :
                            <a href={contacts.instagram.includes("instagram.com") ? contacts.instagram : `https://www.instagram.com/${contacts.instagram}`} target="blank">
                            <i class="fab fa-instagram" style={{color:'black', marginRight:10}}></i>                        
                            </a>
                            }
                            {!contacts.youtube ? null :
                            <a href={contacts.youtube.includes("youtube.com") ? contacts.youtube : `https://www.youtube.com/channel/${contacts.youtube}`} target="blank">
                            <i class="fab fa-youtube" style={{color:'black', marginRight:10}}></i>                        
                            </a>
                            }
                        </p>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className="allyus-card-title">Edit contact Info</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="generalContact">general contact</Label>
                                <Input type="email" id="generalContact" name="generalContact" value={this.state.generalContact} onChange={this.handleInputChange}  />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="pressContact">press contact</Label>
                                <Input type="email" id="pressContact" name="pressContact" value={this.state.pressContact} onChange={this.handleInputChange}/>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="phone">phone number</Label>
                                <Input type="phone" id="phone" name="contactNumber" value={this.state.contactNumber} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="facebook">facebook</Label>
                                <Input type="text" id="facebook" name="facebook" value={this.state.facebook} onChange={this.handleInputChange} />
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="instagram">instagram</Label>
                                <Input type="text" id="instagram"  name="instagram" value={this.state.instagram} onChange={this.handleInputChange}/>
                            </FormGroup>
                            <FormGroup className="allyus-form-group">
                                <Label for="twitter">twitter</Label>
                                <Input type="text" id="twitter" name="twitter" value={this.state.twitter} onChange={this.handleInputChange}/>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={this.onSubmit}> save </Button>
                    </ModalFooter>
                </Modal>
            </AllyusCard>

        );
    }

}

export default PolititionContectCard;
