export const openShareModal = (url) => {
    return{
        type: 'OPEN_SHARE_MODAL',
        url: url
    };
}

export const closeShareModal = () => {
    return{
        type: 'CLOSE_SHARE_MODAL',
    };
}

export const toggleShareModal = () => {
    return{
        type: 'TOGGLE_SHARE_MODAL',
    };
}