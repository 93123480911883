import React, { Component } from 'react';
import PetitionCard from '../../feed/petitionCard';
import { Container, Row, Col } from 'reactstrap';

export default class UserPetitionsCard extends Component {
    render() {
        const { petitions } =this.props; 
        return (
            <div className="userPetitions">
                <Row>
                    <Col lg="4">
                        {/* <div className="userPetition-filter-card allyus-card">
                            <p className="allyus-card-title">petition filter </p>
                            <hr />
                            <p className="allyus-text-primary">Show &nbsp; <i className="fa fa-angle-down"></i></p>
                        </div> */}
                    </Col>
                    <Col sm="12" lg={8}>
                        <div className="userPetitions-card">
                        {
                            petitions && petitions.map(petition => {
                                if (petition){
                                return <PetitionCard petition={petition}/>
                                }
                            })
                        }
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
