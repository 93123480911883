import React, { Component } from 'react';
import PostTarget from '../postTarget/index';
import PostHead from '../postHead/index';
import PostBody from '../postBody/index';
import AllyusPost from '../allyusPost/index';
import AllyusCardWrapper from '../../allyusCardWrapper/index';

import { getProfileNamePost, getProfilePicPost, getProfileUrl } from '../../../functions/profile';

export default class NewsCard extends Component {
  state = {
    activeTab: '1',
    newsAccurate: false,
    newsRefute: false,
    showNewstabs: false,
    comments: [],
  }
  
  render() {
    const { active, currentUser } = this.props;
    const news = this.props.post;
    const post = this.props.post;
    var my_post = (currentUser && post && post.user) && ((currentUser._id === post.user) || (currentUser._id === post.user._id)
    || (currentUser.uuid === post.user.uuid));
    my_post = my_post || (currentUser && currentUser.admin);
    
    return (
      <AllyusCardWrapper className={active ? "active-card" : ""}>
        <AllyusPost>
          <PostTarget
            targetName={getProfileNamePost(news)}
            targetLocation={news.location_city + ", " + news.location_region}
            targetImg={getProfilePicPost(news)}
            postTitle='NEWS'
            postDate={news.created_at}
            targetLink={getProfileUrl(news.user)}
            post={post}

          />
          <PostHead
            supportors={''}
            postTags={news.tags}
            postSummery={news.title}
            postType={'news'}
            pollType={false}
            postCommentsCount={news.no_of_comments}
            postUpvote={news.upvotes}
            postDownvote={news.downvotes}
            post_id={news._id}
            my_post={my_post}
            // deletePost={this.props.deletePost}
            post={post}
          />
          <PostBody
            postType={'news'}
            postImg={news.image}
            postVedio={''}
            pollType={false}
            petition ={''}
            post={this.props.post}
            post_model="posts"
          />
          {/* <PostFooter /> */}
        </AllyusPost>
      </AllyusCardWrapper>
    )
  }
}
