import React from "react";
import  Player  from 'react-player'
import Button from "../../button/index";
import { connect } from "react-redux";
import PollTabs from "../pollTabs/index";

import { toggleSignInModal } from "../../../store/actions";
import {textTolinkPreviewPost} from '../../../functions/textTolink';


import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../../config";
const { allyus_url } = config;

class PostBody extends React.Component {
  state = {
    postDialogOpen: false,
    comments: [],
    topComments: [],
    pageLoaded: false,
    userVoted: false,
    userVotedYes: false,
    userVotedNo: false,
    userVotedOther:false,
    yesVotePercentage: this.props.post.upvotes
      ? 100 *
        (this.props.post.upvotes /
          (this.props.post.upvotes + this.props.post.downvotes + this.props.post.neutralvotes))
      : 0,
    noVotePercentage: this.props.post.downvotes
      ? 100 *
        (this.props.post.downvotes /
          (this.props.post.upvotes + this.props.post.downvotes + this.props.post.neutralvotes))
      : 0,
    otherVotePercentage: this.props.post.downvotes
    ? 100 *
      (this.props.post.neutralvotes /
        (this.props.post.upvotes + this.props.post.downvotes + this.props.post.neutralvotes))
    : 0,
    commentWithRep: "",
    petitionSigned: this.props.petition ? false : true,
    pageLoaded: false,
    showTalkComments: false,
    showPlatformComments: false,
    demographics: {upvotes:{}, downvotes:{}, neutral:{}},
    state_post: this.props.post,
  };

  componentDidMount() {
    this.getPostData();
  }

  componentDidUpdate(prevProps){
    if (this.props.currentUser && this.props.currentUser._id !== prevProps.currentUser._id) {
      this.getPostData();
    }
    if (prevProps.post._id !== this.props.post._id){
      this.getPostData();
    }
  }

  nestComments = commentList => {
    const commentMap = {};
    // move all the comments into a map of id => comment
    commentList.forEach(comment => (commentMap[comment._id] = comment));

    // iterate over the comments again and correctly nest the children
    commentList.forEach(comment => {
      if (comment.parent !== null) {
        const parent = commentMap[comment.parent];
        (parent.children = parent.children || []).push(comment);
      }
    });

    // filter the list to return a list of correctly nested comments
    return commentList.filter(comment => {
      return comment.parent === null;
    });
  };

  getPageComments = (postID, access_token, post_model, callback) => {
    // if add sort=recent will sort by recent instead of highest voted
    post_model = post_model ? post_model : 'posts'
    axios
      .get(
        `${allyus_url}/${post_model}/${postID}/comments?access_token=${access_token}`
      )
      .then(result => {
        // Returning comments
        callback(this.nestComments(result.data));
      })
      .catch(error => {
        this.setState({comments:[]});
        // console.log(error.message)
      });
  };

  getPageCommentsVisitor = (postID,  post_model, callback) => {
    // if add sort=recent will sort by recent instead of highest voted
    post_model = post_model ? post_model : 'posts'
    axios
      .get(
        `${allyus_url}/${post_model}/${postID}/comments`
      )
      .then(result => {
        // Returning comments
        callback(this.nestComments(result.data));
      })
      .catch(error => {
        this.setState({comments:[]});
        // console.log(error.message)
      });
  };

  getPostData = async () => {
    const auth = firebase.auth().currentUser;
    const { post } = this.props;
    var { post_model } = this.props;
    if (!post_model) {post_model = 'posts'};
    if (auth && auth.uid) {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      axios
        .get(
          `${allyus_url}/${post_model}/${post._id}?access_token=${access_token}`
        )
        .then(result => {
          this.setState({
            commentWithRep: !result.data.user.rep
              ? null
              : !result.data.user.rep.comment
              ? ""
              : result.data.user.rep.comment,
            userVoted: !result.data.user.vote
              ? null
              : !result.data.user.vote.vote
              ? false
              : true,
            userVotedYes: !result.data.user.vote
              ? null
              : result.data.user.vote.vote === 1
              ? true
              : false,
            userVotedNo: !result.data.user.vote
              ? null
              : result.data.user.vote.vote === 2
              ? true
              : false,
            userVotedOther: !result.data.user.vote
              ? null
              : result.data.user.vote.vote === 3
              ? true
              : false,
            yesVotePercentage:
              result.data.post ?
                (100 *
                (result.data.post.upvotes /
                  (result.data.post.upvotes + result.data.post.downvotes + result.data.post.neutralvotes))
                ) : result.data.platform ?
                (
                  100 *
                (result.data.platform.upvotes /
                  (result.data.platform.upvotes + result.data.platform.downvotes + result.data.platform.neutralvotes))
                
                ) : 0
                  ,
              noVotePercentage:
              result.data.post ?
                (100 *
                (result.data.post.downvotes /
                  (result.data.post.upvotes + result.data.post.downvotes + result.data.post.neutralvotes))
                ) :  result.data.platform ? (
                  100 *
                (result.data.platform.downvotes /
                  (result.data.platform.upvotes + result.data.platform.downvotes + result.data.platform.neutralvotes))
                ) : 0
                ,
                otherVotePercentage:
                result.data.post ?
                  (100 *
                  (result.data.post.neutralvotes /
                    (result.data.post.upvotes + result.data.post.downvotes + result.data.post.neutralvotes))
                  ) :  result.data.platform ? (
                    100 *
                  (result.data.platform.neutralvotes /
                    (result.data.platform.upvotes + result.data.platform.downvotes + result.data.platform.neutralvotes))
                  ) : 0
                  ,
                  demographics: result.data.demographics ? result.data.demographics : {upvotes:{}, downvotes:{}, neutral:{}}, //TODO platform
                  state_post: result.data.post ? result.data.post : this.props.post,
            pageLoaded: true
          });
      
          if ((result.data.post && result.data.post.no_of_comments > 0 )|| (result.data.platform && result.data.platform.no_of_comments > 0 )) {
            this.getPageComments(post._id, access_token, post_model, pageComments => {
              this.setState({ comments: pageComments, commentsLoaded: true });
            });
          } else {
            this.setState({ pageLoaded: true, commentsLoaded: true, comments: [], topComments:[] });
          }
       
        })
        .catch(error => {
          // console.log(error.message)
        });
    
    } else { // not logged in
      axios
      .get(
        `${allyus_url}/${post_model}/${post._id}`
      )
      .then(result => {
        if ((result.data.post && result.data.post.no_of_comments > 0 )|| (result.data.platform && result.data.platform.no_of_comments > 0 )) {
          this.getPageCommentsVisitor(post._id, post_model, pageComments => {
            this.setState({ comments: pageComments, commentsLoaded: true });
          });
        } else {
          this.setState({ pageLoaded: true, commentsLoaded: true, comments: [], topComments:[] });
        }
        this.setState({
          demographics: result.data.demographics ? result.data.demographics : {upvotes:{}, downvotes:{}, neutral:{}}, //TODO platform
        });
   
      })
      .catch(error => {
        // console.log(error.message)
      });
    } 
  };

  // TODO save unauthenticated vote locally
  postVote = async (voteType) => {
    const auth = firebase.auth().currentUser;
    const post_id = this.props.post._id;
    const featured = this.props.post.featured;
    const petition_id = this.props.petition && this.props.petition._id ? this.props.petition._id : '';
    var { post_model } = this.props;
    // if (featured) {
    //   post_model = 'featured';
    // }
    if (!post_model) {post_model = 'posts'};
    try {
      if (auth && auth.uid) {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(`${allyus_url}/${post_model}/${post_id}/vote/${voteType}?access_token=${access_token}`);
        if (petition_id && voteType === 'upvote') {
          await axios.put(`${allyus_url}/petitions/${petition_id}/sign?access_token=${access_token}`);
        }
        this.getPostData();
        return 1;
      } else if (featured && (post_id !== '5df1be4f5178192de596b323')){
        //unauthenticated vote allowed on featured
        await axios.put(`${allyus_url}/featured/${post_id}/vote/${voteType}`);
        return 1;
      } else {
        this.props.toggleSignInModal(); 
      }
    } catch(err) {
      console.log(err);
    }

  }

  postYesHandler = async () => {
    const res = await this.postVote('upvote');
    if (res) {
      this.setState({
        userVotedYes: true,
        userVotedNo: false,
        userVotedOther: false
      });
    }
  };
  postNoHandler = async () => {
    const res = await this.postVote('downvote');
    if (res) {
      this.setState({
        userVotedYes: false,
        userVotedNo: true,
        userVotedOther: false
      });
    }
  };
  postOtherHandler = async () => {
    const res = await this.postVote('neutral');
    if (res) {
      this.setState({
        userVotedYes: false,
        userVotedNo: false,
        userVotedOther: true
      })
    }
  };


  render() {
    let { props } = this;
    let renderPostMedia;
    let renderPetitionBtn;
    let renderPollTabs;
    let renderButtons;
    const { userVotedNo, userVotedYes, userVotedOther,
       comments, topComments, userVoted, showTalkComments, 
      yesVotePercentage, noVotePercentage, otherVotePercentage } = this.state;
    const auth = firebase.auth().currentUser;
    const showPolltabs = userVoted && auth && auth.uid ? true : false;
    if (props.postType === "poll") {
      renderPetitionBtn = (
        <div className={"post-btn-group" + (props.post.image ? "" : " post-btn-group-no-img")}>
          <Button
            click={this.postYesHandler}
            className={`allyus-white-btn btn-yes ${
              userVotedYes ? "allyus-green-btn" : ""
            }`}
          >
          {userVoted && yesVotePercentage ? Math.round(yesVotePercentage * 10)/10 + "% (Y)" : "Agree (Y)"} 
          </Button>
          <Button
            click={this.postNoHandler}
            className={`allyus-white-btn btn-no ${
              userVotedNo ? "allyus-pink-btn" : ""
            }`}
          >
          {userVoted && noVotePercentage ? Math.round(noVotePercentage * 10)/10 + "% (N)" : "Disagree (N)"} 
          </Button>
          <Button
            click={this.postOtherHandler}
            className={`allyus-white-btn btn-other ${
              userVotedOther ? "allyus-neutral-btn" : ""
            }`}
          >
          {userVoted && otherVotePercentage ? Math.round(otherVotePercentage * 10)/10 + "%" : "Other"}
          </Button>
        </div>
      );
    }
    if (props.postType === "talk") { 
      renderButtons = (
        <div className="allyus-talk-buttons" >
          <div className="allyus-comment-btn">
            <div className="btn-list">
              <p className="text-muted mb-0">
                <span>{props.postCommentsCount}</span> &nbsp;
                  <img src="/icons/new/comments-icon.svg"
                  alt="rep"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="comment"
                  onClick={() => this.setState({showTalkComments:!showTalkComments})}
                />
              </p>
              <p className="text-muted mb-0">
                <span>{props.postUpvote}</span> &nbsp;
                  <img
                  src={userVotedYes ? '/icons/new/upvote-filled.svg' : "/icons/new/upvote-unfilled.svg"}
                  alt="upvote"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="up vote"
                  onMouseOver={e => (e.currentTarget.src = '/icons/new/upvote-filled.svg')}
                  onMouseOut={e => (e.currentTarget.src = userVotedYes ? '/icons/new/upvote-filled.svg' : '/icons/new/upvote-unfilled.svg')}
                  onClick={this.postYesHandler}
                />
              </p>
              <p className="text-muted mb-0">
                <span>{props.postDownvote}</span> &nbsp;
                  <img
                  src={userVotedNo ? '/icons/new/downvote-filled.svg' : "/icons/new/downvote-unfilled.svg"}
                  alt="downvote"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="down vote"
                  onMouseOver={e => (e.currentTarget.src = '/icons/new/downvote-filled.svg')}
                  onMouseOut={e => (e.currentTarget.src = userVotedNo ? '/icons/new/downvote-filled.svg' : '/icons/new/downvote-unfilled.svg')}
                  onClick={this.postNoHandler}

                />
              </p>
              <p className="text-muted mb-0">
    
              </p>
            </div>
            <div className="more"></div>
          </div>
        </div>
      );
    }
    if (props.postType === "news") {
      renderPetitionBtn = (
        <div className={"post-btn-group" + (props.post.image ? "" : " post-btn-group-no-img")}>
          <Button
            click={this.postYesHandler}
            className={`allyus-white-btn btn-yes ${
              userVotedYes ? "allyus-green-btn" : ""
            }`}
          >
           {userVoted && yesVotePercentage ? Math.round(yesVotePercentage * 10)/10 + "%" : "Accurate"} 
          </Button>
          <Button
            click={this.postNoHandler}
            className={`allyus-white-btn btn-no ${
              userVotedNo ? "allyus-pink-btn" : ""
            }`}
          >
            {userVoted && noVotePercentage ? Math.round(noVotePercentage * 10)/10 + "%" : "Refute"} 
          </Button>
          <Button
            click={this.postOtherHandler}
            className={`allyus-white-btn btn-other ${
              userVotedOther ? "allyus-neutral-btn" : ""
            }`}
          >
           {userVoted && otherVotePercentage ? Math.round(otherVotePercentage * 10)/10 + "%" : "Other"} 
          </Button>
        </div>
      );


    }


    if ((props.postType === 'poll' || props.postType === 'petition') && props.postVedio) {
      renderPostMedia = (
        <Player  url={props.postVedio} width={"auto"} controls />
      );
    }

    else if ((props.postType === 'poll' || props.postType === 'petition') && props.postImg) {
      renderPostMedia = (
        <div className="allyus-post-media">
          <img src={props.postImg} alt="post" className="post-media" />
        </div>
      );
    }
    else if ((props.postType === 'poll' || props.postType === 'petition') && props.post.url){
      renderPostMedia=(textTolinkPreviewPost(props.post.url))
    }
    else if ((props.postType === 'poll' || props.postType === 'petition') && !props.postImg && !props.postVedio && !props.post.url){
      renderPostMedia=(textTolinkPreviewPost(props.post.title))
    }
    if (props.postType === 'news' && props.post.url){
      renderPostMedia=(textTolinkPreviewPost(props.post.url));
    }


    // TODO add link previews here?
    if (props.postType === "platform") { 
      renderButtons = (
        <div style={{paddingBottom: 35}}>
          <div className="allyus-comment-btn">
            <div className="btn-list">
              <p className="text-muted mb-0">
                <span>{props.postCommentsCount}</span> &nbsp;
                  <img src="/icons/new/comments-icon.svg"
                  alt="rep"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="comment"
                  onClick={() => this.setState({showPlatformComments:!this.state.showPlatformComments})}
                />
              </p>
              <p className="text-muted mb-0">
                <span>{props.postUpvote}</span> &nbsp;
                  <img
                  src={userVotedYes ? '/icons/new/upvote-filled.svg' : "/icons/new/upvote-unfilled.svg"}
                  alt="upvote"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="up vote"
                  onMouseOver={e => (e.currentTarget.src = '/icons/new/upvote-filled.svg')}
                  onMouseOut={e => (e.currentTarget.src = userVotedYes ? '/icons/new/upvote-filled.svg' : '/icons/new/upvote-unfilled.svg')}
                  onClick={() => this.postYesHandler('platform')}
                />
              </p>
              <p className="text-muted mb-0">
                <span>{props.postDownvote}</span> &nbsp;
                  <img
                  src={userVotedNo ? '/icons/new/downvote-filled.svg' : "/icons/new/downvote-unfilled.svg"}
                  alt="downvote"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="down vote"
                  onMouseOver={e => (e.currentTarget.src = '/icons/new/downvote-filled.svg')}
                  onMouseOut={e => (e.currentTarget.src = userVotedNo ? '/icons/new/downvote-filled.svg' : '/icons/new/downvote-unfilled.svg')}
                  onClick={() => this.postNoHandler('platform')}
                />
              </p>
              <p className="text-muted mb-0">
              </p>
            </div>
            <div className="more"></div>
          </div>
        </div>
        );
    }
    renderPollTabs = (
      <PollTabs
        {...this.state}
        show={showPolltabs}
        // comments={comments}
        // topComments={topComments}
        currentUser={props.currentUser}
        post_id={props.post._id}
        getPostData={this.getPostData.bind(this)}
        // commentWithRep={this.state.commentWithRep}
        post_model={props.post_model}
        postType={props.postType}
        post={props.post}
        // state_post={this.state.state_post}
        // demographics={this.state.demographics}
        toggleSignInModal={this.props.toggleSignInModal}
        share_url={props.post.shareURL ? props.post.shareURL : 
          props.postType === 'petition' ? `${window.location.origin}/petitions/${props.post._id}` : `${window.location.origin}/posts/${props.post._id}`}
        
      />
    );

    return (
      <div>
        {renderButtons}
        <div className="allyus-post-body">
          {renderPetitionBtn}
          {renderPostMedia}
          {props.children}
          {renderPollTabs}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ post, currentUser, tabs }) => {
  return {
    currentUser
  };
};

export default connect(
  mapStateToProps,
  { toggleSignInModal }
)(PostBody);
