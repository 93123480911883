import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { getProfileNamePost, getProfilePicPost, getProfileLinkPost, profilePicError} from '../../../functions/profile';
import Button from '../../button/index';
import TimeAgo from 'timeago-react';
import PostBody from "../../Post/postBody/";
import PostHead from "../../Post/postHead/";

import AllyusCardWrapper from '../../allyusCardWrapper/index';
import DeleteModal from '../../deleteModal';
import Player from 'react-player';

import { connect } from 'react-redux';
import { openShareModal, toggleSignInModal, setEditPost } from "../../../store/actions";

import * as firebase from "firebase/app";
import axios from 'axios';
import config from "../../../config";
const { allyus_url } = config;

class PetitionCard extends Component {
  state = { expanded:false, signed: false, deletePostModal: false };

  componentDidMount() {
    this.checkSigned();
  }

  checkSigned = async () => {
    try {
      const auth = firebase.auth().currentUser;
      const petition_id = this.props.petition._id;
      if (auth && auth.uid) {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        const signed = await axios.get(`${allyus_url}/petitions/${petition_id}/checkusersigned?access_token=${access_token}`);
        this.setState({ signed: signed.data.signed });
      } 
    } catch (err) {
      console.log(err);
    }
  }

  signPetition = async () => {
    try {
      const auth = firebase.auth().currentUser;
      const petition_id = this.props.petition._id;
      if (auth && auth.uid) {
        this.setState({ signed: true });
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(`${allyus_url}/petitions/${petition_id}/sign?access_token=${access_token}`);
      } else {
        this.props.toggleSignInModal(); 
      }
    } catch (err) {
      console.log(err);
    }
  };


  unSignPetition = async () => {
    try {
      const auth = firebase.auth().currentUser;
      const petition_id = this.props.petition._id;
      if (auth && auth.uid) {
        this.setState({ signed: false });
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.delete(`${allyus_url}/petitions/${petition_id}/sign?access_token=${access_token}`);
      } else {
        this.props.toggleSignInModal(); 
      }
    } catch (err) {
      console.log(err);
    }
  };

  closePetition = async () => {
    try {
      const auth = firebase.auth().currentUser;
      const petition_id = this.props.petition._id;
      if (auth && auth.uid) {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(`${allyus_url}/petitions/${petition_id}/close?access_token=${access_token}`);
        alert("Submitted");
      }
    } catch (err) {
      console.log(err);
    }
  }

  render() {
    const { feed_page, petition, currentUser, active } = this.props;
    const { deletePostModal } = this.state;
    var { post }  = petition;
    if (post){
      post.petition_id = petition;
    }
    if (!petition || !post) {
      return <div/>
    }
    let classname = active ? "petition-card active-card" : "petition-card"
    var my_post =   (currentUser && petition && petition.user) && ((currentUser._id === petition.user) || (currentUser._id === petition.user._id) 
    || (currentUser.uuid === petition.user.uuid));
    my_post = my_post || (currentUser && currentUser.admin);
    const item = petition ? petition.user : {};
    const img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
    : item.organization ? 'user-img-organization' : 'user-img-voter';
    
    return (
      <AllyusCardWrapper className={classname}>
        <div className="petition-card allyus-post" >
          { feed_page ? 
            <div>
              <p className="petition-card-title allyus-card-title">petitions in your area </p>
              <hr />
            </div>
            : null }
          <div>
            <div className="petition-detail">
              <Link to={getProfileLinkPost(petition)}><img src={getProfilePicPost(petition)} alt="" className={`${img_style}`} onError={e => profilePicError(e)} /></Link>
              <div className="title">
                <p className="allyus-card-heading">{getProfileNamePost(petition)}</p>
                <p className="allyus-card-sub-heading">{post.location_city + " " + post.location_region}</p>
              </div>
              {/* <TimeAgo className="petition-date text-muted" datetime={petition.created} /> */}
            </div>

            <PostHead
              supportors={petition ? petition.signatures : ''} 
              postTags={post.tags}
              postSummery={petition.title}
              postType={'petition'}
              postCommentsCount={post.no_of_comments}
              postUpvote={post.upvotes}
              postDownvote={post.downvotes}
              hasPetition={petition ? true : false}
              petition ={petition} 
              post_id={post._id}
              my_post={my_post}
              // deletePost={this.props.deletePost}
              post={post}
              closePetition={this.closePetition.bind(this)}
            />
          </div> 

          <div className="petition-img">
            {post.image ? <img src={post.image}/> : post.video ? <Player url={post.video} width={'100%'} controls />: null }
          </div>
          <div className="mt-3">
            <p className="petition-desc allyus-text">
              {petition.pitch}
            </p>
            <p>
              <span className="text-muted">
                Petition to &nbsp;
                <Link to={!petition.receiver_id ? '#' : `/politicians/${petition.receiver_id.nameUrl}`} className="allyus-text-primary ">
                { !petition.receiver_id ? petition.receiver : `${petition.receiver_id.first_name} ${petition.receiver_id.last_name}`}
                </Link>
                  { 
                      !petition.receiver_id ? '' : `,  ${petition.receiver_id.office} ${petition.receiver_id.location_region}`
                }
              </span>
            </p>
            { !this.state.expanded ? (
              <a><p className="allyus-text-primary" onClick={() => this.setState({expanded:true})}>See more &nbsp; <i className="fa fa-angle-down"></i></p></a>
            ) : (
                <div className="mt-3 mx-3 expanded-petition">
                  <div style={{width: 150}}>
                    <Button className="allyus-btn allyus-primary-btn" click={this.state.signed ? this.unSignPetition : this.signPetition}>
                      {this.state.signed ?  "Retract Signature" :  "Sign Petition" }
                    </Button>
                  </div>
                  <div >
                    <Link to={`/posts/${post._id}`}><p className="allyus-text-primary" >Go to poll</p></Link>
                  </div>
                </div>           
            )}
          </div>   
          { !this.state.expanded ? null : (
                <PostBody
                 postType={'petition'}
                 postImg={''}
                 postVedio={''}
                 pollType={false}
                 petition ={petition} 
                 post={post}
                 post_model="posts"
               /> 
          )}
        </div>
        <DeleteModal
             isOpen={deletePostModal}
             toggle={() => this.setState({deletePostModal: !deletePostModal})}
             deleteFunction={() => this.props.deletePost(post._id)}
             deleteName="petition"
         />
      </AllyusCardWrapper>
    )
  }
};

const mapStateToProps = ( state ) => {
  return {};
};

export default connect(
  mapStateToProps,
  { openShareModal, toggleSignInModal, setEditPost }
)(PetitionCard);