import React from "react";
import Comment from './comment';

import { connect } from "react-redux";
import { openShareModal } from "../../store/actions";



class Comments extends React.Component {
  state = {
    commentsToRender: 2,
    post_id: null
  };
  renderMoreComments = () => {
    this.setState({
      commentsToRender: this.state.commentsToRender + 2
    });
  };

  render() {
    const {
      comments,
      currentUser,
      post_id,
      getPostData,
      commentWithRep,
      post_model,
      depth,
      openShareModal,
      post
    } = this.props;
    const { commentsToRender } = this.state;
    const totalComments = comments ? comments.length : 0;
    return (
      <div>
        <ul className={`allyus-comments ${this.props.className}`}>
          {!comments
            ? null
            : comments.slice(0, commentsToRender).map((cm, index) => {
                return (
                  <Comment
                  {...this.props}

                    key={index}
                    comments={cm}
  
                    post_model={post_model ? post_model : "posts"}
                    depth={depth ? depth : 1}
                  />
                );
              })}
        </ul>

        {totalComments > commentsToRender ? (
          <p className="allyus-text-primary" onClick={this.renderMoreComments} style={{cursor:'pointer'}}>
            {depth && depth > 1 ? "View More Replies" : "View More Comments"}
          </p>
        ) : (
          ""
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {};
};

export default connect(
  mapStateToProps,
  { openShareModal }
)(Comments);


