import React from "react";
import { UserCard } from "../../userCard";

export const Connections = props => {
    const list = props.list;
    // const { currentUser, unfollowUser, followUser } = props;
    return (
      <ul>
        {list &&
          list.map((item, i) => {
            return (
              <li key={i}>
                <UserCard item={item} {...props} />
              </li>
            );
          })}
      </ul>
    );
  };