import React from 'react';
import { withRouter } from 'react-router-dom';
import Header from '../jsx/components/header/index';
import CreatePostModal from './components/Post/createPostModal/index';
import SignInModal from './Pages/auth/modal';
import AllyusChatWindow from './components/allyusChatWindow/index';
import ShareModal from './components/Post/share/modal';
import ReportBugModal from './components/reportBugModal';
import TutorialModal from './Pages/tutorial/modal';
import InviteModal from './components/inviteModal';

import { connect } from 'react-redux';
import { toggleSignInModal, toggleCreatePostModal, getCurrentUser, toggleShareModal, toggleReportBugModal, toggleTutorialModal, toggleInviteModal, clearRedux, setInvites } from './store/actions';

import {getProfileUrlCurrentUser} from './functions/profile';

import * as firebase from "firebase/app";
import routes from './config/routes';
import ReactGA from 'react-ga';
import { Helmet } from "react-helmet";

const cloudsponge = window.cloudsponge;


class Layout extends React.Component {

  componentDidMount(){
    this.getContacts = this.getContacts.bind(this);
    if (firebase.auth().currentUser){
      this.props.getCurrentUser();
      ReactGA.set({
        userId: firebase.auth().currentUser.uid
      });
      cloudsponge.init({
        // the neon theme:
        theme: 'neon',
        sources: ['csv', 'gmail', 'windowslive', 'office365', 'yahoo', 'aol', 'linkedin', 'addressbook', 'icloud' ],
        css: "/cloudspongestyles.css",
        // some textual changes specified here
        localeData: {
          SELECT_CONTACTS: 'Choose Your Contacts',
          GET_CONTACTS: 'Select',
          GET_CONTACTS_MOBILE: 'Select',
          SEARCH_CONTACTS: 'Search',
          SEARCH_SELECTED: 'Search',
        },
        afterSubmitContacts: this.getContacts
      });
    } else {
      this.props.clearRedux();
      localStorage.clear();

    }
  }

  getContacts = (contacts, source, owner) => {
    this.props.setInvites(contacts, owner);
  }
  componentDidUpdate(prevProps){
    const {currentUser} = this.props;
    const fireuser = firebase.auth().currentUser;
    if (fireuser && currentUser  && prevProps.currentUser._id !== currentUser._id){
      ReactGA.set({
        userId: fireuser.uid
      });
      cloudsponge.init({
        // the neon theme:
        theme: 'neon',
        sources: ['csv', 'gmail', 'windowslive', 'office365', 'yahoo', 'aol', 'linkedin', 'addressbook', 'icloud' ],
        css: "/cloudspongestyles.css",
        // some textual changes specified here
        localeData: {
          SELECT_CONTACTS: 'Choose Your Contacts',
          GET_CONTACTS: 'Select',
          GET_CONTACTS_MOBILE: 'Select',
          SEARCH_CONTACTS: 'Search',
          SEARCH_SELECTED: 'Search',
        },
        afterSubmitContacts: this.getContacts
      });
    }
  }

  // componentDidUpdate(prevProps){
  //   const {currentUser} = this.props;
  //   const fireuser = firebase.auth().currentUser;
  //   if (currentUser  && prevProps.currentUser._id !== currentUser._id){
  //     if (fireuser && ((fireuser.providerData[0].providerId === 'password' || fireuser.providerData[0].providerId === 'phone') && 
  //     (!fireuser.phoneNumber && !fireuser.emailVerified))){
  //       // handled
  //     }
  //     else if (!currentUser.location_country || (currentUser.location_country === "United States" && !currentUser.postal_code)){
  //        this.props.history.push('/location')
  //      } else if (!currentUser.profile_creation_completed && (currentUser.__t !== 'politician')){
  //         this.props.history.push('/completeprofile');
  //      }
  //   }
  //   else if (currentUser && !prevProps.currentUser.postal_code && currentUser.postal_code && !currentUser.profile_creation_completed && (currentUser.__t !== 'politician') ){
  //     this.props.history.push('/completeprofile');
  //   }
  // }
 
  render() {
    
    const { location, signInModal, createPostModal, shareModal, reportBugModal, tutorialModal, inviteModal, chat, currentUser } = this.props;
    let hideHeader = ['/signin', '/signup_politician', '/forgotpassword', '/confirm', '/politician_signup', '/phone_auth', '/verifyemail'].indexOf(location.pathname) > -1;
  
    return (
      <div>
          <Helmet>
            <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
            <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
            {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
            <meta name="twitter:image" content="/allyuslogo.png"  />
            <meta property="og:image" content="/allyuslogo.png"  />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
        {
          hideHeader ? null : <Header />
        }
        {routes}
        <CreatePostModal
          isOpen={createPostModal}
          toggle={this.props.toggleCreatePostModal}
          location={this.props.location}
          history={this.props.history}
        />
        <SignInModal
          isOpen={signInModal}
          toggle={this.props.toggleSignInModal}
          history={this.props.history}
        />
        <ShareModal
          isOpen={shareModal}
          toggle={this.props.toggleShareModal}
        />
        <ReportBugModal
          isOpen={reportBugModal}
          toggle={this.props.toggleReportBugModal}
          currentUser={currentUser}
        />
        <TutorialModal
          isOpen={tutorialModal}
          toggle={this.props.toggleTutorialModal}
        />
        <InviteModal
          isOpen={inviteModal}
          toggle={this.props.toggleInviteModal}
        />
        {
          chat.show ? <AllyusChatWindow messages={chat.messages} /> : null
        }
        <div style={{textAlign:'center', fontSize:12}}>
          <p>&copy; 2020 Allyus </p>
          {
            this.props.location.pathname !== '/vision' ? null :
            <p style={{fontSize:8}}>With some icons from <a  target="blank" href="https://icons8.com/">Icons8</a></p>
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
      signInModal: state.modals.signin_modal,
      createPostModal: state.modals.create_post_modal,
      shareModal: state.share.isOpen,
      reportBugModal: state.modals.report_bug_modal,
      tutorialModal: state.modals.tutorial_modal,
      inviteModal: state.modals.invite_modal,
      chat : state.chat,
      auth: state.firebase.auth,
      currentUser: state.currentUser,
  }
}


export default connect(mapStateToProps,
  { toggleSignInModal, toggleCreatePostModal, getCurrentUser, toggleShareModal, toggleReportBugModal, toggleTutorialModal, toggleInviteModal, clearRedux, setInvites }
)(withRouter(Layout));
