import React from 'react';

class AllyusPost extends React.Component {
    render() {
        return (
            <div
                className={`allyus-post ${this.props.className}`}
            >
                {this.props.children}
            </div>
        )
    }

}

export default AllyusPost;