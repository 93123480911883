import React, { Component } from "react";
import * as firebase from "firebase/app";
import { Redirect } from "react-router-dom";
import AllyusCard from "../../../components/allyusCard";
import LocationDetailsSection from "../sections/location";
import Button from "../../../components/button";
import { connect } from "react-redux";
import { toggleInviteModal } from "../../../store/actions";
import ReactGA from 'react-ga';

class LocationDetails extends Component {
  componentDidMount(){
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  render() {
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return <Redirect to="/" />;
    }
    return (
      <div style={{ paddingTop: "1%" }} className="page-signup">
        <AllyusCard
          className="signIn"
          title="Thanks for signing up!"
          style={{ marginBottom: "5%", textAlign: "center" }}
        >
          <LocationDetailsSection history={this.props.history} />
        </AllyusCard>
      </div>
    );
  }
}

const mapStateToProps = state => {};

export default connect(
  mapStateToProps,
  { toggleInviteModal }
)(LocationDetails);
