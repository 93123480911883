import React from "react";
import Layout from "./Layout";
import { BrowserRouter } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import { Provider } from "react-redux";
import store from "./store/store.js";
import { persistStore } from "redux-persist";
import { PersistGate } from "redux-persist/integration/react";
import emailjs from "emailjs-com";
import Helmet from 'react-helmet';

import ReactGA from 'react-ga';
import 'autotrack';
import 'autotrack/lib/plugins/outbound-link-tracker';

ReactGA.initialize('UA-149326742-1',
// {debug:true}
);
ReactGA.plugin.require('outboundLinkTracker');

emailjs.init("user_iAsePfYCyLOyld4ezG45e");

let persistor = persistStore(store);

const App = () => {
  return (
    <div className="App">
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <ScrollToTop>
             <Helmet>
                <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
                <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
                {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
                <meta name="twitter:image" content="/allyuslogo.png"  />
                <meta property="og:image" content="/allyuslogo.png"  />
                <meta property="og:image:width" content="600" />
                <meta property="og:image:height" content="315" />
              </Helmet>
              <Layout />
            </ScrollToTop>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
