import React from "react";

export default class MessageList extends React.Component {
  render() {
    return (
      <ul className="message-list">
        {this.props.messages.map((message, index) => {
          if (index <= 10){
          return (
            <li key={message.id}>
              <div
                className={
                  message.senderId !== this.props.chatKitUserId ? "receiver" : "sender"
                }
              >
                {message.text}
              </div>
            </li>
          );
         }
        })}
      </ul>
    );
  }
}
