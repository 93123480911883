import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../config';
const { allyus_url } = config;


// For api call:
// ?type=___ all, posts, or news
// ?trending=___ trending or following or recent
// ?country=__ United States, Canada, etc
// ?region=___ (province or state) Ontario, Florida, etc
// can include ?days=___ for number of days since post created
// ?status=___ open or closed for petitions
   
// TODO lazy loading.
export const getPetitions = () => async (dispatch, getState)=> {
    try {
        var { 
            trending,
            country,
            region,
            status,
            } = getState().petitionFilters;
        status = status && (status.includes('Submitted') || status.includes('submitted')) ? 'closed' : 'open';

        if (firebase.auth().currentUser){
            const  access_token = await firebase.auth().currentUser.getIdToken(true);
            const petitions = await  axios.get(`${allyus_url}/petitions/feed?access_token=${access_token}&trending=${trending}&country=${country}&region=${region}&status=${status}`);
            dispatch ({
                type: 'PETITIONS_FETCH_SUCCESS',
                payload: {
                    petitions: petitions.data
                }
            });
        } else {
            const petitions = await axios.get(`${allyus_url}/petitions/feed?trending=${trending}&country=${country}&region=${region}&status=${status}`);
            dispatch ({
                type: 'PETITIONS_FETCH_SUCCESS',
                payload: {
                    petitions: petitions.data
                }
            });
        }
    } catch(err) {
        console.log(err)
    }
  }

  export const updatePetitionFilter = (filter_name, filter_value) => async (dispatch, getState)=> {
        dispatch ({
            type: 'UPDATE_PETITION_FILTER',
            filter_name : filter_name,
            filter_value : filter_value
        });
        dispatch(getPetitions());
  }
