const INITIAL_STATE = []
  
  const petitionsReducer = (state = INITIAL_STATE, action) => {
      switch (action.type) {
        case 'PETITIONS_FETCH_SUCCESS':
            return action.payload.petitions;
        default:
          return state
      }
    }
    
    export default petitionsReducer;