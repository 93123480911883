import React , { Component } from 'react'

class ChatHead extends Component{

    render(){
        const { senderName }  = this.props;
        return(
            <div className="chat-head">
                <span> { senderName } </span>
                <span className="exit" onClick={ this.props.onClose }> x</span>
            </div>
        )
    }
}

export default ChatHead;