import producer from "immer";

const INITIAL_STATE = {
  chatKitUser: "",
  room_msgs: {},
  rooms: [],
  roomId: '',
  currentRoom: '',
  otherUser: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "FETCH_CHATKIT_USER":
      return { ...state, chatKitUser: action.currentUser };
    case "FETCH_ROOMS":
      return { ...state, rooms: action.rooms, room_msgs: action.room_msgs,  chatKitUser: action.currentUser  };
    case "NEW_MSG":
      return {
        ...state,
        room_msgs: {
          ...state.room_msgs,
          [action.roomId]: {
            ...state.room_msgs[action.roomId],
            [action.roomId]: action.message
          }
        }
      };
    case 'SET_ROOM':
     return {...state, roomId: action.roomId, currentRoom: action.currentRoom, otherUser: action.otherUser};
    default:
      return state;
  }
};
