import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/index";
import {
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Row
} from "reactstrap";
import profile_fields from "../../formdata/profilefields.json";
import ImageInput from "../imageInput";
import { getLocation } from "../../functions/location";
import LocationDetailsModal from "../locationDetails/modal";
import AddressModal from "../locationDetails/addressModal";

import _ from "lodash";
import * as firebase from "firebase/app";
import "firebase/storage";


// NOTE: still figuring out some backend stuff on the details of location, districts, county, etc
export default class UserDetailCard extends React.Component {
  constructor(props) {
    super(props);
    const { profile } = props;
    this.state = {
      first_name: profile.first_name,
      last_name: profile.last_name,
      username: profile.username,
      location_country: profile.location_country,
      political_lean: profile.political_lean,
      postal_code: profile.postal_code,
      profile_picture: profile.profile_picture,
      profile_picture_file: null,
      cover_photo_file: null,
      cover_photo: profile.cover_photo,
      editing_image: "",
      add_image: false,
      location_info: "",
      locationInfoModal: false,
      addressModal: false,
      address: '',
    };
  }
  
  async componentDidUpdate(prevProps){
    if (prevProps.profile._id !== this.props.profile._id){
      const { profile } = this.props;

      this.setState({
        first_name: profile.first_name,
        last_name: profile.last_name,
        username: profile.username,
        location_country: profile.location_country,
        political_lean: profile.political_lean,
        postal_code: profile.postal_code,
        profile_picture: profile.profile_picture,
        profile_picture_file: null,
        cover_photo_file: null,
        cover_photo: profile.cover_photo,
        editing_image: "",
        add_image: false,
        location_info: "",
        locationInfoModal: false,
        addressModal: false,
        address: '',
      });
    }
}

  handleInputChange = event => {
    const value = event.target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    });
  };

  onSubmit = async () => {
    const {
      first_name,
      last_name,
      location_country,
      political_lean,
      postal_code,
      profile_picture_file,
      cover_photo_file,
      location_info
    } = this.state;
    var updatedProfile = {
      first_name,
      last_name,
      location_country,
      political_lean,
      postal_code,
    };
    
    if (profile_picture_file !== null){
      var storageRef = firebase.storage().ref();
      var file_name = this.props.profile._id;
      try {
        var ref = storageRef.child(`profile_pictures/${file_name}`)
        const snapshot = await ref.put(profile_picture_file);
        const downloadURL = await snapshot.ref.getDownloadURL();
        updatedProfile.profile_picture = downloadURL;
      } catch (err){
        console.log(err);
      }
    }
    if (cover_photo_file !== null){
      var storageRef2 = firebase.storage().ref();
      var file_name2 = this.props.profile._id;
      try {
        var ref2 = storageRef2.child(`cover_photos/${file_name2}`)
        const snapshot2 = await ref2.put(cover_photo_file);
        const downloadURL2 = await snapshot2.ref.getDownloadURL();
        updatedProfile.cover_photo = downloadURL2;
      } catch (err){
        console.log(err);
      }
    }
    if (location_info) {
      updatedProfile = _.extend(updatedProfile, location_info);
    }
    if (!location_info && postal_code !== this.props.profile.postal_code) {
      const location_info2 = await getLocation({
        by_zip: true,
        zip: postal_code
      });
      updatedProfile = _.extend(updatedProfile, location_info2);
    }
    this.props.editUser(updatedProfile);
    this.props.toggle();
  };

  // TODO restrict api key on google console
  getLocationGeoLocation() {
    if (navigator.geolocation) {
      this.setState({ locationInfoModal: true });
      try{
        navigator.geolocation.getCurrentPosition(async position => {

          let coords = `${position.coords.latitude}, ${position.coords.longitude}`;
          const location_info = await getLocation({
            coords: coords,
            by_coords: true
          });
          this.setState({ location_info: location_info });
        });
      } catch(err){
         console.log(err);
      }
    } else {
      alert("Geolocation is not supported by this browser.");
      console.log("Geolocation is not supported by this browser.");
    }
  }

  async getAddressLocation() {
    const location_info = await getLocation({
      address: this.state.address,
      by_address: true
    });
    this.setState({ location_info: location_info, addressModal:false, locationInfoModal: true });
  }

  renderImageInput(image) {
    return (
      <ImageInput
        add_image={this.state.add_image}
        toggle={() => this.setState({ add_image: !this.state.add_image })}
        onChange={this.onChangeFile.bind(this)}
        value={this.state[image]}
      />
    );
  }

  onChangeFile = (e) => {
    const file = e.target.files[0];
    var state_file = [this.state.editing_image] + '_file';
    this.setState({[state_file]: file});
    var storageRef = firebase.storage().ref();
    var file_name = this.props.profile._id + '_temp';
    var ref = storageRef.child(`profile_pictures/${file_name}`);
    if (state_file.includes('cover_photo')){
      ref = storageRef.child(`cover_photos/${file_name}`);
  }
    ref.put(file).then((snapshot) => {
      snapshot.ref.getDownloadURL().then((downloadURL) => {
        this.setState({ [this.state.editing_image]: downloadURL, add_image:false});
      });
    }).catch(err => {
      console.log(err);
    });
  }

  render() {
    const {
      cover_photo,
      profile_picture,
      first_name,
      last_name,
      location_country,
      postal_code,
      political_lean,
      editing_image
    } = this.state;
    const { open, toggle } = this.props;
    return (
      <div>
        <Modal
          isOpen={open}
          toggle={toggle}
          className="user-detail-modal-dialog"
        >
          <ModalHeader toggle={toggle}>Edit Profile</ModalHeader>
          <div class="allyus-cover-img">
            <img src={cover_photo} alt="coverphoto" className="cover" />
            <Link
              to="#"
              className="edit"
              onClick={() =>
                this.setState({ add_image: true, editing_image: "cover_photo" })
              }
            >
              <img src="/icons/new/edit-icon-c.svg" alt="edit" />
            </Link>
          </div>
          <div style={{ paddingBottom: 35 }}>
            <div class="allyus-profile-img">
              <img
                src={profile_picture}
                alt="profilephoto"
                className="profile"
              />
              <Link
                to="#"
                className="edit"
                onClick={() =>
                  this.setState({
                    add_image: true,
                    editing_image: "profile_picture"
                  })
                }
              >
                <img src="/icons/new/edit-icon-c.svg" alt="edit" />
              </Link>
            </div>
          </div>
          <ModalBody className="modalbody-edit-profile">
            <div className="allyus-form" >
              <Row form className="mt-5">
                <Col md={6}>
                  <FormGroup className="allyus-form-group">
                    <Label for="exampleEmail">First Name</Label>
                    <Input
                      type="firstName"
                      name="first_name"
                      id="firstName"
                      value={first_name}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="allyus-form-group">
                    <Label for="examplePassword">Last Name</Label>
                    <Input
                      type="lastName"
                      name="last_name"
                      id="lastName"
                      value={last_name}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <FormGroup className="allyus-form-group">
                    <Label for="exampleEmail">Country</Label>
                    <Input
                      type="select"
                      name="location_country"
                      id="exampleEmail"
                      value={location_country}
                      onChange={this.handleInputChange}
                    >
                      <option value=""></option>
                      <option value={"United States"}>{"United States"}</option>
                      <option value={"Canada"}>{"Canada"}</option>
                      <option value={"Other"}>{"Other"}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>

              <Row form>
                <Col md={12}>
                  <FormGroup className="allyus-form-group">
                    <Label for="exampleEmail">Zip Code</Label>
                    <Input
                      type="country"
                      name="postal_code"
                      id="exampleEmail"
                      value={postal_code}
                      onChange={this.handleInputChange}
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row form>
                <Col md={12}>
                  <FormGroup className="allyus-form-group">
                    <Label for="exampleEmail">Political Affiliation</Label>
                    <Input
                      type="select"
                      name="political_lean"
                      id="exampleSelect"
                      onChange={this.handleInputChange}
                      value={political_lean}
                    >
                      <option value=""></option>
                      {profile_fields.political_lean.map((field, i) => {
                        return <option value={field}>{field}</option>;
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              {location_country !== "United States" ? null : (
                <Row form>
                  <Col md={12}>
                    <FormGroup className="allyus-form-group">
                      <Label for="exampleEmail">
                        Location Details (Find Your Reps!){" "}
                      </Label>
                      <div style={{ display: "flex", margin: "10px 0" }}>
                        {
                          this.props.profile.postal_code ? null :
                          <Button
                            className="allyus-white-btn hide-mobile"
                            click={this.getLocationGeoLocation.bind(this)}
                            style={{ marginTop: 10 }}
                          >
                            Fill with Geolocation
                          </Button>
                        }
                        <Button
                          className="allyus-white-btn"
                          click={() => this.setState({addressModal: true})}
                          style={{ marginTop: 10 }}
                        >
                          { this.props.profile.postal_code ? "Change Address" : "Fill with address"}
                        </Button>
                      </div>
                      <p style={{ fontSize: 14 }}>
                        Note: We never save or sell your address or exact location, we
                        only use it to determine your political districts then
                        save those
                      </p>
                    </FormGroup>
                  </Col>
                </Row>
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button
              className="allyus-btn allyus-primary-btn"
              click={this.onSubmit}
            >
              {" "}
              save{" "}
            </Button>
          </ModalFooter>
          {this.renderImageInput(editing_image)}
        </Modal>

        <LocationDetailsModal open={this.state.locationInfoModal} location_info={this.state.location_info}
         toggle={() => this.setState({locationInfoModal: !this.state.locationInfoModal})} 
            onSubmitLocation={() => {
                this.props.editUser(this.state.location_info); 
                this.setState({locationInfoModal: !this.state.locationInfoModal});
                this.props.toggle();}} 
                />
      <AddressModal open={this.state.addressModal} value={this.state.address} 
        onChange={(e)=>this.setState({address: e.target.value})} 
        toggle={()=>this.setState({addressModal: !this.state.addressModal})}
        submit={this.getAddressLocation.bind(this)}
      />
      </div>
    );
  }
}
