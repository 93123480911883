import React, { Component } from "react";
// components
import { Container, Row, Col, Button } from "reactstrap";
import AllyusCard from "../../components/allyusCard/index";
import ChatInput from "../../components/allyusChatWindow/chatInput";
import Autocomplete from "react-autocomplete";
import CustomAutoComplete from '../../components/Post/politicianAutoComplete';
import { Message } from './message';
//redux
import { connect } from "react-redux";
import {
  initializeChatKit,
  sendMessage,
  sendMessageBlast,
  setReadCursor,
  startConvo,
  joinRoom
} from "../../store/actions";
//fucnctional
import _ from 'lodash';
// TODO typing indicator, read
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../config';

const { allyus_url } = config;

const getSuggestions = async value => {
  try {
  const inputValue = value.trim().toLowerCase();
  const inputLength = inputValue.length;
  const access_token = await firebase.auth().currentUser.getIdToken(true);
  const suggestions = await axios.get(`${allyus_url}/chat/users/?access_token=${access_token}&search=${inputValue}`);
  return inputLength === 0 ? [] : suggestions.data
  } catch (err){
    return [];
  }
};


class AllyusMessages extends Component {
  state = {
    inputtext: "",
    inputblast:"",
    renderNewMsg: false,
    newChatUser: "",
    newChatUserId: "",
  };
  componentDidMount() {
    //  this.props.initializeChatKit();
    if (!this.props.chatkit.roomId){
      this.setState({renderNewMsg: true});
    }
    ReactGA.pageview(window.location.pathname + window.location.search);
  }


  componentDidUpdate(prevState, prevProps){
    if (prevProps.roomId !== this.props.roomId){
      const { chatKitUser, room_msgs, rooms, roomId } = this.props.chatkit;
      var room = rooms[_.findIndex(rooms, function(o) { return o.id === roomId; })]
      this.setRoom(room, room_msgs, chatKitUser.id);
    }
  }

  setRoom(room) {
    this.setState({  renderNewMsg: false});
    this.props.joinRoom(room);
  }
  
  sendMessage() {
    const { roomId } = this.props.chatkit;
    this.props.sendMessage(roomId, this.state.inputtext);
    this.setState({ inputtext: "" });
  }

  // move should render to props
  renderNewMsg() {
    const { currentUser } = this.props;
    const tomsg =(
      <div className="to-section">
        To: 
        <CustomAutoComplete 
            setAutoCompleteId={(id) => this.setState({newChatUserId: id})} 
            setAutoCompleteName={(name) => this.setState({newChatUser: name})}
            placeholder="Begin typing a name..."
            getSuggestions={getSuggestions}
            />
        {/* <Autocomplete
          items={currentUser && currentUser.msguserslist ? currentUser.msguserslist : []}
          inputProps={{
            placeholder:"Begin typing a name..."
          }}
          shouldItemRender={(item, value) =>
            item.full_name && item.full_name
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1 ||
              item.username && item.username
              .toLowerCase()
              .indexOf(value.toLowerCase()) > -1

          }
          getItemValue={item =>
            item.full_name ? item.full_name : item.username
          }
          renderItem={(item, highlighted) => (
            <div
              key={item.id}
              style={{
                backgroundColor: highlighted ? "#eee" : "transparent"
              }}
            >
             {item.full_name ? item.full_name : item.username}
            </div>
          )}
          value={this.state.newChatUser}
          onChange={e =>
            this.setState({ newChatUser: e.target.value })
          }
          onSelect={(value, item) =>
            this.setState({
              newChatUser: value,
              newChatUserId: item._id ? item._id : item.uuid
            })
          }
          menuStyle={
            {
              borderRadius: '3px',
              boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
              background: 'rgba(255, 255, 255, 0.9)',
              padding: '2px 0',
              fontSize: '90%',
              position: 'fixed',
              overflow: 'auto',
              maxHeight: '150px', 
            }
          }
        /> */}
      </div>
    )
    return(
      <AllyusCard title={tomsg}>
        {!this.state.newChatUserId ? null : 
        <ChatInput
          value={this.state.inputtext}
          textHandle={e =>
            this.setState({ inputtext: e.target.value })
          }
          onKeyDown={e => {
            if (e.keyCode === 13 && this.state.inputtext) {
              this.props.startConvo(
                this.state.newChatUserId,
                this.state.inputtext
              );
              this.setState({
                inputtext: "",
                renderNewMsg: false,
                newChatUser: "",
                newChatUserId: ""
              });
            }
          }}
        /> 
        }
      </AllyusCard>
    );
  }

  renderPoliticianBlast(){
    const { currentUser } = this.props;
    if (currentUser.__t === 'politician') {
    return(
      <AllyusCard title="Or Send a Message to Your Followers" >
        <ChatInput
          value={this.state.inputblast}
          textHandle={e =>
            this.setState({ inputblast: e.target.value })
          }
          onKeyDown={e => {
            if (e.keyCode === 13 && this.state.inputblast) {
              this.props.sendMessageBlast(this.state.inputblast);
              this.setState({
                inputblast: "",
                renderNewMsg: false,
                newChatUser: "",
                newChatUserId: ""
              });
            }
          }}
        /> 
        
      </AllyusCard>
    );  
      }
  }

  render() {
    const { currentUser, chatkit } = this.props;
    const { chatKitUser, room_msgs, rooms, roomId, otherUser, currentRoom } = chatkit;
    let renderInput = true;
    if (currentRoom && currentRoom.customData && currentRoom.customData.politicianblast && chatKitUser.id !== currentRoom.customData.politicianId){
      renderInput = false;
    }
    return (
      <div className="mt-3">
        <Container fluid>
          <Helmet>
            <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
            <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
            {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
            <meta name="twitter:image" content="/allyuslogo.png"  />
            <meta property="og:image" content="/allyuslogo.png"  />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
          <Row>
            <Col xs={12} lg={3} className={!currentRoom ? "" : "hide-mobile"}>
            {this.state.renderNewMsg ? (
                <div className="show-mobile">
                {this.renderNewMsg()}
                <div style={{height:25}}></div>
                {this.renderPoliticianBlast()}
                <div style={{height:25}}></div>
                </div>
               ) : null }
              <AllyusCard
                title="Messages"
                edit={true}
                click={() => this.setState({ renderNewMsg: true })}
                theme="c"
              >
                {rooms.length > 0 && //move to component
                  rooms.map((room, index) => {
                    return (
                      <Message
                        room={room}
                        msg={
                          room_msgs && room_msgs[room.id] && room_msgs[room.id].length > 0
                            ? room_msgs[room.id][room_msgs[room.id].length - 1]
                            : {}
                        }
                        key={index}
                        className="msg-item"
                        click={() =>
                          this.setRoom(room, room_msgs, chatKitUser.id)
                        }
                        currentUserId={chatKitUser.id}
                      />
                    );
                  })}
              </AllyusCard>
            </Col>
            <Col xs={12} lg={6} className={currentRoom ? "" : "hide-mobile"}>
              {this.state.renderNewMsg ? (
                <div className="hide-mobile">
                {this.renderNewMsg()}
                {this.renderPoliticianBlast()}
                </div>
              ) : roomId ? (
                <AllyusCard 
                  title={<div>
                    <img 
                      style={{cursor:'pointer', width: 28}}
                      alt="back" className="show-mobile"
                      onClick={()=> this.setRoom(null)}
                      src="/icons/icons8-back-50.png" 
                     />  
                      {"    "}{otherUser.name}
                    </div>}
                >
                  <ul className="message-feed">
                    {room_msgs[roomId] &&
                      room_msgs[roomId].map((message, index) => {
                        return ( // TODO import message list and use
                          <li key={message.id}>
                            <div
                              className={
                                message.senderId !== chatKitUser.id
                                  ? "receiver"
                                  : "sender"
                              }
                            >
                              {message.text}
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  { !renderInput ? null :
                  <ChatInput
                    value={this.state.inputtext}
                    textHandle={e =>
                      this.setState({ inputtext: e.target.value })
                    }
                    onKeyDown={e => {
                      if (e.keyCode === 13 && this.state.inputtext) {
                        this.sendMessage();
                      }
                    }}
                  />
                  }
                </AllyusCard>
              ) : null}
            </Col>
            {/* <Col sm={3}>
              <AllyusCard title="connections"></AllyusCard>
            </Col> */}
          </Row>
        </Container>
      </div>
    );
  }
}
const mapStateToProps = state => {
  return {
    chatkit: state.chatkit,
    currentUser: state.currentUser
  };
};
export default connect(
  mapStateToProps,
  { initializeChatKit, sendMessage, sendMessageBlast, setReadCursor, startConvo, joinRoom }
)(AllyusMessages);
