import React, { Component } from 'react';
import Button from '../../button/index';
import CreatePostModal from '../createPostModal/index';
import { connect } from 'react-redux';
import { setPostTypeAction } from '../action';
import { getUserProfilePic, profilePicError } from '../../../functions/profile';

class CreatePost extends Component {
  
  handlePostType = (e) => {
    const type = e.target.name === '' ? e.target.parentElement.name : e.target.name;
    this.props.setPostTypeAction(type);
  }

  render() {

    const { currentUser, logged_in , postType} = this.props;
    return (
      <div className="feed-create-post allyus-card">
        <div className="feed-create-post-body">
          <div className="create-post-title">
            <div>
                <img
                  src={getUserProfilePic(currentUser)}
                  onError={e => profilePicError(e)}
                  alt="profile-img"
                  className="user-img"
                />
              <img src="/images/arrow-down.png" alt="down-arrow" />
            </div>
            <a
              onClick={
                logged_in
                  ? this.props.toggleCreatePostModal
                  : this.props.toggleSignInModal
              }
            >
              {" "}
              <p className="text-muted show-xl">{postType === 'poll' ? "Ask a question... " : postType === 'talk' ? "Start a conversation..." : "Share an article..."} </p>
              <p className="text-muted hide-xl">Create a Post </p>

            </a>
            {/* <CreatePostModal
              isOpen={this.props.createPostModal}
              toggle={this.props.toggleCreatePostModal}
            /> */}
          </div>
          <hr />
          <div className="create-post-btn-group">
            <div className="group1">
              <Button
                click={this.handlePostType}
                name="poll"
                className={`${postType === "poll" ? "active" : ""}`}
              >
                <img src="/icons/new/poll-icon.svg" alt="poll icon" /> &nbsp; poll
              </Button>
              <Button
                click={e => this.handlePostType(e)}
                name="news"
                className={`${postType === "news" ? "active" : ""}`}
              >
                <img src="/icons/new/news-icon.svg" alt="news icon" /> &nbsp; news
              </Button>
              <Button
                click={this.handlePostType}
                name="talk"
                className={`${postType === "talk" ? "active" : ""}`}
              >
                <img src="/icons/new/messaging-icon.svg" alt="message icon" /> &nbsp; talk
              </Button>
            </div>
            <div className="group2">
              <Button className="allyus-btn allyus-primary-btn inactive">
                post
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({post}) =>{
  return{
    postType : post.postType
  }
}
export default  connect(mapStateToProps ,
  { setPostTypeAction } 
  )( CreatePost );
