import React, {Component} from 'react';
import { Input } from 'reactstrap';

class ChatInput extends Component{

    render()
    {
       return(
           <React.Fragment>
           <div className="chatinput">
                <Input type="text"
                    onChange={this.props.textHandle}
                    onKeyDown={this.props.onKeyDown}
                    value={this.props.value}
                    placeholder="Write a message..."
                    // required 
                    // multiline
                    />
           </div>
           </React.Fragment>
       ) 
    }
}

export default ChatInput;