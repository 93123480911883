import React, { Component } from 'react';
import { DropdownItem, } from 'reactstrap';
import { Link } from 'react-router-dom';
import TimeAgo from 'timeago-react';
import { getUserProfilePic, profilePicError } from '../../functions/profile';


// TODO FINISH politicians

// POLL_VOTE: 1,
// PETITION_VOTE: 2,
// ARTICLE_VOTE: 3,
// DISCUSSION_VOTE: 4,

// POLL_COMMENT: 5,
// PETITION_COMMENT: 6,
// NEWS_COMMENT: 7,
// DISCUSSION_COMMENT: 8,

// SIGNED_PETITION: 9,

// POL_PLATFORM_COMMENT: 10,
// POL_PLATFORM_VOTE: 11,

// COMMENT_VOTE: 12,
// COMMENT_REP: 13,
// COMMENT_COMMENT:14,

// VOLUNTEER: 15, 
// DELIVER_PETITION: 16,

// FOLLOW: 17


const getUserPic = (notification) => {
    if (notification.latest_interactor_anonymous){
        let user;
        if (typeof notification.latest_interactor_anonymous === 'string'){
            user = {username:notification.latest_interactor_anonymous, anonymous:true }
        } else {
            user = notification.latest_interactor_anonymous;
        }
        return getUserProfilePic(user);
    } else if (notification.latest_interactor){
        return getUserProfilePic(notification.latest_interactor);
    }
    return "/images/temp_anon_icon.svg";
};

const getUserName = (notification) => {
    if (notification.latest_interactor_anonymous){
        if (typeof notification.latest_interactor_anonymous === 'string'){
            return notification.latest_interactor_anonymous;
        }
        return notification.latest_interactor_anonymous.username;
    } else if (notification.latest_interactor){
        return notification.latest_interactor.first_name + " " + notification.latest_interactor.last_name ;
    }
    return "AnonUser";
};

const getOthers = (notification) => {
    var others = -1;
    var { interactors_anonymous, interactors } = notification;
    interactors =  interactors.filter(i => i !== null);
    interactors_anonymous =  interactors_anonymous.filter(i => i !== null);
    if (interactors_anonymous && interactors_anonymous.length > 0) {
        others += interactors_anonymous.length;
    } if (interactors && interactors.length > 0){
        others += interactors.length;
    }
    if (others > 0){
        if (others === 1){
            return ` and ${others} other`
        }
        return ` and ${others} others`
    }
    return "";
}

const getActivity = (notification) => {
    const { notification_type } = notification;
    if ([1, 2, 3, 4, 11].includes(notification_type)){
        // if (notification.metadata.vote === 1){
            return "voted on your";
        // }
    } else if ([5, 6, 7, 8, 10].includes(notification_type)){
        return "commented on your";
    }
    else if ([13].includes(notification_type)){
        return "repped your";
    }
    else if ([14].includes(notification_type)){
        return "replied to your";
    }
    else if ([9].includes(notification_type)){
        return "signed your";
    } else if (notification_type === 12){
       return "voted on your";
        // if (notification.metadata.comment_vote === 1){
        //     return "upvoted your"
        // } else if (notification.metadata.comment_vote === 2){
        //     return "downvoted your"
        // }
    } else if ([16].includes(notification_type)){
        return "sent you this";
    } else if ([17].includes(notification_type)){
        return "followed you";
    }
    
}

// TODO names
const getTarget = (notification) => {
    var startsWith = '';
    if ([1, 2, 5].includes(notification.notification_type)){
        startsWith = 'poll';
    }
    else if ([6, 9, 16].includes(notification.notification_type)){
        startsWith = 'petition';
    }  
    else if ([3, 7].includes(notification.notification_type)){
        startsWith = 'article';
    }  
    else if ([4, 8].includes(notification.notification_type)){
        startsWith = 'discussion';
    }  
    else if ([12, 13, 14].includes(notification.notification_type)){
        startsWith = 'comment'; 
    }  
    else if ([10, 11].includes(notification.notification_type)){
        startsWith = 'platform';
    }  
    return ` ${startsWith}`
}

const getText = (notification) => {
    const { notification_type, metadata } = notification;
    if (([12, 13].includes(notification_type)) && metadata.comment_text) {
        return metadata.comment_text;
    }
    else if (([14].includes(notification_type)) && metadata.comment_text) {
        return metadata.parent_comment_text;
    } else if (([15]).includes(notification_type)){
        return 'I signed up to volunteer!'
    }
     else if (metadata && metadata.title){
        return metadata.title;
    }
}

// TODO improve
const getLink = (notification) => {
    if (notification.content_model === 'post'){
        return `/posts/${notification.content_id}`;
    } else if (notification.content_model === 'petition'){
        return `/petitions/${notification.content_id}`;
    } else if (notification.content_model === 'comment'){
        if (notification.comment.post_model === 'platform'){
            if (notification.comment.platform.user[0].organization){
                return `/organizations/${notification.comment.platform.user[0].nameUrl}`; 
            }
            return `/politicians/${notification.comment.platform.user[0].nameUrl}`; 
        }
        return `/posts/${notification.comment.post}`;
    } else if (notification.content_model === 'platform'){
        if (notification.platform.user.organization){
            return `/organizations/${notification.platform.user.nameUrl}`;
        }
        return `/politicians/${notification.platform.user.nameUrl}`;  //TODO improve make platform tab active
    } else if (notification.content_model === 'politician'){
        //someone volunteered
        if (notification.politician.organization){
            return `/organizations/${notification.politician.nameUrl}`;
        }
        return `/politicians/${notification.politician.nameUrl}`; 
    } 
    return '#'; // more todo
}
// TODO need to bring comment into focus or persons comment top?
// TODO fix links to be box wide
export const NotificationItem = props => {
    const { notification } = props;
    return (
        <DropdownItem  className={` ${ !notification.details.clicked ? 'higlight': ''}`}>
            <div className="list-item">
                <img src={getUserPic(notification.details)} alt="userphoto" className="userImg" onError={e => profilePicError(e)}/>
                <Link to={getLink(notification.details)} className="ml-2"  onClick={() => props.clickNotification(notification.details._id)}>
                    <p className="allyus-text mb-0 text-overflow-wrap">
                        <span className="allyus-text-bold">{getUserName(notification.details)}</span>
                        <span>
                            {getOthers(notification)} {getActivity(notification.details)}
                            <Link to={getLink(notification.details)} className="allyus-text-primary" onClick={() => props.clickNotification(notification.details._id)}> {getTarget(notification.details)}
                            </Link>
                        </span>
                        {getText(notification.details) ? `:  "${getText(notification.details)}"` : ''}
                    </p>
                    <p className="allyus-text mb-0"></p>
                    <div className="text-muted"> <TimeAgo datetime={notification.details.date_time}/> </div>
                </Link>                
            </div>
        </DropdownItem>
    );
}