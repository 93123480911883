import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SubNavbarFeed from '../../components/navbar/subnav/feedsNavbar';
import UserInfoCard from '../../components/feed/userInfoCard/index';
import RepresentativeCard from '../../components/feed/representativeCard/index';
import PetitionCard from '../../components/feed/petitionCard/index';
import SponsoredCard from '../../components/feed/sponsoredCard/index';
import PollCard from '../../components/Post/poll/index';
import DisucssionCard from '../../components/Post/discussion/index';
import NewsCard from '../../components/Post/news/index';
import CreatePost from '../../components/Post/createPost/index';
import { Divider } from '../../components/common/index';
import { connect } from 'react-redux';
import { toggleAnonymous, toggleSignInModal, toggleCreatePostModal, followUser, unfollowUser, getFeedPosts, editPost, deletePost } from '../../store/actions';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
// import BreakingNews from '../breakingnews';

import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../config';
const { allyus_url }= config;

class Feeds extends Component {

  constructor(props) {
    super(props);
    this.state = {activePost: ''};
  }

   componentDidMount(){
    const post_id = this.props.match.params.post_id;
    if (post_id){
      this.getActivePost(post_id);
    } else {
      this.setState({activePost: ''})
    }
    this.props.getFeedPosts();   
    ReactGA.pageview(window.location.pathname + window.location.search);
 
  }
  
  componentDidUpdate(prevProps){
    if (prevProps.match.params.post_id !== this.props.match.params.post_id){
      const post_id = this.props.match.params.post_id;
      if (post_id){
        this.getActivePost(post_id);
      } else {
        this.setState({activePost: ''})
      }
    }
  }

  toggleProfile = () => {
    this.props.toggleAnonymous();
  }

  async getActivePost(post_id){
    try {
      if (firebase.auth().currentUser){
        const  access_token = await firebase.auth().currentUser.getIdToken(true);
        const post = await axios.get(`${allyus_url}/posts/${post_id}?access_token=${access_token}`);
        this.setState({activePost: post.data});
      } else {
        const post = await axios.get(`${allyus_url}/posts/${post_id}`);
        this.setState({activePost: post.data});
      }
    } catch(err) {
      // console.log(err);
    }
  }
  
  // TODO test this thoroughly once not local
  renderHelmet() {
    const post_id = this.props.match.params.post_id;
    var{ activePost } = this.state;
    activePost = activePost ? activePost.post : '';
    let image_url = "/allyuslogo.png";
    if (post_id && activePost) {
      if (activePost.image){
        image_url = activePost && activePost.image ? activePost.image : image_url; //.replace('https', 'http') : '';
      } 
      const og_url= `${window.location.origin}/posts/${post_id}`;
      return (
        <Helmet>
          <meta property="og:title" content={`${activePost.title}`} />
          <meta property="og:url" content={og_url} />

          <meta name="twitter:image" content={`${image_url}`} />
          <meta property="og:image" content={`${image_url}`} />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="315" />
        </Helmet>
      );
    } 
  }

  renderActivePost() {
    const { activePost } = this.state;

    if (activePost.post.post_type === 1 ){
      return  <PollCard post={activePost.post} key={activePost.post._id} active={true} {...this.props} />
    } else if (activePost.post.post_type === 5) {
      var post = activePost.post;
      post.post_type = 1;
      post.featured = true;
      return <PollCard post={post} key={activePost.post._id} active={true} {...this.props} />
    } else if (activePost.post.post_type === 2) {
      return  <NewsCard post={activePost.post} key={activePost.post._id} active={true} {...this.props}/>

    } else if (activePost.post.post_type === 3) {
      return <DisucssionCard post={activePost.post} key={activePost.post._id} active={true} {...this.props}/>
    }
  }

  render() {
    const { currentUser, feed, followUser, unfollowUser } = this.props;
    const { activePost } = this.state;
    const { posts, petitions, featuredPosts } = feed; 
    const feed_page = true;
    const logged_in = firebase.auth().currentUser ? true : false;
    return (
      <Container fluid>
        {this.renderHelmet()}
        {/* <BreakingNews  /> */}
        <Row style={{ alignItems: 'center', justifyContent: 'center', marginTop: 15, marginBottom: 15}} className="show-mobile">
              <a href="https://apps.apple.com/us/app/allyus/id1534643196" target="_blank"><img src="/images/appstore.png"  width="150" style={{marginRight:5}} /></a>
              <a href="https://play.google.com/store/apps/details?id=com.allyus" target="_blank"><img src="/images/playstore.png"  width="150"   /></a>
        </Row>

        <Row>
          <Col >
            <SubNavbarFeed />
          </Col>
        </Row>
        <Divider className="d-none d-sm-block" />
        <Row className="mt-3">
          <Col xl={{size: 3, offset: 0 }} md={{size: 8, offset: 2 }}>
            {!logged_in ? null :
              <UserInfoCard
                toggleAnonymous={this.props.toggleAnonymous}
                currentUser={currentUser}
              />
            }
      
            <RepresentativeCard 
              is_politician={currentUser.__t === 'politician'} 
              currentUser={currentUser} 
              followUser={followUser}
              unfollowUser={unfollowUser}
              />
          
          </Col>
          <Col xl={{size: 5, offset: 0 }} md={{size: 8, offset: 2 }}>
            <CreatePost 
              logged_in={logged_in} 
              createPostModal={this.props.createPostModal}
              toggleCreatePostModal={this.props.toggleCreatePostModal}
              toggleSignInModal={this.props.toggleSignInModal}
              currentUser={currentUser}
            />

              {
                !activePost ?  null :
                  this.renderActivePost()
              }
              {
                featuredPosts && featuredPosts.map(post => {
                  if (post){
                    post.post_type = 1;
                    post.featured = true;
                    return <PollCard post={post} key={post._id} {...this.props}/>
                  }
                })
              }
              {
                posts.map(post => {
                  if (post){ 
                    if (post.post_type === 1){
                      return <PollCard post={post} key={post._id} {...this.props}/>
                    } else if (post.post_type === 3){
                      return    <DisucssionCard post={post} key={post._id} {...this.props} />
                    } else if (post.post_type === 2){
                      return   <NewsCard post={post} key={post._id} {...this.props}/>
                    } 
                  } 
                })
              }
          </Col>
          <Col xl={{size: 4, offset: 0}} md={{size: 8, offset: 2 }}>
            {
              petitions && petitions[0] ?             
              <PetitionCard feed_page={feed_page}  petition={petitions[0]} {...this.props} />
              : null
            }
            {/* <SponsoredCard /> */}
          </Col>
        </Row>
      </Container>
    )
  }
}


const mapStateToProps = state => {
  return {
      currentUser: state.currentUser,
      signInModal: state.modals.signin_modal,
      createPostModal: state.modals.create_post_modal,
      feed: state.feed
  }
}

export default connect(mapStateToProps, { toggleAnonymous, toggleSignInModal , toggleCreatePostModal, getFeedPosts, followUser, unfollowUser, editPost, deletePost })(Feeds);
