import React from 'react';
import {
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    Badge
  } from 'reactstrap';
import { NotificationItem } from '../../allyusNotification/';
import { Link } from 'react-router-dom';

export default props => {
    const {logged_in, mobileNav, notificationsInfo : { notifications, badge}, clickNotification, getNotifications, clickIcon } = props;
    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav onClick={() => clickIcon('notifications')}>
            <img src={mobileNav ? "/icons/new/notifications-icon-w.svg" : "/icons/new/notifications-icon.svg"} alt="notification" />
            {!logged_in || badge < 1 ? null : <Badge color="secondary">{badge}</Badge>}
            </DropdownToggle>
            { mobileNav ? 
            <DropdownMenu style={{display: 'none'}} right className="notification" ><p></p>menu</DropdownMenu>
            :
            <DropdownMenu right className="notification" style={{height:650, overflow: 'auto',}}>
                <p className="allyus-text-bold mb-1 px-3">Notifications</p>
                {
                notifications.map((item, index) => {
                    return <NotificationItem notification={item} key={index} 
                    getNotifications={getNotifications} clickNotification={clickNotification} />
                })
                }
                <p className="text-center pt-2">
                <Link className="allyus-text-primary " to="/notifications"> See All </Link>
                </p>
            </DropdownMenu>
            }
        </UncontrolledDropdown>  
        );
};