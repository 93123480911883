import React from "react";
import TimeAgo from "timeago-react";
import moment from 'moment';
import { Input, UncontrolledDropdown, DropdownItem , DropdownMenu, DropdownToggle} from "reactstrap";
import Comments from './index';
import DeleteModal from '../deleteModal';
import {
  getProfileNamePost,
  getProfilePicPost,
  getProfileUrl,
  getProfileLinkPost,
  getUserProfilePic,
  profilePicError
} from "../../functions/profile";

import { Link } from "react-router-dom";

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../config";
const { allyus_url } = config;

// TODO clean up file
export default class Comment extends React.Component {
    state = {
      replying: false,
      newComment: "",
      deletePostModal: false,
      editing: false,
      view_replies: false,
    };
  
    sharePost() {
      const post = this.props.post;
      const url = post && post.shareURL ?  post.shareURL : `${window.location.origi}/posts/${this.props.post_model}/${this.props.post_id}`;
      this.props.openShareModal(url);
    }
  
    // TODO are you sure?
    flagComment = async () => {
      if (firebase.auth().currentUser) {
        try {
          const access_token = await firebase.auth().currentUser.getIdToken(true);
          await axios.put(
            `${allyus_url}/comments/${this.props.comments._id}/flag?access_token=${access_token}`
          );
          alert("Comment has been reported");
        } catch (err) {
          // console.log(err);
        }
      } else {
        this.props.toggleSignInModal();
        // toggle login
      }
    };
  
    voteComment = async voteside => {
      try {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(
          `${allyus_url}/comments/${this.props.comments._id}/vote/${voteside}?access_token=${access_token}`
        );
        this.props.getPostData();
      } catch (err) {
        console.log(err);
      }
    };
  
    repComment = async () => {
      try {
        const access_token = await firebase.auth().currentUser.getIdToken(true);
        await axios.put(
          `${allyus_url}/${this.props.post_model}/${this.props.post_id}/comments/${this.props.comments._id}/rep?access_token=${access_token}`
        );
        this.props.getPostData();
      } catch (err) {
        console.log(err);
      }
    };
  
    createCommentHandler = async parent_id => {
      var commentText = this.state.newComment;
      this.setState({ newComment: "", replying: false });
      commentText = commentText.trim();
      if (commentText) {
        try {
          const access_token = await firebase.auth().currentUser.getIdToken(true);
          const newComment = {
            text: commentText,
            parent_id: parent_id ? parent_id : null
          };
          await axios.post(
            `${allyus_url}/${this.props.post_model}/${this.props.post_id}/comments?access_token=${access_token}`,
            newComment
          );
          this.props.getPostData();
        } catch (err) {
          console.log(err);
        }
      }
    };

    editComment = async comment_id => {
        try {
          const access_token = await firebase.auth().currentUser.getIdToken(true);
          var updatedData = {text: this.state.newComment};
          await axios.put(
            `${allyus_url}/comments/${comment_id}/?access_token=${access_token}`,
            {updatedData : updatedData}
          );
          this.setState({editing: false})
          this.props.getPostData();
        } catch (err) {
          console.log(err);
        }
      };

      deleteComment = async comment_id => {
        try {
          const access_token = await firebase.auth().currentUser.getIdToken(true);
          await axios.delete(
            `${allyus_url}/comments/${comment_id}/?access_token=${access_token}`
          );
          this.props.getPostData();
        } catch (err) {
          console.log(err);
        }
      };
  
    renderCreateComment({parent_id, edit_cm_id}) {
      const auth = firebase.auth().currentUser;
      if (this.props.post && this.props.post.anon_comments_disabled && auth && auth.uid && this.props.currentUser && this.props.currentUser.anonymous){
        return (
          <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
            Anonymous comments disabled
          </div>
        );
      }
      else if (auth && auth.uid && this.props.currentUser) {
        const item = this.props.currentUser;
        const img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
        : item.organization ? 'user-img-organization' : 'user-img-voter';
        return (
          <div className="allyus-new-comment nested-new-comment">
            <div>
              <img
                src={getUserProfilePic(this.props.currentUser)}
                alt="profile-img"
                className={`user-img ${img_style}`}
                onError={e => profilePicError(e)}
              />
              <img src="/images/arrow-down.png" alt="down-arrow" />
            </div>
            <Input
              type="textarea"
              name="commentname"
              // id="commentname"
              placeholder="Comment..."
              style={{height:38}}
              value={this.state.newComment}
              onChange={e => {
                this.setState({ newComment: e.target.value });
              }}
              onKeyDown={e => {
                if (e.keyCode === 27){
                  this.setState({editing: false});
                }
              //   if (e.keyCode === 13 && !(e.shiftKey)) {
              //     // if (this.state.editing && edit_cm_id && this.state.newComment ){
              //     //     this.editComment(edit_cm_id);
              //     // } else if (this.state.newComment){
              //     //   this.createCommentHandler(parent_id);
              //     // }
              //   } 
              }}
            />
            <img src="/icons/icons8-email-send-32.png" alt="send" className="send-comment-icon" 
             onClick={() => {
                 if (this.state.editing && edit_cm_id && this.state.newComment ){
                      this.editComment(edit_cm_id);
                  } else if (this.state.newComment){
                    this.createCommentHandler(parent_id);
                  }
             }}
             />
          </div>
        );
      } else {
        this.props.toggleSignInModal();
      }
    }
  
    render() {
      let { comments, currentUser, post_id, getPostData, depth, userVoted, postType } = this.props;
      let { replying, deletePostModal, editing, view_replies} = this.state;
      const my_comment = (currentUser && comments.user) &&
         ((currentUser._id && currentUser._id === comments.user._id) || (currentUser.uuid && currentUser.uuid === comments.user.uuid) || currentUser.admin);
      const userVotedYes = comments.currentUserCommentVote === 1;
      const userVotedNo = comments.currentUserCommentVote === 2;
      // const userVotedOther = comments.currentUserCommentVote === 3;
      var userVotedPost = true;
      if ((postType === 'poll' || postType === 'petition') && !userVoted) {
        userVotedPost = false;
      }

      const repped = this.props.commentWithRep === comments._id; // user can rep one comment per post
      const type =
        comments.post_vote === 1
          ? "positive"
          : comments.post_vote === 2
          ? "negative"
          : comments.post_vote === 3
          ? "neutral"
          : "";
        
        const item = comments.user;
        const img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
        : item.organization ? 'user-img-organization' : 'user-img-voter';
      return (
        <li className="allyus-comment-item">
          {editing ? null : 
          <Link to={getProfileLinkPost(comments)}>
            <img
              src={getProfilePicPost(comments)}
              onError={e => profilePicError(e)} 
              className={`user-img ${img_style}`}
              alt="comments"
            />
          </Link>
          }
          <div className="group">
            {editing ? 
                 this.renderCreateComment({edit_cm_id: comments._id}) 
            : 
            <div className="comment" style={{display:'flex'}}>
              <div className={`comment-type-wrapper ${type}`}></div>
              <div>
                <span className="user-title">{getProfileNamePost(comments)}</span>
                <span className="text-overflow-wrap">{comments.text}</span>
              </div>
              {   !my_comment ? null : 
                <p className="ml-auto allyus-menu hide-mobile">
                    <UncontrolledDropdown >
                    <DropdownToggle >
                        <img src="/icons/new/more-icon.svg" alt="post" />
                    </DropdownToggle>
                    <DropdownMenu right >
                        <DropdownItem onClick={() => this.setState({editing: true, newComment: comments.text})}>
                        <p><i class="far fa-edit"></i>&nbsp; Edit</p>
                        </DropdownItem>
                        <DropdownItem onClick={() => this.setState({deletePostModal: true})}>
                        <p><i class="far fa-trash-alt"></i>&nbsp; Delete</p>
                        </DropdownItem>
                    </DropdownMenu>
                    </UncontrolledDropdown>
                </p>
              }
            </div>
            }
            <div className="comment-btn-list">
              <div className="nested-div">
                <span onClick={() => this.setState({ replying: !replying })}>
                  reply
                </span>
                <span onClick={() => this.sharePost()} className="hide-mobile">share</span>
                <span onClick={() => this.flagComment()} className="hide-mobile">report</span>
                <img
                  src={
                    repped
                      ? "/images/rep_star_filled.svg"
                      : "/icons/new/rep-icon.svg"
                  } // TODO replace with new icon? //TODO more button mobile
                  style={{ width: 15, height: 24 }}
                  alt="rep"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="rep - rep the best comment per post"
                  onClick={this.repComment}
                  onMouseOver={e =>
                    (e.currentTarget.src =
                      repped && this.props.commentWithRep
                        ? "/images/rep_star_filled.svg"
                        : !repped && this.props.commentWithRep
                        ? "/icons/new/rep-icon.svg"
                        : "/images/rep_star_filled.svg")
                  }
                  onMouseOut={e =>
                    (e.currentTarget.src =
                      repped && this.props.commentWithRep
                        ? "/images/rep_star_filled.svg"
                        : "/icons/new/rep-icon.svg")
                  }
                />
  
                <span>{comments.reps}</span>
                <img
                  src={
                    userVotedYes
                      ? "/icons/new/upvote-filled.svg"
                      : "/icons/new/upvote-unfilled.svg"
                  }
                  alt="upvote"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="up vote"
                  onMouseOver={e =>
                    (e.currentTarget.src = "/icons/new/upvote-filled.svg")
                  }
                  onMouseOut={e =>
                    (e.currentTarget.src = userVotedYes
                      ? "/icons/new/upvote-filled.svg"
                      : "/icons/new/upvote-unfilled.svg")
                  }
                  onClick={() => this.voteComment("upvote")}
                />
  
                <span>
                  {comments.upvotes +
                    comments.anonymous_upvotes -
                    comments.downvotes -
                    comments.anonymous_downvotes}
                </span>
                <img
                  src={
                    userVotedNo
                      ? "/icons/new/downvote-filled.svg"
                      : "/icons/new/downvote-unfilled.svg"
                  }
                  alt="downvote"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="down vote"
                  onMouseOver={e =>
                    (e.currentTarget.src = "/icons/new/downvote-filled.svg")
                  }
                  onMouseOut={e =>
                    (e.currentTarget.src = userVotedNo
                      ? "/icons/new/downvote-filled.svg"
                      : "/icons/new/downvote-unfilled.svg")
                  }
                  onClick={() => this.voteComment("downvote")}
                />
              </div>
              {/* <TimeAgo
                className="ml-auto text-muted hide-mobile"
                datetime={comments.created}
              />    */}
              <p className="allyus-menu show-mobile">
                <UncontrolledDropdown>
                      <DropdownToggle>
                        <span style={{color:'#5b5656'}}>More</span>
                      </DropdownToggle>
                      <DropdownMenu down style={{maxWidth:'100%'}}>
                        {!my_comment ? null :
                          <DropdownItem onClick={() => this.setState({editing: true, newComment: comments.text})} style={{color:'#495057'}}>
                            <i class="far fa-edit" ></i> Edit
                          </DropdownItem>
                        }
                        {
                          !my_comment ? null :
                          <DropdownItem onClick={() => this.setState({deletePostModal: true})} style={{color:'#495057'}}>
                            <i class="far fa-trash-alt"></i> Delete
                          </DropdownItem>
                        }
                        <DropdownItem onClick={() => this.sharePost()} style={{color:'#495057'}}><img src="/images/share_icon.svg" alt="share" style={{width:14, marginRight: 0}}/> Share</DropdownItem>
                        <DropdownItem onClick={() => this.flagComment()} style={{color:'#495057'}}><img src="/images/flag_icon.svg" alt="report" style={{width:14, marginRight: 0}} /> Report</DropdownItem>
                      </DropdownMenu>
                </UncontrolledDropdown>
              </p>  
            </div>

            {!replying || depth > 1 || !userVotedPost
              ? null
              : this.renderCreateComment({parent_id: comments._id})}
            {depth > 3 ? null :
            !view_replies && comments.children ?
            (
              <p className="allyus-text-primary" onClick={() => this.setState({view_replies:true})} style={{cursor:'pointer'}}>
              View Replies
            </p>
            ) :
             (
              <Comments
                 {...this.props}
                comments={comments.children}
                currentUser={currentUser}
                post_id={post_id}
                getPostData={getPostData}
                depth={depth + 1}
                className="nested-comments"
                post={this.props.post}
              />
            )}
            {!replying || depth !== 2 || !userVotedPost
              ? null
              : this.renderCreateComment({parent_id: comments._id})}
            {!replying || depth !== 3 || !userVotedPost
              ? null
              : this.renderCreateComment({parent_id: comments.parent})}
          </div>
          <DeleteModal
             isOpen={deletePostModal}
             toggle={() => this.setState({deletePostModal: !deletePostModal})}
             deleteFunction={() => this.deleteComment(comments._id)}
             deleteName="comment"
         />
        </li>
      );
    }
  }
  