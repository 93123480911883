import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../../button/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Col, Row, FormFeedback } from 'reactstrap';
import profile_fields from '../../../formdata/profilefields.json';
import EditProfile from '../../editProfile';
import AnonymousUserDetail from './anonymous';
import CivicButton from '../../civcButton';

import _ from 'lodash';
import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../../config';
const { allyus_url }= config;

export default class UserDetailCard extends React.Component {
    constructor(props) {
        super(props);
        const { profile } = props;
        this.state = { 
          modal: false,
          usernameModal: false,
         };    
    }

    toggle = (e) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    renderUserDetail = () => {
        const {
            first_name,
            last_name,
            full_name,
            location_country,
            location_region, 
            location_city,
            cd,
            county,
            political_lean,
            username,
            anonymous,
            reps,
            followers_count, 
            following_count,
            _id,
            civic_verified,
            influencer,
            lobbyist,
         } = this.props.profile;
        const { my_profile, currentUser } = this.props;
        if (!anonymous) {
            return (
                <div className="userDetail-card">
                    <div className="userDetail-card-head">
                        <div className="group1">
                            <div className="user-detail">
                                <div className="user-title">
                                    <h2 className="allyus-main-heading  mb-1">{full_name}</h2>
                                      {
                                        influencer || lobbyist ?
                                        <h3 className="allyus-sub-heading mb-5">{influencer ? "Influencer" : lobbyist ? "Lobbyist" : ""}</h3>
                                        : null
                                    }
                                    {/* <h3 className="allyus-sub-heading mb-5">{location_region}</h3> */}
                                    {/* <h3 className="allyus-sub-heading">Congressional District {cd}</h3> */}
                                </div>
                                { my_profile ? (
                                    <div className="user-btn-group mt-3">
                                        { currentUser.anonymous ? 
                                        <Button
                                            className={influencer ? "allyus-influencer-btn" : lobbyist ? "allyus-lobbyist-btn" : "allyus-primary-btn"}
                                            width="120"
                                            click={this.props.toggleAnonymous}
                                        >
                                            go public
                                        </Button>
                                         :
                                        <Button
                                            className={influencer ? "allyus-influencer-btn" : lobbyist ? "allyus-lobbyist-btn" : "allyus-primary-btn"}
                                            width="120"
                                            click={this.props.toggleAnonymous}
                                        >
                                            go private
                                        </Button>
                                        }
                                        <Button
                                            className="allyus-btn allyus-white-btn"
                                            width="120"
                                            click={this.toggle}
                                            name="editProfile"
                                        >edit profile
                                    </Button>
                                    </div>
                                 ) : (
                                      currentUser && currentUser.following.includes(_id) ?
                                      <div className="user-btn-group mt-3">
                                        <Button
                                        className={influencer ? "allyus-influencer-btn" : lobbyist ? "allyus-lobbyist-btn" : "allyus-primary-btn"}
                                        width="120"
                                        click={() =>this.props.unfollowUser({id:_id})}
                                        >
                                            Following
                                        </Button>
                                     </div>
                                  : 
                                    <div className="user-btn-group mt-3">
                                        <Button
                                        className={influencer ? "allyus-influencer-border-btn" : lobbyist ? "allyus-lobbyist-border-btn" : "allyus-primary-border-btn"}
                                        width="120"
                                        click={() => this.props.followUser({id:_id})}

                                        >
                                            Follow
                                        </Button>
                                    </div>
                                  
                                )}
                                {
                                    currentUser.admin && !my_profile ?  
                                    <div className="user-btn-group mt-3">
                                        <Button
                                            className="allyus-btn allyus-white-btn"
                                            width="120"
                                            click={this.toggle}
                                            name="editProfile"
                                        >
                                            edit profile
                                        </Button>
                                    </div>
                                    : null
                                }
                            </div>
                            <div className="user-states mt-4">
                                <div style={{cursor:'pointer'}} onClick={() => this.props.openConnectionsTab("2")}>
                                    <span className="states-count allyus-text">{followers_count}</span>
                                    <span className="states-label text-muted pl-1">Followers</span>
                                </div>
                                <div style={{cursor:'pointer'}}  onClick={() => this.props.openConnectionsTab("3")}>
                                    <span className="states-count allyus-text">{following_count}</span>
                                    <span className="states-label text-muted pl-1">Following</span>
                                </div>
                                <div>
                                    <span className="states-count allyus-text">{reps}</span>
                                    <img src="/icons/new/rep-icon.svg" />
                                </div>
                            </div>
                        </div>

                        <div className="group2">
                            <p className="mb-2">
                                <span className="allyus-card-heading mb-0">location</span>
                                <span className="allyus-card-sub-heading" style={{textTransform:'capitalize'}}>{(location_city ? location_city : '') + (location_region && location_city ? ", " : '') + (location_region ? location_region : '')}</span>
                            </p>

                            <p className="mb-2">
                                <span className="allyus-card-heading mb-0">Congressional District</span>
                                {
                                    // !cd ? short form state
                                    // <span className="allyus-card-sub-heading">{location_region}</span>
                                    // :
                                    <span className="allyus-card-sub-heading">{location_region} - {cd}</span>
                                }
                            </p>

                            <p className="mb-2">
                                <span className="allyus-card-heading mb-0">county</span>
                                <span className="allyus-card-sub-heading" style={{textTransform:'capitalize'}}>{county}</span>
                            </p>

                            <p className="mb-2">
                                <span className="allyus-card-heading mb-0">political affiliation</span>
                                <span className="allyus-card-sub-heading">{political_lean}</span>
                            </p>
                        </div>
                    </div>
                    {my_profile ?
                    <div>
                        <hr />
                        <div className="userDetail-card-body">
                           <p className="allyus-text mb-0">Profile {civic_verified ? '' : 'not'} verified {!civic_verified ? null : <img src='/icons/new/verified-checkmark-voter.svg' alt="verified" /> }</p>
                            { civic_verified  ? <p/> :
                            <CivicButton />
                            }
                        </div>
                    </div> 
                    : null }

                    <EditProfile profile={this.props.profile} open={this.state.modal} toggle={this.toggle} editUser={this.props.editUser} />
                </div>
            );
        } else {
            return (
                <AnonymousUserDetail username={username} {...this.props} />
            );
        }
    }
    render() {
        return this.renderUserDetail();
    }
}
