import ACTIONS from './actionTypes';

// export const pollPostYesAction= (id) => {
//     return ({
//         type: ACTIONS.POLL_POST_YES,
//         payload : id
//     });
// }
// export const pollPostNoAction = (id) => {
//     return ({
//         type: ACTIONS.POLL_POST_NO,
//         payload : id
//     });
// }

export const setPostTypeAction = payload => {
    return({
        type : ACTIONS.SET_POST_TYPE,
        payload
    })
}