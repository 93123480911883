import React, { Component } from 'react';
import AllyusCard from '../../allyusCard/index';
import AllyusPost from '../../Post/allyusPost';

import PollCard from '../../Post/poll/index';
import DisucssionCard from '../../Post/discussion/index';
import NewsCard from '../../Post/news/index';

export default class PolititionFeedsCard extends Component {
  render() {
    const { data, target, my_profile, userAdmin } = this.props;
    return (
      <AllyusCard title={` ${target}'s Feed`} edit={false} 
        addButton={my_profile || userAdmin}
        addText="Create Post"
        click={this.props.toggleCreatePostModal}
        >
        <AllyusPost
          
        >
        {
                data.map(post => {
                  if (post){ 
                    if (post.post_type === 1){
                      return <PollCard post={post} key={post._id} {...this.props}/>
                    } else if (post.post_type === 3){
                      return    <DisucssionCard post={post} key={post._id} {...this.props}/>
                    } else if (post.post_type === 2){
                      return   <NewsCard post={post} key={post._id} {...this.props}/>
                    } 
                  } 
                })
              }
        </AllyusPost>

      </AllyusCard>
    )
  }
}
