import React, { Component } from 'react';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';
import AllyusCard from '../../allyusCard/index';
import {AllyusDatePicker } from '../../common/index';
import moment from 'moment';

import profile_fields from '../../../formdata/profilefields.json';


class UserProfileInfoCard extends Component {
  constructor(props) {
    super(props);
    const { info } = props;
    this.state = { 
      modal: false,
      age : info.age,
      gender : info.gender,
      ethnicity : info.ethnicity,
      religion : info.religion,
      education : info.education,
      marital_status : info.marital_status,
      year : info.dob ? moment(info.dob).format("Y") : null,
      month : info.dob ? parseInt(moment(info.dob).format("M")) - 1 : null,
      day : info.dob ? moment(info.dob).format("DD") : null,
     };    
    }

  toggle = (e) => {
    this.setState(prevState => ({
      modal: !prevState.modal
    }));
  }

  handleInputChange = (event)=> {
    const value = event.target.value;
    // const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = event.target.name;
    this.setState({
      [name]: value
    });
  }

  handleDateChange = (value, element) => {
    this.setState({[element]: value})
  }


  onSubmit = () => {
    const { 
      gender ,
      ethnicity ,
      religion ,
      education,
      marital_status ,
      year,
      month,
      day,
     } = this.state;
    const dob = new Date(year, month, day);
    const updatedProfile = { 
      gender,
      ethnicity ,
      religion ,
      education,
      marital_status ,
      dob
    } 
    this.props.editUser(updatedProfile);
    this.toggle();
  }

 

  handleDateChange = (date , placeholder) => {
    this.setState({
      [placeholder] : date
    });
  }

  renderProfileInfo = ()=>{
    const { info, my_profile, userAdmin } = this.props;
    
    if(!info.anonymous){
      return (
        <AllyusCard title={`FULL PROFILE`} edit={my_profile || userAdmin} theme="c" click={this.toggle}>
          <div className="profileInfo">
            <p className="mb-2">
              <span className="allyus-card-heading mb-0">Age</span>
              <span className="allyus-card-sub-heading">{!info.dob ? '' : moment().diff(moment(info.dob), 'years')}</span>
            </p>
            <p className="mb-2">
              <span className="allyus-card-heading mb-0">Gender</span>
              <span className="allyus-card-sub-heading">{info.gender} </span>
            </p>
            <p className="mb-2">
              <span className="allyus-card-heading mb-0">Ethnicity</span>
              <span className="allyus-card-sub-heading">{info.ethnicity} </span>
            </p>
            <p className="mb-2">
              <span className="allyus-card-heading mb-0">Religion</span>
              <span className="allyus-card-sub-heading">{info.religion} </span>
            </p>
            <p className="mb-2">
              <span className="allyus-card-heading mb-0">Education</span>
              <span className="allyus-card-sub-heading">{info.education}</span>
            </p>
            <p className="mb-2">
              <span className="allyus-card-heading mb-0">Marital Status</span>
              <span className="allyus-card-sub-heading">{info.marital_status} </span>
            </p>
          </div>
          
          <Modal isOpen={this.state.modal} toggle={this.toggle} >
            <ModalHeader toggle={this.toggle} className="allyus-card-title">Edit Full Profile</ModalHeader>
            <ModalBody>
              <Form className="allyus-form" onChange={this.update}>
                <FormGroup className="allyus-form-group">              
                  <Label for="exampleText">Birthday</Label>
                  <AllyusDatePicker className="w-75" {...this.state} start="1900" handleDateTimeChange={this.handleDateChange.bind(this)} />
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Gender</Label>
                  <Input type="select" name="gender" id="exampleSelect" onChange={this.handleInputChange} value={this.state.gender}>
                     <option value=""></option>
                    {
                      profile_fields.gender.map((field, i) => {
                        return <option value={field} key={i}>{field}</option>
                      })
                    }                    
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Ethnicity</Label>
                  <Input type="select" name="ethnicity" id="exampleSelect" onChange={this.handleInputChange} value={this.state.ethnicity}>
                     <option value=""></option>
                    {
                      profile_fields.ethnicity.map((field, i) => {
                        return <option value={field} key={i}>{field}</option>
                      })
                    }       
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Religion</Label>
                  <Input type="select" name="religion" id="exampleSelect" onChange={this.handleInputChange} value={this.state.religion}>
                     <option value=""></option>
                    {
                      profile_fields.religion.map((field, i) => {
                        return <option value={field} key={i}>{field}</option>
                      })
                    }      
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Education</Label>
                  <Input type="select" name="education" id="exampleSelect" onChange={this.handleInputChange} value={this.state.education}>
                    <option value=""></option>
                    {
                      profile_fields.education.map((field, i) => {
                        return <option value={field} key={i}>{field}</option>
                      })
                    } 
                  </Input>
                </FormGroup>
                <FormGroup className="allyus-form-group">
                  <Label for="exampleText">Marital Status</Label>
                  <Input type="select" name="marital_status" id="exampleSelect" onChange={this.handleInputChange} value={this.state.marital_status}>
                    <option value=""></option>
                    {
                      profile_fields.marital_status.map((field, i) => {
                        return <option value={field} key={i}>{field}</option>
                      })
                    } 
                  </Input>
                </FormGroup>
              </Form>
            </ModalBody>
            <ModalFooter>
              <Button className="allyus-btn allyus-primary-btn" click={ this.onSubmit }> Save </Button>
            </ModalFooter>
          </Modal>
        </AllyusCard>
      )
    }else{
      return <div className="userProfileInfo-card allyus-card">
            <div className="userProfileInfo-card-top">
              <p className="allyus-card-title">Full Profile </p>
            </div>

            <hr />
            <div className="userProfileInfo-detail group">
              <p className="mb-2">
                <span className="allyus-card-heading mb-0">Age</span>
                <img src='/icons/new/private-icon.svg' alt="private" />
              </p>
              <p className="mb-2">
                <span className="allyus-card-heading mb-0">Gender</span>
                <img src='/icons/new/private-icon.svg' alt="private" />
              </p>
              <p className="mb-2">
                <span className="allyus-card-heading mb-0">Ethnicity</span>
                <img src='/icons/new/private-icon.svg' alt="private" />
              </p>
              <p className="mb-2">
                <span className="allyus-card-heading mb-0">Religion</span>
                <img src='/icons/new/private-icon.svg' alt="private" />
              </p>
              <p className="mb-2">
                <span className="allyus-card-heading mb-0">Education</span>
                <img src='/icons/new/private-icon.svg' alt="private" />
              </p>
              <p className="mb-2">
                <span className="allyus-card-heading mb-0">Marital Status</span>
                <img src='/icons/new/private-icon.svg' alt="private" />
              </p>
            </div>
          </div>
    }
  }
  render() {
    return this.renderProfileInfo();
  }

}

export default UserProfileInfoCard;
