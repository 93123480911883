import ACTION from './actionTypes.js';
import axios from 'axios';
import config from '../../config';
const allyus_url = config.allyus_url;

// TODO
export const getSuggestionsAction = (text) => (dispatch) => {
    // axios.get(`${api}/users?name=${text}`).then(resp => {
    //     dispatch({
    //         type: ACTION.GET_SUGGESTIONS,
    //         payload: resp.data[0]
    //     })

    // }).catch(error => {
    //     console.log( "Suggestion : " , error );
    // });
    dispatch({
        type: ACTION.GET_SUGGESTIONS,
        payload:[]
    })

}

export const ACtextAction = (text) => (dispatch) => {
    dispatch({
        type: ACTION.SET_AC_TEXT,
        payload: text
    });
    dispatch(search(text));
}

export const search = (search) => (dispatch) => {
    axios.get(`${allyus_url}/search?search=${search}`).then(resp => {
        // console.log(resp.data);
        dispatch({
            type: 'SEARCH_FETCH_SUCCESS',
            payload: resp.data
        })

    }).catch(error => {
        console.log(error);
    });
}