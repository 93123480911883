import axios from 'axios';
import config from '../config';
// const {allyus_url} = config;

export const parseAddressComponents = (address_components) => {
    var location_info = {};
    for (let a in address_components){
        if (address_components[a].types.includes("locality")){ //city
            location_info.location_city = address_components[a].long_name;
        } else if (address_components[a].types.includes("administrative_area_level_2")){ //county
            location_info.county= address_components[a].long_name.replace(" County", "");
        } else if (address_components[a].types.includes("administrative_area_level_1")){ //state/province
            location_info.location_region = address_components[a].long_name;
        } else if (address_components[a].types.includes("country")){
            location_info.location_country = address_components[a].long_name;
        } else if (address_components[a].types.includes("postal_code")){
            location_info.postal_code= address_components[a].long_name;
        } else if (address_components[a].types.includes("sublocality_level_1")){
            if (!location_info.location_city){
                location_info.location_city = address_components[a].long_name;
            }
        }
    }
    return location_info;
}

export const parseDivisionsForCd = (divisions) => {
    let cd;
    const keys = Object.keys(divisions);
    for (let k in keys){
        if (keys[k].includes("cd:")){
            let start = keys[k].indexOf('cd:');
            cd = keys[k].slice(start);
            cd = cd.replace("cd:", "");
            cd = parseInt(cd);
        }
    }
    return cd;
}

// TODO switch to geocodio 
export const getLocation = async ({by_coords, by_zip, by_address, coords, zip, address}) => {
    const api_key = config.google_maps_key;
    const api_key_civic = config.civic_info_key;
    
    try {
        let url;
        if (by_coords && coords){
            url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${coords}&key=${api_key}`;
        } else if (by_zip && zip){
            url = `https://maps.googleapis.com/maps/api/geocode/json?address=${zip}&key=${api_key}`
        } else if (by_address && address){
            url = `https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${api_key}`
        }
        url = encodeURI(url);
        const resp = await axios.get(url);
        const location_data = resp.data.results[0];
        var { address_components, formatted_address } = location_data;
        const location_info = parseAddressComponents(address_components);
        if (location_info.location_country === "United States"){
            formatted_address = formatted_address.replace(/ /g, "+" );
            // send formatted address to google civic api
            var civic_url = `https://www.googleapis.com/civicinfo/v2/representatives/?key=${api_key_civic}&address=${formatted_address}&includeOffices=false`;
            civic_url = encodeURI(civic_url);
            const civic_info = await axios.get(civic_url);
            const { divisions }= civic_info.data;
            let cd = parseDivisionsForCd(divisions);
            location_info.cd = cd;
            location_info.divisions = divisions;
        }
        return location_info;
    }catch(err){
        console.log(err);
        return '';
    }
}