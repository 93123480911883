
export const toggleSignInModal = () => (dispatch) => {
    dispatch({
        type: 'TOGGLE_MODAL',
        modal: 'signin_modal',
    });
    dispatch({type: 'CLEAR_AUTH_ERR'})
}

export const toggleCreatePostModal = () => (dispatch) => {
    dispatch({
        type: 'TOGGLE_MODAL',
        modal: 'create_post_modal',
    });
    dispatch({type: 'CLEAR_EDIT_POST'})
}

export const toggleEditPostModal = () => {
    return{
        type: 'TOGGLE_MODAL',
        modal: 'edit_post_modal',
    };
}

export const toggleReportBugModal = () => {
    return{
        type: 'TOGGLE_MODAL',
        modal: 'report_bug_modal',
    };
}


export const toggleTutorialModal = () => {
    return{
        type: 'TOGGLE_MODAL',
        modal: 'tutorial_modal',
    };
}

export const toggleInviteModal = (invite_type) => (dispatch, getState) => {
    if (getState().modals.invite_modal){
        dispatch({type: 'CLEAR_INVITES'});
    }
    dispatch({
        type: 'TOGGLE_MODAL',
        modal: 'invite_modal',
        invite_constituents: invite_type === 'constituents'
    });
}
