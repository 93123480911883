import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Switch from "react-switch";

/* USER HISTORY TYPES */

// POLL_CREATION: 1,
// PETITION_CREATION: 2,
// NEWS_CREATION: 3,
// DISCUSSION_CREATION: 4,
// MOD_CREATION: 5,

// POL_PLATFORM_CREATION: 13,
// POL_NEWS_CREATION: 14,
// POL_EVENT_CREATION:15,

// POLL_COMMENT: 6,
// PETITION_COMMENT: 7,
// NEWS_COMMENT: 8,
// DISCUSSION_COMMENT: 9,
// MOD_COMMENT: 10,
// PLATFORM_COMMENT: 12,

// SIGNED_PETITION: 11,

// POLL_EDIT: 16,
// PETITION_EDIT: 17,
// NEWS_EDIT: 18,
// DISCUSSION_EDIT: 19,

//TODO politician userhistory
const UserActivityCard = (props) => {
    const {  my_profile, profile, userActivity } = props;
    const { first_name } = profile;
    var name = my_profile ? 'your' : first_name + "'s";
    if (profile.anonymous){
        name = my_profile ? 'your' : profile.username + "'s";
    }

    return (
        <div className={`userAcivity-card allyus-card`}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <p className="allyus-card-title">{name} activity </p>
                {
                    !my_profile || profile.anonymous ? null :
                    <label style={{color:'#5b5656'}} >
                        <span>User Activity Private    </span>
                        <Switch 
                            className="react-switch"
                            onColor="#00e8c1"
                            checked={profile.userhistory_private} 
                            onChange={(checked) => {
                                var setting = checked ? 'private' : 'public';
                                props.setUserHistoryPrivacy(setting)
                            }}
                        />
                    </label>
                }
            </div>
            <hr />
            {
                (!profile.anonymous && !profile.userhistory_private) || my_profile ?
                    <ul className="userActivity-list">
                        {
                            userActivity && userActivity.map((activity, i) => {
            
                                return (
                                    <li key={i}>
                                        <div className="list-item">
                                            <div>
                                                <p className="allyus-text mb-0">
                                                    <span className="allyus-text-bold">{profile.full_name}</span>&nbsp;&nbsp;
                                                    <span>
                                                        {
                                                           [1, 2, 4, 5, 13, 14, 15].includes(activity.event_type) ?
                                                            "created"
                                                            : [3].includes(activity.event_type) ?
                                                            "shared"
                                                            :  [6, 7, 8, 9, 10, 12].includes(activity.event_type) ?
                                                            "commented on"
                                                            : [11].includes(activity.event_type) ?
                                                            "signed" 
                                                            : [16, 17, 18, 19].includes(activity.event_type) ?
                                                            "edited" 
                                                            : ""
                                                        } &nbsp;&nbsp;
                                                        <Link className="allyus-text-primary"
                                                            to={
                                                                // TODO platform links
                                                                [1, 3, 4, 16, 18, 19].includes(activity.event_type) && activity.metadata._id ?
                                                                 `/posts/${activity.metadata._id}`
                                                                : [2, 11, 17 ].includes(activity.event_type) && activity.metadata._id ?
                                                                 `/petitions/${activity.metadata._id}`
                                                                : [6, 8, 9].includes(activity.event_type) && activity.metadata.post_id ? 
                                                                 `/posts/${activity.metadata.post_id}`
                                                                : [7].includes(activity.event_type) && activity.metadata.petition_id ? 
                                                                 `/petitions/${activity.metadata.petition_id}`
                                                                : [12].includes(activity.event_type) && activity.metadata.politician && activity.metadata.politician.nameUrl && activity.metadata.politician.user_politician ? 
                                                                 `/politicians/${activity.metadata.politician.nameUrl}`
                                                                : [12].includes(activity.event_type) && activity.metadata.politician && activity.metadata.politician.nameUrl && activity.metadata.politician.organization ?
                                                                 `/organizations/${activity.metadata.politician.nameUrl}` 
                                                                : "/"
                                                            }
                                                        >
                                                        { // TODO Name of post owner
                                                            [1, 6, 16].includes(activity.event_type) ?
                                                            "a poll"
                                                            : [2, 7, 17, 11].includes(activity.event_type) ?
                                                            "a petition"
                                                            : [3, 8, 18].includes(activity.event_type) ?
                                                            "an article"
                                                            : [4, 9, 19].includes(activity.event_type) ?
                                                            "a discussion"
                                                            : [12, 13].includes(activity.event_type) ?
                                                            "a platform issue"
                                                            : ""
                                                        }
                                                        </Link>
                                                    </span>
                                                </p>
                                                <p className="allyus-text mb-0">{
                                                    activity.metadata.title ? activity.metadata.title :
                                                    activity.metadata.text ? activity.metadata.text :
                                                    ""
                                                }
                                                </p>
                                            </div>
                                            <div className="text-muted">  {moment(activity.created_at).format("D MMM")}
                                            </div>
                                        </div>
                                        <hr />
                                    </li>
                                )
                            })
                        }
                    </ul>
                    :
                    <h2 className="allyus-sub-heading">{name} activity is hidden</h2>
            }
        </div>
    )
}

export default UserActivityCard;