const INITIAL_STATE = {
  posts: [],
  petitions: [],
  featuredPosts: [],
}

const feedReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case 'FEED_FETCH_SUCCESS':
          return action.payload;
      default:
        return state
    }
  }
  
  export default feedReducer;