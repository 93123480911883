'use strict';

    // TODO restrict apiKey use on google console https://developers.google.com/maps/documentation/geocoding/get-api-key#restrict_key

// Settings configured here will be merged into the final config object.
// export default {
//   // allyus_url: 'https://www.sparklification.ca',
//   allyus_url: 'http://localhost:3001',
//   chatkit_instance_locator: "v1:us1:74cfc62f-04c9-4d3c-a89c-230b50dd2092", //test
//   google_maps_key: "AIzaSyDYfLXcnF36f1OhwjwM4WBabj2rH4_nEzY",
//   civic_info_key: "AIzaSyCH2_77Gfi7NknAfSfijQst06K6yvHPasY",
// }

//production
export default {
  allyus_url: 'https://api.allyus.org',
  // allyus_url: 'http://localhost:3001',
  chatkit_instance_locator: "v1:us1:5ae617bf-fbef-4e49-852c-dc618ac567a1",
  google_maps_key: "AIzaSyDYfLXcnF36f1OhwjwM4WBabj2rH4_nEzY",
  civic_info_key: "AIzaSyCH2_77Gfi7NknAfSfijQst06K6yvHPasY",
}
