import React from 'react';
import {
    NavItem,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
import Button from '../../button';
import { Link } from 'react-router-dom';
import { getUserProfilePic, getProfileUrlCurrentUser, profilePicError } from '../../../functions/profile';

export default props => {
    const {logged_in, currentUser, handleSignOut, toggleSignInModal } = props;
           
    var profile_link_public = getProfileUrlCurrentUser(currentUser, false);
    var profile_link_anon = getProfileUrlCurrentUser(currentUser, true);

    var profile_link = profile_link_public;
    if (currentUser.anonymous) {
      profile_link = profile_link_anon;
    }
    if (currentUser.__t === 'politician') {
      profile_link = getProfileUrlCurrentUser(currentUser, false);
    }

    if (logged_in){
        return (
            <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav>
                <img
                    className="avatar"
                    src={getUserProfilePic(currentUser)}
                    onError={e => profilePicError(e)}
                    alt="avatar" />
                </DropdownToggle>
                <DropdownMenu right className="profile">
                {
                    currentUser.__t === 'politician' ? 
                    <DropdownItem>
                    <Link to={profile_link}> profile</Link>
                    </DropdownItem>
                    :
                    <DropdownItem>
                    <Link to={profile_link_public}> public profile</Link>
                    </DropdownItem>
                }
                {
                    currentUser.__t === 'politician' ? null
                    :
                    <DropdownItem>
                    <Link to={profile_link_anon}> anonymous profile</Link>
                    </DropdownItem>
                }
                {/* {
                    currentUser.__t !== 'politician' ? null // TODO 
                    :
                    <DropdownItem>
                    <Link to="/"> Manage Subscription</Link>
                    </DropdownItem>
                }    */}
                <DropdownItem>
                     <a onClick={props.toggleInviteModal}>Invite Friends</a>
                </DropdownItem>
                <DropdownItem>
                    <a href="https://allyus.org/privacypolicy" target="blank"> Privacy Policy </a>
                </DropdownItem>
                <DropdownItem>
                    <a href="https://allyus.org/terms" target="blank"> Terms & Conditions </a>
                </DropdownItem>
                <DropdownItem onClick={handleSignOut} >
                    <span>Logout</span>
                </DropdownItem>
                </DropdownMenu>
            </UncontrolledDropdown>
          );
    } 
    return (
        <NavItem style={{ marginLeft: '5px' }}>
            <Button className="allyus-btn allyus-primary-btn"
            click={toggleSignInModal}> Login </Button>
        </NavItem>
    );

};