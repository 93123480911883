import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button, Modal } from "reactstrap";
import { signOut, editUser } from "../../../store/actions";
import { connect } from "react-redux";
import * as firebase from "firebase/app";
import { getLocation } from "../../../functions/location";
import LocationDetailsModal from "../../../components/locationDetails/modal";
import { getProfileUrlCurrentUser } from '../../../functions/profile';
import ReactGA from 'react-ga';


class LocationDetailsSection extends Component {
    constructor(props) {
        super(props);
        this.state = {
          location_country: "",
          postal_code: "",
          location_info: "",
          locationInfoModal: false,
          addressModal: false,
          address: ""
        };
      }
    
      onInputHandle = e => {
        this.setState({
          [e.target.id]: e.target.value
        });
      };
    
      getLocationGeoLocation() {
        if (navigator.geolocation) {
          this.setState({ locationInfoModal: true });
          navigator.geolocation.getCurrentPosition(async position => {
            let coords = `${position.coords.latitude}, ${position.coords.longitude}`;
            // coords = "34.0811997,-118.4142109"; //demo location // TODO CHANGE
            const location_info = await getLocation({
              coords: coords,
              by_coords: true
            });
            this.setState({ location_info: location_info });
            ReactGA.event({
              category: 'Complete Profile',
              action: 'Get Location',
              label: 'By Geolocation'
            });
          });
        } else {
          console.log("Geolocation is not supported by this browser.");
        }
      }
    
      async getAddressLocation() {
        if (this.state.address) {
            const location_info = await getLocation({
            address: this.state.address,
            by_address: true
            });
            this.setState({ location_info: location_info, locationInfoModal: true });
            ReactGA.event({
              category: 'Complete Profile',
              action: 'Get Location',
              label: 'By Address'
            });
        } else {
            alert("Please enter your address")
        }
      }
    
      async getZipCodeLocation() {
        if (this.state.postal_code){
            const location_info = await getLocation({
            by_zip: true,
            zip: this.state.postal_code
            });
            this.setState({ location_info: location_info, locationInfoModal: true });
            ReactGA.event({
              category: 'Complete Profile',
              action: 'Get Location',
              label: 'By Postal Code'
            });
        } else {
            alert("Please enter your zip code")
        }
      }
    

    render() {
        const { postal_code, address } = this.state;
        const {currentUser} = this.props;
        if (currentUser && currentUser.postal_code){
          return (
            <div className="allyus-form button-group" >
                <p>
                    Thanks for filling out your location! You can edit it any time from your profile
                </p>

            </div>  
          );  
        }
        return (
            <div className="allyus-form button-group" >
                <p>
                    Fill out your location info with <strong>one</strong> of the options below to get the most out
                    of the site! 
                </p>

                <p>
                    Entering your address or allowing access to geolocation (if you're
                    at home), will allow us to most accurately find your representatives.
                </p>   
                {/* <p>
                  Choose one.
                </p>       */}
                <FormGroup className="allyus-form-group">
                <Label >Zip Code</Label>
                <Input
                    id="postal_code"
                    name="postal_code"
                    type="phone"
                    value={postal_code}
                    onChange={this.onInputHandle}
                    placeholder="90210"
                />
                <span onClick={this.getZipCodeLocation.bind(this)}>Enter</span>
                {/* <div>
                  <Button className="allyus-btn allyus-primary-border-btn enter" onClick={this.getZipCodeLocation.bind(this)}>
                      Enter
                  </Button>
                </div> */}
                </FormGroup>

                <FormGroup className="allyus-form-group">
                <Label >Full Address</Label>
                <Input
                    onChange={this.onInputHandle}
                    id="address"
                    name="address"
                    value={address}
                    placeholder="768 5th Ave, New York, NY 10019, USA"
                    style={{
                    borderTop: "none",
                    borderLeft: "none",
                    borderRight: "none"
                    }}
                />
                <span onClick={this.getAddressLocation.bind(this)}>Enter</span>
                {/* <div>
                  <Button className="allyus-btn allyus-primary-border-btn enter" onClick={this.getAddressLocation.bind(this)}>
                      Enter
                  </Button>
                </div> */}
                </FormGroup>
                <FormGroup className="allyus-form-group hide-mobile">
                <Label >Geolocation</Label>
                <div style={{ marginTop: 5 }}>
                    <span
                    //   className="allyus-white-btn"
                    onClick={this.getLocationGeoLocation.bind(this)}
                    >
                      Enter
                    </span>
                </div>
                {/* <div>
                  <Button className="allyus-btn allyus-primary-border-btn enter" onClick={this.getLocationGeoLocation.bind(this)}>
                      Enter
                  </Button>
                </div> */}
                </FormGroup>

                <p style={{ fontSize: 14 }}>
                Note: We never save or sell your address or exact location, we only use it
                to determine your political districts then save those
                </p>
                <LocationDetailsModal
                    open={this.state.locationInfoModal}
                    location_info={this.state.location_info}
                    toggle={() =>
                        this.setState({ locationInfoModal: !this.state.locationInfoModal })
                    }
                    onSubmitLocation={() => {
                        this.props.editUser(this.state.location_info);
                        this.setState({ locationInfoModal: !this.state.locationInfoModal });
                        ReactGA.event({
                          category: 'Complete Profile',
                          action: 'Save Location',
                        });
                        const currentUser = firebase.auth().currentUser;
                        const passwordAuth = currentUser && (currentUser.providerData[0].providerId === 'password' || currentUser.providerData[0].providerId === 'phone');
                        if (currentUser && passwordAuth && (currentUser.phoneNumber || currentUser.emailVerified )){
                          this.props.history.push('/completeprofile');
                        } 
                    }}
                />
            </div>
        );
    }

}
  
const mapStateToProps = (state) => {
  return { currentUser: state.currentUser}
}
  export default connect(
   mapStateToProps,
    {  editUser }
  )(LocationDetailsSection);
  