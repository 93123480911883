import React from 'react';

const AllyusCard = props => {
  return (
    <div className={`allyus-card ${props.className !== 'undefined' ? props.className : ''}`}>
      <div className="allyus-card-top">
        <p className="allyus-card-title">{props.title}</p>
        {
          props.edit ?
            <button onClick={props.click} >
              <img src={`/icons/new/edit-icon-${props.theme}.svg`} alt="about" />
            </button> : null
        }
        {
          props.add ?
            <button onClick={props.click} >
              <img src={`/icons/new/plus-icon.svg`} alt="add" /> &nbsp;
              <span className="allyus-text-secondery"> {props.addText} </span>
            </button> : null
        }
        {
          props.addButton ?
          <div>
            <button onClick={props.click} className="allyus-btn allyus-secondery-btn ">
            <img src={`/icons/icons8-plus-24.png`} alt="add" style={{width:16, height:16, marginBottom:3.5}} /> &nbsp;

            {props.addText}
            </button> </div>: null
        }
        {props.isModal ?
         <button onClick={props.onClose}
         style={{
           position:'absolute',
           top: 10,
           right: 10,
         }}
         >
           <span className="close" style={{color:'black'}}>x</span>
        </button> : null
        }
      </div>
      <hr />
      {props.children}
    </div>
  );
}

export default AllyusCard;