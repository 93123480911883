import React, { Component } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { connect } from "react-redux";
import * as firebase from "firebase/app";
import { Redirect } from 'react-router-dom';
import {signInAction, signInSocial, signUpAction, signOut, editUser } from '../../../store/actions';
import AllyusCard from "../../../components/allyusCard";
import LocationDetailsSectioon from "../sections/location";
import { getProfileUrlCurrentUser } from '../../../functions/profile';
import ReactGA from 'react-ga';

class signInModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: "",
      confirmResult: null,
      phoneErr: '',
    };
  }

  componentDidMount() {
    this.readyRecaptcha();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  readyRecaptcha() {
    //firebase.auth().settings.appVerificationDisabledForTesting = true;
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      this.recaptcha,
      {
        size: "normal",
        callback: function(response) {
          // reCAPTCHA solved, allow signInWithPhoneNumber.
          // ...
        },
        "expired-callback": function() {
          // Response expired. Ask user to solve reCAPTCHA again.
          // ...
        }
      }
    );
    window.recaptchaVerifier.render().then(function(widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  }
  
  submitPhoneNumber() {
    const appVerifier = window.recaptchaVerifier;
    const phoneNumber = this.state.phone;
    firebase
      .auth()
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then(confirmResult => {
        this.setState({ confirmResult, phoneErr: '' });
      })
      .catch(err => {
        this.setState({phoneErr: err.message});
        this.readyRecaptcha();
      });
  }

  confirmPhoneNumber() {
    const code = this.state.code;
    const confirmationResult = this.state.confirmResult;
    try {
      var credential = firebase.auth.PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        code
      );

      firebase
        .auth()
        .currentUser.linkAndRetrieveDataWithCredential(credential)
        .then((usercred) => {
            var user = usercred.user;
            // console.log("Account linking success", user);
            const updatedProfile = {
              phone: this.state.phone,
              phone_verified: true,
            }
            this.props.editUser(updatedProfile);
            this.setState({phoneErr: ''});
            if (usercred && this.props.currentUser && !this.props.currentUser.profile_creation_completed && this.props.currentUser.__t !== 'politician'){
              if (!this.props.currentUser.location_country || (this.props.currentUser.location_country === "United States" && !this.props.currentUser.postal_code)){
                this.props.history.push(`/location`);
              } else{
                this.props.history.push('/completeprofile');
              }
            } else {
              this.props.history.push("/");
            }
          })
          .catch((error) => {
            this.setState({phoneErr: error.message});
          });
    } catch (err) {
      console.log(err);
    }
  }

  onInputHandle = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  handleSignOut = () => {
    this.props.signOut();
    this.props.history.push('/');
  }


  render() {
    const { phone, code, phoneErr } = this.state;
    const currentUser = firebase.auth().currentUser;
    if (!currentUser || ((currentUser.providerData[0].providerId === 'password' || currentUser.providerData[0].providerId === 'phone') && (currentUser.phoneNumber || currentUser.emailVerified))){
      if (currentUser && this.props.currentUser && !this.props.currentUser.profile_creation_completed && this.props.currentUser.__t !== 'politician'){
        if (!this.props.currentUser.location_country || (this.props.currentUser.location_country === "United States" && !this.props.currentUser.postal_code)){
          this.props.history.push(`/location`);
        } else {
          this.props.history.push('/completeprofile');
        }
      } else {
        return <Redirect to='/' />
      }
    }
    return (
      <div style={{paddingTop:'1%', marginBottom: 20}} className="page-signup">
        <div>
          <Button className="allyus-btn allyus-primary-border-btn" 
            style={{width:150, position: 'absolute', right:20, borderWidth:0}}
            onClick={this.handleSignOut}
            >
              Log Out
              </Button>
        </div>
        <AllyusCard className="signIn"  title="Thanks for signing up!">
          {phoneErr ? <div className="alert alert-danger">{phoneErr} </div> : ""}
          <div style={{marginBottom:'5%'}}>

            {
              currentUser && currentUser.emailVerified ? null :
              <div>
              <p>We've sent a verification email to your account, please click the link in the email to verify your email</p>
              <p>(if you've done that please refresh the page)</p>
              </div>
            }
            <p><strong>OR</strong> enter and verify your phone number to finish account creation</p>
           
          </div>
          <Form className="allyus-form button-group">
            <FormGroup className="allyus-form-group">
              <Label for="phone">Phone</Label>
              <Input
                id="phone"
                type="phone"
                value={phone}
                onChange={this.onInputHandle}
                placeholder="+11234567890"
              />
            </FormGroup>
            <FormGroup>
             <div ref={ref => (this.recaptcha = ref)}/>
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Button
                id="submit"
                className="allyus-btn allyus-primary-btn signup-btn-m"
                onClick={() => this.submitPhoneNumber()}
              >
                {" "}
                Send Confirmation Code{" "}
              </Button>
            </FormGroup>
         { !this.state.confirmResult ? null :
           <div>
            <FormGroup className="allyus-form-group">
              <Label for="code">Confirmation Code</Label>
              <Input
                id="code"
                type="code"
                value={code}
                onChange={this.onInputHandle}
              />
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Button
                id="submit"
                className="allyus-btn allyus-primary-btn signup-btn-m"
                onClick={() => this.confirmPhoneNumber()}
              >
                {" "}
                Verify Confirmation Code{" "}
              </Button>
            </FormGroup>
            </div>
          }
          </Form>
          {/* <LocationDetailsSectioon /> */}
        </AllyusCard>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  };
};

export default connect(
  mapStateToProps,
  { signInAction, signUpAction, signInSocial, signOut, editUser}
)(signInModal);
