import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import SubNavbarPetitions from '../../components/navbar/subnav/petitionNavbar';
import UserInfoCard from '../../components/feed/userInfoCard/index';
import RepresentativeCard from '../../components/feed/representativeCard/index';
import PetitionCard from '../../components/feed/petitionCard/index';
import SponsoredCard from '../../components/feed/sponsoredCard/index';
import { Divider } from '../../components/common/index';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { toggleAnonymous, toggleSignInModal, toggleCreatePostModal, followUser, unfollowUser, getPetitions, editPost, deletePost  } from '../../store/actions';

import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';

import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../config';
const { allyus_url }= config;

class Petitions extends Component {
  constructor(props) {
    super(props);
    this.state = {activePetition: ''};
  }

  componentDidMount(){
    const petition_id = this.props.match.params.petition_id;
    if (petition_id){
      this.getActivePetition(petition_id);
    } else {
      this.setState({activePetition: ''})
    }

    this.props.getPetitions();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  componentDidUpdate(prevProps){
    if (prevProps.match.params.petition_id !== this.props.match.params.petition_id){
      const petition_id = this.props.match.params.petition_id;
      if (petition_id){
        this.getActivePetition(petition_id);
      } else {
        this.setState({activePetition: ''})
      }
    }
  }

  toggleProfile = () => {
    this.props.toggleAnonymous();
  }

  async getActivePetition(petition_id){
    try {
      if (firebase.auth().currentUser){
        const  access_token = await firebase.auth().currentUser.getIdToken(true);
        const petition = await axios.get(`${allyus_url}/petitions/${petition_id}?access_token=${access_token}`);
        this.setState({activePetition: petition.data});
      } else {
        const petition = await axios.get(`${allyus_url}/petitions/${petition_id}`);
        this.setState({activePetition: petition.data});
      }
    } catch(err) {
      // console.log(err);
    }
  }

  renderHelmet() {
    const petition_id = this.props.match.params.petition_id;
    var { activePetition } = this.state;
    activePetition = activePetition ? activePetition.post : "";
    let image_url = "/allyuslogo.png";
    if (petition_id && activePetition) {
      if (activePetition.image){
        image_url = activePetition && activePetition.image ? activePetition.image : image_url;
      } 
      const og_url = `${window.location.origin}/petitions/${petition_id}`;
      return (
        <Helmet>
          <meta property="og:title" content={`${activePetition.title}`} />
          <meta property="og:url" content={og_url} />
         
          <meta name="twitter:image" content={`${image_url}`} />
          <meta property="og:image" content={`${image_url}`} />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="315" />
        </Helmet>
      );
    } 
  }
  
  render() {
    const { currentUser, auth, petitions, followUser, unfollowUser } = this.props;
    const { activePetition } = this.state;
    const logged_in = firebase.auth().currentUser ? true : false;

    return (
      <Container fluid>
        {this.renderHelmet()}
        <Row>
          <Col >
            <SubNavbarPetitions />
          </Col>
        </Row>
        <Divider />
        <Row className="mt-3">
          <Col sm={3}>
            {!logged_in ? null :
              <UserInfoCard
                toggleAnonymous={this.props.toggleAnonymous}
                currentUser={currentUser}
              />
            }
      
            <RepresentativeCard is_politician={currentUser.__t === 'politician'} currentUser={currentUser} followUser={followUser}
              unfollowUser={unfollowUser} />

          </Col>
          <Col sm={5}>
            {
                !activePetition ?  null :
                <PetitionCard   petition={activePetition}  {...this.props}  active={true} />
            }
            { 
              petitions.map(petition => {
                  return <PetitionCard   petition={petition} {...this.props} />
              })
            }
          </Col>
          <Col sm={4}>
            {/* <SponsoredCard /> */}
          </Col>
        </Row>
      </Container>
    )
  }
}


const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
    signInModal: state.modals.signin_modal,
    createPostModal: state.modals.create_post_modal,
    petitions: state.petitions
  }
}

export default connect(mapStateToProps, { toggleAnonymous, toggleSignInModal, toggleCreatePostModal, followUser, unfollowUser, getPetitions, editPost, deletePost })(Petitions);
