import _ from "lodash";

const INITIAL_STATE = {
    profile: "",
    followers: [],
    following: [],
    userActivity: [],
    connections:[],
    representatives:[],
    petitions: [],
    loaded: false,
    error: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'PROFILE_FETCH_SUCCESS':
            return action.payload;
        case 'PROFILE_LOADING':
            return INITIAL_STATE;
        case 'PROFILE_FETCH_ERROR':
            return {...INITIAL_STATE, loaded:true, error: true}
        default:
            return state;
    }
};