import React, { Component } from 'react';
import AllyusCard from '../../allyusCard/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';

export default class PoliticianDonateCard extends Component {
    constructor(props) {
        super(props);
        const { profile } = props;
        this.state = { 
          modal: false,
          donate_link: profile.donate_link,
        };    
    }

    componentDidUpdate(prevProps) {
        if (prevProps.profile._id !== this.props.profile._id){
            const { profile } = this.props;
            this.setState({ 
                donate_link: profile.donate_link,
              });
        }
    }
        
    toggle = () => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    handleInputChange = (event)=> {
        const value = event.target.value;
        const name = event.target.name;
        this.setState({
            [name]: value
        });
    }

  onSubmit = () => {
    const { 
      donate_link,
     } = this.state;
    const updatedProfile = { 
      donate_link,
    };
    this.props.editUser(updatedProfile);
    this.toggle();
  }


    render() {
        const { my_profile, profile, userAdmin } = this.props;
        const { donate_link } = profile;
        return (
            <AllyusCard
                title='Donations'
                edit={my_profile || userAdmin}
                click={this.toggle}
                theme='p'
            >
                <div>
                    <p className="allyus-text">
                        Receive donations and support your campaigning. Link your primary donation service to AllyUs and redirect users.
                    </p>
                    {donate_link ? null :
                        <Button  className="allyus-secondery-border-btn-text"  click={this.toggle}>Create Donation Button</Button>
                    }
                </div>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle} className="allyus-card-title">Edit Donations Link</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="donate_link">Donations Link</Label>
                                <Input type="text" id="donate_link" name="donate_link" value={this.state.donate_link} onChange={this.handleInputChange} />
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={() => this.onSubmit()}> save </Button>
                    </ModalFooter>
                </Modal>
            </AllyusCard>
        )
    }

}

