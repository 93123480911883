import React from 'react';

const AllyusCardWrapper= props => {
    return (
      <div className={`allyus-card wrapper ${props.className}`}>
        {props.children}
      </div>
    );
  }

  export default AllyusCardWrapper;