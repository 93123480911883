import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import BreakingNews from './index';


export default class AboutCovidResources extends Component {

  render() {  

    return(
        <Container>
          <BreakingNews  />
             <div  style={{paddingTop:50, paddingBottom: 50, textAlign:'center'}}>
                <h4 style={{fontWeight:'bold'}}>Local government websites/health departments</h4>
                <br></br>
                <a href="https://bnonews.com/index.php/2020/04/the-latest-coronavirus-cases/" style={{color:'black'}}><h5>BNO News</h5></a>
                <a href="https://www.cdc.gov/" style={{color:'black'}}><h5>CDC</h5></a>
                <a href="https://www.who.int/" style={{color:'black'}}><h5>WHO</h5></a>
            </div>
            <div style={{paddingTop:50, paddingBottom: 50, textAlign:'center'}} >
                <h4 style={{fontWeight:'bold'}}>Coming Soon</h4>
                <br></br>
                <h5>Language support</h5>
                <h5>Statistics</h5>
                <h5>More resources</h5>
            </div>


        </Container>
    );
  }
}


