import React from 'react';
import TimeAgo from 'timeago-react';
import { Link } from 'react-router-dom';
import { profilePicError } from '../../../functions/profile';

const PostTarget = ({ targetImg, targetName, targetLocation, postTitle, postDate, targetLink, post }) => {
    const item = post ? post.user : {};
    const img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
    : item.organization ? 'user-img-organization' : 'user-img-voter';
    return (
        <div className="allyus-post-target">
            <Link to={targetLink}><img src={targetImg} onError={e => profilePicError(e)} className={`${img_style}`} alt="post" /></Link>
            <div className="title">
                <Link to={targetLink}><p className="allyus-card-heading">{targetName}</p></Link>
                <p className="allyus-card-sub-heading">{targetLocation}</p>
            </div>
            {postTitle ? <p className="post-title ml-auto">{postTitle}</p> : null}
            {/* <TimeAgo  className="post-date text-muted" datetime={postDate} /> */}
        </div>
    )
}

export default PostTarget;