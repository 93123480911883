import React from 'react';
import NewsCard from '../../newsCard/';
import AllyusCard from '../../allyusCard/';
import Button from '../../button/';
import { Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import axios from 'axios';
import * as firebase from "firebase/app";

import config from "../../../config";
const { allyus_url } = config;

class PolititionRecentNewsCard extends React.Component {

    state = { news_link: ''}
 
    toggle = (e) => {
        this.setState(prevState => ({
            modal: !prevState.modal
        }));
    }

    saveNews = async() => {
        try {
            if (this.state.news_link) {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            const { userAdmin, profile: {_id} } = this.props;
            const news = { news_link: this.state.news_link };
            if (!userAdmin){
                 await axios.post(`${allyus_url}/politiciannews?access_token=${access_token}`, {politicianNews : news});
            } else {
                await axios.post(`${allyus_url}/admin/politicians/${_id}/news?access_token=${access_token}`, {politicianNews : news});
            }
            this.setState({news_link: ''});
            this.toggle();
            this.props.getProfile();
            }
        } catch(err) {
            console.log(err);
        }
    }

    deleteNews = async(news_id) => {
        try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            await axios.delete(`${allyus_url}/politiciannews/${news_id}?access_token=${access_token}`);
            this.props.getProfile();
        } catch(err) {
            console.log(err);
        }
    }

    render() {
        const { my_profile, news, userAdmin} = this.props;
        const { news_link } = this.state;
        return (
            <AllyusCard
                title='Recent News'
                add={my_profile || userAdmin}
                addText="Add News"
                click={this.toggle}
                theme='p'
            >
                <Row>
                    {
                        news.map((item, index) => {
                            return (
                                <Col sm={6}>
                                    <NewsCard key={index} news={item} edit={my_profile || userAdmin} deleteNews={this.deleteNews.bind(this)} />
                                </Col>
                            )
                        })
                    }
                </Row>
                <Modal isOpen={this.state.modal} toggle={this.toggle} >
                    <ModalHeader toggle={this.toggle}>Add Article</ModalHeader>
                    <ModalBody>
                        <Form className="allyus-form">
                            <FormGroup className="allyus-form-group">
                                <Label for="exampleText">News Link</Label>
                                <Input type="text" name="text" id="news" value ={news_link} onChange={(e) => this.setState({news_link: e.target.value})} />
                    
                            </FormGroup>
                        </Form>
                    </ModalBody>
                    <ModalFooter>
                        <Button className="allyus-btn allyus-secondery-btn" click={this.saveNews}> save </Button>
                    </ModalFooter>
                </Modal>
            </AllyusCard>

        )
    }
}

export default PolititionRecentNewsCard;