import React, { Component } from "react";
import { Modal } from "reactstrap";
import SignIn from "./sections/signin";

class signInModal extends Component {
  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className="sign-in-dialog"
      >
        <SignIn {...this.props} isModal={true} />
      </Modal>
    );
  }
}

export default signInModal;
