import axios from "axios";
import * as firebase from "firebase/app";
import config from "../../config";
const { allyus_url } = config;

export const getPoliProfile = (nameURL) => async (dispatch, getState) => {
    try {
        const profile_data = await axios.get(`${allyus_url}/politicians/${nameURL}`);
      
        const profile = profile_data.data;
        const platform = await axios.get(`${allyus_url}/politicians/${nameURL}/platform`);
        const events = await axios.get(`${allyus_url}/politicians/${nameURL}/events`);
        const news = await axios.get(`${allyus_url}/politicians/${nameURL}/news`);
        const endorsements = await axios.get(`${allyus_url}/politicians/${nameURL}/endorsements`);
        const feed = await axios.get(`${allyus_url}/politicians/${nameURL}/feed`);
        profile.anonymous = false;
        if (profile){
          dispatch({
            type: 'POLI_PROFILE_FETCH_SUCCESS',
            payload: {
                profile: profile,
                platform: platform.data,
                events: events.data,
                news: news.data,
                endorsements: endorsements.data,
                feed: feed.data,
                loaded: true,
                error: false,  
            }
          });
        }
      } catch(err) {
        console.log(err);
        dispatch({type: 'POLI_PROFILE_FETCH_ERROR'});
      }
};

export const addEndorsement = () => async (dispatch, getState) => {
  try {
    const access_token = await firebase.auth().currentUser.getIdToken(true);
    const nameURL = getState().politicianProfile.profile.nameUrl;
    await axios.post(`${allyus_url}/politicians/${nameURL}/endorse?access_token=${access_token}`);
    dispatch(getPoliProfile(nameURL));

  } catch (err) {
    console.log(err);
  }
}

export const deleteEndorsement = (endorser_id) => async (dispatch, getState) => {
  try {
    const access_token = await firebase.auth().currentUser.getIdToken(true);
    const {nameUrl, _id} = getState().politicianProfile.profile;
    const { admin } = getState().currentUser;
    if  (!admin){
      await axios.delete(`${allyus_url}/politicians/endorsements/${endorser_id}?access_token=${access_token}`);
    } else {
      await axios.delete(`${allyus_url}/admin/politicians/${_id}/endorsements/${endorser_id}?access_token=${access_token}`);
    }
    dispatch(getPoliProfile(nameUrl));

  } catch (err) {
    console.log(err);
  }
}

export const removeMyEndorsement = () => async (dispatch, getState) => {
  try {
    const access_token = await firebase.auth().currentUser.getIdToken(true);
    const nameURL = getState().politicianProfile.profile.nameUrl;
    await axios.delete(`${allyus_url}/politicians/${nameURL}/endorsement?access_token=${access_token}`);
    dispatch(getPoliProfile(nameURL));

  } catch (err) {
    console.log(err);
  }
}