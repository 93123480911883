import ACTIONS from '../actionTypes';

const INITIAL_STATE = {
  "anonymous": true,
  "xp_level": 0,
  "reps": 0,
  "upvotes": 0,
  "downvotes": 0,
  "neutralvotes": 0,
  "following": [],
  "followers": [],
  "representatives": [],
  "topics": [],
  "user_verified": false,
  "civic_verified": false,
  "email_verified": false,
  "phone_verified": false,
  "feature_emails": false,
  "feature_sms": false,
  "marketing_emails": false,
  "marketing_sms": false,
  "userhistory_private": true,
  "user_politician": false,
  "hiddenPosts": [],
  "hiddenPetitions": [],
  "_id": "",
  "email": "",
  "phone": "",
  "username": "",
  "uuid": "",
  "bio": "",
  "dob": null,
  "education": "",
  "ethnicity": "",
  "first_name": "",
  "gender": "",
  "last_name": "",
  "location_city": "",
  "location_country": "",
  "location_region": "",
  "regional_distric_number": "",
  "marital_status": "",
  "political_lean": "",
  "postal_code": "",
  "profile_picture": "/images/temp_anon_icon.svg",
  "anon_profile_picture" : "",
  "cover_photo": "",
  "anon_cover_photo": "",
  "religion": "",
  "created": "",
  "connections": [],
  "nameUrl": "",
  "admin": false,
  "profile_creation_completed": false,
  "my_feed": [],
  "covid_admin": false, //NOTE special temp
  };
  
  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ACTIONS.USER_PROFILE_FETCH_SUCCESS:
        return action.currentUser;
      case ACTIONS.LOG_OUT_PROFILE:
        return INITIAL_STATE;
      default:
        return state;
    }
  };
  