import React from 'react';
import { Nav } from 'reactstrap';
import { YearPicker, MonthPicker, DayPicker } from 'react-dropdown-date';
import moment from 'moment';

const Divider = props => {
  return (
    <div className={`divider ${props.className}`} style={{ borderBottomWidth: `${props.width}` }} />
  )
}

const Overlay = props => {
  return (
    <div className="overlay" />
  )
}



class AllyusMenu extends React.Component {
  state = {
    dropdownOpen: false
  }
  toggle = () => {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen
    });
  }

  render() {
    return (
      <Nav className={`allyus-menu ${this.props.className}`}>
        {/* <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggle}>
          <DropdownToggle nav caret>
            
          </DropdownToggle>
          {this.props.children}
        </Dropdown> */}
      </Nav>
    );
  }
}

class AllyusDatePicker extends React.Component {

  render() {
    const {year, month, day} = this.props;
    // TODO consider changing this date picker
    return (
      <div className={`allyus-date-picker ${this.props.className}`}>
        <YearPicker
          defaultValue={year}
          // default is 1900
          start={this.props.start ? this.props.start : moment().format("Y")}
          // default is current year
          end={2025}
          // default is ASCENDING
          // reverse
          // default is false
          required={true}
          // default is false
          disabled={false}
          // mandatory
          value={year}
          // mandatory
          onChange={(year) => this.props.handleDateTimeChange(year, "year")}
          id={'year'}
          name={'year'}
          classes={'classes'}
          optionClasses={'option classes'}
        />
        <MonthPicker
          defaultValue={month}
          // to get months as numbers array starting at 0
          // numeric
          // default is full name
          short
          // default is Titlecase
          // caps
          // mandatory if end={} is given in YearPicker
          endYearGiven
          // mandatory
          year={year}
          // default is false
          required={true}
          // default is false
          disabled={false}
          // mandatory
          value={month}
          // mandatory
          onChange={(month) => this.props.handleDateTimeChange(month, "month")}
          id={'month'}
          name={'month'}
          classes={'classes'}
          optionClasses={'option classes'}
        />
        <DayPicker
          defaultValue={day}
          // mandatory
          year={year}
          // mandatory
          month={month}
          // mandatory if end={} is given in YearPicker
          endYearGiven
          // default is false
          required={true}
          // default is false
          disabled={false}
          // mandatory
          value={day}
          // mandatory
          onChange={(day) => this.props.handleDateTimeChange(day, "day")}
          id={'day'}
          name={'day'}
          classes={'classes'}
          optionClasses={'option classes'}
        />
      </div>

    )
  }
}

class AllyusTimePicker extends React.Component {

  render() {
    const {hour, min, period} = this.props;
    return (
      <div className={`allyus-time-picker ${this.props.className}`}>
        <select id="hour" name="hour" value={hour} onChange={(e) => this.props.handleDateTimeChange(e, "hour")} >
          {
            ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"].map((item) => {
              return <option value={item} >{item}</option>
            })
          }
        </select>
        <select id="min" name="min" value={min} onChange={(e) => this.props.handleDateTimeChange(e, "min")}  >
          {
            ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15",
              "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31",
              "32", "33", "34", "35", "36", "37", "38", "39", "40", "41", "42", "43", "44", "45", "46", "47", 
              "48", "49", "50", "51", "52", "53", "54", "55", "56", "57", "58", "59", "60"].map((item) => {
                return <option value={item} >{item}</option>
              })
          }
        </select>
        <select id="min" name="min" value={period} onChange={(e) => this.props.handleDateTimeChange(e, "period")} >
          <option value="am">AM</option>
          <option value="pm">PM</option>
        </select>
  
      </div>
    )
  }
}


export { Divider, Overlay, AllyusMenu, AllyusDatePicker, AllyusTimePicker }
