import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ReactPlayer from 'react-player'
import ReactGA from "react-ga";

export default class TutorialModal extends React.Component {
    componentDidUpdate(prevProps) {
        if (!prevProps.isOpen &&  this.props.isOpen){
            ReactGA.modalview(window.location.pathname + '/tutorial');
        }
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="post-modal-dialog" backdrop={true}>
                <ModalHeader toggle={this.props.toggle}>
                    Tutorial
                </ModalHeader>
                <ModalBody className="post-modal-body">
                    <ReactPlayer url='https://www.youtube.com/watch?v=8-C_XuBHpxM' width={'100%'} />
                </ModalBody>
            </Modal>
        )
    }

}

