import React, { Component } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input } from 'reactstrap';
import classnames from 'classnames';
import AllyusCard from '../../allyusCard/';
import Button from '../../button/';
import AllyusListItemCard from '../../allyusListItemCard/';
import _ from 'lodash';
import * as firebase from "firebase/app";

const TAB_ACTIVE_ALL = 1,
  TAB_ACTIVE_ORGANIZATION = 2,
  TAB_ACTIVE_MUNICIPAL = 3,
  TAB_ACTIVE_PROVINCIAL = 4,
  TAB_ACTIVE_FEDERAL = 5;

const All = props => {
  const { allList, edit, currentUser_id } = props;
  return (
    <ul>
      {
        allList && allList.map((item, i) => {
          if (item && item.endorser_id) {
            return (
              <li key={i}>
                <AllyusListItemCard item={item} toggle={props.toggle} edit={edit} my_endorsement={currentUser_id === item.endorser_id._id} />
              </li>
            )
          }
        }) 
      }
    </ul>
  );
}


export default class PolititionEndorsementCard extends Component {
  state = {
    activeSubTab: 1,
    modalDelEndorsement: false,
    modalAddEndorsement: false,
    modalFollowEndorse: false,
    endorser_id: ''
  }

  toggle = (tab) => {
    if (this.state.activeSubTab !== tab) {
      this.setState({
        activeSubTab: tab
      });
    }
  }

  toggleDelEndorsement = (e, endorser_id) => {
    this.setState(prevState => ({
      modalDelEndorsement: !prevState.modalDelEndorsement,
      endorser_id: endorser_id
    }));
  }
  toggleAddEndorsement = (e) => {
    this.setState(prevState => ({
      modalAddEndorsement: !prevState.modalAddEndorsement
    }));
  }
  toggleFollowEndorse = (e) => {
    this.setState(prevState => ({
      modalFollowEndorse: !prevState.modalFollowEndorse
    }));
  }

  render() {
    const  allList  = this.props.endorsements;
    const { my_profile, currentUser, profile } = this.props;
    const userAdmin = currentUser.admin;
    const renderAddEndorsement =  firebase.auth().currentUser && (currentUser._id !== profile._id) &&
    (currentUser.__t === 'politician' || 
    (currentUser.following && currentUser.following.includes(profile._id) && !currentUser.anonymous)); 
    // TODO allyus admin always can endorse?
    //TODO organizations
    // TODO allow anonymous endorsements?
    var municipalList = _.filter(allList, function(o) {return o.endorser_id.officeLevel === 'municipal'; });
    var regionalList = _.filter(allList, function(o) { return o.endorser_id.officeLevel === 'regional'; });
    var federalList = _.filter(allList, function(o) { return o.endorser_id.officeLevel === 'federal'; });
    return (
      <AllyusCard
        title='Endorsement'
        add={currentUser._id !== profile._id} 
        addText="Add Endorsement"
        click={renderAddEndorsement ? this.toggleAddEndorsement : this.toggleFollowEndorse}
        theme='p'
        className="allyus-sub-tab-nav"
      >
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === TAB_ACTIVE_ALL })}
              onClick={() => { this.toggle(TAB_ACTIVE_ALL); }}
            >
              All
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === TAB_ACTIVE_ORGANIZATION })}
              onClick={() => { this.toggle(TAB_ACTIVE_ORGANIZATION); }}
            >
              Organizations
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === TAB_ACTIVE_MUNICIPAL })}
              onClick={() => { this.toggle(TAB_ACTIVE_MUNICIPAL); }}
            >
              Municipal
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === TAB_ACTIVE_PROVINCIAL })}
              onClick={() => { this.toggle(TAB_ACTIVE_PROVINCIAL); }}
            >
              State
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeSubTab === TAB_ACTIVE_FEDERAL })}
              onClick={() => { this.toggle(TAB_ACTIVE_FEDERAL); }}
            >
              Federal
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={this.state.activeSubTab}>
          <TabPane tabId={TAB_ACTIVE_ALL}>
            <Row>
              <Col sm="12">
                <All allList={allList} toggle={this.toggleDelEndorsement} edit={my_profile || userAdmin} currentUser_id={currentUser._id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TAB_ACTIVE_ORGANIZATION}>
            <Row>
              <Col sm="12">
                <All allList={[]} toggle={this.toggleDelEndorsement} edit={my_profile || userAdmin} currentUser_id={currentUser._id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TAB_ACTIVE_MUNICIPAL}>
            <Row>
              <Col sm="12">
                <All allList={municipalList} toggle={this.toggleDelEndorsement} edit={my_profile || userAdmin} currentUser_id={currentUser._id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TAB_ACTIVE_PROVINCIAL}>
            <Row>
              <Col sm="12">
                 <All allList={regionalList} toggle={this.toggleDelEndorsement} edit={my_profile || userAdmin} currentUser_id={currentUser._id} />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TAB_ACTIVE_FEDERAL}>
            <Row>
              <Col sm="12">
                 <All allList={federalList} toggle={this.toggleDelEndorsement} edit={my_profile || userAdmin} currentUser_id={currentUser._id} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        {/* ******************** Modal Delete Endorsement ********************* */}
        <Modal isOpen={this.state.modalDelEndorsement} toggle={this.toggleDelEndorsement} >
          <ModalHeader toggle={this.toggleDelEndorsement}>Delete Endorsement</ModalHeader>
          <ModalBody>
            <p className="allyus-text"> Are you sure you want to delete this  endorsement? </p>
          </ModalBody>
          <ModalFooter>
            <Button className="allyus-btn allyus-white-btn" click={() => {
              if (my_profile || userAdmin) {
                this.props.deleteEndorsement(this.state.endorser_id)
              } else {
                this.props.removeMyEndorsement();
              } 
              this.toggleDelEndorsement();
            }}> Delete </Button>
            <Button className="allyus-btn allyus-secondery-btn" click={this.toggleDelEndorsement}> Not really </Button>
          </ModalFooter>
        </Modal>

        <Modal isOpen={this.state.modalFollowEndorse} toggle={this.toggleFollowEndorse} >
          <ModalHeader toggle={this.toggleFollowEndorse}>Follow to Endorse</ModalHeader>
          <ModalBody>
            <p className="allyus-text"> Follow to endorse {profile.full_name}. </p>
            <p className="allyus-text">Note: endorsements must be made from a public profile.</p>
          </ModalBody>
          <ModalFooter>
            <Button className="allyus-btn allyus-white-btn" click={this.toggleFollowEndorse}> Ok </Button>
          </ModalFooter>
        </Modal>
        {/* ******************** Modal Add Endorsement ********************* */}
        <Modal isOpen={this.state.modalAddEndorsement} toggle={this.toggleAddEndorsement} >
          <ModalHeader toggle={this.toggleAddEndorsement}>Add Endorsement</ModalHeader>
          <ModalBody>
            <p className="allyus-text"> Are you sure you want to endorse {profile.full_name}? </p>
          </ModalBody>
          <ModalFooter>
            <Button className="allyus-btn allyus-white-btn" click={this.toggleAddEndorsement}> No </Button>
            <Button className="allyus-btn allyus-secondery-btn" click={() => {this.props.addEndorsement(); this.toggleAddEndorsement();}}> Yes </Button>
          </ModalFooter>
        </Modal>
      </AllyusCard>
    )
  }
}