import React, { Component } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import UserDetailCard from "../../components/voterProfile/userDetailsCard/index";
import classnames from "classnames";
import UserActivityCard from "../../components/voterProfile/userActivityCard/index";
import UserConnectionsCard from "../../components/voterProfile/userConnectionsCard/index";
import UserRepresentativeCard from "../../components/voterProfile/userRepresentativeCard/index";
import UserAboutCard from "../../components/voterProfile/userAboutCard/index";
import UserProfileInfoCard from "../../components/voterProfile/userProfileInfoCard/index";
import UserPetitionsCard from "../../components/voterProfile/userPetitionsCard/index";
import { connect } from "react-redux";
import { toggleAnonymous, toggleSignInModal, getCurrentUser , editUser, followUser, unfollowUser, getProfile, setUserHistoryPrivacy} from "../../store/actions";
import AllyusCardWrapper from '../../components/allyusCardWrapper/index';
import { Helmet } from "react-helmet";
import { getUserProfilePic, getProfileUrlCurrentUser, profilePicError } from "../../functions/profile";
import ReactGA from 'react-ga';

class PublicProfile extends Component {
  state = {
    userProfileType: "private",
    activeTab: "1",
    modalDetail: false,
    modalAbout: false,
    profile: "",
    followers: [],
    followers_count: 0,
    following: [],
    following_count: 0,
    connections: [],
    userActivity: [],
    activeConnectionsTab: "1",
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  toggleAnonymous = () => {
    this.props.toggleAnonymous();
    if (!this.props.currentUser.anonymous){
      this.props.history.push(getProfileUrlCurrentUser(this.props.currentUser, true));
    }
  };

  componentDidMount() {
    // this.props.getCurrentUser();
    this.props.getProfile(this.props.match.params.name);
    ReactGA.pageview(window.location.pathname + window.location.search);
    
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.name !== prevProps.match.params.name) {
      this.props.getProfile(this.props.match.params.name);
    }
    if (prevProps.currentUser !== this.props.currentUser) {
      this.props.getProfile(this.props.match.params.name);
    }
  }


  renderProfileType = () => {
    const { currentUser, editUser } = this.props;

    const {
      profile,
      followers,
      following,
      connections,
      userActivity,
      petitions,
      representatives
    } = this.props.userProfile;
    var my_profile = currentUser._id === profile._id;
    var logged_in = this.props.auth.uid ? true : false;
    const item = profile;
    const img_style = item.influencer ? 'profile-img-influencer' : item.lobbyist ? 'profile-img-lobbyist' : item.user_politician ? 'profile-img-politician'
    : item.organization ? 'profile-img-organization' : 'profile-img-voter';
    return (
      <Container fluid>
          <Helmet>
            <meta property="og:title" content="Allyus - Make your voice heard. Own your democracy." />
            <meta property="og:description" content="Allyus is a location based political social network which empowers people to change government policy."/>
            {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
            <meta name="twitter:image" content="/allyuslogo.png"  />
            <meta property="og:image" content="/allyuslogo.png"  />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
        <Row className="mt-0 mt-lg-3">
          <Col lg={{size : 8 , offset :0}} md={{size: 8, offset: 2 }}>
            <div className="public-profile-card p-0">
              <AllyusCardWrapper>
                <div className="allyus-cover-img">
                  <img
                    src={profile.cover_photo ? profile.cover_photo : "/images/bg1.jpg"}
                    alt="cover img"
                    className="cover"
                  />
                </div>
                <div className={`allyus-profile-img ${img_style}`}>
                  <img
                    src={getUserProfilePic(profile)}
                    onError={e => profilePicError(e)} 
                    alt="profile img"
                    className={`profile`}
                  />
                </div>
                <div className={"public-profile-nav allyus-tab-nav " + (profile.influencer ? "influencer-profile-nav" : profile.lobbyist ? "lobbyist-profile-nav" : "")}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        Connections
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "3"
                        })}
                        onClick={() => {
                          this.toggle("3");
                        }}
                      >
                        Representatives
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "4"
                        })}
                        onClick={() => {
                          this.toggle("4");
                        }}
                      >
                        Petitions
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <UserDetailCard
                    profile={profile}
                    toggleAnonymous={my_profile ? this.toggleAnonymous : null}
                    my_profile={my_profile}
                    userAdmin={currentUser.admin}
                    currentUser={currentUser}
                    followUser={this.props.followUser}
                    unfollowUser={this.props.unfollowUser}
                    logged_in={logged_in}
                    editUser={editUser}
                    openConnectionsTab={(connectionsTab)=>{this.setState({activeConnectionsTab:connectionsTab}); this.toggle("2"); }}
                  />
                </div>
              </AllyusCardWrapper>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12">
                      <UserActivityCard
                        userActivity={userActivity}
                        my_profile={my_profile}
                        userAdmin={currentUser.admin}
                        profile={profile}
                        setUserHistoryPrivacy={this.props.setUserHistoryPrivacy}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <UserConnectionsCard
                        connectionsList={connections}
                        repsList={representatives}
                        followersList={followers}
                        followingList={following}
                        my_profile={my_profile}
                        userAdmin={currentUser.admin}
                        first_name={profile.first_name}
                        currentUser={currentUser}
                        logged_in={logged_in}
                        followUser={this.props.followUser}
                        unfollowUser={this.props.unfollowUser}
                        activeConnectionsTab={this.state.activeConnectionsTab}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="3">
                  <Row>
                    <Col sm="12">
                      <UserRepresentativeCard
                        allList={representatives}
                        my_profile={my_profile}
                        userAdmin={currentUser.admin}
                        first_name={profile.first_name}
                        currentUser={currentUser}
                        followUser={this.props.followUser}
                        unfollowUser={this.props.unfollowUser}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="4">
                  <Row>
                    <Col sm="12">
                      <UserPetitionsCard petitions={petitions}  />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </div>
          </Col>
          <Col lg={{size : 4 , offset :0}}  md={{size: 8, offset: 2 }}>
            <UserAboutCard profile={profile} my_profile={my_profile} userAdmin={currentUser.admin} editUser={editUser} />
            <UserProfileInfoCard
              info={profile}
              my_profile={my_profile}
              userAdmin={currentUser.admin}
              followers={followers}
              following={following}
              editUser={editUser}
            />
          </Col>
        </Row>
      </Container>
    );

  };

  render() {
    const { userProfile } = this.props;
    if (!userProfile.loaded) {
      return <div className="loading-container">
        <img src="/images/stroke_logo_animated.svg" alt="loading..." />
      </div>; // TODO
    } else if (userProfile.loaded && userProfile.error) {
      // TODO
      return <div />
    }
    if (userProfile) {
      return this.renderProfileType();
    }
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    currentUser: state.currentUser,
    userProfile: state.userProfile,
  };
};

export default connect(
  mapStateToProps,
  { toggleAnonymous, toggleSignInModal, getCurrentUser, getProfile, followUser, unfollowUser, editUser, setUserHistoryPrivacy }
)(PublicProfile);
