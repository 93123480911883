import ACTIONS from './actionType';
import producer from 'immer';

const initialState = {
    authError: null,
    token: null,
    created: false
};

export default (state = initialState, action) => {
    return producer(state, draft => {
        switch (action.type) {

            case ACTIONS.SIGNIN_SUCCESS:
                console.log("SignIn success");
                draft.authError = '';
                draft.token = action.payload.token;
                break;

            case ACTIONS.SIGNIN_FAILED:
                console.log("SignIn Failed", action.payload.message);
                draft.authError = `Login failed ${action.payload.message}`;
                draft.token = null;
                break;
            case ACTIONS.SIGNUP_SUCCESS:
                console.log("SignUp success");
                draft.authError = '';
                draft.created = true;

                break;

            case ACTIONS.SIGNUP_FAILED:
                console.log("SignUp Failed", action.payload.message);
                draft.authError = `signup failed : ${action.payload.message}`;
                draft.created = false;

                break;

            case ACTIONS.SIGN_OUT:
                console.log('logout success');
                draft.authError = '';
                draft.token = null;
                break;
            case 'CLEAR_AUTH_ERR': 
                draft.authError = '';
                break;
            default:
                return state;
        }
    })

}