import React from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import { compose } from "redux";
import { Container } from "reactstrap";
import { Link, withRouter } from "react-router-dom";
import { NavLink as RRNavLink } from "react-router-dom";
import { connect } from "react-redux";
import {
  getCurrentUser,
  getNotifications,
  toggleReportBugModal,
  toggleTutorialModal,
} from "../../store/actions";
import CommonNav from "./common";

class MainNavbar extends React.Component {
  state = {
    isOpen: false,
  };

  componentDidMount() {
    this.props.getCurrentUser();
    if (this.props.auth.uid) {
      this.props.getNotifications();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.auth.uid !== this.props.auth.uid && this.props.auth.uid) {
      this.props.getNotifications();
    }
  }

  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { reportBugModal, tutorialModal } = this.props;
    return (
      <div className="allyus-main-nav" style={{ backgroundColor: "white" }}>
        <Container fluid>
          <Navbar color="light" light expand="md">
            <NavbarBrand href="/">
              <img src="/images/logo.png" alt="" />
              <p
                style={{
                  fontSize: "10px",
                  color: "#9e9e9e",
                  textAlign: "center",
                }}
              >
                {" "}
                beta{" "}
              </p>
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="mr-auto">
                <NavItem>
                  <NavLink
                    tag={RRNavLink}
                    to="/"
                    activeClassName="main-active"
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }
                      if (reportBugModal || tutorialModal) {
                        return false;
                      }
                      if (match.isExact) {
                        return true;
                      } else if (location.pathname.includes("/posts/")) {
                        return true;
                      }
                      return false;
                    }}
                  >
                    Feed
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    tag={RRNavLink}
                    to="/vision"
                    activeClassName="main-active"
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }
                      if (reportBugModal || tutorialModal) {
                        return false;
                      }
                      if (match.isExact) {
                        return true;
                      }
                    }}
                  >
                    About
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                <NavLink
                    tag={RRNavLink}
                    to="/petitions"
                    activeClassName="main-active"
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }
                      if (reportBugModal || tutorialModal){
                        return false;
                      }
                      if (match.isExact || location.pathname.includes('/petitions/')){
                        return true;
                      }
                    }}
                  >
                    Petitions
                  </NavLink>
                </NavItem> */}
                {/* <NavItem>
                  <div onClick={this.props.toggleTutorialModal}>
                    <NavLink style={{ color: "#ff7082" }}>Tutorial</NavLink>
                  </div>
                </NavItem> */}
                <NavItem>
                  <div onClick={this.props.toggleReportBugModal}>
                    <NavLink>Bug Report</NavLink>
                  </div>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ color: "#e8bc00" }}
                    tag={RRNavLink}
                    to="/covid-19"
                    activeClassName="main-active"
                    isActive={(match, location) => {
                      if (!match) {
                        return false;
                      }
                      if (reportBugModal || tutorialModal) {
                        return false;
                      }
                      if (match.isExact) {
                        return true;
                      }
                    }}
                  >
                    Covid-19
                  </NavLink>
                </NavItem>
                {/* <NavItem>
                  <a
                    class="bmc-button"
                    target="_blank"
                    href="https://www.buymeacoffee.com/allyus"
                  >
                    <img
                      src="https://cdn.buymeacoffee.com/buttons/bmc-new-btn-logo.svg"
                      alt="Buy me a coffee"
                    />
                    <span className="bmc-button-text">Buy me a coffee</span>
                  </a>
                </NavItem> */}
              </Nav>
              <CommonNav
                history={this.props.history}
                toggle={this.toggle.bind(this)}
              />
            </Collapse>
          </Navbar>
        </Container>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    currentUser: state.currentUser,
    reportBugModal: state.modals.report_bug_modal,
    tutorialModal: state.modals.tutorial_modal,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps, {
    getCurrentUser,
    getNotifications,
    toggleReportBugModal,
    toggleTutorialModal,
  })
)(MainNavbar);
