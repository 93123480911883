import React, { Component } from "react";
import { connect } from 'react-redux';
import { Container, Row, Col } from "reactstrap";
import { Helmet } from "react-helmet";
import ReactGA from 'react-ga';
import FormBuilder from 'react-form-builder2';
import DemoBar from './components/demobarpreview';
import 'react-form-builder2/dist/app.css';
import { Redirect } from "react-router-dom";
import * as firebase from "firebase/app";
import {_defaultItems} from './components/toolbaritems';
import config from '../../config';
const allyus_url = config.allyus_url;
// Form Data
const url = allyus_url +'/forms/covid/draft';
const saveUrl =  allyus_url + '/forms/covid/questions/autosave';
const publishUrl = allyus_url + '/forms/covid/questions/publish';

class EditBreakingNews extends Component {

  constructor(props) {
    super(props);
    this.state = {
      access_token: ''
    }
  }
  async componentDidMount(){
    // ReactGA.pageview(window.location.pathname + window.location.search);
    try {
        if (firebase.auth().currentUser){
          const access_token = await firebase.auth().currentUser.getIdToken(true);
          this.setState({access_token: access_token });
        }
  
      }
      catch (err) {
        console.log(err);
      }
  }
  
  render() {  
    const {currentUser} = this.props;
    const {access_token} = this.state;
    if (!currentUser || (currentUser && currentUser._id &&  !(currentUser.admin || currentUser.covid_admin)) || (currentUser && !currentUser._id)){
      return (
        <Redirect
            to={{
              pathname: "/",
            }}
         />
      );
    }
    else if (access_token && (currentUser.admin || currentUser.covid_admin)) {
      return (
        <Container style={{marginTop:25}} >
          <Helmet>
            <link rel="stylesheet" type="text/css" href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css" />
          </Helmet>
          <DemoBar publishUrl={`${publishUrl}?access_token=${access_token}`} />
          <FormBuilder.ReactFormBuilder
          saveUrl={`${saveUrl}?access_token=${access_token}`}
          url={`${url}?access_token=${access_token}`}
          toolbarItems={_defaultItems}
          />
        </Container>
      );
    }
    return <Container />
  }
}


const mapStateToProps = state => {
  return {
    currentUser: state.currentUser,
  };
};

export default connect(mapStateToProps, {})(EditBreakingNews)
