import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {  FormGroup, Label, Input } from 'reactstrap';
import Button from '../../button/index';
import tag_data from '../../../formdata/tags.json';
import { setPostTypeAction } from '../action';
import { getFeedPosts, editPost, getPoliProfile, getCurrentUser } from '../../../store/actions';
import { connect } from 'react-redux';
import PoliticianAutoComplete from '../politicianAutoComplete';
import  Player from 'react-player'
import ReactGA from "react-ga";

import _ from 'lodash';
import * as firebase from "firebase/app";
import "firebase/storage";

import axios from 'axios';
import config from '../../../config';
import { getUserProfilePic, profilePicError } from '../../../functions/profile';
import MicrolinkCard from '@microlink/react';

const uuidv4 = require('uuid/v4');
const { allyus_url } = config;

const getSuggestions = async value => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;
    const suggestions = await axios.get(`${allyus_url}/politicians/byname/?name=${inputValue}`);
    return inputLength === 0 ? [] : suggestions.data
  };

class CreatePostModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedTags: [],
            createPetition: false,
            petitionTitle: '',
            petitionText: '',
            petitionReciver: '',
            petitionRecieverId: '',
            petitionStance: '',
            petitionImg: '',
            postText: '',
            image_link: '',
            file: '',
            add_image: false,
            featured: false,
            editMode: false,
            postDescription:'',
            postVideo: '',    
            add_video: false,
            anon_comments_disabled: false,
            preview_image: '',
            preview_url: ''
        }
    
    }


    componentDidUpdate(prevProps, prevState){

        if (!prevProps.isOpen && this.props.isOpen && this.props.editingPost){
            this.setState(this.props.editingPost);
            ReactGA.modalview(window.location.pathname + '/editpost');
        }
        else if (!prevProps.isOpen &&  this.props.isOpen && !this.props.editingPost){
            ReactGA.modalview(window.location.pathname + '/createpost');
        }
        if (this.state.image_link && (prevState.image_link !== this.state.image_link)){
            this.updateMediaLink(this.state.image_link);
        }
        if (this.state.postVideo && (prevState.postVideo !== this.state.postVideo)){
            this.updateMediaLink(this.state.postVideo);
        }
    }

    handlePostType = (e) => {
        const type = e.target.name === '' ? e.target.parentElement.name : e.target.name;
        this.props.setPostTypeAction(type);
      }
    
      // TODO could add validation to backend
    validatePost() {
        const { postText, selectedTags, petitionImg, postVideo, createPetition,
            petitionTitle, petitionText, petitionReciver, petitionStance, image_link} = this.state;
        const { postType } = this.props;
    
            
        var includes_municipal = selectedTags.includes('Municipal');
        var includes_state = selectedTags.includes('State');
        var includes_federal = selectedTags.includes('Federal');
        var includes_international = selectedTags.includes('International');

        if (!postText){
            if (postType === 'poll') {
                alert('Please enter a question');
            } else if (postType === 'talk'){
                alert('Please enter a conversation starter');
            } else if (postType === 'news'){
                alert('Please enter a link');
            }
            return false;
        } else if  (selectedTags.length < 1){
            alert('Please choose at least one tag');
            return false;
        } else if ( !includes_municipal && !includes_state && !includes_federal && !includes_international ) {
            alert('Please choose one location tag');
            return false;
        } else if (
            (includes_municipal && (includes_state || includes_federal || includes_international)) || 
            (includes_state && (includes_municipal || includes_federal || includes_international)) || 
            (includes_federal && (includes_municipal || includes_state || includes_international)) || 
            (includes_international && (includes_municipal || includes_state || includes_federal)) 
            ) 
        {
            alert('Please choose exactly one location tag');
            return false;
        } else if (postType === 'poll' && !petitionImg && !postVideo && !image_link){
            alert('Please add an image or video');
            return false;
        } else if ((petitionImg && !petitionImg.includes('https:')) || (postVideo && !postVideo.includes('https:'))){
            alert('Media links must be secure (https)');
            return false;
        } else if (postType === 'poll' && createPetition && (!petitionTitle || !petitionText || !petitionStance || !petitionReciver)){
            alert('Please fill out petition fields');
            return false;
        } else {
            return true;
        }
    }

    

    async handleSubmitPost(){
        try {
            const access_token = await firebase.auth().currentUser.getIdToken(true);
            const { postText, selectedTags, petitionImg, postVideo, createPetition, 
                    petitionTitle, petitionText, petitionReciver, petitionRecieverId, petitionStance, featured, editMode, postDescription,
                  anon_comments_disabled, preview_image , preview_url, image_link} = this.state;
            if (!access_token){
                alert('Must be logged in to post!');
            } else if (this.validatePost()) {
                var { postType } = this.props;
                var post = {};
                
                let post_type ;
                if (postType === 'poll'){
                    post_type = 1;
                }
                else if (postType === 'news'){
                    post_type = 2;
                    postType = 'article';
                } else if (postType === 'talk') {
                    post_type = 3;
                    postType = 'discussion';
                }
                
                post[postType] = {};
                post[postType].anon_comments_disabled = anon_comments_disabled;

                post[postType].post_type = post_type;
                if (postType !== 'article'){
                    post[postType].title = postText;
                } else if (editMode && this.props.editingPost._id){
                    post[postType].title = postText;
                } else {
                    post[postType].link = postText;
                    post[postType].title = postDescription;
                }
                post[postType].tags = selectedTags;

                if (postType === 'poll'){
                    post[postType].image = preview_image; 
                    post[postType].video = postVideo;
                    post[postType].preview_image = preview_image;
                    post[postType].url = preview_url;
                    if (createPetition) {
                    var petition = {};
                        petition.title = petitionTitle; 
                        petition.pitch = petitionText;
                        petition.vote_side = petitionStance === 'yes_stance' ? "YES" : "NO";
                        petition.receiver = !petitionRecieverId ? petitionReciver : ''; 
                        petition.receiver_id = petitionRecieverId ? petitionRecieverId : null; 
                        post.petition = petition;
                    }
                }
                let resp;
                let new_post_id;
                if (editMode && this.props.editingPost._id){
                    var update = {};
                    update.updatedData = post[postType];
                    if (petition){
                        update.updatedPetition = petition;
                    }
                    resp = await axios.put(`${allyus_url}/posts/${this.props.editingPost._id}?access_token=${access_token}`, 
                            update
                        );
                        new_post_id = resp.data;
                        this.props.history.push(`/posts/${new_post_id}`);
                } else {
                    if (featured){
                        resp = await axios.post(`${allyus_url}/featured/?access_token=${access_token}`, 
                        post
                    );
                        new_post_id = resp.data;
                        this.props.history.push(`/posts/${new_post_id}`);
                    } else {
                        resp = await axios.post(`${allyus_url}/posts/${postType}?access_token=${access_token}`, 
                            post
                        );
                        new_post_id = resp.data;
                        this.props.history.push(`/posts/${new_post_id}`);
                    }
                }
               
                    this.props.toggle();
                    this.setState({
                        selectedTags: [],
                        createPetition: false,
                        petitionTitle: '',
                        petitionText: '',
                        petitionReciver: '',
                        petitionRecieverId: '',
                        petitionStance: '',
                        petitionImg: '',
                        postVideo: '',
                        postText: '',
                        postDescription: '',
                        preview_image: '',
                        preview_url: '',
                        image_link:'',
                    });
                    this.props.getFeedPosts();
                    this.props.getCurrentUser();
                   if (this.props.location.pathname.match(/\/politicians|\/organizations/) && this.props.politicianProfile.profile.nameUrl){
                    this.props.getPoliProfile(this.props.politicianProfile.profile.nameUrl);
                   }
             
            }
        } catch(err) {
            console.log(err);
        }
    }

    handlePostText = (post) => {
        this.setState({ postText: post });
      }

    handleSelectedTags = (item) => {
        if (this.state.selectedTags.includes(item)){
          var filtered = this.state.selectedTags.filter(function(value, index, arr){
            return value != item;
          });
          this.setState({selectedTags: filtered});
        } else if (this.state.selectedTags.length < 3){
          this.setState(prevState => (
            {
              selectedTags: [...prevState.selectedTags, item]
            }
          ));
        }
      }

    handlePoll = (e) => {
            this.setState(prevState => ({
                createPetition: !prevState.createPetition
            }))
    }
      
    handleChange = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    handlePetitionStance = (e) => {
        const stance = e.target.name === '' ? e.target.parentElement.name : e.target.name;
        this.setState({
         petitionStance: stance,
        })
    }

    updateMediaLink = async (url) => {
        try {
            const response = await axios.get(`https://api.linkpreview.net/?key=5df034c32a761b01586238b50366fa9b031a986cfa645&q=${url}`);
            this.setState({preview_image: response.data.image, preview_url: response.data.url});
            // console.log(response.data.image, response.data.url)
        }catch(err){
            console.log(err);
        }
    }

    onChangeFile = (e) => {
        const file = e.target.files[0];
        var file_name =  uuidv4();
        var storageRef = firebase.storage().ref();
        var ref = storageRef.child(`post_images/${file_name}`)
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({ petitionImg: downloadURL, preview_image: downloadURL, add_image:false, postVideo: '', image_link: '', preview_url:''});
          });
        }).catch(err => {
          console.log(err);
        });
    }
    handleFileChange = (e) => {
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            this.setState({
                petitionImg: reader.result,
                file: file,
            })
        }
        reader.readAsDataURL(file)
       
    }
    
    renderPetitionImg(e) {
        if (this.state.preview_image){
        return (
            <div className="petition-img img-container">
                <div className="img-remove-wrapper" >
                    <span onClick={() => this.setState({
                            petitionImg: '', preview_image: '',  preview_url: '', image_link: '',  postVideo: ''
                        })}  
                    aria-label="close" > &#10060; </span>
                </div>
                <img src={this.state.preview_image} alt="post image" />
            </div>
        );
        }
        // return (
        //     <ModalBody>
        //         {/* <div style={{display:'flex', justifyContent:'space-between'}}>
        //         Link
        //         <div className="img-remove-wrapper" >
        //             <span onClick={() => this.setState({
        //                     petitionImg: '', preview_url: '', preview_image: '',
        //                 })}  
        //             aria-label="close" > &#10060; </span>
        //         </div>
        //         </div>
        //         <textarea className="petition-img img-container"
        //         style={{height:38, width:'100%', border: 'none', borderLeft:'none', borderRight: 'none'}}
        //         placeholder="Link"
        //         value={this.state.preview_url ? this.state.preview_url  : this.state.petitionImg}
        //         disabled
        //         /> */}
      
        //     </ModalBody>
        // );
        return(
            <ModalBody>
                <div style={{display:'flex', justifyContent:'flex-end'}}>
                    <span onClick={() => this.setState({
                            petitionImg: '', preview_url: '', preview_image: '', image_link: '', postVideo: ''
                        })}  
                    aria-label="close" > &#10060; </span>
                </div>
                <MicrolinkCard url={this.state.preview_url ? this.state.preview_url  : this.state.petitionImg} className="post-media"  
                />
            </ModalBody>
        );

    }
    renderVideo() {
        return(
            <div className="img-container">
                <div className="img-remove-wrapper" >
                        <span onClick={()=> this.setState({postVideo:'', preview_image: '', preview_url: '', petitionImg: '', image_link: ''})} 
                         aria-label="close" > &#10060; </span>
                </div>
                <Player width={'100%'} controls
                    url={this.state.postVideo} >
                </Player>
               
            </div>
            );
    }
    renderCreatePoll(){
        const {postVideo, petitionImg, image_link} = this.state;
        const {currentUser} = this.props;
        if (postVideo || petitionImg || image_link){
            return(
                <div className="petition-footer">
                    {
                    (currentUser.admin || (currentUser.__t === 'politician' && currentUser.user_politician)) ?
                    
                    <FormGroup check style={{padding:'1.2rem 2rem'}}>
                        <Label check>
                        <Input type="checkbox" 
                            checked={this.state.anon_comments_disabled}
                            onClick={() => this.setState({anon_comments_disabled:!this.state.anon_comments_disabled})}
                        />{' '}
                        Disable Anonymous Comments
                        </Label>
                    </FormGroup>
                    : null
                    }
                    <Button className={`allyus-primary-btn`} 
                    click={() => this.handleSubmitPost()}>{ this.state.editMode ? "Save" : "Create"} {this.props.postType}</Button>
                </div>
            );
        }
    }
    renderImageInput() {
        return (
            <Modal isOpen={this.state.add_image} toggle={()=> this.setState({add_image: !this.state.add_image})} className="post-modal-dialog" backdrop={true}>
                <div className="allyus-form">
                <div className=" create-petition " style={{backgroundColor:'white'}}>
                <button onClick={()=> this.setState({add_image: !this.state.add_image})} className="close">
                    <span className="close" >x</span>
                </button>
                <FormGroup className="allyus-form-group" style={{marginRight:'10%'}}>
                    <Label for="imageurl">Paste Direct Image Link</Label>
                    <Input
                        onChange={(e) => this.setState({petitionImg :e.target.value, preview_image: e.target.value, add_image:false, 
                            postVideo:'', image_link: '', preview_url:e.target.value})}
                        value={this.state.petitionImg}
                        // placeholder="Image Link"
                        style={{borderTop:'none', borderLeft:'none', borderRight:'none'}}
                        />
                </FormGroup>
                <FormGroup className="allyus-form-group" style={{marginRight:'10%'}}>
                    <Label for="imageurl">Or Paste Indirect Image Link (ie article link if image is primary image in article)</Label>
                    <Input
                        onChange={(e) => this.setState({image_link :e.target.value, add_image:false, postVideo:'', petitionImg: '', preview_image:'', preview_url: ''})}
                        value={this.state.image_link}
                        // placeholder="Image Link"
                        style={{borderTop:'none', borderLeft:'none', borderRight:'none'}}
                        />
                </FormGroup>
                <FormGroup className="allyus-form-group">
                 <Label for="imageurl">Or Choose File</Label>
                 </FormGroup>
                    <input
                    type="file"
                    onChange={this.onChangeFile}
                    // name="image_link"
                    // value={value}
                    />
                
                </div>
                 
                </div>
            </Modal>
        );
    }

    renderVideoInput() {
        return (
            <Modal isOpen={this.state.add_video} toggle={()=> this.setState({add_video: !this.state.add_video})} className="post-modal-dialog" backdrop={true}>
                <div className="allyus-form">
                <div className=" create-petition " style={{backgroundColor:'white'}}>
                <button onClick={()=> this.setState({add_video: !this.state.add_video})} className="close">
                    <span className="close" >x</span>
                </button>
                <FormGroup className="allyus-form-group" style={{marginRight:'10%'}}>
                    <Label for="imageurl">Paste Video Link</Label>
                    <Input
                        onChange={(e) => this.setState({postVideo :e.target.value, petitionImg: '', image_link: '', preview_url:'', preview_image: '', add_video:false})}
                        value={this.state.postVideo}
                        // placeholder="Image Link"
                        style={{borderTop:'none', borderLeft:'none', borderRight:'none'}}
                        />
                </FormGroup>
                </div>
              </div>
            </Modal>
        );
    }

    renderCreatePetition = () => {
        const { currentUser } = this.props;
        return (
            <div className="allyus-form">
                <div className=" create-petition ">
                    <FormGroup className="allyus-form-group">
                        <Label for="exampleEmail">petition title</Label>
                        <Input
                            onChange={this.handleChange}
                            name="petitionTitle"
                            value={this.state.petitionTitle} />
                    </FormGroup>
                    <FormGroup className="allyus-form-group">
                        <Label for="exampleEmail">petition reciever</Label>
                        <PoliticianAutoComplete 
                            setAutoCompleteId={(id) => this.setState({petitionRecieverId: id})} 
                            setAutoCompleteName={(name) => this.setState({petitionReciver: name})}
                            placeholder="e.g Local Politician"
                            getSuggestions={getSuggestions}
                            editValue={this.state.petitionReceiverName ? this.state.petitionReceiverName 
                                : this.state.petitionReciver ? this.state.petitionReciver : ''}
                            />
                    </FormGroup>
                    <FormGroup className="allyus-form-group">
                        <Label for="exampleEmail">create a pitch</Label>
                        <Input
                            type="textarea"
                            onChange={this.handleChange}
                            name="petitionText"
                            value={this.state.petitionText} />
                    </FormGroup>
                    <div className="petition-stance">
                        <h3 className="allyus-card-sub-heading">Petition Stance</h3>
                        <p>
                            <Button
                                name="yes_stance"
                                className={`${this.state.petitionStance === 'yes_stance' ? 'active' : ''}`}
                                click={(e) => this.handlePetitionStance(e)}
                                width="100" > Agree </Button>{`   `}
                            <Button
                                name="no_stance"
                                className={`${this.state.petitionStance === 'no_stance' ? 'active' : ''}`}
                                click={(e) => this.handlePetitionStance(e)}
                                width="100" > Disagree </Button>
                        </p>
                    </div>
                </div>
                <div className="px-5 py-4">
                    {
                        (currentUser.admin || (currentUser.__t === 'politician' && currentUser.user_politician)) ?
                    <FormGroup check style={{paddingBottom:'1.5rem'}}>
                        <Label check>
                        <Input type="checkbox" 
                            checked={this.state.anon_comments_disabled}
                            onClick={() => this.setState({anon_comments_disabled:!this.state.anon_comments_disabled})}
                        />{' '}
                        Disable Anonymous Comments
                        </Label>
                    </FormGroup>
                    : null }
                    <Button className={`allyus-primary-btn`} click={() => this.handleSubmitPost()}> {this.state.editMode ? "Save Petition" : "Create Petition"}</Button>
                </div>
            </div>
        );
    }
    render() {
        const tags = tag_data.tags;
        const { postType, currentUser } = this.props;
        const { createPetition, petitionImg, postText, featured, postDescription, editMode, postVideo, image_link } = this.state;
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className="post-modal-dialog" backdrop={true}>
                <ModalHeader toggle={this.props.toggle}>
                    <div>
                        <img src={getUserProfilePic(currentUser)} onError={e => profilePicError(e)} alt="profile-img" className="user-img" />
                        <img src="/images/arrow-down.png" alt="down-arrow" />
                    </div>
                    <textarea
                        // type="textarea"
                        style={{height:38, width:'78%', border: 'none'}}
                        value={postText}
                        onChange={(e) => this.handlePostText(e.target.value)}
                        placeholder={postType === 'poll' ? "Ask a question... " : postType === 'talk' ? "Start a conversation..." : "Share an article (link)..."} 
                        />
                </ModalHeader>
        
                <div className="modal-btn-group">
                {
                    this.state.editMode ? null :
                    <div className="group1 ">
                        <Button
                            click={this.handlePostType}
                            name="poll"
                            className={`${postType === 'poll' ? 'active' : ''}`}
                        >
                            <img src="/icons/new/poll-icon.svg" alt="post modal" /> &nbsp; poll
                        </Button>
                        <Button
                            click={this.handlePostType}
                            name="news"
                            className={`${postType === 'news' ? 'active' : ''}`}
                        >
                            <img src="/icons/new/news-icon.svg" alt="post" /> &nbsp;  news
                            </Button>
                        <Button
                            click={this.handlePostType}
                            name="talk"
                            className={`${postType === 'talk' ? 'active' : ''}`}
                        >
                            <img src="/icons/new/messaging-icon.svg" alt="post" /> &nbsp; talk
                        </Button>
                     </div>
                    }
                    {
                        !currentUser.admin ? null :
                        <FormGroup check>
                            <Label check>
                                <Input 
                                type="checkbox" 
                                value={featured}
                                checked={featured}
                                onClick={() => this.setState({featured:!featured})}
                                />
                                Featured Poll
                            </Label>
                        </FormGroup>
                    }
                    </div>

                <ModalBody className="post-modal-body">
                    <p className="allyus-text">Select up to three tags</p>
                    <div className="tag-group">
                        {
                            tags.map((t, i) => {
                                var selected = this.state.selectedTags.includes(t);
                                let icon = selected ? <i className="fa fa-check"></i> : '+';
                                return (
                                    <p className={`tags mr-2 ${selected? "active" : ""}`} key={i}>
                                        <span
                                            style={{cursor:'pointer'}}
                                            onClick={(e) => this.handleSelectedTags(e.target.innerHTML)}
                                            key={i}
                                        >
                                            {t}
                                        </span>
                                        &nbsp;
                                    <span>{icon}</span>
                                    </p>

                                )
                            }
                            )
                        }
                    </div>

                </ModalBody>
                { postType !== 'news' || editMode ? null :
                    <ModalBody>
                        <textarea
                            style={{height:38, width:'100%', border: 'none', borderLeft:'none', borderRight: 'none'}}
                            placeholder="description (optional)"
                            value={postDescription}
                            onChange={(e)=>this.setState({postDescription: e.target.value})}
                            />
                     </ModalBody>
                    }
                <ModalFooter>
                
                    {this.props.postType !== 'poll' ? null :
                    <span style={{cursor:'pointer'}} onClick={e => this.setState({add_image: true})}>
                    <p
                        className="add-img" style={{marginTop:8}}>
                        <img src="/icons/new/image-icon.svg" alt="add image" /> &nbsp;&nbsp;
                       
                        <label  className="allyus-text">Add Image</label> 
                  
                    </p>
                    </span>
                    }
                    {this.props.postType !== 'poll' ? null :
                    <span style={{cursor:'pointer'}} onClick={e => this.setState({add_video: true})}>
                    <p
                        className="add-img" style={{marginTop:8,}}>
                        <img src="/icons/icons8-video-24.png" alt="add video" style={{width:20, height:20}}/> &nbsp;&nbsp;
                        <label  className="allyus-text">Add Video</label> 
                    </p>
                    </span>
                    }
                   
                    { this.props.postType === 'poll' && !this.state.editMode
                      ? (
                          <p onClick={this.handlePoll}
                              className="">
                              <img src="/icons/new/petition-icon.svg" alt="post" />&nbsp;&nbsp;
                              <span className="allyus-text" id="petition">{"Create Petition"}</span>
                          </p>
                        )
                      : null
                    }
                     { ((this.props.postType !== 'poll') && (currentUser.admin || (currentUser.__t === 'politician' && currentUser.user_politician))) ? 
                    <FormGroup check>
                        <Label check>
                        <Input type="checkbox" 
                            checked={this.state.anon_comments_disabled}
                            onClick={() => this.setState({anon_comments_disabled:!this.state.anon_comments_disabled})}
                        />{' '}
                        Disable Anonymous Comments
                        </Label>
                    </FormGroup>
                    : null
                     }
                    <Button
                        className={`allyus-primary-btn ml-auto ${postType === 'poll' ? 'hide' : 'show'}`}
                        click={() => this.handleSubmitPost()} width="100"> {this.state.editMode ? "Save" : "Post"}
                    </Button>
                </ModalFooter>
     
                <div>
                    {(petitionImg || image_link) && postType === 'poll' ? 
                    this.renderPetitionImg() :
                    postVideo && postType === 'poll' ?
                    this.renderVideo() :
                     null}
                    {this.props.postType === 'poll' && !createPetition ? this.renderCreatePoll() : null}
                    {this.props.postType === 'poll' && createPetition ? this.renderCreatePetition() : null}

                </div>
                {this.renderImageInput()}
                {this.renderVideoInput()}
            </Modal>
        )
    }

}

const mapStateToProps = state => {
    return {
        currentUser: state.currentUser,
        postType : state.post.postType,
        editingPost : state.editingPost,
        politicianProfile: state.politicianProfile,
    }
  }

  export default connect(mapStateToProps, { setPostTypeAction, getFeedPosts, getPoliProfile, getCurrentUser })(CreatePostModal);
