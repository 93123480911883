import React from "react";
import {
  Nav,
  NavItem,
} from "reactstrap";
import { compose } from "redux";
import Button from "../button/index";
import Autocomplete from "../autoComplete/index";
import { withRouter } from "react-router-dom";
import {
  getCurrentUser,
  toggleSignInModal,
  toggleCreatePostModal,
  toggleInviteModal,
  logOutUser,
  getNotifications,
  clickNotification,
  joinRoom,
  signOut,
  setBadgeClicked
} from "../../store/actions";
import {
  ACtextAction,
  getSuggestionsAction,
  search
} from "../autoComplete/actions";
import { connect } from "react-redux";
import { showChatWindow, hideChatWindow } from "../allyusChatWindow/actions";
import * as firebase from "firebase/app";

import NavAvatar from "./sections/navAvatar";
import NotificationsSection from "./sections/notifications";
// import MessagesSection from "./sections/messages";

class MainNavbar extends React.Component {
  state = {
    autoCompleteSuggestion: [],
    search: ''
  };

  clickIcon = type => {
    if (!firebase.auth().currentUser) {
      this.props.toggleSignInModal();
    } else if (type && type === "notifications") {
      this.props.setBadgeClicked();
      if (this.props.mobileNav){
        this.props.history.push("/notifications");
      }
    } else if (type && type === "messages" && this.props.mobileNav) {
      this.props.history.push("/messages");
    }
  };

  handleSignOut = () => {
    this.props.signOut();
    this.props.history.push("/");
  };

  onChangeText = e => {
    var val = e.target.value;
    this.setState({search: val});
    // setTimeout(() => {
      this.props.search(val);
      
  // }, 500);
    // this.props.ACtextAction(val);
    // this.props.getSuggestionsAction(val);
  };
  onKeyDown = e => {
    if (e.keyCode === 13) {
      this.props.history.push('/search');
    }
  };

  render() {
    const { currentUser, auth, mobileNav } = this.props;
    const { search } = this.state;
    const logged_in = auth.uid ? true : false;

    return (
      <Nav className={!mobileNav ? "ml-auto" : "primary-nav"}>
        <NavItem>
          {/* <Autocomplete
            //search
            onChangeText={val => this.onChangeText(val)}
            suggestions={this.props.suggestions}
            search={this.props.search}
            history={this.props.history}
          /> */}
            <div className={`auto-complete`}>
              <div className="input-group">
                <input type="text"
                  className="form-control"
                  value={search}
                  onChange={this.onChangeText}
                  onKeyDown={this.onKeyDown}
                  placeholder="Search"
                />

                <div className="input-group-append">
                  <button className="search-btn" type="button">
                    <img src="/icons/new/search-icon.svg" alt=""/>
                  </button>
                </div>
              </div>
            </div>
        </NavItem>
{/* 
        <MessagesSection
          logged_in={logged_in}
          clickIcon={this.clickIcon.bind(this)}
          {...this.props}
        /> */}
        <NotificationsSection
          logged_in={logged_in}
          clickIcon={this.clickIcon.bind(this)}
          {...this.props}
        />

        <NavItem className="create-post-btn">
          <Button
            className="allyus-btn allyus-primary-btn"
            click={
              logged_in
                ? this.props.toggleCreatePostModal
                : this.props.toggleSignInModal
            }
          >
            {" "}
            Post{" "}
          </Button>
        </NavItem>

        <NavAvatar
          logged_in={logged_in}
          handleSignOut={this.handleSignOut.bind(this)}
          currentUser={currentUser}
          toggleSignInModal={this.props.toggleSignInModal}
          toggleInviteModal={this.props.toggleInviteModal}
        />
      </Nav>
    );
  }
}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    currentUser: state.currentUser,
    suggestions: state.autoComplete.suggestions,
    notificationsInfo: state.notifications,
    chatkit: state.chatkit
  };
};

export default compose(
  withRouter,
  connect(
    mapStateToProps,
    {
      signOut,
      getCurrentUser,
      toggleSignInModal,
      toggleCreatePostModal,
      toggleInviteModal,
      logOutUser,
      showChatWindow,
      hideChatWindow,
      ACtextAction,
      getSuggestionsAction,
      getNotifications,
      clickNotification,
      search,
      joinRoom,
      setBadgeClicked
    }
  )
)(MainNavbar);
