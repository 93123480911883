import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';
import feedReducer from './feeds/reducer';
import selectorsReducer from './feeds/selectorsReducer';
import petitionsReducer from './petitions/reducer';
import petitionsSelectorsReducer from './petitions/selectorsReducer';
import { firebaseReducer } from 'react-redux-firebase';
import authReducer from './auth/reducer'; // for demo lets just do fake authentication because internet connection might not be good
import currentUserReducer from './currentUser/reducer';
import modalsReducer from './modals/reducer';
import chatReducer from '../components/allyusChatWindow/reducer';
import postReducer from '../components/Post/reducer';
import tabsReducer from '../store/tabs/reducer';
import userProfileReducer from './voterProfile/reducer';
import politicianProfileReducer from './politicianProfile/reducer';
import autoCompleteReducer from '../components/autoComplete/reducer';
import notificationsReducer from './notifications/reducer';
import chatkitReducer from './chatkitUser/reducer';
import shareReducer from './share/reducer';
import editingPostReducer from './posts/reducer';
import invitesReducer from './invites/reducer';


const appReducer = combineReducers({
    feed : feedReducer,
    feedFilters: selectorsReducer,
    petitions : petitionsReducer,
    petitionFilters: petitionsSelectorsReducer,
    auth : authReducer,
    firebase : firebaseReducer , 
    currentUser : currentUserReducer ,
    modals : modalsReducer ,
    chat : chatReducer,
    post : postReducer,
    tabs : tabsReducer,
    userProfile: userProfileReducer,
    politicianProfile: politicianProfileReducer,
    autoComplete : autoCompleteReducer,
    notifications: notificationsReducer,
    chatkit: chatkitReducer,
    share: shareReducer,
    editingPost: editingPostReducer,
    invitesReducer: invitesReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'SIGNOUT') {
        storage.removeItem('persist:root')
        state = undefined;
    }
    return appReducer(state, action);
};

export default rootReducer;
