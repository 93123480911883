import React, { Component } from "react";
import { Container } from "reactstrap";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import FormBuilder from "react-form-builder2";
import DemoBar from "./components/demobar";

import "react-form-builder2/dist/app.css";

import config from "../../config";
const allyus_url = config.allyus_url;
// Form Data
const url = `${allyus_url}/forms/covid/`;
const answersUrl = `${allyus_url}/forms/covid/answers`;

export default class BreakingNews extends Component {
  componentDidMount() {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    const map_data = this.props.covidMapData;
    return (
      <div style={{ marginTop: 25, marginBottom: 75 }}>
        <Helmet>
          <link
            rel="stylesheet"
            type="text/css"
            href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <meta
            property="og:title"
            content="Allyus - Make your voice heard. Own your democracy."
          />
          <meta
            property="og:description"
            content="Allyus is a location based political social network which empowers people to change government policy."
          />
          {/* <meta property="og:url" content={`${window.location.origin}`} /> */}
          <meta name="twitter:image" content="/allyuslogo.png" />
          <meta property="og:image" content="/allyuslogo.png" />
          <meta property="og:image:width" content="600" />
          <meta property="og:image:height" content="315" />
        </Helmet>
        <DemoBar answersUrl={answersUrl} />
        <div style={{ display: "none" }}>
          <FormBuilder.ReactFormBuilder url={url} />
        </div>
        <div style={{ height: 50 }}></div>
        <div className="scrolldown">
          <p
            style={{
              writingMode: "vertical-lr",
              textTransform: "uppercase",
              color: "black",
              fontSize: "0.9rem",
              fontWeight: "600",
              letterSpacing: "0.1em",
              margin: 0,
              padding: 0,
              display: "flex",
            }}
          >
            Scroll &nbsp;<i class="fas fa-arrow-down"></i>{" "}
          </p>
        </div>
        <div className="dashboard-container dashboard-lg">
          <div class="embed-container">
            <iframe
              width="500"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              title="Where are Coronavirus COVID-19 active cases, total cases, and deaths, per Johns Hopkins University, Potential Cases per Allyus?"
              // src="//allyus.maps.arcgis.com/apps/Embed/index.html?webmap=0ad0d208054b412fb601b43880b20e27&extent=-168.8481,-41.1029,58.0854,67.547&zoom=true&previewImage=true&scale=true&search=true&searchextent=true&legend=true&disable_scroll=true&theme=dark"
              src="https://allyus.maps.arcgis.com/apps/opsdashboard/index.html#/e20117d002c04dc3ad77a3699b4b46a1"
            ></iframe>
          </div>
        </div>
        <div className="dashboard-container dashboard-sm">
          <div class="embed-container">
            <iframe
              width="500"
              height="400"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              title="Where are Coronavirus COVID-19 active cases, total cases, and deaths, per Johns Hopkins University, Potential Cases per Allyus?"
              // src="//allyus.maps.arcgis.com/apps/Embed/index.html?webmap=0ad0d208054b412fb601b43880b20e27&extent=-168.8481,-41.1029,58.0854,67.547&zoom=true&previewImage=true&scale=true&search=true&searchextent=true&legend=true&disable_scroll=true&theme=dark"
              src="https://allyus.maps.arcgis.com/apps/opsdashboard/index.html#/b3020a465d224df3892480cbb775658a"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}
