import { ChatManager, TokenProvider } from "@pusher/chatkit-client";
import axios from "axios";
import _ from "lodash";
import * as firebase from "firebase/app";
import { getOtherUser } from "../../Pages/messages/functions";
import config from "../../config";
const { allyus_url, chatkit_instance_locator } = config;

export const initializeChatKit = () => async (
  dispatch,
  getState
) => {
  try {
    
    const user = getState().currentUser;
    if (firebase.auth().currentUser && user) {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      let userId = user.anonymous ? user.uuid : user._id;
      const chatManager = new ChatManager({
        instanceLocator: chatkit_instance_locator,
        userId: userId,
        tokenProvider: new TokenProvider({
          url: `${allyus_url}/chat/auth`,
          queryParams: {
            access_token: access_token
          }
        })
      });
      const currentUser = await chatManager.connect();
      // dispatch({
      //   type: "FETCH_CHATKIT_USER",
      //   currentUser: currentUser
      // });
      let room_msgs = {};
      var rooms = await Promise.all(
        currentUser.rooms.map(room =>
          currentUser.subscribeToRoom({
            roomId: room.id,
            hooks: {
              onMessage: message => {
                if (!room_msgs[room.id]) {
                  room_msgs[room.id] = [];
                }
                room_msgs[room.id].push(message);
                dispatch({
                  type: "NEW_MSG",
                  message: message
                });
              }
            },
            messageLimit: 50 
          })
        )
      );
      rooms = _.filter(rooms, function(o) {
        return o.lastMessageAt;
      });
      if (user.__t === "politician") {
        // if have multiple blasts because of limit 500 users per room, only display one
        var blaststr = user._id + "blast";
        var fisrtblastId = user._id + "blast1";
        rooms = _.filter(rooms, function(o) {
          return !o.id.includes(blaststr) || o.id === fisrtblastId;
        });
      }
      rooms = rooms.sort(
        (a, b) => new Date(b.lastMessageAt) - new Date(a.lastMessageAt)
      );
      dispatch({
        type: "FETCH_ROOMS",
        rooms: rooms,
        room_msgs: room_msgs,
        currentUser: currentUser
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const sendMessage = (roomId, text) => (dispatch, getState) => {
  const { chatKitUser, currentRoom } = getState().chatkit;
  if (chatKitUser) {
    if (
      currentRoom &&
      currentRoom.customData &&
      currentRoom.customData.politicianblast &&
      chatKitUser.id === currentRoom.customData.politicianId
    ) {
      dispatch(sendMessageBlast(text));
    } else {
      chatKitUser
        .sendSimpleMessage({
          roomId: roomId,
          text: text
        })
        .then(messageId => {
          dispatch(setReadCursor(roomId, messageId));
          console.log(`Added message to ${roomId}`);
        })
        .catch(err => {
          console.log(`Error adding message to ${roomId}: ${err}`);
        });
    }
  }
};

export const sendMessageBlast = text => async (
  dispatch,
  getState
) => {
  
  const { rooms, chatKitUser } = getState().chatkit;
  const roomId = chatKitUser.id + "blast1";
  const access_token = await firebase.auth().currentUser.getIdToken(true);
  await axios.post(`${allyus_url}/chat/blastmsg?access_token=${access_token}`, {
    text: text
  });
  var room =
    rooms[
      _.findIndex(rooms, function(o) {
        return o.id === roomId;
      })
    ];
  dispatch(joinRoom(room));
  // TODO set read cursor
  // need to get msg ids back
};

export const setReadCursor = (roomId, messageId) => (
  dispatch,
  getState
) => {
  const { chatkit } = getState();
  const { chatKitUser } = chatkit;
  chatKitUser
    .setReadCursor({
      roomId: roomId,
      position: messageId
    })
    .then(() => {
      // console.log('Success!')
    })
    .catch(err => {
      console.log(`Error setting cursor: ${err}`);
    });
};

export const joinRoom = (room, messageId1 ) => (dispatch, getState) => {
  if (room) {
    const roomId = room.id;
    const chatkit = getState().chatkit;
    const { room_msgs, chatKitUser } = chatkit;
    const currentUserId = chatKitUser.id;

    let other_user = getOtherUser(room, currentUserId);
    const messageId = messageId1 ? messageId1 :
      room_msgs[roomId] && room_msgs[roomId].length > 0
        ? room_msgs[roomId][room_msgs[roomId].length - 1]["id"]
        : "";
    dispatch({
      type: "SET_ROOM",
      roomId: roomId,
      currentRoom: room,
      otherUser: other_user
    });
    if (messageId) {
      dispatch(setReadCursor(roomId, messageId));
    }
  } else {
    dispatch({
      type: "SET_ROOM",
      roomId: '',
      currentRoom: '',
      otherUser: ''
    });
  }
};

export const startConvo = (otherId, text) => (dispatch, getState) => {
  const chatKitUser = getState().chatkit.chatKitUser;
  const userId = chatKitUser.id;
  if (otherId !== userId) {
    const exists = chatKitUser.rooms.find(
      x => x.id === otherId + userId || x.id === userId + otherId
    );
    if (exists) {
      dispatch(joinRoom(exists));
      dispatch(sendMessage(exists.id, text));
    } else {
      dispatch(createRoom(otherId, text));
    }
  }
};

export const createRoom = (otherId, text) => async (
  dispatch,
  getState
) => {
  const chatKitUser = getState().chatkit.chatKitUser;
  
  try {
    if (chatKitUser) {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      var newchat = { otherId: otherId, text: text };
      const convo_data = await axios.post(
        `${allyus_url}/chat/newchat?access_token=${access_token}`,
        { newchat: newchat }
      );
      const { room, msgId } = convo_data.data;
      dispatch(initializeChatKit());
      dispatch(joinRoom(room, msgId));
    }
  } catch (err) {
    alert("Error creating chat");
  }
};
