import React, { Component } from "react";
import {
  Container,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Row,
  Col
} from "reactstrap";
import classnames from "classnames";

import UserDetailCard from "../../components/voterProfile/userDetailsCard/index";
import UserActivityCard from "../../components/voterProfile/userActivityCard/index";
import UserAboutCard from "../../components/voterProfile/userAboutCard/index";
import UserProfileInfoCard from "../../components/voterProfile/userProfileInfoCard/index";
import { connect } from "react-redux";
import { toggleAnonymous, getCurrentUser, followUser, unfollowUser, editUser, getAnonymousProfile } from "../../store/actions";
import { Helmet } from "react-helmet";
import { getUserProfilePic, getProfileUrlCurrentUser, profilePicError } from "../../functions/profile";
import UserConnectionsCard from "../../components/voterProfile/userConnectionsCard/index";


class PublicProfileAnonymous extends Component {
  state = {
    activeTab: "1",
    modalDetail: false,
    modalAbout: false,
    activeConnectionsTab: "2",
  };

  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };

  componentDidMount() {
    this.props.getAnonymousProfile(this.props.match.params.username);
  }

  componentDidUpdate(prevProps) {
    if (this.props.match.params.username !== prevProps.match.params.username) {
      this.props.getAnonymousProfile(this.props.match.params.username);
    }
    if (prevProps.currentUser !== this.props.currentUser) {
      this.props.getAnonymousProfile(this.props.match.params.username);
    }
  }


    toggleAnonymous = () => {
    this.props.toggleAnonymous();
    if (this.props.currentUser.anonymous){
      this.props.history.push(getProfileUrlCurrentUser(this.props.currentUser, true));
    }
  };


  
  renderProfileType = () => {
    const { currentUser } = this.props;
    const { profile } = this.props.userProfile;  
    const { activeTab } = this.state;
    var logged_in = this.props.auth.uid ? true : false;
    var my_profile = currentUser.username === profile.username;
    const item = profile;
    const img_style = item.influencer ? 'profile-img-influencer' : item.lobbyist ? 'profile-img-lobbyist' : item.user_politician ? 'profile-img-politician'
    : item.organization ? 'profile-img-organization' : 'profile-img-voter';
      return (
        <Container fluid>
          <Helmet>
            <meta property="og:image" content="/allyuslogo.png" />
            <meta property="og:image:width" content="600" />
            <meta property="og:image:height" content="315" />
          </Helmet>
          <Row className="mt-0 mt-lg-3">
            <Col lg={{size : 8 , offset :0}} md={{size: 8, offset: 2 }}>
              <div className="private-profile-card allyus-card p-0">
                <div className="allyus-cover-img">
                  <img
                    src={profile.anon_cover_photo ? profile.anon_cover_photo : "/images/bg2.jpg"}
                    alt="cover img"
                    className="cover"
                  />
                </div>
                <div  className={`allyus-profile-img ${img_style}`}>
                  <img
                    src={getUserProfilePic(profile)}
                    onError={e => profilePicError(e)} 
                    alt="profile img"
                    className="profile"
                  />
                </div>
                {!my_profile ? null :
                <div className={"public-profile-nav allyus-tab-nav show-xl" + (profile.influencer ? "influencer-profile-nav" : profile.lobbyist ? "lobbyist-profile-nav" : "")}>
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "1"
                        })}
                        onClick={() => {
                          this.toggle("1");
                        }}
                      >
                        About
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: this.state.activeTab === "2"
                        })}
                        onClick={() => {
                          this.toggle("2");
                        }}
                      >
                        Connections
                      </NavLink>
                    </NavItem>
                  </Nav>
                </div>
                }
                <div className="private-profile-card-body">
                  {my_profile ? null : <hr className="nav-divider mt-5" />}
                  <UserDetailCard
                    profile={profile}
                    toggleAnonymous={my_profile ? this.toggleAnonymous : null}
                    my_profile={my_profile}
                    userAdmin={currentUser.admin}
                    currentUser={this.props.currentUser}
                    followUser={this.props.followUser}
                    unfollowUser={this.props.unfollowUser}
                    editUser={this.props.editUser}
                    history={this.props.history}
                    openConnectionsTab={(connectionsTab)=> {
                      if (my_profile) {
                      this.setState({activeConnectionsTab:connectionsTab, activeTab:"2"});
                      }
                     }}

                  />
                </div>
              </div>
            {
               my_profile && activeTab === "2"  ?
              <UserConnectionsCard
              connectionsList={[]}
              repsList={[]}
              followersList={currentUser.anon_profile_followers}
              followingList={currentUser.anon_profile_following}
              my_profile={my_profile}
              userAdmin={currentUser.admin}
              first_name={currentUser.username}
              currentUser={currentUser}
              logged_in={logged_in}
              followUser={this.props.followUser}
              unfollowUser={this.props.unfollowUser}
              activeConnectionsTab={this.state.activeConnectionsTab}
              anon_connections={true}
            />
            :  
            <UserActivityCard
              userActivity={my_profile ? this.props.userProfile.userActivity : []}
              profile={profile}
              my_profile={my_profile}
              userAdmin={currentUser.admin}
            /> 
            }
            </Col>
            <Col lg={{size : 4 , offset :0}}  md={{size: 8, offset: 2 }}>
              <UserAboutCard
                profile={profile}
                my_profile={my_profile}
                userAdmin={currentUser.admin}
              />
              <UserProfileInfoCard
                info={profile}
                my_profile={my_profile}
                userAdmin={currentUser.admin}
              />
            </Col>
          </Row>
        </Container>
      );
  };


  render() {
    const { userProfile } = this.props;
    if (!userProfile.loaded){
      return <div > LOADING </div>; // TODO
    } else if (userProfile.loaded && userProfile.error){
      // TODO
      return <div />
    }
    if (userProfile) {
      return this.renderProfileType();
    }
  }

}

const mapStateToProps = state => {
  return {
    auth: state.firebase.auth,
    currentUser: state.currentUser,
    userProfile: state.userProfile
  };
};

export default connect(
  mapStateToProps,
  { toggleAnonymous, getCurrentUser, getAnonymousProfile, followUser, unfollowUser, editUser }
)(PublicProfileAnonymous);
