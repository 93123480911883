const INITIAL_STATE = {
    notifications: [],
    badge: 0,
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'NOTIFICATIONS_FETCH':
            return {...state, notifications: action.notifications, badge: action.badge};
        case 'SET_BADGE_CLICKED':
                return {...state, badge: 0};
        default:
            return state;
    }
};