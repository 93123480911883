import _ from 'lodash';

export const getOtherUser = (room, currentUserId) => {
    if (room && room.users) {
      let other_user = "";
      var room_members = room.users;
      if (room.customData && room.customData.politicianblast){
        const { customData: { politicianId } } = room;
        var j = _.findIndex(room_members, function(o) { return o.id === politicianId; });
        other_user = room_members[j];
      } else {
        for (var i in room_members) {
          if (room_members[i].id !== currentUserId) {
            other_user = room_members[i];
          } else if (i === room_members.length - 1 && !other_user) {
            other_user = room_members[i]; // if no other user is current user
          }
        }
      }
      return other_user;
    }
    return '';
  };