import React from "react";
import { Modal, FormGroup, Label, Input, ModalFooter } from "reactstrap";
import Button from "../button";

const AddressInput = props => {
  const { open, value, onChange, toggle, submit } = props;
  return (
    <Modal
      isOpen={open}
      toggle={toggle}
      className="post-modal-dialog"
      backdrop={true}
    >
      <div className="allyus-form">
        <div className=" create-petition " style={{ backgroundColor: "white" }}>
          <button onClick={toggle} className="close-btn close">
              <span className="close" >x</span>
          </button>
          <FormGroup className="allyus-form-group">
            <Label for="imageurl">Full Address</Label>
            <Input
              onChange={onChange}
              name="image_link"
              value={value}
              placeholder="768 5th Ave, New York, NY 10019, USA"
              style={{
                borderTop: "none",
                borderLeft: "none",
                borderRight: "none"
              }}
            />
          </FormGroup>
        </div>
      </div>
      <ModalFooter>
        <Button
          className="allyus-btn allyus-primary-btn"
          click={submit}
        >
          {" "}
          done{" "}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddressInput;
