import React, { Component } from 'react';
import PostTarget from '../postTarget/index';
import PostHead from '../postHead/index';
import PostBody from '../postBody/index';
import AllyusPost from '../allyusPost/index';
import AllyusCardWrapper from '../../allyusCardWrapper/index';

import { getProfileNamePost, getProfilePicPost, getProfileUrl } from '../../../functions/profile';


export default class DiscussionCard extends Component {
  state = {
    moreComments: false,
    dropdownOpen: false,
    comments: [],
  }
  toggle = () => {
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen
    }));
  }
  handleCommentList = () => {
    this.setState(prevState => (
      {
        moreComments: !prevState.moreComments
      }
    ))
  }

  render() {
    const { active, currentUser } = this.props;
    const discussion  = this.props.post;
    const post = this.props.post;
    var my_post = (currentUser && post && post.user) && ((currentUser._id === post.user) || (currentUser._id === post.user._id)
    || (currentUser.uuid === post.user.uuid));
    my_post = my_post || (currentUser && currentUser.admin);
    return (
      <AllyusCardWrapper className={active ? "active-card" : ""}>
        <AllyusPost>
          <PostTarget
            targetName={getProfileNamePost(discussion)}
            targetLocation={discussion.location_city + ", " + discussion.location_region}
            targetImg={getProfilePicPost(discussion)}
            postTitle='Discussion'
            postDate={discussion.created_at}
            targetLink={getProfileUrl(discussion.user)}
            post={post}
          />
          <PostHead
            supportors={''}
            postTags={discussion.tags}
            postSummery={discussion.title}
            postType={'talk'}
            post_id={discussion._id}
            my_post={my_post}
            // deletePost={this.props.deletePost}
            post={post}
          />
          <PostBody
            postType={'talk'}
            postImg={discussion.image}
            postVedio={''}
            pollType={false}
            petition={''}
            post={this.props.post}
            postCommentsCount={discussion.no_of_comments}
            postUpvote={discussion.upvotes}
            postDownvote={discussion.downvotes}
            post_model="posts"
          />
        </AllyusPost>
      </AllyusCardWrapper>
    )
  }
}
