import React from 'react';
import AllyusCardWrapper from '../allyusCardWrapper/index';
import { AccordianHead, AccordianBody } from '../allyusAccordian/index';
import PostBody from '../Post/postBody/index';
import DeleteModal from '../deleteModal';
import MicrolinkCard from '@microlink/react';

class IssueCard extends React.Component {
    state = {deletePostModal: false};
    render() {
        const { toggleEditIssue, toggleAddIssue, isOpen, Id, issue,
        issue: { icon_image, title, description, news_link, legislation, upvotes, downvotes, reps, no_of_comments, comments_disabled }, 
        edit, name , itemId, toggle } = this.props;
        const {deletePostModal} = this.state;
        return (
            <AllyusCardWrapper className="allyus-post">
                <div className="issue" >
                    <AccordianHead itemId={Id} >
                        <div className="issue-head"  onClick={() => toggle(itemId)}>
                            <div className="title">
                               {!icon_image 
                               ? null : <img src={icon_image} alt="issue"  style={{marginRight:10}} /> 
                               }
                            <h3>{title}</h3>
                            </div>
                            { edit ? ( 
                                <div className="more ml-auto">
                                    <button onClick={toggleAddIssue}>
                                        <img src={`/icons/new/plus-icon.svg`} alt="add" /> &nbsp;
                                    <span className="allyus-text-secondery"> Add Issue </span>
                                    </button> &nbsp; &nbsp;
                                    <img src="/icons/new/edit-icon-p.svg" alt="edit" onClick={toggleEditIssue} /> &nbsp; &nbsp;

                                    {
                                        isOpen ? <i className="fa fa-angle-up icon"></i> :
                                            <i className="fa fa-angle-down icon"></i>
                                    }
                                </div>
                            ) : (
                                <div className="more ml-auto" >
                                {
                                    isOpen ? <i className="fa fa-angle-up icon"></i> :
                                        <i className="fa fa-angle-down icon"></i>
                                }
                                </div>
                            )}
                        </div>
                    </AccordianHead>
                    <AccordianBody>
                        <div className="issue-body">
                            <p className="mt-3 allyus-text">{description} </p>
                            {!legislation ? null : <p className=" mt-3 allyus-text-bold">{name} Endorses:</p>}
                            <p>{legislation}</p>

                            {
                                !news_link ? null :
                                <p className=" mt-3 allyus-text-bold">Related News Coverage:</p>
                            }
                            {
                                !news_link ? null :
                                <MicrolinkCard url={news_link} style={{marginBottom: "1rem",}} />
                            }

                            <PostBody
                                postType={'platform'}
                                postImg={''}
                                postVedio={''}
                                pollType={false}
                                petition={''}
                                post={issue}
                                postCommentsCount={issue.no_of_comments}
                                postUpvote={issue.upvotes}
                                postDownvote={issue.downvotes}
                                post_model={"politiciansplatform"}
                            />
                        </div>
                        { edit ? 
                            <div style={{textAlign: 'right'}}>
                                <span 
                                    className="secondary-color" 
                                    style={{cursor: 'pointer'}}
                                    onClick={() => this.setState({deletePostModal: !deletePostModal})}
                                    >
                                    <i class="far fa-trash-alt"></i>
                                </span>   
                            </div>
                        : null }
                    </AccordianBody>
                </div>
                <DeleteModal
                    isOpen={deletePostModal}
                    toggle={() => this.setState({deletePostModal: !deletePostModal})}
                    deleteFunction={() => this.props.deletePost(issue._id)}
                    deleteName="issue"
                    secondary={true}
                />
            </AllyusCardWrapper>

        )
    }
}

export default IssueCard;