import React, { Component } from "react";
import { Modal } from "reactstrap";
import Share from "./index";

import { connect } from "react-redux";
import { toggleShareModal, closeShareModal } from "../../../store/actions";

class ShareModal extends Component {
  render() {
    const { isOpen, url, toggleShareModal, closeShareModal } = this.props;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggleShareModal}
        className="share-dialog"
      >
        <button onClick={closeShareModal} className="close-btn close">
            <span className="close" >x</span>
        </button>
        <Share share_url={url} closeShareModal={closeShareModal} />
      </Modal>
    );
  }
}

const mapStateToProps = ({ share: { isOpen, url } }) => {
  return {
    isOpen,
    url
  };
};

export default connect(
  mapStateToProps,
  { toggleShareModal, closeShareModal }
)(ShareModal);
