import React from "react";
import ReactPlayer from "react-player";
import Helmet from "react-helmet";
export default class AllyusInfo extends React.Component {
  render() {
    return (
      <div>
        <br/>
        <div>
          Allyus was built in order to foster more transparent and accountable
          communication between government and citizens. Due to the recent
          outbreak of Covid-19 we have pivoted to provide a what we think is a
          more valuable public service. This is a short video which explains what we
          used to be and what we intend to continue to build after this pandemic
          ends. Thank you for helping us create a safer world and feel free to
          be in touch at info@allyus.org with and questions or concerns.
        </div>
        <br/>
        <br />
        <ReactPlayer
          url="https://www.youtube.com/watch?v=8-C_XuBHpxM"
          width={"100%"}
        />

      </div>
    );
  }
}
