const INITIAL_STATE = {
    owner_email: '',
    emails: [],
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    case 'SET_INVITES':
        return action.set_invites;
    case 'CLEAR_INVITES':
        return {emails: [], owner_email:''}
    default:
            return state;
    }
};