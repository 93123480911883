import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col, Input } from 'reactstrap';
import classnames from 'classnames';
import Comments from '../../comments/index';
import Demoghraphics from '../demoghraphics';
import Share from '../share';

import * as firebase from "firebase/app";
import axios from "axios";
import config from "../../../config";
import { getUserProfilePic, profilePicError } from '../../../functions/profile';
const { allyus_url } = config;

const TAB_ACTIVE_COMMENT = 1,
  TAB_ACTIVE_DEMOGRAPHICS = 2,
  TAB_ACTIVE_SHARE = 3;

class PollTabs extends React.Component {
  state = {
    activeTab: 1,
    newComment: '',
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

createCommentHandler = async (parent_id) => {
  var commentText = this.state.newComment;
  this.setState({newComment: ''});
  commentText = commentText.trim();
  if (commentText) {
    try {
      const access_token = await firebase.auth().currentUser.getIdToken(true);
      const newComment = {
        text: commentText,
        parent_id: parent_id ? parent_id : null
      };
      await axios.post(`${allyus_url}/${this.props.post_model}/${this.props.post_id}/comments?access_token=${access_token}`,
      newComment );
      this.props.getPostData();
    } catch (err){
      // console.log(err);
    }
  }
}

  renderCreateComment() {
    const item = this.props.currentUser;
    const img_style = item.influencer ? 'user-img-influencer' : item.lobbyist ? 'user-img-lobbyist' : item.user_politician ? 'user-img-politician'
    : item.organization ? 'user-img-organization' : 'user-img-voter';
    const auth = firebase.auth().currentUser;
    if (this.props.post && this.props.post.anon_comments_disabled && auth && auth.uid && this.props.currentUser && this.props.currentUser.anonymous){
      return (
        <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
          Anonymous comments disabled
        </div>
      );
    }
    else if (auth && auth.uid){
      return (
        <div className="allyus-new-comment">
          <div>
              <img
                  src={getUserProfilePic(this.props.currentUser)}
                  onError={e => profilePicError(e)} 
                  alt="profile-img"
                  className={`user-img ${img_style}`}
                />
            <img src="/images/arrow-down.png" alt="down-arrow" />
          </div>
          <Input // TODO move
            type="textarea"
            name="commentname"
            // id="commentname"
            style={{height:38}}
            placeholder="Comment..."
            value={this.state.newComment}
            onChange={(e) => {
              this.setState({ newComment: e.target.value })
            }}
          />
          <img src="/icons/icons8-email-send-32.png" alt="send" className="send-comment-icon" 
            onClick={() => this.createCommentHandler()}
          />
        </div>
      );
    }
    return (
      <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
        Log in to comment
      </div>
    );
  }

render() {
  const { show, comments, topComments, currentUser, postType, userVoted } = this.props;
  const auth = firebase.auth().currentUser;
  // if (postType !== 'platform' ) {
    return (
      <div className="allyus-post-tabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={classnames({ active: this.state.activeTab === TAB_ACTIVE_COMMENT })}
              onClick={() => { this.toggle(TAB_ACTIVE_COMMENT); }}
            >
              Comments
            </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === TAB_ACTIVE_DEMOGRAPHICS })}
                onClick={() => {this.toggle(TAB_ACTIVE_DEMOGRAPHICS); }}
              >
                Demographics
            </NavLink>
            </NavItem>
            {
              (postType === 'platform' ) ? null :
            <NavItem>
              <NavLink
                className={classnames({ active: this.state.activeTab === TAB_ACTIVE_SHARE })}
                onClick={() => { this.toggle(TAB_ACTIVE_SHARE); }}
              >
                Share
            </NavLink>
          </NavItem>
            }
        </Nav>
        <TabContent activeTab={this.state.activeTab}>
          <TabPane tabId={TAB_ACTIVE_COMMENT}>
            <Row>
              <Col sm="12">
                <div className="w-100 px-3">
                  <Comments {...this.props} />
                </div>
                {
                (postType === 'poll' || postType === 'petition') && !show?
                <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
                  Vote to comment
                </div>
                :
                this.renderCreateComment()
                }
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TAB_ACTIVE_DEMOGRAPHICS}>
            <Row>
              <Col sm="12">
                {
                   (postType === 'poll' || postType === 'petition') && !show && !currentUser.admin && !this.props.post.featured ?
                   <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
                    Vote to see demographics
                   </div>
                 : //auth && auth.uid ?
                   <Demoghraphics currentUser={currentUser}  demographics={this.props.demographics} upvotes={this.props.state_post.upvotes} downvotes={this.props.state_post.downvotes} neutralvotes={this.props.state_post.neutralvotes}  post={this.props.post} isPoll={(postType === 'poll' || postType === 'petition')} />
                // :    
                //   <div className="allyus-text" style={{textAlign:'center', margin:20, fontWeight:'bold'}}>
                //      Log in to see demographics
                //   </div>
                }
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId={TAB_ACTIVE_SHARE}>
            <Row>
              <Col sm="12">
                <Share share_url={this.props.share_url} />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    );
  // } 
  // else if (postType === 'platform') {
  //   return(
  //     <div>
  //         <div className="w-100 px-3">
  //         <Comments {...this.props} />
  //       </div>
  //       {this.renderCreateComment()}
  //     </div>
  //   );
  // }
  // else {
  //   return (
  //     <div className="w-100 px-3">
  //       <Comments className="mt-3"   {...this.props} comments={topComments} />
  //     </div>
  //   );
  // }

}

}
export default PollTabs;