import React from 'react';
import Button from '../../button/index';
import { Modal, ModalHeader, ModalFooter, ModalBody, Form, FormGroup, Label, Input, Col, Row, FormFeedback } from 'reactstrap';
import { Link } from "react-router-dom";
import ImageInput from "../../imageInput";

import { getProfileUrlCurrentUser } from '../../../functions/profile';

import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../../config';
const { allyus_url }= config;

export default class UserDetailCard extends React.Component {
    constructor(props) {
        super(props);
        const { profile } = props;
        this.state = { 
          username: profile.username,
          usernameModal: false,
          usernameTaken: false,
          anon_profile_picture: profile.anon_profile_picture,
          anon_cover_photo: profile.anon_cover_photo,
          editing_image: "",
          add_image: false,
          anon_profile_picture_file: null,
          anon_cover_photo_file: null,
         };    
    }
    checkUserName = (event) => {
        const value = event.target.value;
        this.setState({
            username: value
        });
        if (value.toLowerCase() !== this.props.profile.username.toLowerCase()){
            setTimeout(() => {
                axios.get(`${allyus_url}/users/username/${value}`).then(() =>{
                    this.setState({usernameTaken: true});
                }).catch((err) =>{
                    console.log(err);
                    // User does not exist
                    this.setState({usernameTaken: false});
                });
            }, 500);
        }

    }
    onSubmitUsername = async () => {
        const { username, usernameTaken, anon_profile_picture, anon_cover_photo, anon_profile_picture_file, anon_cover_photo_file } = this.state;
        var updatedProfile = { username };

        if (anon_profile_picture_file !== null){
            var storageRef = firebase.storage().ref();
            var file_name = this.props.profile.uuid;
            try {
              var ref = storageRef.child(`profile_pictures/${file_name}`)
              const snapshot = await ref.put(anon_profile_picture_file);
              const downloadURL = await snapshot.ref.getDownloadURL();
              updatedProfile.anon_profile_picture = downloadURL;
            } catch (err){
              console.log(err);
            }
          }
          if (anon_cover_photo_file !== null){
            var storageRef2 = firebase.storage().ref();
            var file_name2 = this.props.profile.uuid;
            try {
              var ref2 = storageRef2.child(`cover_photos/${file_name2}`)
              const snapshot2 = await ref2.put(anon_cover_photo_file);
              const downloadURL2 = await snapshot2.ref.getDownloadURL();
              updatedProfile.anon_cover_photo = downloadURL2;
            } catch (err){
              console.log(err);
            }
          }
          
        if ( username && !usernameTaken ) {
            this.props.editUser(updatedProfile);
            var profile_url = getProfileUrlCurrentUser({username: username}, true)
            this.props.history.push(profile_url);
        }

    }

    renderImageInput(image) {
        return (
          <ImageInput
            add_image={this.state.add_image}
            toggle={() => this.setState({ add_image: !this.state.add_image })}
            onChange={this.onChangeFile.bind(this)}
            value={this.state[image]}
          />
        );
      }
    
      onChangeFile = (e) => {
        const file = e.target.files[0];
        var state_file = [this.state.editing_image] + '_file';
        this.setState({[state_file]: file});
        var storageRef = firebase.storage().ref();
        var file_name = this.props.profile.uuid + '_temp';
        var ref = storageRef.child(`profile_pictures/${file_name}`)
        if (state_file.includes('cover_photo')){
            ref = storageRef.child(`cover_photos/${file_name}`);
        }
        ref.put(file).then((snapshot) => {
          snapshot.ref.getDownloadURL().then((downloadURL) => {
            this.setState({ [this.state.editing_image]: downloadURL, add_image:false});
          });
        }).catch(err => {
          console.log(err);
        });
      }

    render() {
        const {open, toggle} = this.props;
        const {anon_profile_picture, anon_cover_photo, editing_image} = this.state;
        return(
            <Modal isOpen={open} toggle={toggle} className="user-detail-modal-dialog">
                <ModalHeader toggle={toggle}>Edit Anon Profile</ModalHeader>
                <div class="allyus-cover-img">
                    <img src={anon_cover_photo} alt="coverphoto" className="cover" />
                    <Link
                    to="#"
                    className="edit"
                    onClick={() =>
                        this.setState({ add_image: true, editing_image: "anon_cover_photo" })
                    }
                    >
                    <img src="/icons/new/edit-icon-c.svg" alt="edit" />
                    </Link>
                </div>
                <div style={{ paddingBottom: 35 }}>
                    <div class="allyus-profile-img">
                    <img
                        src={anon_profile_picture}
                        alt="profilephoto"
                        className="profile"
                    />
                    <Link
                        to="#"
                        className="edit"
                        onClick={() =>
                        this.setState({
                            add_image: true,
                            editing_image: "anon_profile_picture"
                        })
                        }
                    >
                        <img src="/icons/new/edit-icon-c.svg" alt="edit" />
                    </Link>
                    </div>
                </div>
                <ModalBody>
                    <div className="allyus-form">
                        <Row form>
                            <Col md={12}>
                                <FormGroup className="allyus-form-group">
                                    <Label for="exampleEmail">Username</Label>
                                    <Input type="text" name="username" id="username" 
                                            value={this.state.username} onChange={this.checkUserName} invalid={this.state.usernameTaken} valid={!this.state.usernameTaken && this.state.username}
                                        />
                                    <FormFeedback valid>Great! that name is available</FormFeedback>
                                    <FormFeedback invalid>Sorry! that name is taken</FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button className="allyus-btn allyus-primary-btn" click={this.onSubmitUsername}
                    > save </Button>
                </ModalFooter>
                {this.renderImageInput(editing_image)}

            </Modal>
        );
    }
}