const INITIAL_STATE = {
    url: '${window.location.origin}',
    isOpen: false
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case 'OPEN_SHARE_MODAL':
            return {isOpen: true, url: action.url};
        case 'TOGGLE_SHARE_MODAL':
            return {...state, isOpen: !state.isOpen};    
        case 'CLOSE_SHARE_MODAL':
            return INITIAL_STATE;
        default:
            return state;
    }
};