import React from "react";
import { Modal, FormGroup, Label, Input } from "reactstrap";
import AllyusCard from "../allyusCard";
import Button from "../button";
import ReactGA from "react-ga";

import * as firebase from "firebase/app";
import axios from 'axios';
import config from '../../config';
const { allyus_url } = config;

export default class ReportBugModal extends React.Component {
  constructor(props) {
    super(props);
    const {currentUser} = props;
    this.state = {
      email: currentUser && currentUser.email ? currentUser.email : "",
      bug: "",
      copyright: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.isOpen &&  this.props.isOpen){
        ReactGA.modalview(window.location.pathname + '/reportbug');
    }
}

  onChange = e => {
    this.setState({
      [e.target.id]: e.target.value
    });
  };

  validateEmail(email) {
    var re = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+(?:[a-zA-Z]{2}|com|org|net|gov|info|ca|us|io)\b/;
    return re.test(email);
  }

  onSubmit = async e => {
    try {
      const {email, bug, copyright} = this.state;
      const {toggle} = this.props;
      const currentUser = firebase.auth().currentUser;

      if (email && bug && (this.validateEmail(email) || currentUser)) {
        var bugreport =  {email, description:bug, copyright};
        if (currentUser){
          const access_token = await currentUser.getIdToken(true);
          await axios.post(
            `${allyus_url}/bugreports?access_token=${access_token}`, bugreport
          );
        } else {
          await axios.post(
            `${allyus_url}/bugreports`, bugreport
          );
        }
        alert("Thank you for your report!");
        toggle();
        this.setState({email: '', bug: '', copyright:false});
      }
    } catch(err) {
      console.log(err);
    }
  }


  render() {
    const { toggle, isOpen } = this.props;
    const { email, bug, copyright } = this.state;
    const { onChange, onSubmit } = this;
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop={true}
        className="modal-style"
      >
        <AllyusCard title="Report Bug" className="signIn" isModal={true} onClose={toggle}>
          <div className="allyus-form button-group">
            <FormGroup className="allyus-form-group">
              <Label for="email">Email</Label>
              <Input
                id="email"
                type="email"
                value={email}
                onChange={onChange}
              />
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Label for="last_name">Bug Description</Label>
              <Input
                type="textarea"
                id="bug"
                name="bug"
                onChange={onChange}
                value={bug}
                style={{height:250}}
              />
            </FormGroup>
            <FormGroup>
            <Input 
                type="checkbox" 
                value={copyright}
                checked={copyright}
                onClick={() => this.setState({copyright:!copyright})}
                />
              <Label>Copyright Issue (please include link)</Label>
            </FormGroup>
            <FormGroup className="allyus-form-group">
              <Button
                id="submit"
                className="allyus-btn allyus-primary-btn signup-btn-m"
                onClick={onSubmit}
              >
                {" "}
                Submit{" "}
              </Button>
            </FormGroup>
          </div>
        </AllyusCard>
      </Modal>
    );
  }
}
