export const API_URL = 'http://localhost:4000/'

const ACTIONS = {
  USER_PROFILE_FETCH_SUCCESS : 'USER_PROFILE_FETCH_SUCCESS',
  LOG_OUT_PROFILE : 'LOG_OUT_PROFILE',
  TOGGLE_ANONYMOUS: 'TOGGLE_ANONYMOUS',

  OPEN_SHARE_MODAL: 'OPEN_SHARE_MODAL',
  CLOSE_SHARE_MODAL: 'CLOSE_SHARE_MODAL',
  TOGGLE_SHARE_MODAL: 'TOGGLE_SHARE_MODAL',

  NOTIFICATIONS_FETCH: 'NOTIFICATIONS_FETCH',
  BADGE_FETCH: 'BADGE_FETCH', // for notifications

  TOGGLE_MODAL: 'TOGGLE_MODAL',

  PROFILE_FETCH_SUCCESS: 'PROFILE_FETCH_SUCCESS',
  PROFILE_LOADING: 'PROFILE_LOADING',
  PROFILE_FETCH_ERROR: 'PROFILE_FETCH_ERROR',
}

export default ACTIONS;